import React, { useEffect, createContext, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { Admin } from '../../dto/admin.dto';
import { querify, validateResponse } from '../../scripts/functions';
import IndexedDbRepository from '../../scripts/indexDB/indexDB';
import RepositoryListener from '../../scripts/indexDB/repositoryListener';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import Header from '../header/header';
import SideBar from '../navbar/navbar';
import FallbackComponent from '../_utility-components/error-boundary';
import { NotFoundLocalApp } from '../_utility-components/not-found';
import Playground from '../_utility-components/playground';
import Analytics from './analytics';
import Clients from './clients/clients';
import Content from './content';
import Integrations from './integrations/integrations-wrapper';
import Marketing from './marketing';
import OrderPage from './orders/order-page';
import OrdersPage from './orders/orders';
import Categories from './products/categories';
import Product from './products/product';
import Products from './products/products';
import Sets from './sets/sets';
import ClientPage from './clients/client-page';
import Collections from './collections/collections';
import CollectionPage from './collections/collection-page';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { OrderStatus } from '../../dto/order-status.dto';
import { Paymethod } from '../../dto/paymethod.dto';
import { HeaderSidebarPopup } from '../header/header-sidebar-popup';
import Refunds from './orders/refunds/refunds';
import { Order } from './orders/dto/order.dto';
import SetPage from './sets/set-page';
import useMounted from '../../scripts/hooks/use-mounted';
import SettingsContextWrapper from './settings/settings-context-wrapper';

type IndexDbRepositories = 'orders' | 'admins' | 'status' | 'paymethod';

function MainPage () {
  const _isMounted = useMounted()
  const indexDbRepositories = useRef({
    orders: new IndexedDbRepository( 'id', 'orders' ),
    admins: new IndexedDbRepository( 'id', 'admins' ),
    status: new IndexedDbRepository( 'id', 'status' ),
    paymethod: new IndexedDbRepository( 'id', 'paymethod' ),
  })
  const _canStopLoading = useRef(true);
  const prefix = useAppSelector(state => state.staticValues.router_link_prefix || '')
  const integrations = useAppSelector(state => state.integrations);
  const moySklad = integrations.find(i => i.code === 'moy_sklad');

  const [pageLoaded, setPageLoaded] = useState(false);
  const dispatch = useDispatch();

  const adminDispatch = (admins: Admin[]) => dispatch({type: 'ADMIN', payload: admins})
  
  const setLoad = (bool: boolean) => dispatch({type: 'LOADING', payload: bool})
  const setCurrentPage = (text: string) => dispatch({type: 'CURRENT_PAGE', payload: prefix + '/' + text})
  const setOrdersCount = (count: number) => dispatch({type: 'ORDERS_COUNT', payload: count})
  const paymethodsDispatch = (paymethods: Paymethod[]) => dispatch({type: 'PAYMETHOD', payload: paymethods})
  const statusDispatch = (statuses: OrderStatus[]) => dispatch({type: 'ORDER_STATUSES', payload: statuses})
  // const paidStatusDispatch = (paidStatuses: PaidStatus[]) => dispatch({type: 'ORDER_PAID_STATUSES', payload: paidStatuses})

  useEffect(() => {
    getData()
    .finally(() => {
      if(_isMounted.current) {
        setPageLoaded(true)
        setLoad(false)
      }
    })
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelParentStoppingLoading = (bool: boolean) => _canStopLoading.current = bool

  const getData = async () => {
    return await Promise.all([
      getManagers(),
      getCount(),
      getOrderStatuses(),
      getPaymethods(),
    ])
  };

  const getManagers = () => {
    return mercheryFetch<Admin[]>('admin_user', 'GET')
    .then((res) => {
      _isMounted.current &&
      validateResponse(res) &&
      batch(() => {
        adminDispatch(res.records)
      })

      return res
    })
  }
  
  const getCount = () => {
    const orderFilters: Partial<Order> = {
      status: 1,
      complete: true 
    }   
    const query = querify({
      filters: orderFilters
    });
    
    return mercheryFetch<number>(`orders/count?${query}`, 'GET')
    .then((res) => {
      _isMounted.current &&
      validateResponse(res) &&
      setOrdersCount(res.records)

      return res
    })
  }

  const getPaymethods = () => {
    return mercheryFetch<Paymethod[]>('paymethod', 'GET')
    .then((res) => {
      _isMounted.current &&
      validateResponse(res) &&
      paymethodsDispatch(res.records)

      return res
    })
  }
  const getOrderStatuses = () => {
    return mercheryFetch<OrderStatus[]>('status', 'GET')
    .then((res) => {
      _isMounted.current &&
      validateResponse(res) &&
      batch(() => {
        statusDispatch(res.records)
      })
      return res
    })
  }
  // const getPaidStatuses = () => {
  //   mercheryFetch<PaidStatus[]>('orders/paidstatus', 'GET')
  //   .then((res) => 
  //   _mounted.current && 
  //     validateResponse(res) && 
  //     paidStatusDispatch(res.records)
  //   )
  // }

  const handleFindAll =   async( repository: keyof typeof indexDbRepositories.current) => await indexDbRepositories.current[repository].findAll()
  const handleDeleteAll = async( repository: keyof typeof indexDbRepositories.current) => await indexDbRepositories.current[repository].deleteAll()
  const handleMultiSave = async( repository: keyof typeof indexDbRepositories.current, data: any) => await indexDbRepositories.current[repository].multiSave( data )
  const handleAdd =       async( repository: keyof typeof indexDbRepositories.current, data: any) => await indexDbRepositories.current[repository].save( data )
  const handleDelete =    async( repository: keyof typeof indexDbRepositories.current, idToDelete: any) => await indexDbRepositories.current[repository].deleteById( idToDelete )
  const handleFind =      async( repository: keyof typeof indexDbRepositories.current, idToFind : any) => await indexDbRepositories.current[repository].findById( idToFind )
  
  const clearAndSet: ClertAndSetIndexDb = async (repository, data) => {
    await handleDeleteAll(repository);
    await handleMultiSave(repository, data)
    return true;
  }

  const pageProps: MainRouteChild = {
    cancelParentStoppingLoading,
    setCurrentPage,
    pageLoaded,
  }

  const indexDbProps = {
    onFind: handleFind,
    onAdd: handleAdd,
    onMultiAdd: handleMultiSave,
    onDelete: handleDelete,
    clearAndSet,
    onFindAll: handleFindAll,
  }

  return (
    <RepositoryListener>
        {pageLoaded ? <>
          <Route path={`${prefix}/`}
            render={(routeProps) => (
              <Header
                // {...routeProps}
                // {...pageProps} 
                // {...indexDbProps}
              />
            )}
          />

          <Route path={`${prefix}/`}
            render={(routeProps) => (
              <HeaderSidebarPopup/>
            )}
          />
          
          <div className="merchery-inner">
            <Route 
              path={`${prefix}/`} 
              component={SideBar} 
              {...pageProps} 
            />

            <div className="main-wrapper">
              <Switch>
                <Route exact path={`${prefix}/orders`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <OrdersPage
                      {...routeProps}
                      {...pageProps} 
                      {...indexDbProps}
                    />
                  )}
                />;

                <Route exact path={`${prefix}/refunds`} 
                  render={(routeProps) => (
                    <Refunds
                      {...routeProps}
                      {...pageProps} 
                      {...indexDbProps}
                    />
                  )}
                />;

                <Route path={`${prefix}/orders/:orderid/`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <OrderPage
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route exact path={`${prefix}/products`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <Products
                      {...routeProps}
                      {...pageProps} 
                    />
                )}/>

                <Route path={`${prefix}/categories`} 
                  render={(routeProps) => (
                    <Categories
                      {...routeProps}
                      {...pageProps} 
                    />
                )}/>

                {
                  moySklad?.turned_on ? null :
                  <Route 
                    path={`${prefix}/sets/:id`} 
                    render={(routeProps) => (
                      <SetPage
                        {...routeProps}
                        {...pageProps} 
                      />
                    )}
                  />
                }

                <Route path={`${prefix}/sets`} 
                  render={(routeProps) => (
                    <Sets
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                {
                  moySklad?.turned_on ? null :
                  <Route path={`${prefix}/collections/:id`} 
                    render={(routeProps) => (
                      <CollectionPage
                        {...routeProps}
                        {...pageProps} 
                      />
                    )}/>
                }

                <Route exact path={`${prefix}/collections`} 
                  render={(routeProps) => (
                    <Collections
                      {...routeProps}
                      {...pageProps}
                    />
                  )}/>

                <Route path={`${prefix}/products/create-product`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <Product
                      {...routeProps}
                      {...pageProps} 
                      {...indexDbProps}
                      pageState={('create' as const)}
                    />
                  )}/>

                <Route path={`${prefix}/products/:productid`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <Product
                      {...routeProps}
                      {...pageProps} 
                      {...indexDbProps}
                      pageState={('change' as const)}
                    />
                  )}/>

                <Route path={`${prefix}/clients/:id`} 
                  render={(routeProps) => (
                    <ClientPage
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route exact path={`${prefix}/clients/`} 
                  render={(routeProps) => (
                    <Clients
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route path={`${prefix}/marketing/`}
                  render={(routeProps) => (
                    <Marketing
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route path={`${prefix}/content/`}
                  render={(routeProps) => (
                    <Content
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route path={`${prefix}/analytics/`} 
                  render={(routeProps) => (
                    <Analytics
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                {/* <Route exact path={`${prefix}/settings/`} 
                  render={(routeProps) => (
                    <SettingsPage
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/> */}

                <SettingsContextWrapper
                  {...pageProps}
                />

                <Route path={`${prefix}/integrations/`} 
                  render={(routeProps) => (
                    <Integrations
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Route path={`${prefix}/playground/`} 
                  render={(routeProps) => (// @ts-ignore: Unreachable code error
                    <Playground
                      {...routeProps}
                      {...pageProps} 
                    />
                  )}/>

                <Redirect exact path={`${prefix}`} to={`${prefix}/orders`}/>
                <Route component={NotFoundLocalApp}/>
              </Switch>
            </div>
          </div>
        </> : null}
    </RepositoryListener>
  )
}

export type ClertAndSetIndexDb = (repository: IndexDbRepositories, data: any) => Promise<boolean>

export interface MainRouteChild {
  pageLoaded: boolean,
  pageState?: string,
  setCurrentPage: (name: string) => void,
  cancelParentStoppingLoading: (bool: boolean) => void,
}

export default withErrorBoundary(MainPage, {FallbackComponent: FallbackComponent});
import {ImageDeleteResponse, ImageExtended, ImageFile} from "../../../../../../dto/images/images.dto";
import {ExtractedLinks, extractImages} from "../../../../../../scripts/utils/extractImages";
import useMounted from "../../../../../../scripts/hooks/use-mounted";
import {useTabIndex} from "../../../../../../scripts/hooks/use-tabindex";
import {mercheryFetch, MyResponse} from "../../../../../../scripts/fetchConstructor";
import {validateResponse} from "../../../../../../scripts/functions";
import {batch} from "react-redux";
import {withLoader} from "../../../../../../scripts/with-loader";
import Picture from "../../../../../_utility-components/picture/picture";
import MyButton from "../../../../../_utility-components/button/button";
import MyDropzone from "../../../../../_utility-components/dropzone";
import React, {useEffect, useMemo, useState} from "react";
import UploadImage from "../../../../../../img/upload-image";
import QueryString from "qs";

function GetEmptyRender () {
	return <div className={'without-files'}>
		<div className="files-load-image">
			<UploadImage/>
		</div>
		<div className="files-load-image files-load-image__has-error">
			<UploadImage color='#4A5267'/>
		</div>
		<h4>Загрузить favicon</h4>
	</div>;
}

export function FaviconDropzone ({
	localEdit
}: {
	localEdit: boolean
}) {
	const _isMounted = useMounted()

	const [faviconData, setFaviconData] = useState<ImageExtended | null>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!faviconData) {
			const query = QueryString.stringify({
				module: 'Site',
				module_id: 2,
				main: true,
			})
			setLoading(true)

			mercheryFetch<ImageExtended[]>(`images?${query}`, "GET")
			.then((res) => {
				if (_isMounted.current && validateResponse(res)) {
					const image = res.records.at(0)
					image && setFaviconData(image)
				}
			})
			.finally(() => {
				setLoading(false)
			})
		}
	}, [])

	const sendImage = async (newFiles: ImageFile[]): Promise<MyResponse<ImageExtended, false>> => {
		const newImage = newFiles.at(0);
		setLoading(true)

		if (faviconData?.src) {
			await deleteImage()
		}

		const requestBody = {
			newFile: {
				...newImage,
				main: true
			},
		}

		return mercheryFetch<ImageExtended>(
			'images/create/favicon',
			'POST',
			requestBody,
			{ withoutToastUp: true }
		)
		.then((res) => {
			if (!_isMounted.current || !res.success) return res;

			const createdImage = res.records;
			createdImage && batch(() => {
				setFaviconData(createdImage)
			})

			return res
		})
		.finally(() => {
			setLoading(false)
		})
	}

	const deleteImage = async () => {
		if (!faviconData) return false;

		return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
			filters: {
				module: 'Site',
				module_id: 2
			}
		})
			.then(res => {
				if (!_isMounted.current || !validateResponse(res)) return false;

				batch(() => {
					setFaviconData(null)
				})
			})
	}

	const extractedImages = useMemo(() =>
		extractImages(faviconData, '')
	, [faviconData]);

	if(!localEdit && faviconData) {
		return null
		// return <div className="site-logo__inner site-logo__favicon">
		// 	<Picture
		// 		images={extractedImages || faviconData?.src}
		// 	/>
		// </div>
	}

	const fileHandler = (a: ImageFile[]) => sendImage((a))
	if(localEdit) {
		return (
			<div className="site-logo__favicon">
				<MyDropzone
					files={faviconData ? [faviconData] : []}
					optionalProps={{
						multiple: false,
						maxFiles: 1,
					}}
					disabled={loading}
					fileHandler={fileHandler}
					contentRender={
						<DropzoneContentWithLoader
							condition={!loading}
							extractedImages={extractedImages}
							deleteImage={deleteImage}
						/>
					}
					emptyRender={ <GetEmptyRender /> }
				/>
			</div>
		);
	}
	return null;
}

export function LogoDropzone ({
	localEdit
}: {
	localEdit: boolean
}) {
	const _isMounted = useMounted()
	const imageModule = 'Site';

	const [logoData, setLogoData] = useState<ImageExtended | null>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if(!logoData) {
			const query = QueryString.stringify({
				module: 'Site',
				module_id: 1,
				main: true,
			})
			setLoading(true)

			mercheryFetch<ImageExtended[]>(`images?${query}`, "GET")
      .then((res) => {
        if(_isMounted.current && validateResponse(res)) {
          const image = res.records.at(0)
          image && setLogoData(image)
        }
      })
      .finally(() => {
        setLoading(false)
      })
		}
	}, [])

	const sendImage = async (newFiles: ImageFile[]): Promise<MyResponse<ImageExtended[], false>> => {
		const newImage = newFiles.at(0);
		setLoading(true)

		if (logoData?.src) {
			await deleteImage()
		}

		const requestBody = {
			module_id: 1,
			newImages: [{
				...newImage,
				main: true,
			}],
			module: imageModule,
			cropSizes: ['original', 'medium', 'small'],
		}

		return mercheryFetch<ImageExtended[]>('images/create', 'POST', requestBody, {
			withoutToastUp: true
		})
    .then((res) => {
      if (!_isMounted.current || !res.success) {
				setLogoData(null)
        return res;
      }

      const createdImage = res.records.at(0);

      createdImage && 
      batch(() => {
        setLogoData(createdImage)
      })

			return res
    })
    .finally(() => {
      setLoading(false)
    })
	}

	const deleteImage = async () => {
		if (!logoData) return false;

		return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
			filters: {
				module: 'Site',
				module_id: 1,
			}
		})
    .then(res => {
      if (!_isMounted.current || !validateResponse(res)) return false;

      batch(() => {
        setLogoData(null)
      })
    })
	}

	const fileHandler = (a: ImageFile[]) => sendImage((a));

	const extractedImages = useMemo(() => {
		return extractImages(logoData, 'small')
	}, [logoData])

	if(!localEdit && logoData) {
		return <div className="site-logo__inner site-logo__dropzone">
			<Picture
				images={extractedImages || logoData?.src}
			/>
		</div>
	}

	if(localEdit) {
		return (
			<div className="site-logo__dropzone">
				<MyDropzone
					files={logoData ? [logoData] : []}
					optionalProps={{
						multiple: false,
						maxFiles: 1,
					}}
          isLoading={loading}
					fileHandler={fileHandler}
					contentRender={
						<DropzoneContentWithLoader
							extractedImages={extractedImages}
							deleteImage={deleteImage}
							condition={!loading}
						/>
					}
				/>
			</div>
		);
	}

	return null;
}

const DropzoneContentWithLoader: React.FC<{
	extractedImages: ExtractedLinks | null,
	deleteImage: () => void,
	condition: boolean
}> = withLoader(
	({
		 extractedImages,
		 deleteImage,
	 }) => {
		const tabIndex = useTabIndex();

		return !extractedImages ? null :
			<div className='site-logo__picture'>
				<Picture
					images={extractedImages}/>

				<MyButton
					removeDefaultClass
					className="new-loaded_file-close-btn"
					tabIndex={tabIndex}
					onClick={(e) => {
						e.stopPropagation();
						deleteImage();
					}}
					onKeyUp={(e) => e.key === 'Enter' ? deleteImage() : false}
				>
					<i className="icofont-close-line-circled"></i>
				</MyButton>
			</div>
	}
)
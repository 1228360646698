import React, { memo, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { num2str } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyCheckbox from '../../../../_utility-components/checkbox/my-checkbox';
import { OnCountHandler } from '../../../../_utility-components/input/input';
import { ProductVariantExtended } from '../../dto/variants.dto';
import { VariantLabelsControl } from './variant-labels-control';

interface Props {
  filteredVariants: ProductVariantExtended[],
  allVariantsCheckboxHandler: () => void
}

function SelectedVariantsController({
  filteredVariants,
  allVariantsCheckboxHandler
}: Props) {
  const variants = useAppSelector(state => state.productVariants)
  const topPanelOpened = useAppSelector(state=> state.topPanelShown);
  const selectedVariantsAttributes = useAppSelector(state => state.selectedVariantsAttributes);
  const tabIndexLevel = 1
  const selectedVariants = useMemo(() => 
    variants.filter(variant => 
      selectedVariantsAttributes.some(selectedAttrs => 
        variant.attributes.every(attr => 
          selectedAttrs.some(sva => 
            sva.attr === attr.attr && 
            sva.value_id === attr.value_id
          )
        )
      )
    ), [selectedVariantsAttributes, variants])

  const className = `table-item table-items-header variants-header-menu ${topPanelOpened ? 'after-top-panel' : ''}`;
  const checkboxCondition = selectedVariantsAttributes.length === filteredVariants.length;
  
  const commonVariant = useMemo(() =>
    getCommonVariant(selectedVariants)
    , [selectedVariants])
  
  const dispatch = useDispatch()
  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })

  const variantChanger = <T extends keyof ProductVariantExtended, >(value: ProductVariantExtended[T], label: T) => {
    
    let changedVariants = variants
      .filter(v => filteredVariants.some(fv => fv.id === v.id))
      .map(v => ({
        ...v,
        [label]: value
      }))

    batch(() => {
      variantsDispatch(
        variants.map(v => 
          changedVariants.find(chV => chV.id === v.id) || v
        )
      )
    })
  }

  const countHandler: OnCountHandler = (count) => variantChanger(count, 'remain')
  
  return (
    <div className={className}>
      <div className="op-check hide-for-print">
        <MyCheckbox
          condition={checkboxCondition} 
          actionHandler={allVariantsCheckboxHandler}        
        />
      </div>
      <div></div>
      <div className="op-variant">
        {num2str(selectedVariantsAttributes.length, ['вариант', 'варианта', 'вариантов'])}
      </div>
      
      <VariantLabelsControl
        variant={commonVariant}
        remainCount={commonVariant.remain}
        countHandler={countHandler}
        tabIndexLevel={tabIndexLevel}
        variantChanger={variantChanger}
      />
    </div>
  )
}

type VariantFields = {
  show_date: number | null;
  price: number;
  weight: number;
  external_id: string | null;
  barcode: string | null;
  remain: number
};

function getCommonVariant(variants: ProductVariantExtended[]): VariantFields {
  const defaultVariant = {
    show_date: new Date().getTime(),
    price: 0,
    weight: 0,
    external_id: null,
    barcode: null,
    remain: 0
  };
  if(!variants.length) {
    return defaultVariant
  }

  const commonVariant = { ...defaultVariant };

  for (let key in commonVariant) {
    let firstValue = (variants[0] as any)[key];
    console.log(firstValue)
    let isCommon = variants.every(variant => (variant as any)[key] === firstValue);

    if (!isCommon) {
      (commonVariant as any)[key] = (defaultVariant as any)[key];
    } else {
      (commonVariant as any)[key] = firstValue;
    }
  }

  return commonVariant;
}

export default memo(SelectedVariantsController);
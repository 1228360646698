import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import MyTable from 'src/components/_utility-components/common-table/table';
import { Id } from 'src/dto/master-dto/id.dto';
import { dateToRuLocale } from 'src/scripts/utils/date-to-locale';
import useSortableData, { SortableDataConfig } from 'src/scripts/hooks/use-sortable-data';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { priceWithPennies, priceWithStep, ptsansCurrency, querify, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import useRowClick from '../../../../scripts/hooks/use-row-click';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import FallbackComponent from '../../../_utility-components/error-boundary';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import { RefundFilterItem } from './dto/refund-filters.dto';
import { RefundQueryFilters } from './dto/refund-query-filters.dto';
import { RefundDto } from './dto/refund.dto';
import SetStatus from './refunds-modules/set-status';
import HeaderCell from "../../../_utility-components/common-table/header-cell";
import {HeaderRow} from "../../../_utility-components/common-table/header-row";
import {BodyCell} from "../../../_utility-components/common-table/body-cell";
import {TableBody} from "../../../_utility-components/common-table/table-body";
import {BodyRow} from "../../../_utility-components/common-table/body-row";

function RefundTable() {
  const refunds = useAppSelector(state => state.refunds);
  const refundFilters = useAppSelector(state => state.refundFilters);

  const searchInput = useAppSelector(state => state.ordersSearch)
  const notFirstRender = useRef(false);
  const _isMounted = useMounted();
  const dispatch = useDispatch();
  const location = useLocation<{config: SortableDataConfig<RefundDto>}>();

  const [loading, setLoading] = useState(false);

  const defaultSorting = {
    key: "order_id",
    direction: "descending",
  }
  const refundsDispatch = (refunds: RefundDto[]) => dispatch({ type: 'ORDERS_REFUNDS', payload: refunds });
  const [ tableRefunds, requestSort, config ] = useSortableData([...(refunds || [])].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()), location.state?.config || defaultSorting);
  const tabIndex = useTabIndex();

  const linkProps = (orderId: Id) => {
    return ({
      to: `/app/orders/${orderId}`,
      state: { config: config }
    })
  }

  const [rowClick, setActiveRowId] = useRowClick(linkProps, [
    '.refund-table__btn-wrapper', 
  ])
  // const { focusedItem, focusHandler, } = useRowFocus<RefundDto>(null);

  useEffect(() => {
    getRefunds()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const plainedFilters = useMemo(() => 
    refundFilters ?
      Object.fromEntries(
        (Object.entries(refundFilters) as [key: string, value: RefundFilterItem[]][])
        .filter(([key, value]) => value.filter(v => v.selected).length)
        .map(([key, value]) => [key, value.flatMap(item => item.selected ? item.id : [])])
      )
    : undefined, [refundFilters]
  );
  
  useEffect(() => {
    if(plainedFilters) {
      if(notFirstRender.current || searchInput) {
        getRefunds({
          filters: plainedFilters,
          ...(searchInput && { search: searchInput })
        })
      }

      notFirstRender.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, plainedFilters])

  const getRefunds = (filters?: RefundQueryFilters) => {
    const query = filters ? '?' + querify(filters) : '';

    setLoading(true)

    mercheryFetch<RefundDto[]>(`orders/refund${query}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      refundsDispatch(res.records)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const changeRefund = (id: Id, changes: Partial<RefundDto>) => {
    if(!refunds) {
      return false
    }
    mercheryFetch<RefundDto[]>('orders/refund', 'PATCH', {
      changes: [{
        id: id,
        ...changes
      }]
    })
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        const updatedRefunds = res.records
        const refundsWithUpdated = refunds.map((r) => updatedRefunds.find(ur => ur.id === r.id) || r)
        
        refundsDispatch(refundsWithUpdated)
      }
    })
  }

  if(loading) {
    return <CommonTableLoader/>
  }

  if(!tableRefunds.length) {
    return <>
      Возвратов с заданными фильтрами не найдено
    </>
  }

  return (
    <MyTable id={'refunds-table'} myClassName={'refunds-table'}>

      <HeaderRow className='refund-table__cells-template'>
        <HeaderCell children={'заказ'} label={'order_id'} config={config} handler={requestSort} className='center-align' />
        <HeaderCell children={'получен'} label={'order_recieve_date'} config={config} handler={requestSort} className='center-align'/>
        <HeaderCell children={'возвращен'} label={'refund_date'} config={config} handler={requestSort} className='center-align'/>
        <HeaderCell children={'покупатель'} label={'client_id'} config={config} handler={requestSort}/>
        <HeaderCell children={'сумма возврата'} label={'refund_total'} config={config} handler={requestSort} className='right-align'/>
        <HeaderCell children={'сумма заказа'} label={'order_total'} config={config} handler={requestSort} className='right-align'/>
        <HeaderCell children={'Статус'} label={'status'} config={config} handler={requestSort} className='center-align'/>
      </HeaderRow>

      <TableBody>
        {tableRefunds.map(refund => {
          // const focused = focusedItem && focusedItem.id === refund.id ? 'focused' : '';

          const statusHandler = (statusId: Id) => 
            changeRefund(refund.id, { status_id: statusId });

          const refundTotal = priceWithStep( priceWithPennies(refund.refund_total) );
          const orderTotal = priceWithStep( priceWithPennies(refund.order_total) );

          return (
            <BodyRow 
              key={refund.id} 
              className={`refund-table__cells-template`}
              tabIndex={tabIndex}
              // onFocus={() => focusHandler(refund.id, refunds)} 
              onMouseUp={(e) => rowClick(e, refund.order_id, refund.id)}
              onMouseDown={() => _isMounted.current && setActiveRowId(refund.id)}
              onKeyUp={(e) => e.key === 'Enter' ? rowClick(e, refund.order_id, refund.id) : null}
            >
              <BodyCell myClassName='center-align bold'>{refund.order_id}</BodyCell>
              
              <BodyCell myClassName='center-align'>
                {refund.order_recieve_date ? 
                  dateToRuLocale(refund.order_recieve_date) 
                :
                  '—'
                }
              </BodyCell>
              
              <BodyCell myClassName='center-align'>
                {refund.refund_date ? 
                  dateToRuLocale(refund.refund_date) 
                : 
                  '—'
                }
              </BodyCell>
              
              <BodyCell>{refund.client?.name || "Данные о клиенте не найдены"}</BodyCell>

              <BodyCell myClassName='right-align'>
                {refundTotal} {ptsansCurrency('₽')}
              </BodyCell>

              <BodyCell myClassName='right-align'>
                {orderTotal} {ptsansCurrency('₽')}
              </BodyCell>

              <BodyCell myClassName='center-align refund-table__btn-wrapper'>
                <SetStatus
                  tabIndex={-1}
                  btnClassName='refund-status__cell-btn header-font-xxs' 
                  refund={refund} 
                  statusHandler={statusHandler}
                />
              </BodyCell>
            </BodyRow>
          )
        })}
      </TableBody>

    </MyTable>
  );
}

export default withErrorBoundary(RefundTable, {FallbackComponent: FallbackComponent});
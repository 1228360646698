import React from 'react';
import { FilterTab, FilterTabRules } from './filter-tab';
import { RefundFilterChanger } from '../refunds-header';

interface Props {
  filterChanger: RefundFilterChanger
}

function RefundSearchFilters({
  filterChanger,
}: Props) {
  const tabHandler = (rules: FilterTabRules, concreteSelected: boolean) => 
    filterChanger({ 
      itemIds: rules[0],
      filterName: rules[1], 
      concreteSelected, 
      cleanPrev: true, 
      cleanOthers: true
    });
  
  return (
    <div className="search-close-bar-btns">
      <FilterTab
        handler={(rules: FilterTabRules) => tabHandler(rules, false)}
        text={'все'} 
        rules={[[], undefined]}
      />

      <FilterTab handler={(rules: FilterTabRules) => tabHandler(rules, true)} 
        text={'новые'} 
        rules={[[1], 'status_id']}
      />
      
      <FilterTab handler={(rules: FilterTabRules) => tabHandler(rules, true)} 
        text={'проведенные'} 
        rules={[[2], 'status_id']}
      />
    </div>
  );
}

export default RefundSearchFilters;
import React, {createContext, useCallback, useMemo, useRef, useState} from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useInfiniteScrollShowMore from 'src/scripts/hooks/use-infinite-scroll-show-more';
import {CategoryId, Id} from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { uniqByKeepLast, validateResponse } from '../../../../scripts/functions';
import AddItemsLoader from '../../../_utility-components/loaders/add-items-loader';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import { ExtendedProduct, ProductReorder } from '../dto/products.dto';
import LoadMore from './load-more-btn';
import ProductsTableBody from './table-body/table-body';
import ProductsTableHeader from './table-header/table-header';
import useProductsFilters from "../hooks/use-products-filters";
import useInitFilters, { EntityGetter, entityGetterQuery } from "src/scripts/hooks/use-init-filters"; 
import {AppliedFilters} from "../../../_utility-components/applied-filters";
import SearchBar from "./search";
import {ProductFilters} from "../dto/products-filters.dto";
import {FilterChangerProps, FilterItem} from "../../../../scripts/utils/entity-filters";
import useProductsChangesListener from "../../../../ws-listeners/products-changes.listener";
import PageIsOutOfDate from "../../../_utility-components/page-is-out-of-date/page-is-out-of-date";
import sortExtendedProducts from "../products.utils";
import MultiChangeCompletePopup from './multi-change-complete-popup';

const productsItemsGetSize = 25;

export type MultiProductsActionCompletedParams = null | {
  filters: FilterChangerProps<ExtendedProduct>[],
  completeText: string,
}

export type ProductsOrderingMode = 'category' | 'mainPage';

export const ProductsTableContext = createContext<{
  sortedProducts: ExtendedProduct[],
  uniqueCategories: CategoryId[],
  disableDraggable: boolean,
  multiProductsActionCompletedParams: MultiProductsActionCompletedParams,
  setMultiProductsActionCompletedParams: (params: MultiProductsActionCompletedParams) => void,
  applyFilters: (...changes: FilterChangerProps<ExtendedProduct>[]) => void,
  newProducts: Set<Id>
  setNewProducts: (newProducts: Set<Id>) => void,
  orderingMode: ProductsOrderingMode,
  setOrderingMode: (mode: ProductsOrderingMode) => void,
}>({
  sortedProducts: [],
  uniqueCategories: [],
  disableDraggable: false,
  multiProductsActionCompletedParams: null,
  setMultiProductsActionCompletedParams: () => {},
  applyFilters: () => {},
  newProducts: new Set([]),
  setNewProducts: () => {},
  orderingMode: 'category',
  setOrderingMode: () => {},
})

export default function ProductsTable() {
  const _isMounted = useRef(true);

  const products = useAppSelector(state => state.products);
  const selectedProducts = useAppSelector(state => state.selectedProducts);
  const currentProductsCount = useAppSelector(state => state.currentProductsCount);
  const allProductsCount = useAppSelector(state => state.allProductsCount);
  const searchInput = useAppSelector(state => state.searchInput);

  const uniqueCategories = useMemo(() => [...new Set(products.map(a => a.top))], [products]);
  const sortedProducts = useMemo(() => sortExtendedProducts(products), [products]);

  const [productFilters, updateFilters] = useProductsFilters();

  const [loading, setLoading] = useState(false);
  const [multiProductsActionCompletedParams, setMultiProductsActionCompletedParams] = useState<MultiProductsActionCompletedParams>(null);
  const [newProducts, setNewProducts] = useState<Set<Id>>(new Set([]));
  const [orderingMode, setOrderingMode] = useState<ProductsOrderingMode>('category');

  const dispatch = useDispatch();
  const searchInputDispatch = (text: string) => dispatch({ type: 'PRODUCTS_SEARCH_INPUT_VALUE', payload: text});
  const productsDispatch = useCallback((items: ExtendedProduct[]) => dispatch({ type: 'PRODUCTS', payload: items}), [dispatch]);
  const selectedProductsDispatch = useCallback((ids: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS', payload: ids }), [dispatch]);
  const productsCountDispatch = useCallback((count: number) => dispatch({ type: 'PRODUCTS_COUNT', payload: count}), [dispatch]);
  const allProductsCountDispatch = useCallback((count: number) => dispatch({ type: 'PRODUCTS_ALL_COUNT', payload: count}), [dispatch]);
  const reorderStatusDispatch = useCallback((bool: boolean) => dispatch({ type: 'REORDER_SAVING_iN_PROCESS', payload: bool}), [dispatch]);

  const [reorderChangesInQueue, applyRenderChanges] = useProductsChangesListener()

  const getProducts: EntityGetter<{clearCount?: boolean}> = ({
    filters, 
    clearCount
  }) => {
    const queryFilters = entityGetterQuery({
      ...(filters && {
        filters,
      }),
      ...(searchInput && {
        search: searchInput,
      }),
      pageSize: productsItemsGetSize
    })

    setLoading(true)

    mercheryFetch<ExtendedProduct, true>(`products?${queryFilters}`, "GET")
    .then(res => {
      if(!_isMounted.current || !validateResponse<ExtendedProduct, true>(res)) {
        return false
      }

      const gotProducts = (clearCount 
        ? res.records.rows 
        : uniqByKeepLast<ExtendedProduct>([...sortedProducts, ...res.records.rows], (p) => (p.id))
      ) || [];

      const count = res.records.count;

      batch(() => {
        productsDispatch(gotProducts)
        selectedProductsDispatch(
          selectedProducts.filter(id =>
            gotProducts.some(product => product.id === id)
          )
        )
        productsCountDispatch(gotProducts?.length || 0)
        allProductsCountDispatch(count || 0)
      })
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const [ hasMore, showMore ] = useInfiniteScrollShowMore({
    getItems: getProducts,
    currentCount: currentProductsCount,
    getSize: productsItemsGetSize,
    allCount: allProductsCount,
  })

  useInitFilters(
    productFilters,
    searchInput,
    searchInputDispatch,
    (filterArgs) => getProducts({...filterArgs, clearCount: true}), 
    updateFilters,
  )

  const nonCategoryFiltersActive = useMemo(() => {
    if(!productFilters) {
      return false
    }
    const filtersCopy: ProductFilters = JSON.parse(JSON.stringify(productFilters));

    delete filtersCopy.top;

    const filtersValues = Object.values(filtersCopy) as (FilterItem[] | undefined)[]
    const isSelectedNonCategoryFilters = filtersValues.some(item =>
      item && item.some(value => value.selected)
    )
    return isSelectedNonCategoryFilters;
  }, [productFilters])

  const disableDraggable = useMemo((() => {
    return Boolean(nonCategoryFiltersActive || reorderChangesInQueue.length)
  }), [nonCategoryFiltersActive, reorderChangesInQueue])

  const reorderRequest = (requestOptions: ProductReorder) => {
    reorderStatusDispatch(true)

    mercheryFetch<ExtendedProduct[]>('products/reorder', 'PATCH', {
      ...requestOptions,
      filters: {},
    })
    .then(res => {
      if (!_isMounted.current || !validateResponse(res)) {
        throw Error;
      }

      const updatedProducts =
        [...sortedProducts]
        .map(product => 
          res.records.find(record => 
            record.id === product.id)
          || product
        )

      productsDispatch(updatedProducts);
    })
    .finally(() => {
      reorderStatusDispatch(false);
    });
  }

  const onDragStart = () => {
    hideVariantsWhileReordering()
  }

  const hideVariantsWhileReordering = () => {
    const elements = document.getElementsByClassName('product-variants-rows-wrapper') as HTMLCollectionOf<HTMLDivElement>;
    for(let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
  }

  const showVariantsAfterReordering = () => {
    const elements = document.getElementsByClassName('product-variants-rows-wrapper') as HTMLCollectionOf<HTMLDivElement>;
    for(let i = 0; i < elements.length; i++) {
      elements[i].style.removeProperty('display');
    }
  }

  const onDragEnd = (info: DropResult) => {
    showVariantsAfterReordering()

    const { destination, source } = info;
    const topFound: string | undefined = info.type.split('-').at(-1);

    if (!topFound ||
      !destination ||
      source.index === destination.index ||
      destination?.index === undefined
    ) {
      return;
    }

    const top = +topFound;
    const filtered = sortedProducts.filter(p => p.top === top)

    const newList = [...filtered];
    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);

    const productsByCategoryWithNewTop = newList.map((item, index) => ({...item, order: index + 1}))
    const shuffledProducts =
      products.map(product =>
        productsByCategoryWithNewTop.find(sortedProduct =>
          product.id === sortedProduct.id
        ) || product
      )

    productsDispatch(shuffledProducts);

    const requestOptions: ProductReorder = {
      id: +info.draggableId,
      difference: destination.index - source.index,
    };
    
    reorderRequest(requestOptions)
  }

  const applyFilters = (...changes: FilterChangerProps<ExtendedProduct>[]) => {
    updateFilters(...changes)
  }

  return (
    <ProductsTableContext.Provider value={{
      sortedProducts,
      uniqueCategories,
      disableDraggable,
      multiProductsActionCompletedParams,
      setMultiProductsActionCompletedParams,
      applyFilters,
      newProducts,
      setNewProducts,
      orderingMode, 
      setOrderingMode,
    }}>
      <div className='products-table__wrapper'>
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          <MultiChangeCompletePopup/>

          <SearchBar />

          <AppliedFilters<ExtendedProduct>
            filterNames={['top', 'show_date', 'brand']}
            filters={productFilters}
            updateFilters={updateFilters}
          />

          {loading ?
            <CommonTableLoader />
          :
            <>
              <InfiniteScroll
                dataLength={products.length}
                next={showMore}
                hasMore={Boolean(hasMore)}
                loader={Array(productsItemsGetSize).map(() => <AddItemsLoader/>)}
                scrollableTarget={'table-items'}
              >
                {products.length ?
                  <div id='table-items' className="table-items">
                    <ProductsTableHeader/>

                    <ProductsTableBody/>
                  </div>
                :
                  <div>
                    Нет товаров с заданными критериями
                  </div>
                }
              </InfiniteScroll>

              <LoadMore
                showMoreProducts={showMore}
              />
            </>
          }
        </DragDropContext>

        {reorderChangesInQueue.length ?
          <PageIsOutOfDate
            condition={Boolean(reorderChangesInQueue.length)}
            actionHandler={applyRenderChanges}
          />
        : null}

      </div>
    </ProductsTableContext.Provider>
  )
}
import React, { memo } from 'react';
import { useOrderDisabled } from '../../hooks/use-order-disabled';
import MyButton from '../../../../_utility-components/button/button';
import { Order } from '../../dto/order.dto';
import { OrderChangeHandler } from '../../order-page';
import withAddItemPopup from '../../order-page-popups/add-item-popup';
import { CanBeAddedProduct } from '../../order-page-popups/can-be-added-product.dto';
import ProductToBeAddedInOrder from '../../order-page-popups/product-row-to-be-added';
import Discount from '../popups/discount';
import OrderTotalPrice from '../popups/order-total-price';
import Promo from '../promo';

interface Props {
  order: Order,
  changeData: OrderChangeHandler,
  setAddItemPopupOpen: (bool: boolean) => void
}

function ItemsListFooter({
  order,
  changeData,
  setAddItemPopupOpen
}: Props) {
  const [disabled, {disabledByStatus}] = useOrderDisabled(order)
  const disableText = disabledByStatus ? `Заказ завершен` : `Пока невозможно редактировать заказ`;

  return (
    <div className={`op-footer ${disabled ? 'op-footer--disabled' : ''}`}>
      {!disabled ? 
        <>
          <div className="op-footer-btns hide-for-print">
            <MyButton
              className="order-page-btn hide-for-print" 
              onClick={() => setAddItemPopupOpen(true)}
            >
              + Добавить товар
            </MyButton>

            <Discount
              changeData={changeData}
            />

            <Promo 
              changeData={changeData} 
              />
          </div>

          <OrderTotalPrice/>
        </> 
      : 
        <>
          <div className='text-font-s'>
            {disableText}  
          </div>
          
          <OrderTotalPrice/>
        </>
      }


    </div>
  );
}

export const AddItemsInOrder = withAddItemPopup<CanBeAddedProduct>(ProductToBeAddedInOrder)

export default memo(ItemsListFooter);
import React, { useContext } from 'react';
import MyButton from '../../../../_utility-components/button/button';
import { OrderContext } from '../../order-page';
import { OrderDeliveryContext } from './delivery';
import { OrderDeliveryChangeStateSwitcher } from './order-delivery-change-state-switcher';
import { OrderDeliveryStatus } from './order-delivery-status';

interface Props {
  changeDeliveryState: boolean;
  toInitial: () => void;
  safeHandler: () => Promise<false | undefined>;
}

export function OrderDeliveryHeader({ 
  changeDeliveryState, 
  toInitial, 
  safeHandler, 
}: Props) {

  const {
    order,
  } = useContext(OrderContext);

  const {
    delivery,
  } = useContext(OrderDeliveryContext);

  if(!order) {
    return null
  }

  return <div className="op-delivery-top header-font-m sticky-header">
    <span>Отгрузка и доставка</span>

    {changeDeliveryState && delivery ? (
      <div className="op-delivery-change-btns">
        {delivery.type ? <>
          <MyButton
            className="white-btn"
            onClick={toInitial}>
            Отменить
          </MyButton>

          <MyButton
            id="merchery-btn"
            className="blue-btn"
            onClick={safeHandler}>
            Сохранить
          </MyButton>
        </> : null}
      </div>
    ) : !delivery?.note ?
      <OrderDeliveryChangeStateSwitcher/>
    : <OrderDeliveryStatus/>}
  </div>;
}

import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import omit from 'lodash/omit'

import { ExtendedProduct } from '../../components/main-pages/products/dto/products.dto';
import { useAppSelector } from '../pre-type/use-selector';
import { ProductOption, ProductsAttrValues } from '../../components/main-pages/products/product-page-modules/dto/options.dto';
import { ProductVariantExtended } from '../../components/main-pages/products/dto/variants.dto';
import { Id } from '../../dto/master-dto/id.dto';
import { differenceBy } from 'lodash';
import {MutableRefObject} from "react";

export type UpdateItem<T> = Partial<T> & {id: Id}
export type WithOptionId<T> = T & {optionId: Id | null}
// export type ReorderItem = {id: Id}

export interface CompareResult<T extends {id: Id}> {
  updated: UpdateItem<T>[], 
  added: T[], 
  removed: T[], 
  // reordered: ReorderItem[] 
}

/**
 * Compare two arrays and sort their elements into three categories: updated, added, removed
 * @param {T[]} [init] - Original array
 * @param {T[]} actual - Updated array
 * @param {(keyof T[])} [leaveInitFields] - In updated array fields from leaveInitFields taken from init array
 * @returns {{ updated: T[], added: T[], removed: T[] }} An object containing three arrays: updated, added, removed
 */
function compareArrays<T extends {id: Id}>({ 
  init, 
  actual, 
  leaveInitFields,
  orderField,
}: { 
  init: T[]; 
  actual: T[];
  leaveInitFields?: Array<keyof T>; 
  orderField: keyof T;
}): CompareResult<T> {
  const updated: UpdateItem<T>[] = [];

  const added = differenceBy(actual, init, 'id');
  const removed = differenceBy(init, actual, 'id');

  init.forEach((initElement) => {
    const actualElement: T | undefined = find(actual, ['id', initElement.id]); //Find the corresponding item in 'actual' array based on 'id'
    if(actualElement) {
      const updatedKeys = (Object.keys(initElement) as Array<keyof T>).reduce((result, key) => {
        if (!isEqual(initElement[key], actualElement[key])) {
          result[key] = actualElement[key]; // If there is a difference, set the new value
        }
        return result;
      }, {} as Partial<T>);
      if (Object.keys(updatedKeys).length > 0) {
        updated.push({ 
          id: initElement.id, 
          ...(leaveInitFields && Object.fromEntries(leaveInitFields.map(field => [field, initElement[field]]))),
          ...updatedKeys 
        }); // Push the updated item to 'updated' array
      }
    }
  });

  return { 
    updated, 
    added, 
    removed, 
    // reordered 
  };
}

export function useProductComparison(initProduct: MutableRefObject<ExtendedProduct | undefined>) {
  const product = useAppSelector((state) => state.product);
  const options: ProductOption[] = useAppSelector((state) => state.productOptions);
  const initOptions: ProductOption[] = useAppSelector((state) => state.initOptions);
  const variants: ProductVariantExtended[] = useAppSelector((state) => state.productVariants);
  const initVariants: ProductVariantExtended[] = useAppSelector((state) => state.initVariants);
  const orderField: keyof ExtendedProduct = 'category_order';
  const variantOrderField: keyof ProductVariantExtended = 'order';
  const optionsOrderField: keyof ProductOption = 'order';
  const optionsValuesOrderField: keyof ProductsAttrValues = 'order';

  const compareProducts = () => product && initProduct.current && compareArrays({ init: [omit(initProduct.current, ['src'])], actual: [omit(product, ['src'])], orderField });
  const compareOptions = () => { 
    const comparedOptions = compareArrays({ init: initOptions.map(o => omit(o, ['values'])), actual: options.map(o => omit(o, ['values'])), orderField: optionsOrderField })
    return comparedOptions
  };
  const compareVariants = () => compareArrays({ init: initVariants, actual: variants, orderField: variantOrderField });
  const compareOptionsValues = (): CompareResult<WithOptionId<ProductsAttrValues>> => {
    const initVals = flatMap(initOptions, option => option.values.map(value => ({...value, optionId: option.notCreated ? null : option.id})));
    const actualVals = flatMap(options, option => option.values.map(value => ({...value, optionId: option.notCreated ? null : option.id})));
    return compareArrays({ init: initVals, actual: actualVals, leaveInitFields: ['optionId'], orderField: optionsValuesOrderField });
  };

  return { compareProducts, compareOptions, compareVariants, compareOptionsValues };
}
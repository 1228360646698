import React, { useEffect } from 'react';
import MyInput from '../../../_utility-components/input/index';
import { Client } from '../dto/client.dto';
import { useDispatch } from 'react-redux';
import { LoyaltyDto } from '../dto/client-loyalty.dto';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { findRangeOfLoyalty, priceWithPennies, priceWithStep } from 'src/scripts/functions';
import { LoyaltySettingsBuyoutRanges } from '../dto/loyalty-range.dto';
import { dateToRuLocale } from '../../../../scripts/utils/date-to-locale';
import { Id } from '../../../../dto/master-dto/id.dto';

interface LoyaltyCardProps {
  changeClient: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  client: Client;
}

export function LoyaltyCard({ 
  changeClient, 
  client 
}: LoyaltyCardProps) {
  const ranges = useAppSelector(state => 
    state.settings.find(s => s.callname === 'client_loyalty_ranges')
  )
  
  const loyalty = useAppSelector(state => state.clientLoyalty)

  const dispatch = useDispatch();
  const loyaltyDispatch = (loyalty: LoyaltyDto | null) => dispatch({ type: 'CURRENT_CLIENT_LOYALTY', payload: loyalty});

  useEffect(() => {
    return () => {
      loyaltyDispatch(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loyaltyDispatch(client.loyalty)
  }, [client.loyalty])

  const createNewLoyalty = (clientId: Id, loyalty?: string): LoyaltyDto => {
    return {
      client_id: clientId,
      loyalty_card: loyalty || '',
      buyout_range: 0,
      bonus_points: 0,
      register_date: null,
    }
  }

  const cardChanger = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    const changedLoyalty: LoyaltyDto = 
      !loyalty ? 
        createNewLoyalty(value) 
      : {
        ...loyalty, 
        loyalty_card: value
      }

    loyaltyDispatch(changedLoyalty)
  }

  const loyaltyRange = loyalty?.buyout_range && ranges?.value ? 
    findRangeOfLoyalty(loyalty.buyout_range, (ranges.value as unknown as LoyaltySettingsBuyoutRanges)) 
    : null;
  const discountByRange = loyaltyRange ? loyaltyRange.discount : null;

  const nextRangeRequirement = loyalty?.buyout_range && ranges?.value ? 
    findRangeOfLoyalty(loyalty.buyout_range + 1, (ranges.value as unknown as LoyaltySettingsBuyoutRanges))?.to 
    : null 

  const registerDate = loyalty?.register_date ? `Зарегистрирована ${dateToRuLocale(loyalty.register_date)}` : '';
  const currentDiscountByLoyalty = discountByRange !== null ? `Скидка по карте ${discountByRange}%` : 'Нет скидки';
  const beforeNextBuyoutRangeText = loyalty?.buyout_range && nextRangeRequirement && client.ordersTotal ?
    `до следующего уровня ${priceWithStep(
      priceWithPennies(
        nextRangeRequirement - client.ordersTotal
      )
    )}` 
  : 'без уровня';

  return <div className='client-summary__grid-element'>
    <h4 className='header-font-s'>
      Карта лояльности
    </h4>

    <MyInput myClassName={'grid-element'}
      name={'loyalty'}
      required={false}
      onChange={cardChanger}
      value={loyalty?.loyalty_card || ''} 
    />

    <div 
      className='text-font-s'
      style={{ 
        marginTop: -4 
      }}
    >
      {registerDate}
    </div>

    <div 
      className='header-font-m'
      style={{ 
        marginTop: 24, 
        marginBottom: -4 
      }}
    >
      {currentDiscountByLoyalty}
    </div>

    <div className='text-font-s'>
      {beforeNextBuyoutRangeText}
    </div>
  </div>;
}

import React, {MouseEvent, useMemo, useState} from 'react';
import { useRouteMatch } from 'react-router';
import {ImageExtended, ImageFile} from '../../../../dto/images/images.dto';
import { SortingItem } from '../../../../dto/sorting.dto';
import {extractImages} from '../../../../scripts/utils/extractImages';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import transliterate from '../../../../scripts/utils/transliteration';
import MyInput from '../../../_utility-components/input/index';
import SelectInput from '../../../_utility-components/select-input/select-input';
import MyDropzone from '../../../_utility-components/dropzone';
import { withDescription } from '../../categories/description';
import { SetImageModules } from '../set-page';
import { SetDto } from '../dto/set.dto';
import {MyResponse} from "../../../../scripts/fetchConstructor";
import DropzoneContent from "../../../_utility-components/dropzone-content";

interface Props {
  setChanger: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  setDispatch: (setProps: Partial<SetDto>) => void, 
  sendImage: (newFiles: ImageFile[], module: SetImageModules) => Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>,
  deleteImage: (module: SetImageModules) => Promise<false | undefined>,
  thisSet: SetDto | undefined
}

function SetSummary({
  setChanger,
  setDispatch,
  sendImage,
  deleteImage,
  thisSet,
}: Props) {
  const match = useRouteMatch<{id: string}>();
  const isNew = match.params.id === 'new';

  const [loading, setLoading] = useState<boolean>(false)
  const sorting = useAppSelector(state => state.sorting);
  const domain = useAppSelector(state => state.settings.find(s => s.callname === 'site_front_domain'));
  
  const extractedImages = useMemo(() => {
    return thisSet?.src ? extractImages(thisSet.src, 'small') : null
  }, [thisSet?.src])
  
  const nameChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const changes: Partial<SetDto> = {
      name: value
    }
    if(isNew) {
      changes.url = transliterate(value).toLowerCase()
    }
    setDispatch(changes)
  }

  if(!thisSet) {
    return null
  }

  const urlToClipBoard = (e: MouseEvent) => {
    e.preventDefault();
    if(domain) {
      navigator.clipboard.writeText(domain.value + '/app/sets/' + thisSet.url);
    }
  }

  const fileHandler = async (files: ImageFile[]) => {
    setLoading(true);
    const res = await sendImage(files, 'Set')
    setLoading(false);
    return res
  }
  
  return (
    <section className='collection-page__summary'>
      <div className='collection-page__summary__inputs-wrapper'>
        <MyInput myClassName={'grid-element'}
          name={'name'}
          required={false}
          onChange={nameChanger}
          value={thisSet.name}
        >
          <h5 className="char-label__header header-font-s">Наименование</h5>
        </MyInput>

        <MyInput myClassName={'grid-element'}
          name={'url'}
          type={"nav"}
          required={false}
          onChange={setChanger}
          value={thisSet.url}
          maxLength={45}
        >
          <h5 className="char-label__header header-font-s">URL</h5>
          <div className='possible-url text-font-s'
            onClick={urlToClipBoard}
          >
            /app/sets/<span>{thisSet.url}</span>
          </div>
        </MyInput>

        <SelectInput<SortingItem> className={'grid-element'}
          items={sorting || []}
          selectedItem={sorting?.find(v => v.id === thisSet.sorting_id)}
          selectHandler={(value: SortingItem) => setDispatch({ sorting_id: value.id })}
          inputProps={{ children: <h5 className="char-label__header header-font-s">Сортировка товаров</h5> }} />

        <SelectInput<{}> className={'grid-element'} // Сделать шаблоны
          items={[]}
          selectedItem={{}}
          selectHandler={() => {}}
          inputProps={{ children: <h5 className="char-label__header header-font-s">Шаблон</h5> }} />
      </div>

      <div className='collection-page__summary__image'>
        <MyDropzone
          files={thisSet.src ? [thisSet.src] : []}
          optionalProps={{
            multiple: false,
            maxFiles: 1,
            disabled: thisSet.newSet
          }}
          isLoading={loading}
          disabled={thisSet?.newSet}
          fileHandler={fileHandler}
          contentRender={
            <DropzoneContent
              extractedImages={extractedImages}
              deleteImage={() => deleteImage('Set')}
              fallBackLink={thisSet.src?.src || null}
            />
          }
        />
      </div>

      <SetDescription
        description={thisSet.description}
        descriptionHandler={(desc) => setDispatch({description: desc})} 
      />
    </section>
  );
}

const SetDescription = withDescription(({
  children
}) => {
  return (
    <div className='category-page__summary__description'>
      {children}
    </div>
  )
})

export default SetSummary;
import QueryString from 'qs';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { SiteLocalStore } from '../../../../../dto/shop.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { getObjectsDiffsByFields, uuidv4, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useOrderDelivery from '../../hooks/use-order-delivery';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import FallbackComponent from '../../../../_utility-components/error-boundary';
import { Delivery, OrderDelivery } from '../../dto/delivery';
import { Order } from '../../dto/order.dto';
import { OrderChangeHandler, OrderContext } from '../../order-page';
import { DeliveriesToSelect } from './deliveries-to-select';
import { OrderDeliveryHeader } from './order-delivery-header';
import { SelectedDeliveryBody } from './selected-delivery/selected-delivery-body';
import {CdekWebhooksInfoRes} from "./cdek/dto/cdek-order-delete-res.dto";

interface Props {
  updateOrderState: (order: Partial<Order>) => void,
  className?: string,
  creationState?: boolean
}

// from и setFrom отвечают за магазин отправления

export const OrderDeliveryContext = React.createContext<{
  currentDeliveryId: Id | null,
  orderDeliveries: OrderDelivery[] | null, 
  delivery: OrderDelivery | null,
  currentTariff: Tariff | null,
  from: SiteLocalStore | null,
  allStoreTariffs: DifferentStoreTariffs,
  initDeliveryCreation: (initValue?: Partial<OrderDelivery>) => OrderDelivery | false,
  setCurrentDeliveryId: (id: Id) => void
  updateOrderDeliveriesCollection: (deliveries: OrderDelivery[]) => void,
  changeSelectedDelivery: (changes: Partial<OrderDelivery>) => void,
  setCurrentTariff: (tariff: Tariff | null) => void,
  setFrom: (tariff: SiteLocalStore | null) => void,
  setAllStoreTariffs: (allTariffs: DifferentStoreTariffs) => void,
  setChangeDeliveryState: React.Dispatch<React.SetStateAction<boolean>>,
  safeHandler: () => Promise<false | undefined>
  itemsWeight: number | null
}>({
  currentDeliveryId: null,
  orderDeliveries: null,
  delivery: null,
  currentTariff: null,
  from: null,
  allStoreTariffs: {},
  initDeliveryCreation: () => false,
  setCurrentDeliveryId: () => {},
  updateOrderDeliveriesCollection: () => {},
  changeSelectedDelivery: () => {},
  setCurrentTariff: () => {},
  setFrom: () => {},
  setAllStoreTariffs: () => {},
  setChangeDeliveryState: () => {},
  safeHandler: () => {return new Promise(() => false)},
  itemsWeight: null
});

function DeliverySection ({
  updateOrderState,
  className,
  creationState,
}: Props) {
  const {
    orderChange,
  } = useContext(OrderContext)
  const _isMounted = useMounted()
  const stores = useAppSelector(state => state.stores);
  const orderItems = useAppSelector(state => state.productItemsInContext)

  const [from, setFrom] = useState<SiteLocalStore | null>(null);
  const [currentTariff, setCurrentTariff] = useState<Tariff | null>(null);
  const [allStoreTariffs, setAllStoreTariffs] = useState<DifferentStoreTariffs>({});

  const {
    order,
    isOrderUnchangeable,
  } = useContext(OrderContext)
  const orderDelivery = useOrderDelivery(order)

  const [changeDeliveryState, setChangeDeliveryState] = useState(false);
  const [currentDeliveryId, setCurrentDeliveryId] = useState(order?.current_delivery_id || null);
  const [orderDeliveries, setOrderDeliveries] = useState(order?.deliveries || null);
  const [intermediateDelivery, setIntermediateDelivery] = useState<OrderDelivery | null>(orderDelivery || null);

  const itemsWeight = useMemo(() => orderItems.reduce(
    (prev, cur) =>
      prev + (cur.weight || 0) * cur.count * 1000, 0) / 1000 || null
  , [orderItems]);

  useEffect(() => {
    setIntermediateDelivery(
      orderDeliveries?.find(
        d =>
          d.id === currentDeliveryId) || null
    )
  }, [currentDeliveryId])

  const deliveryChanges = useMemo(() => 
    intermediateDelivery && orderDelivery ?
      getObjectsDiffsByFields(intermediateDelivery, orderDelivery, Object.keys(orderDelivery))
    : intermediateDelivery
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [intermediateDelivery, order]);

  useEffect(() => {
    getDeliveries()
    .then((gotDeliveries) => {
      if(gotDeliveries) {
        batch(() => {
          deliveriesDispatch(gotDeliveries)
          setChangeDeliveryState(!!creationState)
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(from !== null && allStoreTariffs[from.id]) {
      const currentTariffs = allStoreTariffs[from.id]
      if(currentTariffs.length && currentTariff?.id !== currentTariffs[0].id) {
        setCurrentTariff(currentTariffs[0])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  useEffect(() => {
    if(intermediateDelivery && from && allStoreTariffs && intermediateDelivery.tariff_id) {
      const initTariff = 
        allStoreTariffs[from.id]
        .find(tariff => 
          tariff.id === intermediateDelivery.tariff_id
        )
        
      initTariff && setCurrentTariff(initTariff)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreTariffs]);

  useEffect(() => {
    if(currentTariff === null && stores.length && from?.id !== stores[0].id) {
      setFrom(stores[0])
    }

    if(currentTariff && intermediateDelivery && currentTariff.id !== intermediateDelivery.tariff_id) {
      setIntermediateDelivery({
        ...intermediateDelivery,
        tariff_id: String(currentTariff.id),
        cost: currentTariff.price
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTariff]);

  const dispatch = useDispatch()
  const deliveriesDispatch = (deliveries: Delivery[]) => dispatch({ type: 'ORDERS_DELIVERIES', payload: deliveries })
  const orderDispatch = (order: Order) => dispatch({ type: 'ORDERS', payload: order })

  const getDeliveries = async () => {
    const params = QueryString.stringify({
      show: true
    })
    const res = await mercheryFetch<Delivery[]>('delivery?' + params, 'GET', {});

    if (!_isMounted.current || !validateResponse(res))
      return false;
    return res.records;
  };

  const initDeliveryCreation = (initValue?: Partial<OrderDelivery>): OrderDelivery | false => {
    if(!order) {
      return false
    }
    const newOrderDelivery: OrderDelivery = {
      id: uuidv4(),
      order_id: order.id,
      type: 0,
      city: null,
      city_code: null,
      term: null,
      address: order.client?.address || null,
      cost: null,
      register_cost: null,
      note: null,
      delivery_service_uuid: null,
      tariff_id: null,
      pickpoint_id: null,
      store_id: null,
      point_id: null,
      point_address: null,
      shipping_date: null,
      receiving_date: null,
      registration_date: null,
      weight: itemsWeight,
      length: null,
      width: null,
      height: null,
      postcode: null,

      newDelivery: true,
      ...initValue
    }

    return newOrderDelivery
  }

  const updateChanges = (changes: Partial<OrderDelivery>) => {
    const changedDelivery = intermediateDelivery ? {
      ...intermediateDelivery,
      ...changes
    } : initDeliveryCreation(changes)

    if(!changedDelivery) {
      return false
    }

    setIntermediateDelivery(changedDelivery)
  }

  const toInitial = () => {
    if(!orderDelivery) {
      return false
    }
    batch(() => {
      setCurrentDeliveryId(order?.current_delivery_id || null)
      setOrderDeliveries(order?.deliveries || null)
      setChangeDeliveryState(false)
      setIntermediateDelivery(orderDelivery)
    })
  };

  const safeHandler = async () => {
    if(!order) {
      return false
    }

    if(isOrderUnchangeable || !deliveryChanges || Object.keys(deliveryChanges).length === 0) {
      toInitial()
      return false
    }

    const toCreate: boolean = Boolean(intermediateDelivery?.newDelivery);
    
    (
      toCreate ?
      createDeliveryRequest() :
      changeDeliveryRequest()
    )
    .then(delivery => {
      console.log('delivery', delivery)
      if(!delivery) {
        return false
      }

      batch(() => {
        if(toCreate) {
          updateOrderState({
            // current_delivery_id: delivery.id,
            deliveries: order.deliveries ? [...order.deliveries, delivery] : [delivery]
          })
        } else {
          updateOrderState({
            deliveries: order.deliveries ? order.deliveries.map(d => d.id !== delivery.id ? d : {...delivery}) : [delivery]
          })
        }
        setChangeDeliveryState(false)
        if(order.current_delivery_id !== currentDeliveryId) {
          orderChange({
            current_delivery_id: delivery.id
          })
        }
      })
    })
  };

  const createDeliveryRequest = async () => {
    if(!intermediateDelivery || !intermediateDelivery.order_id) {
      return false
    }

    const res = await mercheryFetch<OrderDelivery>('orders/delivery', 'POST', {
      ...intermediateDelivery
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    return res.records
  }

  const changeDeliveryRequest = async () => {
    if(!orderDelivery || !intermediateDelivery || !intermediateDelivery.order_id || !intermediateDelivery.id) {
      return false
    }

    const res = await mercheryFetch<OrderDelivery>('orders/delivery', 'PATCH', {
      id: intermediateDelivery.id,
      ...deliveryChanges
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    return res.records
  }

  return (
    <OrderDeliveryContext.Provider value={{
      currentDeliveryId,
      orderDeliveries,
      delivery: intermediateDelivery,
      currentTariff,
      from,
      allStoreTariffs,
      initDeliveryCreation,
      setCurrentDeliveryId,
      updateOrderDeliveriesCollection: setOrderDeliveries,
      changeSelectedDelivery: updateChanges,
      setCurrentTariff,
      setFrom,
      setAllStoreTariffs,
      setChangeDeliveryState,
      safeHandler,
      itemsWeight,
    }}>
      <div className={`order-page-delivery order-page__section ${className || ''}`}>
        <OrderDeliveryHeader
          changeDeliveryState={changeDeliveryState} 
          toInitial={toInitial} 
          safeHandler={safeHandler} />

        {changeDeliveryState ? (
          <DeliveriesToSelect/>
        ) : (
          <SelectedDeliveryBody/>
        )}
      </div>
    </OrderDeliveryContext.Provider>
  );
}

export default withErrorBoundary(memo(DeliverySection), {FallbackComponent: FallbackComponent})

export interface DeliveryItemProps {
  delivery: Delivery,
  changeSelectedDelivery: (changes: Partial<Delivery>) => void,
  orderChange: OrderChangeHandler,
  orderDeliveryPropsChanges: Delivery | null
  order: Order,
}

export interface DifferentStoreTariffs {
  [key: Id]: Tariff[]
}

export interface Tariff {
  id: Id,
  price: number,
}

export type TariffHandler = (tariff: Tariff) => void

export type GetTariffs = (store: SiteLocalStore) => false | Promise<Tariff[] | false>

export type SetDeliveryChanges = (changes: Partial<Delivery>) => void
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { FilterId, Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch, MyResponse } from '../../../../scripts/fetchConstructor';
import { querify, uuidv4, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../scripts/hooks/use-load';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { PossibleValuesPopup, usePossibleValues } from '../../../../scripts/hooks/use-possible-values';
import Bubbles from '../../../_utility-components/bubbles';
import MyInput from '../../../_utility-components/input/index';
import { ProductTag } from '../dto/product-tag.dto';

export function TagsOfProduct() {
  const product = useAppSelector(state => state.product);
  const _isMounted = useMounted() 
  const inputRef = useRef<HTMLInputElement | null>(null);

  const tags = useAppSelector(state => state.productTags)
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [tagsWithThisProduct, setTagsWithThisProduct] = useState<ProductTag[]>([]);

  const dispatch = useDispatch()
  const tagsDispatch = (tags: ProductTag[]) => dispatch({ type: 'CURRENT_PRODUCT_TAGS', payload: tags})

  const [, setLoaded] = useLoad()
  
  const {setSearch, search, setShowData, dataLoading, data, showData} = usePossibleValues<ProductTag>({
    urlPath: 'tags',
  });

  useEffect(() => {
    if(product && !product.newProduct && !tagsLoaded) {
      const query = querify({
        filters: { product: product?.id },
        ...(search && {search})
      });

      mercheryFetch<ProductTag[]>(`tags?${query}`, 'GET')
      .then(res => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        batch(() => {
          setTagsWithThisProduct(res.records)
          setTagsLoaded(true)
        })
      })
    }
  }, [product])

  const addTagToProduct = async (tag: ProductTag) => {
    if(!tag || !product) {
      return false
    }

    const newProductsIds = [...new Set([...tag.products_ids, product.id])]

    const cancelAddition = (
      JSON.stringify(newProductsIds) === JSON.stringify(tag.products_ids)
    )
    
    let res: MyResponse<ProductTag[], false> | false = false;

    if(!cancelAddition) {
      res = await mercheryFetch<ProductTag[]>('tags', 'PATCH', {
        toChange: [{
          id: tag.id,
          products_ids: newProductsIds
        }]
      })
    }

    batch(() => {
      if(_isMounted.current && res && validateResponse(res)) {
        setSearch('')
        setShowData(false)

        setTagsWithThisProduct([
          ...tagsWithThisProduct,
          res.records[0]
        ])
      }
    })

    return res
  }

  const removeProductFromTag = async (tagToRemoveFrom: ProductTag) => {
    if(!tagToRemoveFrom || !product) {
      return false
    }

    const newProductsIds = tagToRemoveFrom.products_ids.filter(id => id !== product.id)
    const cancelAddition = (
      JSON.stringify(newProductsIds) === JSON.stringify(tagToRemoveFrom.products_ids)
    )
    
    let res: MyResponse<ProductTag[], false> | false = false;

    if(!cancelAddition) {
      res = await mercheryFetch<ProductTag[]>('tags', 'PATCH', {
        toChange: [{
          id: tagToRemoveFrom.id,
          products_ids: newProductsIds
        }]
      })
    }
    if(_isMounted.current && res && validateResponse(res)) {
      setTagsWithThisProduct(
        tagsWithThisProduct.filter(
          tag => tag.id !== tagToRemoveFrom.id
        )
      )
    }
  }

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearch(e.target.value)
  };

  const createTag = (name: string) => {

    const newTag: ProductTag = {
      id: uuidv4(),
      name: name,
      products_ids: [],
      products: [],
      newTag: true
    }

    setLoaded(true)

    mercheryFetch<ProductTag[]>('tags', 'POST', {
      toCreate: [
        newTag
      ]
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      const tagsWithoutNew = tags.filter(c => !c.newTag)

      batch(() => {
        tagsDispatch([...tagsWithoutNew, ...res.records])
      })
      return res.records[0]
    })
    .then((tag) => {
      tag && addTagToProduct(tag)
    })
    .finally(() => {
      setLoaded(false)
    })
  }
  
  return <div className='product-page__right-side-bar__label product__tags'>
    <h5 className="header-font-s">
      Теги
    </h5>

    <MyInput
      inputRef={inputRef}
      value={search}
      onChange={inputHandler}
    />
    
    <PossibleValuesPopup
      clickHandler={addTagToProduct} 
      dataLoading={dataLoading} 
      search={search}
      withAddNew={createTag}
      data={
        data.filter(tag => 
          !tagsWithThisProduct.some(added => added.id === tag.id)
        )
      } 
      showData={showData} 
      setShowData={setShowData}
    />

    <Bubbles<ProductTag>
      deleteHandler={removeProductFromTag}
      items={tagsWithThisProduct}
    />
  </div>;
}
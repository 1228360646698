export const leavePage = 'LEAVE_PAGE';
export const enterPage = 'ENTER_PAGE';
export const pageViewersCount = 'PAGE_VIEWERS_COUNT';
export const forceReenterViewers = 'FORCE_REENTER';

export const newOrdersCount = 'NEW_ORDERS_COUNT';
export const updateOrder = 'UPDATE_ORDER';
export const createOrder = 'CREATE_ORDER';
export const deleteOrder = 'DELETE_ORDER';

export const updateOrderItem = 'UPDATE_ORDER_ITEM';
export const createOrderItem = 'CREATE_ORDER_ITEM';
export const deleteOrderItem = 'DELETE_ORDER_ITEM';

export const updateProduct = 'UPDATE_PRODUCT';
export const createProduct = 'CREATE_PRODUCT';
export const deleteProduct = 'DELETE_PRODUCT';
export const reorderProduct = 'REORDER_PRODUCT';

export const updateClient = 'UPDATE_CLIENT';
export const createClient = 'CREATE_CLIENT';
export const deleteClient = 'DELETE_CLIENT';

export const updateSet = 'UPDATE_SET';
export const createSet = 'CREATE_SET';
export const deleteSet = 'DELETE_SET';

export const updateCollection = 'UPDATE_COLLECTION';
export const createCollection = 'CREATE_COLLECTION';
export const deleteCollection = 'DELETE_COLLECTION';
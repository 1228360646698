import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { withErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { CategoryId, Id } from '../../../../../dto/master-dto/id.dto';
import UnitOfMeasurement from '../../../../../dto/units-of-measurment.dto';
import { mercheryFetch, MyResponse } from '../../../../../scripts/fetchConstructor';
import { arraymove, getArrayOfObjectsChanges, objOfArraysHasProps, querify, validateMultipleResponses, validateResponse } from '../../../../../scripts/functions';
import { AppLoaderWrapper } from '../../../../_utility-components/loaders/app-loader';
import { Category } from '../../dto/categories.dto';
import { CharGroupsDto, CharScopeDto, CharsDto, CharsLabelDto, CharsTypesDto, MultiSelectDto, MultiSelectValueDto, MultiSelectValueName } from '../../dto/chars.dto';
import CharsBottom from './char-section-bottom';
import CharsTop from './char-section-top';
import OneChar from './one-char';

export type CharacteristicsPage = 'product' | 'categories'

interface Props {
  productCategory: CategoryId,
  page: CharacteristicsPage,
  isCreatePage: boolean
}

function ProductsCharacteristics({
  productCategory,
  page,
  isCreatePage
}: Props) {
  const _isMounted = useRef(true);
  const currentProductId = useAppSelector(state => state.product?.id);
  const charGroups = useAppSelector(state => state.charGroups);
  const chars = useAppSelector(state => state.chars);
  const options = useAppSelector(state => state.productOptions);
  const scopes = useAppSelector(state => state.scopes);
  const labels = useAppSelector(state => state.labels);
  const multiSelect = useAppSelector(state => state.multiSelect);
  const multiSelectValues = useAppSelector(state => state.multiSelectValues);

  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);
  const categoriesReverse = useMemo(() => [...categories].reverse(), [categories])
  const getCategIndex = useCallback((group: CharGroupsDto) => categoriesReverse.findIndex(cat => cat.id === group.category_id), [categoriesReverse])

  const orderedGroups = useMemo(() => 
    [...charGroups].sort((a,b) =>
      getCategIndex(a) - getCategIndex(b))
  , [charGroups, getCategIndex]);
  
  const [initialCharGroups, setInitialCharGroups] = useState<CharGroupsDto[]>([]);
  const [initialChars, setInitialChars] = useState<CharsDto[]>([]);
  const [initialLabels, setInitialLabels] = useState<CharsLabelDto[]>([]);
  const [initialScopes, setInitialScopes] = useState<CharScopeDto[]>([]);
  const [initialMultiSelectValues, setInitialMultiSelectValues] = useState<MultiSelectValueDto[]>([]);

  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const [charEditingId, setCharEditingId] = useState<Id | null>(null);

  const charsGroupsReducerType = 'CHARS_GROUPS';
  const charsReducerType = 'CHARS';
  const scopesReducerType = 'CHARS_SCOPES';
  const labelsReducerType = 'CHARS_LABELS';
  const msReducerType = 'CHARS_LABELS_MULTISELECT';
  const valuesReducerType = 'CHARS_LABELS_MULTISELECT_VALUES';
  const multiselectNamesReducerType = 'CHARS_LABELS_MULTISELECT_NAMES';
  const labelTypesReducerType = 'CHARS_LABELS_TYPES';
  const categoriesType = 'PRODUCT_ASSOCIATED_CATEGORIES';
  const unitsType = 'UNITS_OF_MEASUREMENT';

  const setCharsGroups = (value: CharGroupsDto[]) => dispatch({type: charsGroupsReducerType, payload: value})
  const setChars = (value: CharsDto[]) => dispatch({type: charsReducerType, payload: value})
  const setLabels = (value: CharsLabelDto[]) => dispatch({type: labelsReducerType, payload: value})
  const setScopes = (value: CharScopeDto[]) => dispatch({type: scopesReducerType, payload: value})
  const setMultiSelect = (value: MultiSelectDto[]) => dispatch({type: msReducerType, payload: value})
  const setMultiSelectValues = (value: MultiSelectValueDto[]) => dispatch({type: valuesReducerType, payload: value})
  const setMultiSelectNames = (value: MultiSelectValueName[]) => dispatch({type: multiselectNamesReducerType, payload: value})
  const setLabelTypes = (value: CharsTypesDto[]) => dispatch({type: labelTypesReducerType, payload: value})
  const setCategories = (value: Category[]) => dispatch({ type: categoriesType, payload: value})
  const setUnits = (value: UnitOfMeasurement[]) => dispatch({type: unitsType, payload: value})

  const charGroupsDiff = useMemo(() => getArrayOfObjectsChanges(charGroups, initialCharGroups, ['name', 'category_id']), [charGroups, initialCharGroups]);
  const charsDiff = useMemo(() => getArrayOfObjectsChanges(chars, initialChars, ['order', 'scope']), [chars, initialChars]);
  const labelsDiff = useMemo(() => getArrayOfObjectsChanges(labels, initialLabels, ['name', 'type_id', 'order', 'unit', 'unit_id']), [labels, initialLabels]);
  const scopesDiff = useMemo(() => getArrayOfObjectsChanges(scopes, initialScopes, ['value', 'value_id']), [scopes, initialScopes]);
  const multiSelectValuesDiff = useMemo(() => getArrayOfObjectsChanges(multiSelectValues, initialMultiSelectValues, ['value', 'order']), [multiSelectValues, initialMultiSelectValues]);

  const categoriesRoutePath = 'category/ancestors';
  const charGroupsRoutePath = 'characteristics/groups';
  const charRoutePath = 'characteristics';
  const typesRoutePath = 'characteristics/labels/types';
  const labelsRoutePath = 'characteristics/labels';
  const scopesRoutePath = 'characteristics/scopes';
  const msRoutePath = 'characteristics/multiselects';
  const msValuesRoutePath = 'characteristics/multiselects/values';
  const msNamesRoutePath = 'characteristics/multiselects/names';
  const scopesExtendsRoutePath = 'characteristics/scopes/extend';

  const postReq =   useCallback(<T,>(link: string, added: {}[]): Promise<MyResponse<T>> | undefined => 
    added.length ? 
      mercheryFetch<T>(link, 'POST', { toCreate: added })
    : undefined
  , [])
  const patchReq =  useCallback(<T,>(link: string, changes: {}[]): Promise<MyResponse<T>> | undefined => 
    changes.length ? 
      mercheryFetch<T>(link, 'PATCH', { changes })
    : undefined
  , [])
  const deleteReq = useCallback((link: string, deleted: Id[]): Promise<MyResponse<number[]>> | undefined => 
    deleted.length ? 
      mercheryFetch(link, 'DELETE', { toDelete: {id: deleted} })
    : undefined
  , [])

  const charModuleDiff = [
    charsDiff,
    scopesDiff,
    multiSelectValuesDiff,
  ].map(Object.values).flat();

  const charModuleHaveDiff = useMemo(() => charModuleDiff.some(a => a.length), [charModuleDiff])
  const showTopBar = useMemo(() => charModuleHaveDiff && !objOfArraysHasProps(charGroupsDiff), [charGroupsDiff, charModuleHaveDiff])

  useEffect(() => {
    if(page === 'product') {
      const scopeToDelete = scopes.filter((scope) => 
        scope.option_id !== null && 
        !options.some(option => 
          option.id === scope.option_id && 
          option.values.some(optionValue =>  
            optionValue?.id === scope.option_value_id
          )
        )
      )

      const charsChanges = (
        chars.filter((char => !options.some(option => option.id === char.scope)))
        .map(char => ({id: char.id, scope: null}))
      )

      batch(() => {
        if(scopeToDelete.length) {
          deleteReq(scopesRoutePath, scopeToDelete.map(scope => scope.id))
          setScopes(scopes.filter(scope => !scopeToDelete.some(delScope => scope.id === delScope.id)))
          setInitialScopes(initialScopes.filter(scope => !scopeToDelete.some(delScope => scope.id === delScope.id)))
        }

        if(charsChanges.length) {
          patchReq(charRoutePath, charsChanges)
          setChars(chars.map(char => {
            const hasChanges = charsChanges.find(change => change.id === char.id)
            if(hasChanges) return ({
              ...char, 
              ...hasChanges 
            })
            return char
          }))
          setInitialChars(initialChars.map(char => {
            const hasChanges = charsChanges.find(change => change.id === char.id)
            if(hasChanges) return ({
              ...char, 
              ...hasChanges
            })
            return char
          }))
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    _isMounted.current = true;
  
    mercheryFetch<UnitOfMeasurement[]>('unit-of-measurement', 'GET')
    .then((gotUnits) => {
      if(!_isMounted.current || !validateResponse(gotUnits)) {
        return false
      }

      const units = gotUnits.records;
      setUnits( units )
    })

    return () => {
      batch(() => {
        setChars([])
        setScopes([])
      })
      _isMounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, productCategory])

  const getData = async () => {
    try {
      const shortGet = <T,>(path: string, query?: object) => mercheryFetch<T>(`${path}${query ? '?' + querify(query) : ''}`, 'GET')
      setLoaded(false)
  
      if(!productCategory) {
        return false
      }
      
      const [gotCharGroups,
        gotChars, gotTypes, gotMSNames, gotCategories
      ] = await Promise.all([
        shortGet<CharGroupsDto[]>(charGroupsRoutePath, {
          filters: {
            category_id: productCategory,
            deleted: false
          },
          byAncestors: page === 'product'
        }),
        currentProductId ? shortGet<CharsDto[]>(charRoutePath, {product_id: currentProductId}) : undefined,
        shortGet<CharsTypesDto[]>(typesRoutePath),
        shortGet<MultiSelectValueName[]>(msNamesRoutePath, {
          filters: {category: productCategory},
          options: {getUpperLevelCategories: true}
        }),
        shortGet<Category[]>(categoriesRoutePath, {id: productCategory})
      ])
      
      if(!_isMounted.current ||
        !validateResponse(gotCharGroups) ||
        !validateResponse(gotTypes) ||
        !validateResponse(gotCategories)
      ) {
        return false
      }
  
      const charGroups = gotCharGroups.records;
      const types = gotTypes.records;
      const categories = gotCategories.records;
      
      batch(() => {
        setLabelTypes(types)
  
        setCharsGroups(charGroups)
        setInitialCharGroups(charGroups)
  
        if(validateResponse(gotChars)) {
          const chars = gotChars.records;
          setChars(chars)
          setInitialChars(chars)
        }

        if(validateResponse(gotMSNames)) {
          const msNames = gotMSNames.records;
          setMultiSelectNames(msNames)
        }
        setCategories(categories)
      })
  
      const gotLabels = await shortGet<CharsLabelDto[]>(labelsRoutePath, {
        char_group: charGroups.map(char => char.id)
      });
  
      if(!_isMounted.current || !validateResponse(gotLabels)) {
        return false
      }

      const labels = [...gotLabels.records] || []
  
      batch(() => {
        setLabels(labels)
        setInitialLabels(labels)
      })
  
      if(!labels.length) {
        return false
      }
  
      const labelsWithMultiSelectsIds = labels
        .filter(sc => sc.type_id === 4)
        .map(label => label.id)

      const gotMultiSelects = await shortGet<MultiSelectDto[]>(msRoutePath, {
        label_id: labelsWithMultiSelectsIds
      })

      if(!_isMounted.current) return false
      if(!validateResponse(gotMultiSelects)) return false

      const multiSelects = gotMultiSelects.records || []
      
      const gotScopes = page === 'product' ? await shortGet<CharScopeDto[]>(scopesExtendsRoutePath, {
        label_id: labels.map(ms => ms.id),
        product_id: currentProductId
      }) : false
      let msValuesRequest = page === 'product' && multiSelects.length ? shortGet<MultiSelectValueDto[]>(msValuesRoutePath, {filters: {multiselect_id: multiSelects.map(ms => ms.id)}}) : false
  
      const gotMSValues = await Promise.all([
        ...(msValuesRequest ? [msValuesRequest] : [])
      ])
  
      if(!_isMounted.current) return false;
  
      batch(() => {
        if(gotScopes && validateResponse(gotScopes)) {
          setScopes(gotScopes.records)
          setInitialScopes(gotScopes.records)
        }

        setMultiSelect(multiSelects)
        if(validateMultipleResponses(gotMSValues)) {
          const values = gotMSValues.flatMap(msv => msv.records) || [];
          setMultiSelectValues(values)
          setInitialMultiSelectValues(values)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoaded(true)
    }
  }
  
  const saveHandler = useCallback(async () => {

    if(objOfArraysHasProps(charsDiff)) {
      await Promise.all([
        postReq(charRoutePath, charsDiff.added),
        patchReq(charRoutePath, charsDiff.changes),
        deleteReq(charRoutePath, charsDiff.deleted),
      ])
    }

    let newScopes: CharScopeDto[] = [];
    let changedScopes: CharScopeDto[] = [];
    let scopesIdsForReplace: (Id | undefined)[][] = [];

    if(objOfArraysHasProps(scopesDiff)) {
      const scopesChanges = scopesDiff.changes.map(scope => {
        const addValueId = scope.value_id || scopes.find(sc => sc.id === scope.id)?.value_id
        return ({
          ...scope,
          ...(addValueId && {value_id: addValueId})
        })
      })
      await Promise.all([
        postReq<CharScopeDto[]>(scopesRoutePath, scopesDiff.added),
        patchReq<CharScopeDto[]>(scopesRoutePath, scopesChanges),
        deleteReq(scopesRoutePath, scopesDiff.deleted),
      ]).then(([createRes, changeRes]) => {
        if(!_isMounted.current) return false
        
        if(validateResponse(createRes)) {
          newScopes = createRes.records
          scopesIdsForReplace = scopesDiff.added.map(addedScope => ([
            addedScope.id,
            newScopes.find(createdScope => 
              createdScope.label_id === addedScope.label_id &&
              createdScope.option_id === addedScope.option_id &&
              createdScope.option_value_id === addedScope.option_value_id
            )?.id
          ]))
        }

        if(validateResponse(changeRes)) {
          changedScopes = changeRes.records
        }
      })
    }

    let newValues: MultiSelectValueDto[] = [];
    let changedValues: MultiSelectValueDto[] = [];

    if(objOfArraysHasProps(multiSelectValuesDiff)) {
      let valuesWithReplacedIds = []

      for (let index = 0; index < multiSelectValuesDiff.added.length; index++) {
        const value = multiSelectValuesDiff.added[index];
        valuesWithReplacedIds.push({...value})
        
        if(scopesIdsForReplace?.length) {
          valuesWithReplacedIds[index].scope_id = scopesIdsForReplace.find( ([uuid, id]) => value.scope_id === uuid )?.[1] || value.scope_id
        }
        // if(namesIdsForReplace?.length) {
        //   valuesWithReplacedIds[index].name_id = namesIdsForReplace.find( ([uuid, id]) => value.name_id === uuid )?.[1] || value.name_id
        // }
      }

      await Promise.all([
        postReq<MultiSelectValueDto[]>(msValuesRoutePath, valuesWithReplacedIds),
        patchReq<MultiSelectValueDto[]>(msValuesRoutePath, multiSelectValuesDiff.changes),
        deleteReq(msValuesRoutePath, multiSelectValuesDiff.deleted),
      ]).then(([createRes, changeRes]) => {
        if(!_isMounted.current) return false
        if(validateResponse(createRes)) newValues = createRes.records
        if(validateResponse(changeRes)) changedValues = changeRes.records
      })
    }
    
    batch(() => {
      if(!_isMounted.current) 
      return false;

      const needCharsUpdate = objOfArraysHasProps(charsDiff)
      if(needCharsUpdate) {
        setInitialChars(chars)
      }

      const needLabelsUpdate = labelsDiff.changes.length
      if(needLabelsUpdate) {
        setInitialLabels(labels)
      }

      const needScopesUpdate = objOfArraysHasProps(scopesDiff)
      if(needScopesUpdate) {
        let notDeletedNotAdded = scopes.flatMap(scope => {
          if(scopesDiff.added.some(addedScope => addedScope.id === scope.id)) {
            return []
          }
          return changedScopes.find(changedScope => changedScope.id === scope.id) || scope
        })

        const updatedScopes = [...notDeletedNotAdded, ...newScopes]
        setScopes(updatedScopes)
        setInitialScopes(updatedScopes)
      }

      const needValueUpdate = 
        objOfArraysHasProps(multiSelectValuesDiff) 
      
      if(needValueUpdate) {
        const notDeletedNotAdded = multiSelectValues.flatMap(value => {
          if(multiSelectValuesDiff.added.some(addedValue => addedValue.id === value.id)) return [];
          if(scopesDiff.deleted.some(scope => scope === value.id)) return [];

          const changedValue = changedValues && changedValues.find(changedValue => changedValue.id === value.id)
          if(changedValue) return changedValue
          return value
        })

        const updatedValues = [...notDeletedNotAdded, ...newValues]
        setMultiSelectValues(updatedValues)
        setInitialMultiSelectValues(updatedValues)
      }
    })
  }, [chars, charsDiff, deleteReq, labels, labelsDiff.changes.length, multiSelectValues, multiSelectValuesDiff, patchReq, postReq, scopes, scopesDiff, setMultiSelectValues, setScopes]);

  const cancelHandler = useCallback(() => {
    if(!_isMounted.current) return false
    batch(() => {
      if(charGroupsDiff.deleted.length) setCharsGroups(initialCharGroups)
      if(objOfArraysHasProps(charsDiff)) setChars(initialChars)
      if(labelsDiff.changes.length) {
        setLabels(labels.map(label => 
          labelsDiff.changes.some(changedLabel => changedLabel.id === label.id) ? initialLabels.find((l => l.id === label.id)) || label : label
        ))
      }
      if(objOfArraysHasProps(scopesDiff)) setScopes(initialScopes)
      if(objOfArraysHasProps(multiSelectValuesDiff)) setMultiSelectValues(initialMultiSelectValues)
    })
  }, [charGroupsDiff, charsDiff, initialCharGroups, initialChars, initialLabels, initialMultiSelectValues, initialScopes, labels, labelsDiff.changes, multiSelectValuesDiff, scopesDiff, setChars, setCharsGroups, setLabels, setMultiSelectValues, setScopes])

  const clearDeletedCharData = (char: CharsDto) => {
    batch(() => {
      setChars(chars.filter(ch => ch.id !== char.id))
      const thisCharLabels = labels.filter(label => label.char_group === char.id )
      const thisCharScopes = scopes.filter(scope => thisCharLabels.some(label => scope.label_id === label.id))
      setScopes(scopes.filter(scope => !thisCharLabels.some(label => scope.label_id === label.id)))

      setMultiSelectValues(multiSelectValues.filter(value => !thisCharScopes.some(scope => value.scope_id === scope.id)))
    })
  }

  const onDragEnd = (info: DropResult) => {
    const {destination, source} = info
    if(!destination || destination?.index === source.index) 
      return false;
    
    const nodeType = info.type.split('-')[0]
    if(!nodeType) 
      return false;

    if( nodeType === 'groupLabel' ) {
      const parentId: Id = destination.droppableId.split('-')[1]
      const difference = destination.index - source.index;
      const newOrderIsBiggest = source.index < source.index + difference;
      const sortedArray = [...labels].filter(item => item.char_group === +parentId)
                          .sort((a,b) => a.order - b.order);
      const reorderedArray = sortedArray.map(item => {
        let order = item.order === source.index ? destination.index :
          item.order === destination.index && newOrderIsBiggest ? item.order - 1 :
          item.order === destination.index && !newOrderIsBiggest ? item.order + 1 :
          item.order > destination.index && item.order < source.index ? item.order + 1 :
          item.order < destination.index && item.order > source.index ? item.order - 1 :
          item.order;
        return {
          ...item, 
          order
        }
      })

      setLabels(labels.map(item => reorderedArray.find(rItem => rItem.id === item.id) || item))

    } else if( 'multiselect' ) {
      const reorderMultiSelect = multiSelect.find(ms => ms.label_id === +destination.droppableId.split('-')[1])
      if(!reorderMultiSelect) return false

      const newOrderArray = arraymove(reorderMultiSelect.names, source.index, destination.index)
      
      setMultiSelect(multiSelect.map(ms => ms.id !== reorderMultiSelect.id ? ms : {
        ...reorderMultiSelect,
        names: newOrderArray
      }))

    }

  };

  if(isCreatePage) {
    return null
  }

  return _isMounted.current ? (
    loaded ? (
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <div>
          <CharsTop
            charsHaveChanges={showTopBar}
            saveHandler={saveHandler}
            cancelHandler={cancelHandler}
          />
          {orderedGroups.length ? 
            <div className='product-page__characteristics flex-gap-16'>

              {orderedGroups.map(gr => {
                return (
                  <OneChar
                    key={gr.id}
                    thisCharGroup={gr}
                    options={options}
                    initialCharGroups={initialCharGroups}
                    setInitialCharGroups={setInitialCharGroups}
                    setInitialLabels={setInitialLabels}
                    initialLabels={initialLabels}
                    labelsDiff={labelsDiff}
                    charGroupsDiff={charGroupsDiff}
                    charEditingId={charEditingId}
                    setCharEditingId={setCharEditingId}
                    clearDeletedCharData={clearDeletedCharData}
                    page={page}
                  />
                )
              })}
            </div>
          : null}

          <CharsBottom 
            categoryId={productCategory}
            charEditingId={charEditingId}
            setCharEditingId={setCharEditingId}
          />
        </div>
      </DragDropContext>
    ) : <AppLoaderWrapper/>
  ) : null;
}

export default withErrorBoundary(ProductsCharacteristics, {FallbackComponent: FallbackComponent});
import React, { useMemo } from 'react';
import { ImageExtended } from '../../../../../dto/images/images.dto';
import { extractImages } from '../../../../../scripts/utils/extractImages';
import Picture from '../../../../_utility-components/picture/picture';

export interface CollectionItemImageProps {
  src?: ImageExtended | ImageExtended[] | undefined
}

function CollectionItemImage(props: CollectionItemImageProps) {
  const {src} = props;

  const extractedImages = useMemo(() => {
    const image = Array.isArray(src) ? src.find(img => img.main) || src[0] : src
    return image ? extractImages(image, 'small') : null
  }, [src])

  return (
    <div className='row-item__image'>
      <Picture images={extractedImages} />
    </div>
  );
}

export default CollectionItemImage;
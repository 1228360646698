import React, { MouseEventHandler, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import simpleDown from '../../../../img/simple-down.svg';
import { num2str, numWithSep, priceWithPennies, ptsansCurrency } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useStorageUiState } from '../../../../scripts/hooks/use-storage-ui-state';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import { OrdersFilters } from '../dto/filters';
import { OrderContext } from '../order-page';
import { EditClientInOrder } from './popups/client-in-order.tsx/edit-client-in-order';
import useOrderFilters from "../hooks/use-order-filters";

function ClientInOrder() {
  const {
    order,
    orderChange,
  } = useContext(OrderContext);

  const [ordersFilters, updateOrdersFilters] = useOrderFilters();
  const tabIndex = useTabIndex()
  const history = useHistory()

  const historyStateDefaultLocalStorageKey = 'uiPurchasesHistoryState'
  const [purchasesHistoryState, setPurchasesHistoryState] = useStorageUiState<boolean>(true, historyStateDefaultLocalStorageKey)

  if(!order) {
    return null
  }
  
  const client = order.client;

  const filterOrdersByUser: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()

    console.log(order)
    console.log(ordersFilters)
    console.log(ordersFilters?.client_id)

    updateOrdersFilters({
      itemIds: [order.client_id],
      filterName: 'client_id',
      cleanPrev: true,
      cleanOthers: true
    })
    // order && ordersFiltersDispatch({
    //   client_id: ordersFilters?.client_id && ordersFilters.client_id.map(filter =>
    //     filter.id === order.client_id ?
    //       ({...filter, selected: true})
    //     : filter)
    // })

    history.push({
      pathname: `/app/orders`,
    })
  }

  const ordersLink = 
    client?.ordersCount ? 
      <Link 
        tabIndex={tabIndex}
        onClick={filterOrdersByUser}
        to={''} 
        className="special-span orders-by-user"
      >
        {num2str(client.ordersCount, ['заказ', 'заказа', 'заказов'])}
      </Link>
    : null;

  return (
    <div className="order-page-side-panel order-page__section">
      <div className="order-page-side-panel-main">
        <div className={'flex-wrapper'} style={{width: '100%'}}>
          <h3 className='header-font-m' >Клиент</h3>
          <EditClientInOrder
            order={order}
            changeData={orderChange}
          />
        </div>

        {client ? 
          <>
            <span className='header-font-m'>
              <Link 
                to={{ pathname: `/app/clients/${client.id}` }}
              >
                {client.name}
              </Link>
            </span>
            <div className='text-font-s'>
              {client.discountGroup ? client.discountGroup.name : 'новый клиент'}
            </div>
          </>
        : null}
      </div>

      {client ? 
        <div className="order-page-side-panel-contacts">
          <h4>Контакты</h4>
          <a className='blue-color' href={`mailto:${client.email}`}>{client.email}</a>
          <div>{client.phone}</div>
        </div>
      : null}

      {client ? 
        <div className={`order-page-side-panel-purchases-history hide-for-print ${purchasesHistoryState ? 'opened' : ''}`}>
          <MyButton 
            className="purchases-history-show-btn" 
            removeDefaultClass
            onClick={() => setPurchasesHistoryState(!purchasesHistoryState)}
          >
            <h4>История покупок</h4>

            <div className="icon-div">
              <img alt="" src={String(simpleDown)}/>
            </div>
          </MyButton>

          {purchasesHistoryState ? <>
            <div className="total-orders-sum">
              Всего {ordersLink} на сумму {numWithSep(priceWithPennies(client.ordersTotal))} {ptsansCurrency('₽')}
            </div>

            <div className="returns-and-buyout">
              <div>
                <div className="title-div">Возвраты</div>
                <div className="text-div">{numWithSep(priceWithPennies(client.refundTotal))} {ptsansCurrency('₽')}</div>
              </div>
              
              <div>
                <div className="title-div">выкуп</div>
                <div className="text-div">{client.buyout} %</div>
              </div>
            </div>
          </> : null}
        </div>
      : null}
    </div>
  );
}

export default ClientInOrder;



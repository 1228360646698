import React, { useContext } from 'react';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import { OrderDeliveryContext } from '../delivery';
import { SelectedDeliveryAddressAndCost } from './selected-delivery-address-and-cost';
import { SelectedDeliveryImage } from './selected-delivery-image';
import { SelectedDeliveryFooter } from './selected-delivery-footer';
import { OrderDeliveryChangeStateSwitcher } from '../order-delivery-change-state-switcher';

export function SelectedDeliveryBody() {
  const {
    delivery
  } = useContext(OrderDeliveryContext)
  const deliveries = useAppSelector(state => state.deliveryServices)

  if(!delivery) {
    return null
  }
  
  const curDelId = +delivery.type;
  const thisDelivery = deliveries.find(d => d.id === curDelId)

  if(!thisDelivery) {
    return null
  }

  return <>
    <div className="op-delivery-center">
      <div className="left">
        <SelectedDeliveryImage/>
      </div>
      
      <div className="right">
        <OrderDeliveryChangeStateSwitcher/>
      </div>

      {/* {!creationState && (curDelId === 7) ?
        <CreateDeliveryNote
        />
      : null} */}
    </div>

    <SelectedDeliveryAddressAndCost/>

    <SelectedDeliveryFooter/>
  </>;
}
import React, { useContext } from 'react';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import AppLoader from '../../../../_utility-components/loaders/app-loader';
import { OrderDeliveryContext } from '../delivery/delivery';

interface Props {
  fromHandler: () => void,
}

function DeliveryFromSelect ({
  fromHandler
}: Props) {
  const storeList = useAppSelector(state => state.stores);

  const {
    from, 
    setFrom
  } = useContext(OrderDeliveryContext)
  
  const {RenderPopup, closePopup, openPopup} = usePopup();
  const handleClick = () => {
    fromHandler()
    openPopup()
  }

  return (
    <div className="delivery-price-and-mode header-font-m">
      <MyButton
        onClick={handleClick}
        removeDefaultClass
        className="delivery-mode"
      >
        {from?.name ? 
          <span>{from.name} {from.city}</span> 
        : <span className="blue-color">Выбрать место</span>}
      </MyButton>

      <RenderPopup
        className={'delivery-tariff'}>
        <div className='popup-group'>
          {storeList?.length ? 
            storeList.map((store, index) => (
              <MyButton
                className='delivery-tariff-row popup-element' 
                key={index} 
                onClick={() => {
                  setFrom({...store})
                  closePopup()
                }}>

                <div className="delivery-tariff-row-header">
                  {store.name} {store.city}
                </div>
                <div>{store.address}</div> 
                
              </MyButton>
            )) 
          : <AppLoader/>}
        </div>
      </RenderPopup>
    </div>
  )
}

export default DeliveryFromSelect;
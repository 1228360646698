import ProductOneVariantDropzone from "./one-variant-dropzone";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import React from "react";
import { ProductVariantExtended } from "../../dto/variants.dto";
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import { ProductAttributes } from "../../dto/attributes.dto";
import { batch, useDispatch } from "react-redux";
import { OnCountHandler } from "../../../../_utility-components/input/input";
import MyCheckbox from "../../../../_utility-components/checkbox/my-checkbox";
import { VariantLabelsControl } from "./variant-labels-control";

interface Props {
  variant: ProductVariantExtended,
}

function ProductOneVariant({
  variant, 
}: Props) {
  const topPanelOpened = useAppSelector(state => state.topPanelShown);
  const [remainCount, setRemainCount] = useState(variant.remain);
  const tabIndexLevel = 1
  const variants = useAppSelector(state => state.productVariants)
  const selectedVariantsAttributes = useAppSelector(state => state.selectedVariantsAttributes)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const [previewVariant, setPreviewVariant] = useState<Partial<ProductVariantExtended>>({});

  const dispatch = useDispatch()
  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const selectedVariantAttributesDispatch = (attrs: ProductAttributes[][]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })

  const variantSelected = selectedVariantsAttributes.some(selectedVariantsAttributes => variant.attributes.every(attr => selectedVariantsAttributes.some(sva => sva === attr)))
  const variantDeletedClassName = variant.show_date === null ? 'deleted-variant' : ''
  const variantSelectedClassName = variantSelected ? 'selected' : ''
  const selectedVariantMenuClassName = variantSelected ? 'selected-variants-menu' : ''
  const headerRowClassName = '' 

  const afterTopPanelClassName = topPanelOpened ? 'after-top-panel' : '';

  const countHandler: OnCountHandler = useCallback((count) => setRemainCount(count), [])

  const variantChanger = <T extends keyof ProductVariantExtended, >(value: ProductVariantExtended[T], label: T) => {
    let changedVariant = {
      ...previewVariant,
      [label]: value
    }
    
    let shallowVariants = variants.map(v => v.id === variant.id ? {...variant, ...changedVariant} : v)

    batch(() => {
      setPreviewVariant(changedVariant)
      variantsDispatch(shallowVariants)
    })
  }

  const variantCheckBoxHandler = () => {
    if(moySkladIntegrationOn) 
      return false

    const shallowSelectedVariantsAttributes = [...selectedVariantsAttributes]
    const variantIndex = shallowSelectedVariantsAttributes.findIndex(sAttr => 
      variant.attributes.every(a => 
        sAttr.some(sva => sva === a)
      )
    )

    if(variantIndex === -1) {
      shallowSelectedVariantsAttributes.push(variant.attributes)
    } else {
      shallowSelectedVariantsAttributes.splice(variantIndex, 1)
    }

    selectedVariantAttributesDispatch(shallowSelectedVariantsAttributes)
  }

  useEffect(() => {
    let timeOutId = setTimeout(() => {
      if(variant.remain !== remainCount) {
        variantChanger(remainCount, 'remain')
      }
      return false
    }, 300);
    return () => clearTimeout(timeOutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainCount, variantChanger, variant.attributes])

  useEffect(() => {
    if(variant.remain !== remainCount) {
      setRemainCount(variant.remain)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant.remain])

  return (
    <div className={`table-item ${variantDeletedClassName} ${variantSelectedClassName} ${selectedVariantMenuClassName} ${headerRowClassName} ${afterTopPanelClassName}`}>
      <div className="op-check hide-for-print">
        <MyCheckbox 
          condition={variantSelected} 
          actionHandler={variantCheckBoxHandler}        
        />
      </div>
      <div className="op-image">
        <ProductOneVariantDropzone
          variant={variant}
          />
      </div>
      <div className="op-variant">
        <div>
          <div>
            {variant.attributes?.map(a => a.value).join(' / ') }
          </div>
        </div>
      </div>

      <VariantLabelsControl
        variant={variant}
        remainCount={remainCount}
        countHandler={countHandler}
        tabIndexLevel={tabIndexLevel}
        variantChanger={variantChanger}
      />
    </div>
  )
}

export default ProductOneVariant
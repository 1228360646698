import React, { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router';
import { ImageDeleteResponse, ImageFile, ImageExtended } from '../../../dto/images/images.dto';
import { SortingItem } from '../../../dto/sorting.dto';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { getObjectsDiffsByFields, toastUp, validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import MyButton from '../../_utility-components/button/button';
import TopPagePanel from '../../_utility-components/top-page-panel';
import SeoSection from '../categories/seo';
import Summary from '../categories/summary-section';
import { Category } from './dto/categories.dto';
import ProductsCharacteristics from './product-page-modules/product-characteristics-modules/product-characteristics';
import useMounted from "../../../scripts/hooks/use-mounted";
import useUnload from "../../../scripts/hooks/use-unload";
import {MainRouteChild} from "../main-page";

export type CategoryImageModules = 'Category' | 'OgCategory'

interface CategoryPageLocationState {
  focusLabel?: string
}

interface Props extends MainRouteChild {

}

function CategoryPage(props: Props) {
  const _isMounted = useMounted();
  const categories = useAppSelector(state => state.categories || []);
  const selectedCategory = useAppSelector(state => state.selectedCategory);
  const labelsToFindDiffs = useMemo((): (keyof Category)[] => [
    'UUID', 'name', 'nav', 'sorting_id', 'show_date',
    'description', 'seo_description','og_title', 'seo_title', 'og_description', 'og_src'
  ], []);

  const initCategory = useMemo(() => selectedCategory && categories.find(c => c.id === selectedCategory.id), [categories, selectedCategory])

  const history = useHistory();
  const location = useLocation<CategoryPageLocationState>();
  const dispatch = useDispatch();

  const categoryDispatch = useCallback((categoryProps: Partial<Category>) => dispatch({type: 'PRODUCT_SELECTED_CATEGORY', payload: {...selectedCategory, ...categoryProps}}), [dispatch, selectedCategory])
  const categoriesDispatch = useCallback((categories: Category[]) => dispatch({type: 'CATEGORIES', payload: categories}), [dispatch])
  const sortingDispatch = useCallback((items: SortingItem[]) => dispatch({type: 'SORTING_ITEMS', payload: items}), [dispatch])

  const categoryChanger = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name: labelName, value} = e.target;
    categoryDispatch({[labelName]: value})
  }, [categoryDispatch])

  const categoryChanges = useMemo(() => {
    return selectedCategory && initCategory ? getObjectsDiffsByFields<Category>(selectedCategory, initCategory, labelsToFindDiffs) : {}
  }, [initCategory, labelsToFindDiffs, selectedCategory])

  const categoryHasChanges: boolean =
    (!!Object.keys(categoryChanges).length &&
    initCategory &&
    Number(location.pathname.split('/').at(-1)) === initCategory.id) || false;

  useEffect(() => {
    if(location.state?.focusLabel) {
      const input = document.querySelector<HTMLInputElement>(`.category-page__summary__inputs-wrapper [name="${location.state?.focusLabel}"]`)
      input?.focus()
    }
  }, [location.state])

  useEffect(() => {
    const id = location.pathname.split('/').at(-1)
    if(id && categories.length && +id !== selectedCategory?.id) {
      const foundCategory = categories.find(c => c.id === +id)
      if(!foundCategory)
        history.replace('/app/categories');
      else 
        categoryDispatch(foundCategory);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, categoryHasChanges);
  
  useEffect(() => {
    props.setCurrentPage('categories')

    mercheryFetch<SortingItem[]>('sorting', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      sortingDispatch(res.records)
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(initCategory && selectedCategory && initCategory.top !== selectedCategory.top) {
      categoryDispatch({top: initCategory.top})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCategory?.top]);

  const deleteImage = useCallback(async (module: CategoryImageModules) => {
    const fieldName: keyof Category = module === 'Category' ? 'src' : 'og_src'
    if(!selectedCategory) return false;

    return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
      filters: {
        module_id: selectedCategory.id,
        module: module
      }
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      batch(() => {
        categoryDispatch({[fieldName]: undefined})
        categoriesDispatch(categories.map(c => c.id !== selectedCategory.id ? c : {...c, [fieldName]: undefined}))
      })
    })
  }, [categories, categoriesDispatch, categoryDispatch, selectedCategory])

  const sendImage = useCallback(async (newFiles: ImageFile[], module: CategoryImageModules) => {
    const isOgImage = module === 'OgCategory';
    const fieldName: keyof Category = isOgImage ? 'og_src' : 'src';
    const newImage = newFiles.at(0)

    if(!selectedCategory || !newImage) {
      return undefined
    }

    if(selectedCategory[fieldName]) {
      await deleteImage(module)
    }

    const requestBody =  {
      module_id: selectedCategory.id,
      newImages: newFiles,
      cropSizes: ['original', 'medium', 'small'],
      module
    }

    return mercheryFetch<ImageExtended[]>('images/create', 'POST', requestBody, {
      withoutToastUp: true
    })
    .then((res) => {
      if(!_isMounted.current || !res.success) {
        initCategory && categoryDispatch(initCategory)
        return res
      }

      const createdImage = res.records.at(0);
      createdImage && batch(() => {
        categoryDispatch({[fieldName]: createdImage})
        categoriesDispatch(categories.map(c => c.id !== selectedCategory.id ? c : {...c, [fieldName]: createdImage}))
      })

      return res
    })
  }, [categories, categoriesDispatch, categoryDispatch, deleteImage, initCategory, selectedCategory])
  
  const updateCategory = useCallback(async () => {
    if(categoryChanges && Object.keys(categoryChanges).length && selectedCategory) {
      await mercheryFetch<Category[]>('category', 'PATCH', {
        changes: [{id: selectedCategory.id, ...categoryChanges}]
      })
      .then(res => {
        if(!_isMounted.current) return false;
        if(!validateResponse(res)) {
          toastUp(res.message)

          initCategory && categoryDispatch(initCategory)
          return false
        } 

        const updatedCategory = res.records.find(c => c.id === selectedCategory.id);
        updatedCategory && batch(() => {
          categoryDispatch(updatedCategory)
          updatedCategory && categoriesDispatch(categories.map(c => c.id !== updatedCategory.id ? c : updatedCategory))
        })
      })
    }

    if(categoryChanges.og_src) {
      await deleteImage('OgCategory')
    }
  }, [categories, categoriesDispatch, categoryChanges, categoryDispatch, deleteImage, initCategory, selectedCategory])

  const cancelBtnHandler = useCallback(() => {
    if(initCategory) {
      categoryDispatch(initCategory)
    } else if(selectedCategory) {
      categoriesDispatch(categories.filter(c => c.id !== selectedCategory.id))
    }
  }, [categories, categoriesDispatch, categoryDispatch, initCategory, selectedCategory])

  if(!selectedCategory) {
    return null
  }

  return (
    <div className='category-page'>
      <Summary
        categoryChanger={categoryChanger}
        categoryDispatch={categoryDispatch}
        deleteImage={deleteImage}
        sendImage={sendImage}
      />

      {!selectedCategory?.newCategory ? <>
        <SeoSection
          changer={categoryChanger}
          sendImage={(files) => sendImage(files, 'OgCategory')}
          deleteImage={() => deleteImage('OgCategory')}
          dispatch={categoryDispatch} 
          item={selectedCategory}
          disabled={selectedCategory.newCategory}
        />

        <section className='category-page__characteristics'>
          <ProductsCharacteristics
            isCreatePage={false}
            page={'categories'}
            productCategory={selectedCategory.id}
          />
        </section>
      </> : null}

      <TopPagePanel topPanelOpened={categoryHasChanges}
        fixed={true}
      >
        <Prompt
          when={_isMounted.current}
          message='Остались несохраненные изменения, покинуть страницу?'
        />
        <div className="left">
          <div className="text-div">
            Несохраненные изменения
          </div>
        </div>
        <div className="right">
          <MyButton
            id={'product-cancel-btn'}
            className="dark-btn"
            onClick={cancelBtnHandler}
          >
            Отменить
          </MyButton>
          <MyButton
            id={'product-confirm-btn'}
            className="blue-btn"
            onClick={updateCategory}
          >
            Сохранить
          </MyButton>
        </div>
      </TopPagePanel>
    </div>
  );
}

export default CategoryPage;
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import CategoriesFilterAndControl, { CategoryFilterChanger } from '../categories/categories-filter-and-control';
import CategoryPage from './category';
import { Category } from './dto/categories.dto';
import {MainRouteChild} from "../main-page";

interface Props extends MainRouteChild {
}

function Categories (props: Props) {
  const _isMounted = useRef(true)
  const selectedCategory = useAppSelector(state => state.selectedCategory);
  const categories = useAppSelector(state => state.categories);;

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    props.setCurrentPage('categories');
    // category
    mercheryFetch<Category[]>('category','GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      categoriesDispatch(res.records)
    })

    return () => {_isMounted.current = false}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(categories?.length) {
      const locationCategoryId = Number(location.pathname.split('/').at(-1));
      if(locationCategoryId !== selectedCategory?.id && !isNaN(locationCategoryId) ) {
        const foundCategory = categories.find(c => c.id === locationCategoryId)
        foundCategory && selectCategoryDispatch(foundCategory)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const selectCategoryDispatch = useCallback((item: Category) => dispatch({type: 'PRODUCT_SELECTED_CATEGORY', payload: item}), [dispatch])
  const categoriesDispatch = useCallback((items: Category[]) => dispatch({type: 'CATEGORIES', payload: items}), [dispatch])

  const categoryHandler = useCallback<CategoryFilterChanger>((categoryId) => {
    const category = categories && categories.find(c => c.id === categoryId)
    if(category) {
      history.push('/app/categories/' + categoryId)
      selectCategoryDispatch(category)
    }
  }, [categories, history, selectCategoryDispatch])
  
  // useEffect(() => {
  //   console.log('useeffect history.push')
  //   if(selectedCategory) {
  //     const locationCategoryId = Number(location.pathname.split('/').at(-1));
  //     if(isNaN(locationCategoryId) || (locationCategoryId !== selectedCategory.id)) {
  //       history.push('/app/categories/' + selectedCategory.id)
  //     }
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedCategory])

  return (
    <div className="categories">
      <div className={``}>
        <h1>Категории</h1>
      </div>

      <div className='categories__inner'>
        <CategoriesFilterAndControl
          filtersMod={false}
          withNoCategory={false}
          categoryHandler={categoryHandler}
        />

        <Switch>
          <Route
            exact
            path={'/app/categories/:categoryid'}
            render={(routeProps) =>
              <CategoryPage
                {...props}
                {...routeProps} />
            }
          />

          <Route
            render={(routeProps) =>
              <DefaultCategoryPage
                {...props}
                {...routeProps} />
            }
          />
          </Switch>
      </div>
    </div>
  );
}

function DefaultCategoryPage (props: Props) {
  useEffect(() => {
    props.setCurrentPage('categories')
  }, []);

  return (
    <div>Выберите категорию</div>
  )
  ;
}

export default Categories;
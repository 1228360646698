import React from 'react';
import MyCheckbox from '../../../_utility-components/checkbox/my-checkbox';
import HeaderCell from "../../../_utility-components/common-table/header-cell";

interface Props {
  headerCheckHandler: () => void,
  checkboxIsActive: boolean,
}

function SetTableHeader({
  headerCheckHandler,
  checkboxIsActive,
}: Props) {
  return (
    <div className='table__header sets__row'>
      <HeaderCell className='right-align'
        children={
          <MyCheckbox text={''} 
            condition={checkboxIsActive}
            actionHandler={headerCheckHandler}
          />
        }/>
      <HeaderCell children={'Фото'} className="center-align"/>
      <HeaderCell children={'Комплект'}/>
      <HeaderCell children={'Коллекция'}/>
      <HeaderCell children={'Товаров'} className='center-align'/>
      <HeaderCell children={'Статус'}/>
    </div>
  );
}

export default SetTableHeader;
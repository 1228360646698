import QueryString from 'qs';
import React, { useContext, useEffect } from 'react';
import HtmlParser from 'react-html-parser';
import { batch } from 'react-redux';
import { mercheryFetch } from '../../../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../../../scripts/functions';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../../scripts/hooks/use-mounted';
import { usePossibleValues } from '../../../../../../scripts/hooks/use-possible-values';
import Bubbles from '../../../../../_utility-components/bubbles';
import MyButton from '../../../../../_utility-components/button/button';
import MyInput from '../../../../../_utility-components/input/index';
import Popup from '../../../../../_utility-components/popup';
import { DimensionsPreset, OrderDelivery } from '../../../dto/delivery';
import { OrderDeliveryContext } from '../delivery';
import { CdekDeliveryContext } from './cdek';
import { CdekCityDto } from './dto/cdek.dto';

export function CdekCityAndDimensions() {
  const settings = useAppSelector(state => state.settings)
  const {
    setSearch, 
    search, 
    setShowData,
    dataLoading, 
    data, 
    showData
  } = usePossibleValues<CdekCityDto, 'city'>({
    urlPath: 'integrations/cdek/cities',
    textField: 'city',
    params: {
      pagination: {
        page: 0,
        size: 10
      }
    }
  });
  const _isMounted = useMounted()

  const {
    delivery,
    changeSelectedDelivery,
    setCurrentTariff,
    setFrom,
    itemsWeight,
  } = useContext(OrderDeliveryContext);

  const {
    setCities
  } = useContext(CdekDeliveryContext)

  useEffect(() => {
    if(delivery?.city_code) {
      const query = QueryString.stringify({
        filters :{
          code: delivery?.city_code
        }
      })
  
      mercheryFetch<CdekCityDto[]>(`integrations/cdek/cities?${query}`, 'GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }
        setCities(res.records)
      })
    }
  }, [])

  useEffect(() => {
    setCities(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = ( 
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: keyof OrderDelivery
  ) => {
    const value = e.target.value;

    batch(() => {
      setFrom(null)
      changeSelectedDelivery({[label]: value})
      setCurrentTariff(null)
    })
  };

  const cityInputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    batch(() => {
      setFrom(null)
      changeSelectedDelivery({
        city: value,
        city_code: null
      })
      setCurrentTariff(null)
      setSearch(event.target.value)
    })
  }
  
  const cityHandler = (city: CdekCityDto) => {
    batch(() => {
      changeSelectedDelivery({
        city: city.city,
        city_code: String(city.code)
      })
      setShowData(false)
    })
  };

  const deliveryDimensionsPresets = settings.find(setting => setting.callname === 'dimensions_presets')?.value as undefined | null | DimensionsPreset[]
  const selectDimesionsPreset = (item: DimensionsPreset) => {
    changeSelectedDelivery({
      width: item.width,
      height: item.height,
      length: item.length
    })
  }

  const returnItemsWeight = () => {
    batch(() => {
      setFrom(null)
      changeSelectedDelivery({weight: itemsWeight})
      setCurrentTariff(null)
    })
  }

  return (
    <div className="op-delivery-cdek__city-dimensions">
      <div className="op-delivery-cdek__city-dimensions__column op-delivery-cdek__city-dimensions__column--city-column">
        <h4 className='wide-text-font-xxs'>
          ГОРОД
        </h4>

        <MyInput
          id="delivery-city"
          name="delivery-city"
          myClassName='delivery-city-input'
          placeholder="Город получателя"
          value={delivery?.city || ''}
          onChange={cityInputOnChangeHandler}
        />

        {showData ? (
          <Popup
            className={'possible-values-popup op-delivery-cdek__city-dimensions__possible-cities'}
            popupClose={() => setShowData(false)}
            changingDirection={true}
          >
            <div className='popup-wrapper popup-group'>
              {dataLoading ? 
                <div className='popup-element'>
                  Загрузка...
                </div>
              : !data.length ? 
                <div className='popup-element'>
                  Ничего не найдено
                </div>
              :
                data.map((n) => {
                  let result = '';
                  const index = n.city.toLowerCase().indexOf(search.toLowerCase());
                  
                  if (index !== -1) {
                    const beforeMatch = n.city.substring(0, index);
                    const match = n.city.substring(index, index + search.length);
                    const afterMatch = n.city.substring(index + search.length);
                    
                    result += `${beforeMatch}<strong>${match}</strong>${afterMatch}`;
                  }

                  return (
                    <MyButton
                      key={n.id}
                      removeDefaultClass
                      onClick={() => cityHandler(n)}
                      className='popup-element'
                    >
                      {HtmlParser(result)}, {n.region}, {n.country}
                    </MyButton>
                  )
                }
                )}
            </div>
          </Popup>
        ) : null}
      </div>

      <div className="op-delivery-cdek__city-dimensions__column">
        <div className='delivery-dimesions-presets__wrapper'>
          <h4 className='wide-text-font-xxs'>
            ПАРАМЕТРЫ ПОСЫЛКИ
          </h4>
          
          {deliveryDimensionsPresets ? 
            <Bubbles
              onClick={selectDimesionsPreset}
              items={deliveryDimensionsPresets.map(preset => ({...preset, id: preset.name}))} 
              tooltipConstructor={item => `${item.length}x${item.width}x${item.height}`}
            />
          : null}
        </div>
        
        <div className='op-delivery-cdek__city-dimensions__inputs'>
          <MyInput
            id="delivery-length"
            name="delivery-length"
            myClassName='delivery-length-input'
            placeholder="Длина, см"
            type={'number'}
            min={0}
            value={delivery?.length || ''}
            onChange={e => handleChange(e, 'length')}
          />
          
          <MyInput
            id="delivery-width"
            name="delivery-width"
            myClassName='delivery-width-input'
            placeholder="Ширина, см"
            type={'number'}
            min={0}
            value={delivery?.width || ''}
            onChange={e => handleChange(e, 'width')}
          />

          <MyInput
            id="delivery-height"
            name="delivery-height"
            myClassName='delivery-height-input'
            placeholder="Высота, см"
            type={'number'}
            min={0}
            value={delivery?.height || ''} 
            onChange={e => handleChange(e, 'height')}
          />
        </div>
      </div>

      <div className='op-delivery-cdek__city-dimensions__column'>
        <h4></h4>
        <MyInput
          id="delivery-weight"
          name="delivery-weight"
          myClassName='delivery-weight-input'
          placeholder="Вес, кг"
          type={'number'}
          min={0}
          value={delivery?.weight || ''}
          onChange={e => handleChange(e, 'weight')}
          innerChildren={
            delivery?.weight && delivery.weight !== itemsWeight ?
              <i className="icofont-refresh merchery-input__icon"
                 onClick={returnItemsWeight}
              ></i>
            : null
          }
        />
      </div>
    </div>
  )
}

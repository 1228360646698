import React from 'react';
import { useDispatch } from 'react-redux';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import { CurrentPromoCode } from './promo';
import { PromocodeStatus } from './promocode-status';

interface PromoEditProps {
  setCurrentPromocode: (promoCode: CurrentPromoCode) => void;
  isPercent: boolean;
  popupClose: () => void;
  setPromo: () => void;
  createCodeHandler: () => void,
  currentPromocode: CurrentPromoCode,
}
export function PromoEdit({
  setCurrentPromocode,
  isPercent, 
  setPromo,
  popupClose,
  createCodeHandler,
  currentPromocode,
}: PromoEditProps) {

  const dispatch = useDispatch()
  const setCreateNewCodeState = (value: boolean) => dispatch({ type: 'UI_PROMO_CREATE_STATE', payload: value })

  return <>
    <div className="discount-promo-header promo">
      <h2>Промокод</h2>
    </div>

    <div className="discount-promo-set-discount-promo">
      <div className="discount-promo-set-discount-promo-top promo">
        <MyInput
          placeholder="Введите действительный промокод"
          onChange={(e) => setCurrentPromocode({
            ...currentPromocode,
            code: e.target.value
          })}
          value={currentPromocode?.code || ''}
          name="promo"
          withFocus={false}
          withBlur={false}
          withBind='confirm-discount' />
      </div>

      <div className="discount-promo-set-discount-promo-bottom promo">
        {!currentPromocode?.code ?
          <MyButton
            removeDefaultClass
            className="promo-create-btn"
            onClick={() => setCreateNewCodeState(true)}>
            Создать новый
          </MyButton>
          :
          <PromocodeStatus
            createCodeHandler={createCodeHandler}
            value={currentPromocode?.price}
            isPercent={isPercent}
            />}
      </div>
    </div>
    <div className="discount-promo-btns">
      <MyButton
        className="white-btn"
        onClick={popupClose}>
        Отменить
      </MyButton>

      <MyButton
        id="confirm-discount"
        className="blue-btn"
        onClick={setPromo}>
        Сохранить
      </MyButton>
    </div>
  </>;
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import SelectInput from '../../../../../_utility-components/select-input/index';
import { CharScopeDto, CharsLabelDto, CharsTypesDto } from '../../../dto/chars.dto';

interface Props {
  labelChangeByField: (changes: Partial<CharsLabelDto>) => void;
  label: CharsLabelDto;
}

function CharLabelTypeSelect({
  label,
  labelChangeByField,
}: Props) {
  const labelTypeId = useMemo(() => label.type_id, [label]);
  const labelTypes = useAppSelector(state => state.labelTypes);
  const scopes = useAppSelector(state => state.scopes);
  const getSelectedType = useCallback((typeId: Id) => labelTypes.find(type => type.id === typeId), [labelTypes]);
  const [selectedType, setSelectedType] = useState(getSelectedType(labelTypeId));

  const dispatch = useDispatch();
  const scopesDispatch = (scopes: CharScopeDto[]) => dispatch({type: 'CHARS_SCOPES', payload: scopes});

  useEffect(() => {
    if(selectedType && labelTypeId !== selectedType.id) {
      console.log(getSelectedType(labelTypeId))
      setSelectedType(getSelectedType(labelTypeId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelTypeId, selectedType])

  const selectHandler = useCallback((type: CharsTypesDto) => {
    const newType = getSelectedType(type.id)

    if(!newType) {
      return false
    }

    const changedScopes = scopes.map((scope) => {
      if(scope.label_id === label.id && (newType.id === 4 || label.type_id === 4)) {
        return {
          ...scope,
          value: null,
          value_id: null,
        }
      }
      return scope
    })

    batch(() => {
      setSelectedType(newType)
      labelChangeByField({
        type_id: newType.id,
        type_name: newType.name,
      })
      scopesDispatch(changedScopes)
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelTypes, labelChangeByField])

  if(!selectedType) {
    return <>
      {label.type_name}
    </>
  }
  
  return (
    <SelectInput
      className={'char-label_type-select my-select-input--tall-input'}
      items={labelTypes}
      selectedItem={selectedType}
      valueLabelName={'desc'}
      selectHandler={selectHandler}
    /> 
  );
}

export default CharLabelTypeSelect
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyInput from '../../../../../_utility-components/input/index';
import { CharGroupsDto, CharsLabelDto } from '../../../dto/chars.dto';

interface Props {
  label: CharsLabelDto
  group: CharGroupsDto
  charEditing: boolean
  labelChangeByField: (label: Partial<CharsLabelDto>) => void
}

function CharLabelHeader({
  label,
  group,
  charEditing,
  labelChangeByField
}: Props) {
  const labels = useAppSelector(state => state.labels.filter(l => l.char_group === group.id))
  const [labelName, setLabelName] = useState(label.name);
  const nameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setLabelName(event.target.value), [label]);

  useEffect(() => {
    if(label.name !== labelName) {
      setLabelName(label.name)
    }
  }, [label])

  useEffect(() => {
    let timeOutId = setTimeout(() => {
      if(label.name !== labelName) {
        labelChangeByField({name: labelName})
      }
    }, 300);
    return () => clearTimeout(timeOutId)
  }, [labelName]);

  const anotherLabelHaveSameName = useMemo(() => {
    const labelWithThisName = labels.find(l => l.name.toLowerCase() === labelName.toLowerCase());
    return labelWithThisName && labelWithThisName.id !== label.id
  }, [labels, labelName])

  return (
    charEditing ? 
      <div className='char-label__header--editing'>
        {/* <h5 className='char-label__header header-font-s'>Редактирование названия поля</h5> */}
        <MyInput
          value={labelName || ''}
          hasError={anotherLabelHaveSameName}
          placeholder={anotherLabelHaveSameName ? 'Повторяющееся название в одной группе' : ' '}
          myClassName={!anotherLabelHaveSameName ? `placeholder--animation-off` : ''}
          onChange={nameHandler}
        />
      </div>
    : 
      <h5 className='char-label__header header-font-s'>
        {`${labelName}${label.type_name === 'number' ? `, ${label.unit}` : ''}`}
      </h5>
  )
}

export default CharLabelHeader
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import tinymce from 'tinymce';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { scrollPageTo, validateResponse } from '../../../../../scripts/functions';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import useRouteId from '../../../../../scripts/hooks/use-route-id';
import MyButton from '../../../../_utility-components/button/button';
import MyInput from '../../../../_utility-components/input/index';
import { ExtendedProduct } from '../../dto/products.dto';
import { ProductInputHandler } from '../../product';
import ProductEditor from '../description-editor';

interface Props {
  pageIsCreatePage: boolean,
  initProduct: React.MutableRefObject<ExtendedProduct | undefined>
}

function ProductDescription({
  pageIsCreatePage,
  initProduct, 
}: Props) {
  const _isMounted = useMounted()
  const product = useAppSelector(state => state.product)
  const productId = useRouteId('productid')
  const detailedDescChangeActive = useAppSelector(state => state.detailedDescChangeActive);
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const descriptionChanges = useMemo(() => {
    if(moySkladIntegrationOn || !initProduct.current || !product) 
      return false
    
    const changes: Partial<ExtendedProduct> = {}

    if(product.anons !== initProduct.current?.anons) {
      changes.anons = product.anons
    }

    if(product.description !== initProduct.current?.description) {
      changes.description = product.description
    }

    return Object.keys(changes).length ? changes : false
  }, [initProduct, moySkladIntegrationOn, product])

  const dispatch = useDispatch();
  const setDescriptionEditing = useCallback((bool: boolean) => dispatch({type: 'PRODUCT_DETAILED_DESCRIPTION_EDITING', payload: bool}), [dispatch]);
  const productDispatch = (item: ExtendedProduct | undefined) => dispatch({type: 'PRODUCT_ITEM', payload: item});

  const inputHandler: ProductInputHandler = <T extends keyof ExtendedProduct,>(label: T, value: ExtendedProduct[T]) => {
    if(!product) {
      return false
    }

    productDispatch({
      ...product, 
      [label]: value
    })
  }
  const handleEditorChange = (value: ExtendedProduct['description']) => inputHandler('description', value)

  const descriptionEditSwitch = useCallback(() => {
    setDescriptionEditing(!detailedDescChangeActive)
    if(!detailedDescChangeActive) scrollPageTo('.inputs-row.description')
  }, [detailedDescChangeActive, setDescriptionEditing])

  const updateDescLabels = () => {
    if(moySkladIntegrationOn) return false;
    console.log('descriptionChanges', descriptionChanges)
    return mercheryFetch<ExtendedProduct[]>('products', 'PATCH', {
      changes: [{
        id: productId,
        ...descriptionChanges
      }]
    })
  }

  const descriptionToInitialState = async () => {
    if(!product || !initProduct.current) {
      return false
    }

    productDispatch({
      ...product,
      description: initProduct.current.description,
      anons: initProduct.current.anons,
    })
    setDescriptionEditing(detailedDescChangeActive)

    const descriptionEditor = tinymce.get('product-description-editor')
    if(descriptionEditor) descriptionEditor.setContent(initProduct.current.description)
  }

  const saveDescriptionChanges = () => {
    if(moySkladIntegrationOn) return false

    const updateProduct = updateDescLabels()
    if(!updateProduct) {
      return false
    }

    updateProduct
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false

      if(res.statusCode === 204) {
        descriptionToInitialState()
      }

      const product = res.records[0]
      
      batch(() => {
        if(initProduct.current) {
          initProduct.current = {
            ...initProduct.current,
            description: product.description,
            anons: product.anons,
          }
        }
        console.log(initProduct.current)
        productDispatch(product)
        setDescriptionEditing(false)
      })
    })
  }

  if(!product) {
    return null
  }

  return (
    <div className="product-page-element product-page-description">
      <div className="product-page-description-header">
        <h3 className="product-page-h3">Описание товара</h3>

        {moySkladIntegrationOn || pageIsCreatePage ? 
          null
        : descriptionChanges ?
          <div className="op-delivery-change-btns">
            <MyButton className="white-btn"
              onClick={descriptionToInitialState} 
            >
              Отменить
            </MyButton>
            <MyButton id="merchery-btn" className="blue-btn" 
              onClick={saveDescriptionChanges} 
            >
              Сохранить изменения в описании
            </MyButton>
          </div>
        : detailedDescChangeActive ?
          <MyButton 
            className='product-page-description-edit-btn edit-btn'
            removeDefaultClass
            onClick={descriptionEditSwitch} 
          >
            <i className="icofont-not-allowed"></i>
          </MyButton>
        : 
          <MyButton
            className='product-page-description-edit-btn edit-btn'
            removeDefaultClass
            onClick={descriptionEditSwitch} 
          >
            <i className='icofont-ui-edit'></i>
          </MyButton>
        }
      </div>

      <AnonsLabel
        inputHandler={inputHandler}
      /> 

      <DescriptionLabel
        handleEditorChange={handleEditorChange}
      />
    </div>
  );
}

export const DescriptionLabel = ({
  handleEditorChange,
}: {
  handleEditorChange: (value: ExtendedProduct['description']) => void
}) => {
  const product = useAppSelector(state => state.product)
  const detailedDescChangeActive = useAppSelector(state => state.detailedDescChangeActive)
  const [description, setDescription] = useState<ExtendedProduct['description'] | undefined>(product?.description || undefined);

  useEffect(() => {
    if(product?.description !== description) {
      setDescription(product?.description || undefined)
    }
  }, [product?.description])

  useEffect(() => {
    if(description && product?.description !== description) {
      handleEditorChange(description)
    }
  }, [description])

  return (
    <div className={`inputs-row description`}>
      <h4 className="wide-text-font-xxs">Подробное описание</h4>
  
      {detailedDescChangeActive ? 
        <ProductEditor
          handleEditorChange={setDescription}
          description={description || ''}
        />
      : <div className="text-font-xs">{ReactHtmlParser(description || '')}</div>}
    </div>
  )
}

const AnonsLabel = ({
  inputHandler,
}: {
  inputHandler: ProductInputHandler
}) => {
  const product = useAppSelector(state => state.product)
  const detailedDescChangeActive = useAppSelector(state => state.detailedDescChangeActive)
  const [anons, setAnons] = useState(product?.anons || undefined);

  useEffect(() => {
    if(product?.anons !== anons) {
      setAnons(product?.anons || undefined)
    }
  }, [product?.anons])

  useEffect(() => {
    if(anons && product?.description !== anons) {
      inputHandler('anons', anons)
    }
  }, [anons])

  return (
    <div className={`inputs-row short-desc`}>
      <h4 className="wide-text-font-xxs">Краткое описание</h4>

      {detailedDescChangeActive ? 
        <MyInput
          value={anons || ''}
          required={false}
          shortInput={true}
          noPlaceholder
          min={0}
          onChange={e => inputHandler('anons', e.target.value)}
        />
      : <div className="text-font-xs">{anons}</div>}
    </div>
  )
}

export default memo(ProductDescription);
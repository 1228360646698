import React, {memo, useContext} from 'react';
import { batch, useDispatch } from 'react-redux';
import { dateToRuLocale } from 'src/scripts/utils/date-to-locale';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import RestoreButton from '../../../_utility-components/restore-button';
import { Order } from '../dto/order.dto';
import { OrderContext } from '../order-page';
import ManagersOnPage from "../../../_utility-components/manager-on-page";

function OrderPageHeader() {
  const orders = useAppSelector(state => state.orders)
  const dispatch = useDispatch();
  const ordersDispatch = (orders: Order[]) => dispatch({type: 'ORDERS', payload: orders});
  const thisOrderDispatch = (order: Order) => {
    batch(() => {
      setOrderDeleted(false)
      ordersDispatch(orders.length ? orders.map(c => c.id !== order.id ? c : order) : [order])
    })
  }

  const {
    order,
    orderDeleted,
    setOrderDeleted,
    managersOnPage
  } = useContext(OrderContext)

  if(!order) {
    return null
  }

  return (
    <div className="order-page-header">
      <div className='order-page-header__left-inner'>
        <h2>Заказ {order.id}</h2> 
        <span>{`от ${dateToRuLocale(order.date)}`}</span>

        {orderDeleted ?
          <div 
            className='wide-text-font-xxs' 
            style={{
              padding: '4px 25px',
              background: '#d75a5a',
              textTransform: 'uppercase',
              borderRadius: '4px',
              color: 'white'
            }}
          >
            удален
          </div>
        : null}

      </div>
      <div className='order-page-header__right-inner'>
        {orderDeleted ?
          <RestoreButton<Order>
            restoreUrlPath={`orders/restore/${order.id}`}
            dispatchHandler={thisOrderDispatch}
          />
        : null}

        <ManagersOnPage
          managersOnPage={managersOnPage}
          size={'small'}
        />
      </div>
    </div>
  );
}

export default memo(OrderPageHeader);
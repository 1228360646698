import React, { useContext } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useAppSelector } from "../../../../scripts/pre-type/use-selector";
import { useTabIndex } from "../../../../scripts/hooks/use-tabindex";
import FallbackComponent from "../../../_utility-components/error-boundary";
import { ExtendedProduct } from "../../products/dto/products.dto";
import { ProductRow } from "../../products/products-items/product-items";
import { productLikeFields } from "../../products/products-items/product-items-utils";
import { CollectionContext } from "../collection-page";

function ProductToBeAddedInCollection ({
  product,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: {
  product: ExtendedProduct,
  canBeAddedSelected: ExtendedProduct[],
  setCanBeAddedSelected: (items: ExtendedProduct[]) => void,
}) {
  const showRemain = useAppSelector(state => state.settings.find(setting => setting.callname === 'remainder'))?.value
  const {
    collection,
  } = useContext(CollectionContext);
  const tabIndex = useTabIndex(2);

  const addSelected = (productFromHandlerRow: ExtendedProduct) => {
    const productToBeAdded = productFromHandlerRow || product;
    const selected = canBeAddedSelected.some(item => item.id === productToBeAdded.id)

    if(selected) {
      removeSelected()
      return false
    } 

    setCanBeAddedSelected([...canBeAddedSelected, productFromHandlerRow])
  }

  const removeSelected = () => {
    setCanBeAddedSelected(canBeAddedSelected.filter(item => item.id !== product.id))
  }

  const canBeAddedSelectedProduct = canBeAddedSelected?.find(selected => selected.id === product.id)

  const externalIdForLabels = !canBeAddedSelectedProduct ? product.external_id : canBeAddedSelectedProduct.external_id
  const remainForLabels = !canBeAddedSelectedProduct ? product.remain : canBeAddedSelectedProduct.remain

  const withVariants = product?.variants?.length

  const alreadyAdded = (collection?.items || []).some(item => item.item_id === product.id && item.item_type === 'product')
  const disabled = (!showRemain || (!withVariants && !remainForLabels)) || alreadyAdded;

  const shownProductFields = [
    'checkbox',
    'src',
    'name',
    { key: 'external_id', options: { 
      remain: remainForLabels, 
      external_id: externalIdForLabels 
    }},
    'price'
  ] as const;

  return (
    <ProductRow
      item={product}
      shownFields={shownProductFields}
      componentsFields={productLikeFields}
      className={`row-item collections__add-items-rows--template`}
      changer={undefined}
      selectHandler={addSelected}
      disabled={disabled}
      tabIndex={tabIndex}
      selected={canBeAddedSelectedProduct !== undefined || alreadyAdded}/>
  )
}

export default withErrorBoundary(ProductToBeAddedInCollection, {FallbackComponent: FallbackComponent})
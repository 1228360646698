/* eslint-disable react-hooks/exhaustive-deps */
import qs from 'qs';
import React, { useContext, useEffect } from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { uuidv4, validateResponse } from '../../../../scripts/functions';
import useMounted from '../../../../scripts/hooks/use-mounted';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import MyCheckbox from '../../../_utility-components/checkbox/index';
import MyInput from '../../../_utility-components/input/index';
import VisualSeparatorLine from '../../../_utility-components/visual-separator-line';
import { ExtendedProduct } from '../dto/products.dto';
import { ProductVariantExtended, ProductVariantFilter } from '../dto/variants.dto';
import { ProductInputHandler, ProductPageContext } from '../product';
import { ProductOption } from './dto/options.dto';
import ProductOneOption from './one-option';

interface Props {
  isCreate: boolean,
  inputHandler: ProductInputHandler,
}

export type UpdateOptionsHandler = (id: Id, option?: ProductOption) => boolean;

function ProductOptions({
  isCreate,
  inputHandler,
}: Props) {
  const _isMounted = useMounted()
  const product = useAppSelector(state => state.product)
  const tabIndexLevel = 1
  const tabIndex = useTabIndex(tabIndexLevel)
  const options = useAppSelector(state => state.productOptions)
  const initOptions = useAppSelector(state => state.initOptions)
  const initVariants = useAppSelector(state => state.initVariants)
  const additionalOptions = useAppSelector(state => state.additionalOptions)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const { setOptionsLoaded } = useContext(ProductPageContext);
  
  const dispatch = useDispatch()
  const optionsDispatch = (options: ProductOption[]) => dispatch({ type: 'PRODUCTS_OPTIONS', payload: options })
  const initOptionsDispatch = (options: ProductOption[]) => dispatch({ type: 'PRODUCTS_INIT_OPTIONS', payload: options })

  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })

  const additionalOptionsDispatch = (additionalOptions: boolean) => dispatch({ type: 'PRODUCT_ADDITIONAL_OPTIONS_ACTIVE', payload: additionalOptions })

  const selectedVariantAttributesDispatch = (attrs: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })
  const selectedVariantFiltersDispatch = (filters: ProductVariantFilter[]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_FILTERS', payload: filters })

  const productid = useRouteId('productid')

  useEffect(() => {
    if(!isCreate) {
      getOptions()
    } else {
      batch(() => {
        setOptionsLoaded(true)
        additionalOptionsDispatch(false)
        initOptionsDispatch([])
        optionsDispatch([])
        variantsDispatch([])
        initVariantsDispatch([])
        selectedVariantAttributesDispatch([])
        selectedVariantFiltersDispatch([])
      })
    }
  }, [])

  const getOptions = async () => {
    const params = qs.stringify({
      product_id: productid,
    }, {arrayFormat: 'comma'})

    const res = await mercheryFetch<ProductOption[]>(`products/options?${params}`, 'GET', {})

    if(!_isMounted.current || !validateResponse(res)) 
      return false

    const options = res.records

    batch(() => {
      setOptionsLoaded(true)
      initOptionsDispatch(options)
      optionsDispatch(options)
      additionalOptionsDispatch(!!options.length)
    })

    return res
  }

  const updateOption: UpdateOptionsHandler = (id: Id, option?: ProductOption) => {
    if(moySkladIntegrationOn) 
      return false

    const shallowOptions = 
      option !== undefined ? 
        options.map(op => op.id === id ? option : op) 
      : options.filter(op => op.id !== id);

    batch(() => {
      optionsDispatch(shallowOptions)
      if(shallowOptions.length === 0) {
        additionalOptionsDispatch(false)
      }
    })

    return true
  }

  const addBlankOption = () => {
    const lastOrder = options.length ? options.reduce((a,b) => a.order > b.order ? a : b).order : 0;

    const newOption: ProductOption = {
      id: uuidv4(),
      product_id: productid,
      name_id: uuidv4(),
      title: '',
      values: [],
      order: lastOrder + 1,
      notCreated: true,
      notSaved: true,
      newName: true
    }

    optionsDispatch([
      ...options, 
      newOption
    ])
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: keyof ExtendedProduct, type?: string) => {
    const typedValue = type === 'number' ? Number(e.target.value) : e.target.value
    inputHandler(label, typedValue)
  }

  const additionalOptionsHandler = () => {
    if(moySkladIntegrationOn) return false;

    batch(() => {
      additionalOptionsDispatch(!additionalOptions)

      if(!options.length || additionalOptions) {
        optionsDispatch([])
        variantsDispatch([])
        selectedVariantAttributesDispatch([])
        selectedVariantFiltersDispatch([])
      }

      if(additionalOptions) {
        optionsDispatch([])
        initVariantsDispatch([])
      }

      if(!additionalOptions && initOptions.length) {
        optionsDispatch([...initOptions])
        variantsDispatch([...initVariants])
      }
    })
  }

  const onDragStart = () => {
    if(moySkladIntegrationOn) return false
    const elemnet = document?.activeElement as HTMLElement | null
    elemnet?.blur()
  }

  const onDragEnd: OnDragEndResponder = (result) => {
    try {
      if(moySkladIntegrationOn || !result.destination) {
        return false
      }
      
      const newList = [...options];

      if (result.type.includes("option")) {
        const [removed] = newList.splice(result.source.index, 1);
        newList.splice(result.destination.index, 0, removed);
        
        const updatedOrders = newList.map((item, index) => ({ ...item, order: index + 1 }));
    
        optionsDispatch(updatedOrders);
      }
    
      // if (result.type.includes("value")) {
      //   // Split draggableId into [optionOrder, valueId]
      //   const [optionOrder, valueId] = result.draggableId.split('-');
    
      //   // Map through the list to create a new list
      //   const updatedList = newList.map(option => {
      //     // If the current option is the one we're looking for
      //     if (option.order === parseInt(optionOrder)) {
      //       // Map through the values to create a new values array
      //       const updatedValues = option.values.map((value, index) => {
      //         // If the current value is the one we're looking for
      //         if (value.id == valueId && result.destination) {
      //           // Return a new value object with the updated order
      //           return { ...value, order: result.destination.index + 1 };
      //         }
      //         // For all other values, return the value as is
      //         return value;
      //       });
      //       // Sort the updated values array by order
      //       updatedValues.sort((a, b) => a.order - b.order);
      //       // Return a new option object with the updated values array
      //       return { ...option, values: [...updatedValues] };
      //     }
      //     // For all other options, return the option as is
      //     return option;
      //   });
    
      //   // Dispatch to update the state
      //   batch(() => {
      //     optionsDispatch(updatedList);
      //     initOptionsDispatch(updatedList);
      //   })
      // }
    } catch (error) {
      console.log(error)
    }
  }

  if(!product) {
    return null
  }
  
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <div className="product-page-element product-page-additional-options">
        <div className="product-page-additional-options-header side-padding-24">
          <h3 className="product-page-h3">Опции</h3>
        </div>

        <div className='product-page-additional-options-labels side-padding-24'>
          <div className={`inputs-row vendor-code`}>
            <h4 className="header-font-s">Артикул</h4>
            <MyInput
              noPlaceholder
              value={product.external_id || ''}
              tabIndex={tabIndex}
              shortInput={true}
              maxLength={32}
              min={0}
              required={false}
              onChange={(e) => onChangeHandler(e, 'external_id')}
            />
          </div>
          <div className={`inputs-row barcode`}>
            <h4 className="header-font-s">Штрихкод</h4>
            <MyInput
              noPlaceholder
              value={product.barcode || ''}
              tabIndex={tabIndex}
              shortInput={true}
              maxLength={32}
              required={false}
              min={0}
              onChange={(e) => onChangeHandler(e, 'barcode')}
            />
          </div>
        </div>

        <div className='side-padding-24'>
          <MyCheckbox
            className='additional-options-switcher'
            text='Товар имеет дополнительные опции (цвет, размер и т.д.)'
            actionHandler={additionalOptionsHandler}
            tabIndex={tabIndex}
            condition={additionalOptions}
          />
        </div>

        {additionalOptions ? <>
          {options.length ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Droppable type={`option`} droppableId={`option`}>
                {(provided, snapshot) => (
                  <div 
                    className={`product-page-additional-options-options-wrapper`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {options
                      .sort((a, b) => a.order - b.order)
                      .map((o, index) => 
                        <ProductOneOption 
                          key={o.order}
                          option={o}
                          elementIndex={index}
                          updateOption={updateOption} 
                          />
                      )
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ) : <VisualSeparatorLine/>}

          <MyButton
            removeDefaultClass
            className='product-page-additional-options-add-option'
            onClick={addBlankOption}
          >
            <i className="icofont-plus"></i> Добавить опцию
          </MyButton>
        </> : null}
      </div>
    </DragDropContext>
  );
}
export default ProductOptions;
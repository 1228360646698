import React, { useContext, useEffect, useState } from 'react';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { ptsansCurrency } from '../../../../../scripts/functions';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import MyButton from '../../../../_utility-components/button/button';
import DeliveryFromSelect from '../popups/delivery-from-select';
import DeliveryPriceAndMode from '../popups/delivery-price-and-mode';
import { GetTariffs, OrderDeliveryContext, Tariff, TariffHandler } from './delivery';

interface PickPointTariff {
  DeliveryMode: string;
  Discount: number | string | null;
  Name: string;
  Tariff: number | string;
  NDS: number | string;
}

type PickPointRes = {
  SessionId: string;
  Services: PickPointTariff[];
  InvoiceNumber: string;
  DPMin: number;
  DPMinPriority: number;
  DPMax: number;
  DPMaxPriority: number;
  Zone: string;
  ErrorCode: number;
  ErrorMessage: string;
};

interface PickpointLoginRes {
  SessionId: string
  ErrorCode: number
  ErrorMessage: string
  ExpiresIn: Date
}

interface Props {
  tariffHandler: TariffHandler,
  fromHandler: (func: GetTariffs) => void,
}
  
function Pickpoint ({
  tariffHandler,
  fromHandler,
}: Props) {
  const _isMounted = useMounted()

  const {
    delivery,
    changeSelectedDelivery,
    currentTariff,
    setCurrentTariff,
    setFrom,
  } = useContext(OrderDeliveryContext)

  const login = "23gig3",
        password = "60kwstS",
        ikn = "9990489912";
  const [pickpointTariffs, setPickpointTariffs] = useState<PickPointTariff[]>([]);

  useEffect(() => {
		// appendScript('https://pickpoint.ru/select/postamat.js');

    // if(+delivery_type === 7 && delivery_tariff_id && delivery_city && point_id) {
    //   await getCalcTariffWithStores();
    //   const tariff = this.state.tariffs[store_id];

    //   if(!tariff) return false 
    //   this._mounted && this.setState({
    //     from: stores.filter((s) => +s.id === +store_id)[0],
    //     tariffsForCurrentDeparture: tariff,
    //     currentTariff: tariff[+delivery_tariff_id - 1] || {}
    //   })
    // }

    // return () => {
  	// 	removeScript('https://pickpoint.ru/select/postamat.js');
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!delivery) {
    return null
  }

	const callBack = (obj: {
    id: any,
    shortaddress: any,
    cityname: any,
    delivery_tariff_id: any,
  }) => {
    if(!_isMounted.current) 
      return false;

    changeSelectedDelivery({
			pickpoint_id: obj.id,
			address: obj.shortaddress,
			city: obj.cityname,
      tariff_id: obj.delivery_tariff_id,
      store_id: null,
      cost: 0,
    })
    setFrom(null)
    setCurrentTariff(null)
	};

	const createSession = async () => {
    return await mercheryFetch<PickpointLoginRes>('https://e-solution.pickpoint.ru/api/login', "POST", {
			Login: login,
			Password: password
		}, {externalUrl: true})
		.then((res) => {
      // console.log(res)
			if(!res || res.ErrorCode !== 0) return false
      return res.SessionId
    })
	};

  const getTariffs: GetTariffs = async (store) => {
		const sessionId = await createSession()
    if(!sessionId || !store) 
      return false
    
		return mercheryFetch<PickPointRes>('https://e-solution.pickpoint.ru/api/calctariff', 'POST', {
			SessionId: sessionId,
			IKN: ikn,
			Length: '10',
			Depth: '12',
			Width: '20',
			Weight: "2",
			PTNumber: delivery.pickpoint_id,
			FromCity: store.city,
			FromRegion: store.region
		}, {externalUrl: true})
    .then((res) => {
      if(!_isMounted.current || res.ErrorCode === -1) 
        return false

      setPickpointTariffs(res.Services)
      const standartedTariffs = res.Services.map(tariff => ({
        id: tariff.Name,
        price: +tariff.Tariff
      }))

      return standartedTariffs
    })
	};

	const pickpointOpen = () => {
    if(!_isMounted.current) 
      return false
    
    // @ts-ignore: Unreachable code error
    window.PickPoint?.open(callBack, {ikn: ikn})
  };

  const openBtnText = (currentTariff && pickpointTariffs.find(pt => pt.Name === currentTariff.id)?.Name) || 'Выбрать тариф';

  return (
    <div className="op-delivery-simple op-delivery-extended">
      {delivery.pickpoint_id ? 
        <>
        <div className="group-by-row">
          <div className="flex-wrapper">
            <div className="wide-text-font-xxs">пункт выдачи</div>
            <div className="wide-text-font-xxs">место отправления</div>
          </div>

          <div className="flex-wrapper">
            <div>
              <MyButton 
                className="blue-text" 
                removeDefaultClass 
                onClick={pickpointOpen}>
                {delivery.pickpoint_id}
              </MyButton>
              
              {delivery.city + ' ' + delivery.address} 
            </div>

            <div className="delivery-price-and-mode header-font-m">
              <DeliveryFromSelect 
                fromHandler={() => fromHandler(getTariffs)}
              />
            </div>
          </div>
        </div>
        <div className="group-by-row">
          <div className="flex-wrapper">
            <div className="title-div">Тариф</div>
            <div className="title-div">Стоимость</div>
          </div>
          <div className="flex-wrapper">
            <DeliveryPriceAndMode
              className={'pickpoint-mode'} 
              tariffHandler={tariffHandler} 
              MainActionBtnText={PickPointTariffHandler({ pickpointTariffs })} 
              openBtnText={openBtnText}/>
            <div>
              <div className="delivery-tariff-row-header">
                {delivery.cost ? <>
                  {delivery.cost} {ptsansCurrency('₽')}
                </> : null}
              </div>
            </div>
          </div>
        </div>
        </>
      : (
        <MyButton 
          className="open-widget-btn"
          onClick={pickpointOpen}>
          Открыть виджет
        </MyButton>
      )}
    </div>
  );
}

export default Pickpoint

function PickPointTariffHandler({ pickpointTariffs }: { pickpointTariffs: PickPointTariff[]; }): (tariff: Tariff) => JSX.Element {
  return (tariff: Tariff) => {
    const currentPickpointTariff = pickpointTariffs.find(pt => pt.Name === tariff.id);
    if (!currentPickpointTariff)
      return <></>;

    return (
      <>
        <div className="delivery-tariff-row-header">
          {currentPickpointTariff.Name} {currentPickpointTariff.DeliveryMode === "Priority" ? 'с приоритетом' : null}
        </div>
        <div>
          {currentPickpointTariff.Tariff} {ptsansCurrency('₽')}
        </div>
      </>
    );
  };
}

import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id } from '../../../../dto/master-dto/id.dto';
import { Promocode } from '../../../../dto/promocode';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { ptsansCurrency, uuidv4, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../scripts/hooks/use-load';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { WebSocketContext } from '../../../../scripts/web.socket';
import { OrderChangeHandler } from '../order-page';
import { NewPromo } from './new-promo';
import { PromoEdit } from './promo-edit';

interface Props {
  changeData: OrderChangeHandler
}

export type CurrentPromoCode = Omit<Promocode, 'id'> & {id: Id | null}

function Promo ({
  changeData,
}: Props) {
  const prevWSMessageId = useRef<string | null>(null);
  const _isMounted = useMounted();
  const orders = useAppSelector(state => state.orders)
  const orderId = useRouteId('orderid')
  const order = orders.find(o => o.id === orderId);
  const promoList = useAppSelector(state => state.promocodes);
  const initPromocode = order && promoList.find(promo => promo.id === order.promo_code);

  const {RenderButton, RenderPopup, openPopup, closePopup} = usePopup();
  const [, setLoad] = useLoad();
  const [isPercent, setIsPercent] = useState(false);

  const [newPromoCode, setNewPromoCode] = useState<CurrentPromoCode>({
    id: null,
    code: '',
    price: 0,
    used: false,
    multi: false,
    isPercent: false,
  });

  const [currentPromocode, setCurrentPromocode] = useState<CurrentPromoCode>(initPromocode || newPromoCode);

  useEffect(() => {
    setCurrentPromocode(initPromocode || newPromoCode)
  }, [initPromocode])

  const createNewCodeState = useAppSelector(state => state.uiPromoCreateState)
  const websocketMessage = useContext(WebSocketContext);

  const dispatch = useDispatch();
  const promocodesDispatch = (promocodes: Promocode[]) => dispatch({ type: 'ORDERS_PROMOCODES', payload: promocodes })
  const setCreateNewCodeState = (value: boolean) => dispatch({ type: 'UI_PROMO_CREATE_STATE', payload: value })

  // useEffect(() => {
  //   setCreateNewCodeState(!currentPromocode)
  // }, [])

  useEffect(() => {
    if(!Number.isNaN(orderId)) {

      getPromocodes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    if(currentPromocode) {
      if(!initPromocode && currentPromocode.code) {
        setCurrentPromocode(newPromoCode)
      } else if(initPromocode && initPromocode.code !== currentPromocode.code) {
        setCurrentPromocode(initPromocode)
      }
    }
  }, [initPromocode])

  useEffect(() => {
    if(initPromocode?.code !== currentPromocode?.code) {
      const findedCode = currentPromocode && promoList.find(promo => promo.code === currentPromocode.code)

      if(findedCode) {
        setCurrentPromocode(findedCode)
        setIsPercent(findedCode.isPercent)
      } else {
        setCurrentPromocode({
          ...newPromoCode,
          id: null,
          code: currentPromocode?.code
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPromocode?.code])

  const getPromocodes = () => {
    mercheryFetch<Promocode[]>('promocode', 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      promocodesDispatch(res.records)
    })
  };

  const supplementPromocode = (code: Promocode) => {
    if(promoList.find(p => p.id === code.id)) {
      return false
    }

    promocodesDispatch(
      [...promoList, code]
      .sort((a,b) => b.id - a.id)
    )
  }

  // useEffect(() => {
  //   if(websocketMessage && websocketMessage?.messageUUID !== prevWSMessageId.current) {
  //     const currentRoute = websocketMessage.event.split('://')
  //     const group = currentRoute[0]
  //     const action = currentRoute[1]
  //     // const value = currentRoute[2]

  //     if(group === 'orders') {
  //       if(action === 'promocode') {
  //         supplementPromocode(websocketMessage.message)
  //         return;
  //       } 
  //     }
  //   }
  //   prevWSMessageId.current = websocketMessage?.messageUUID || null
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [websocketMessage])

  const checkValidity = () => {
    let pattern = new RegExp("^[0-9]+$");
    return pattern.test('' + newPromoCode.price);
  }

  const createHandler = async () => {
    if(!checkValidity()) return false
    
    const newCode = await createNewPromo({
      ...newPromoCode,
      isPercent: isPercent
    })

    newCode && batch(() => {
      setNewPromoCode({
        id: uuidv4(),
        code: '',
        price: 0,
        used: false,
        multi: false,
        isPercent: false,
      })
      setCreateNewCodeState(false)

      setCurrentPromocode(newCode)
      setIsPercent(newCode.isPercent)
    })

    newCode && setPromo(newCode.id);
  };

  const createNewPromo = async (data: {
    code: string
    price: number
    isPercent: boolean
    multi?: boolean
  }) => {
    setLoad(true)

    return mercheryFetch<Promocode>('promocode/create', 'POST', data)
    .then(async (res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      promocodesDispatch([...promoList, res.records].sort((a,b) => b.id - a.id))
      return res.records
    })
    .finally(() => {
      setLoad(false)
    })
  };

  const promoSwitch = () => setIsPercent(!isPercent)

  const setPromo = (codeId: Id | null) => {
    changeData({promo_code: codeId}, true)
    .then(res => {
      if(_isMounted.current && res === true) {
        closePopup();
      }
    })
  }

  const createCodeHandler = () => {
    if (currentPromocode?.price) {
      
      setCurrentPromocode(newPromoCode)
      return false;
    }

    setNewPromoCode({
      ...newPromoCode, 
      code: currentPromocode?.code || ''
    });
    setCreateNewCodeState(true);
  }

  return (
    <>
      <RenderButton
        className={`order-page-btn hide-for-print discount-promo ${order?.promo_code ? '' : ' disabled'}`} 
        onClick={openPopup}>
        {order?.promo_code && initPromocode ? 
          <>
            Промокод 
            <span>
              <span style={{fontWeight: 600}}>{initPromocode.code}</span>
              :
            </span>
            <span style={{display: 'inline-flex', alignItems: 'center', columnGap: '4px'}}>
              {initPromocode.price}
              {initPromocode.isPercent ? '%': ptsansCurrency('₽')}
            </span>
          </>
        : 'Применить код'}
      </RenderButton>

      <RenderPopup
        className={'fixed-on-center promo'}
        withBlackout
        withCloseBtn
        tabIndexDeep={2}>
          
        {!createNewCodeState ? (
          <PromoEdit 
            currentPromocode={currentPromocode}
            setCurrentPromocode={setCurrentPromocode}
            createCodeHandler={createCodeHandler}
            isPercent={isPercent}
            setPromo={() => {setPromo(currentPromocode?.id || null)}}
            popupClose={closePopup}
            />
        ) : (
          <NewPromo
            popupClose={closePopup}
            isPercent={isPercent}
            newPromoCode={newPromoCode}
            setNewPromoCode={setNewPromoCode}
            promoSwitch={promoSwitch}
            createHandler={createHandler} 
            />
        )}
      </RenderPopup>
    </>
  )
}

export default memo(Promo)
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import dotsMenu from 'src/img/context-menu-dots.svg';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../../scripts/hooks/use-load';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import { Client } from '../../dto/client.dto';
import ClientsDeletePopupRender from './client-delete-popup';
import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

function ClientRowConfig({
  linkProps,
  client
}: {
  linkProps: (id: Id) => {
    to: string,
  },
  client: Client,
}) {
  const clients = useAppSelector(state => state.clients);
  const selectedClients = useAppSelector(state => state.selectedClientsIds);
  const _isMounted = useMounted();
  const [ deletePopupOpened, setDeletePopupOpened ] = useState(false);

  const history = useHistory()
  const dispatch = useDispatch()

  const clientsDispatch = (clients: Client[]) => dispatch({ type: 'CLIENTS', payload: clients })
  const selectedClientsDispatch = (ids: Id[]) => dispatch({ type: 'CLIENTS_SELECTED', payload: ids })

  const { RenderButton, RenderPopup, closePopup } = usePopup()

  const [ , setLoad ] = useLoad()

  const editHandler = () => {
    const locationData = linkProps(client.id)

    closePopup()

    history.push({
      ...locationData,
      pathname: locationData.to
    })
  }

  const deleteHandler = () => {
    if(!clients) {
      return false
    }
    
    setLoad(true)

    const idsToDelete = [client.id]

    mercheryFetch<boolean>('client', 'DELETE', {
      id: idsToDelete
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      batch(() => {
        clientsDispatch(
          clients.filter(client => 
            !idsToDelete.some(id => id === client.id)
          )
        )

        selectedClientsDispatch(
          selectedClients.filter(selected => !idsToDelete.some(id => id === selected))
        )
      })
    })
    .finally(() => {
      setLoad(false)
    })
  };

  return (
    <BodyCell myClassName='left-align config-popup-wrapper'>
      <RenderButton
        removeDefaultClass
        className='dot-menu config-btn'
      >
        <img src={String(dotsMenu)} alt={'row-menu'}/>
      </RenderButton>

      <RenderPopup
        className='config-popup'
      >
        <div className='popup-element' onClick={editHandler}>
          Редактировать
        </div>

        <div className='popup-element' onClick={() => {
          closePopup()
          setDeletePopupOpened(true)
        }}>
          Удалить
        </div>
      </RenderPopup>

      {deletePopupOpened ?
        ReactDOM.createPortal(
          <ClientsDeletePopupRender 
            selectedClients={[client.id]} 
            parentPopupClose={() => setDeletePopupOpened(false)} 
            mainActionHandler={deleteHandler}
          />
        , document.body)
      : null}
    </BodyCell>
  );
}

export default ClientRowConfig;
import React from 'react';
import { numWithSep, priceWithPennies, ptsansCurrency } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { Order } from '../../orders/dto/order.dto';

interface ClientHistoryOrderTotalProps {
  orderHasRefunded: boolean;
  order: Order;
  orderCancelled: boolean;
}

export function ClientHistoryOrderTotal({ 
  orderHasRefunded, 
  order, 
  orderCancelled 
}: ClientHistoryOrderTotalProps) {
  const refunds = useAppSelector(state => state.refunds)

  if(!order.total) {
    return null
  }

  const totalRefunds = !refunds ? 0 :
    refunds
    .filter(refund => 
      refund.order_id === order.id)
    .reduce((total, refund) => 
      total + refund.refund_total, 0);

  let finalTotal = order.total - totalRefunds;

  const cancelledOrderClass = orderCancelled ? 'client-order--cancelled' : '';
  const fullRefundClass = finalTotal === 0 ? 'client-order--full-refund' : '';
  
  return <div className={`client-order__header__right-side ${cancelledOrderClass} ${fullRefundClass}`}>
    {orderHasRefunded ? (
      <div className='header-font-l'>

        <span className='client-order__price-with-currancy'>
          <span className='client-order__price'>
            {numWithSep(priceWithPennies(finalTotal || 0))}
          </span> {ptsansCurrency('₽')} 
        </span>

        &nbsp;/&nbsp;

      </div>
    ) : null}

    <div className='header-font-l'>
      <span className='client-order__price-with-currancy client-order__price-with-currancy--order-total'>
        <span className='client-order__price'>
          {numWithSep(priceWithPennies(order.total || 0))}
        </span> {ptsansCurrency('₽')}
      </span>
    </div>
  </div>;
}

import React, { createContext } from 'react';
import staticValues from '../data/static-values.json';
import useWebSocket, {SendMessage} from "react-use-websocket";

export const WebSocketContext = createContext<WebSocketContextType>({
  sendMessage: () => {},
  lastMessage: null,
  readyState: 0
}) 

type WebSocketContextType = {
  sendMessage: SendMessage,
  lastMessage: WebSocketEventMap['message'] | null,
  readyState: number,
};

const WebSocketProvider = ({ children }: {children: React.ReactNode}) => {
  const webSocketUrl = staticValues[process.env.NODE_ENV === 'development' ? 'dev_values' : 'prod_values'].websocket_url;
  if(typeof webSocketUrl !== 'string') {
    throw new Error('no websocket url')
  }
  const {
    sendMessage,
    lastMessage,
    readyState,
  } = useWebSocket(webSocketUrl, {
    onOpen: () => console.log('websocket opened'),
    retryOnError: true,
    shouldReconnect: (closeEvent) => true,
  });

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
        lastMessage,
        readyState,
      }}>

      {children}

    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;

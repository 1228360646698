import { ImageFile, ImageExtended } from "../../dto/images/images.dto"

export interface ExtractedLinks {
  default: string
  webp?: string
  jpeg?: string
 
  searched?: any
}

type Img = ImageFile | ImageExtended

export function extractImages <T extends Img>(img: T | undefined | null, scale: string, predicate?: (value: T) => unknown): ExtractedLinks | null {
  if(!img) return null;

  if(imageIsFile(img)) {
    return {
      default: img.src,
      ...(predicate !== undefined && {
        searched: predicate(img)
      })
    }
  }
  const validatedResize = img?.resizes.filter(r => r.scale === scale);

  const webp = validatedResize?.find(thumb => thumb.format === 'webp')?.src
  const jpeg = validatedResize?.find(thumb => thumb.format === 'jpeg')?.src

  return {
    default: img.src,
    webp,
    jpeg,
    ...(predicate !== undefined && {
      searched: predicate(img)
    })
  }
}

function imageIsFile(item: ImageFile | ImageExtended): item is ImageFile {
  return (item as ImageFile).newFile
}
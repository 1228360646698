/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import { uuidv4 } from '../../../../../../scripts/functions';
import MultiSelect from '../../../../../_utility-components/multi-select/multi-select';
import { CharScopeDto, MSValueAsCheckboxOption, MultiSelectDto, MultiSelectValueDto } from '../../../dto/chars.dto';

interface Props {
  myClassName: string,
  currentShownScope: CharScopeDto | null,
  thisMultiSelect: MultiSelectDto,
  scopeCreate: (props: Partial<CharScopeDto>) => CharScopeDto | false,
}

function CharLabelMultiSelect({
  myClassName = '',
  currentShownScope,
  thisMultiSelect,
  scopeCreate,
}: Props) {
  const values = useAppSelector(state => state.multiSelectValues);
  const names = useAppSelector(state => state.multiSelectNames);
  const dispatch = useDispatch();

  const thisMultiSelectValues: MSValueAsCheckboxOption[] = 
    thisMultiSelect.names
      .map((nameId) => ({
        name_id: nameId,
        value: values.find(v => v.scope_id === currentShownScope?.id && v.name_id === nameId)?.value || false,
        name: names.find(n => n.id === nameId)?.name || ''
      }))
  
  const valuesReducerType = 'CHARS_LABELS_MULTISELECT_VALUES';
  const setValues = (value: MultiSelectValueDto[]) => 
    dispatch({type: valuesReducerType, payload: value})

  const valueChange = (nameId: Id) => {
    
    if(!currentShownScope) {
      const newScope = scopeCreate({ value: null, value_id: thisMultiSelect.id })
      console.log('newScope', newScope)

      if(!newScope) return false;

      const newMSValue: MultiSelectValueDto = {
        id: uuidv4(),
        multiselect_id: thisMultiSelect.id,
        scope_id: newScope.id,
        name_id: nameId,
        value: true
      }

      setValues([...values, newMSValue])
      return false
    }

    const valueExist = values.some(v => v.name_id === nameId && currentShownScope.id === v.scope_id)

    if(valueExist) {
      const updatedValues = values.map(v => {
        if( v.name_id !== nameId || v.scope_id !== currentShownScope.id ) {
          return v
        }
        return {...v, value: !v.value}
      })

      setValues([...updatedValues])
    } else {
      const newMSValue: MultiSelectValueDto = {
        id: uuidv4(),
        multiselect_id: thisMultiSelect.id,
        scope_id: currentShownScope.id,
        name_id: nameId,
        value: true
      }

      setValues([...values, newMSValue])
    }
  }

  if(!thisMultiSelectValues.length) {
    return null
  }

  return (
    <div className={`${myClassName} char-label__multi-select`}>
      <MultiSelect
        handler={valueChange}
        selects={thisMultiSelectValues}
      />
    </div>
  );
}

export default CharLabelMultiSelect;
import React from "react";
import {SortableDataConfig, SortableDataHandler} from "../../../../scripts/hooks/use-sortable-data";
import {Order} from "../dto/order.dto";
import HeaderCell from "../../../_utility-components/common-table/header-cell";
import {HeaderRow} from "../../../_utility-components/common-table/header-row";

export function OrderTableHeader({
  requestSort,
  config
}: {
  config: SortableDataConfig<Order> | undefined,
  requestSort: SortableDataHandler<Order>,
}) {
  return <HeaderRow className={'orders-table__cells-template'}>
    <HeaderCell
      className='tt-id'
      children={'Номер'}
      label={'id'}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-date'
      children={'Дата'}
      label={'date'}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-buyer'
      children={'получатель'}
      label={'client.name'}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-delivery'
      children={'доставка'}
      label={'current_delivery_id' as keyof Order}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-cost'
      children={'стоимость'}
      label={'total' as keyof Order}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-status'
      children={'Статус'}
      label={'status' as keyof Order}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className='tt-refund-flag'
      children={''}
    />
    <HeaderCell
      className='tt-manager'
      children={'менеджер'}
      label={'admin_id' as keyof Order}
      config={config}
      handler={requestSort}
    />
    <HeaderCell
      className=''
      children={''}
    />
  </HeaderRow>
}
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import { MainRouteChild } from '../main-page';
import CollectionsTable from './modules/table';

interface Props extends MainRouteChild {
}

function Collections(props: Props) {
  
  const integrations = useAppSelector(state => state.integrations);
  const moySklad = integrations.find(i => i.code === 'moy_sklad')


  useEffect(() => {
    props.setCurrentPage('collections')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toCreation = useMemo(() => ({
    pathname: '/app/collections/new',
  }), [])

  if(moySklad?.turned_on) {
    return <div>Не поддерживается со включенной интеграцией "Мой Склад"</div>
  }

  return (
    <div className='collections'>
      <div className="collections__header">
        <h1>Коллекции</h1>

        <Link className='my-btn blue-btn'
          to={toCreation}
        >
          Добавить коллекцию
        </Link>
      </div>

      <CollectionsTable/>
    </div>
  );
}

export default Collections;
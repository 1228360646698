import React from 'react';

export interface SetItemNameProps {
  name: string, 
}

function SetItemName(props: SetItemNameProps) {
  const { name } = props;

  return (
    <div>
      <div>{name}</div>
    </div>
  );
}

export default SetItemName;
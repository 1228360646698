import MyButton from "../../../../_utility-components/button/button";
import React, {useMemo} from "react";
import { ProductVariantExtended } from "../../dto/variants.dto";
import MyInput from "../../../../_utility-components/input/index";
import { OnCountHandler } from "../../../../_utility-components/input/input";
import { gramToKg, kgToGram, priceToPennies, priceWithPennies } from "../../../../../scripts/functions";

interface VariantLabelsControlProps {
  variant: {
    show_date: ProductVariantExtended['show_date']
    price: ProductVariantExtended['price']
    weight: ProductVariantExtended['weight']
    external_id: ProductVariantExtended['external_id']
    barcode: ProductVariantExtended['barcode']
  };
  remainCount: number;
  countHandler: OnCountHandler;
  tabIndexLevel: number;
  variantChanger: <T extends keyof ProductVariantExtended>(value: ProductVariantExtended[T], label: T) => void;
}

export function VariantLabelsControl({ 
  variant, 
  remainCount, 
  countHandler, 
  tabIndexLevel, 
  variantChanger 
}: VariantLabelsControlProps) {

  const inputHandler = <T extends keyof ProductVariantExtended,>(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const label = event.target.name as T

    if(label === 'price') {
      const value = +event.target.value as ProductVariantExtended['price']
      const price = priceToPennies(value as ProductVariantExtended['price']) as number 
      variantChanger<'price'>(price, label)
      return false
    }

    if(label === 'weight') {
      const value = +event.target.value as ProductVariantExtended['weight']
      const price = kgToGram(value as ProductVariantExtended['weight']) as number
      variantChanger<'weight'>(price, label)
      return false
    }
    const value = event.target.value as ProductVariantExtended[T]

    variantChanger<T>(value, label)
  }

  const hidedVariant = useMemo(() => (
    variant.show_date === null ||
    variant.show_date > new Date().getTime()
  ), [variant.show_date])

  return <>
    <div className="op-variant-price">
      <MyInput
        messagetext={''}
        value={priceWithPennies(variant.price) || ''}
        name={'price'}
        onChange={inputHandler}
        placeholder={'₽'}
        placeholderClass={'currency-pt-sans'}
        myClassName={'inline-input placeholder--animation-off'}
        type="number"
        min={0} />
    </div>
    <div className="op-quantity">
      <MyInput
        value={remainCount}
        name={'remain'}
        onCount={countHandler}
        onChange={inputHandler}
        myClassName={'inline-input placeholder--animation-off'}
        type="number"
        required={false}
        customArrow={true} />
    </div>
    <div className="op-weight">
      <MyInput
        value={gramToKg(variant.weight) || 0}
        name={'weight'}
        onChange={inputHandler}
        myClassName={'inline-input placeholder--animation-off input-padding-off'}
        type="number"
        min={0} />
    </div>
    <div className="op-vendor-code">
      <MyInput
        value={variant.external_id || ''}
        name={'external_id'}
        onChange={inputHandler}
        myClassName={'inline-input placeholder--animation-off input-padding-off'}
        required={false}
        min={0} />
    </div>
    <div className="op-barcode">
      <MyInput
        value={variant.barcode || ''}
        name={'barcode'}
        onChange={inputHandler}
        myClassName={'inline-input placeholder--animation-off input-padding-off'}
        required={false} />
    </div>
    <div className="op-config">
      {!hidedVariant ? (
        <MyButton
          className={`delete-btn`}
          disabled={hidedVariant}
          tabIndexLevel={tabIndexLevel}
          onClick={() => variantChanger(null, 'show_date')}
        >
          <i className='icofont-ui-delete'></i>
        </MyButton>
      ) : (
        <MyButton
          className={`undo-btn`}
          tabIndexLevel={tabIndexLevel}
          disabled={!hidedVariant}
          onClick={() => variantChanger(0, 'show_date')}
        >
          <i className="icofont-undo"></i>
        </MyButton>
      )}
    </div>
  </>;
}

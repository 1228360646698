/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import { uuidv4 } from '../../../../../../scripts/functions';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import MyInput from '../../../../../_utility-components/input/index';
import { CharGroupsDto, CharsLabelDto } from '../../../dto/chars.dto';
import CharLabel from '../char-label';
import { CharacteristicsPage } from '../product-characteristics';

interface Props {
  labels: CharsLabelDto[];
  charEditing: boolean;
  group: CharGroupsDto;
  page: CharacteristicsPage;
  charScope: Id | null,
  charScopeValue: Id | null,
  labelsDispatch: (label: CharsLabelDto) => void;
  removeLabelAndDispatch: (label: CharsLabelDto) => void;
}

function Labels({
  page,
  labels, 
  group,
  charEditing,
  charScope,
  charScopeValue,
  labelsDispatch,
  removeLabelAndDispatch,
}: Props) {
  const allLabels = useAppSelector( state => state.labels);

  const wrapperClassName = `characteristic-labels ${page === 'product' || charEditing ? 'flex-gap-16' : 'category-page__characteristic-labels'}`;
  const headerClassName = `header-font-s side-padding-16 characteristic-labels__header`;

  const labelsRender = labels.map((label) =>
    <CharLabel
      key={label.order}
      page={page}
      labelsDispatch={labelsDispatch}
      removeLabelAndDispatch={removeLabelAndDispatch}
      label={label}
      group={group}
      charEditing={charEditing} 
      charScope={charScope} 
      charScopeValue={charScopeValue}
    />
  )

  const dispatch = useDispatch()
  const labelsReducerType = 'CHARS_LABELS';
  const setLabels = (value: CharsLabelDto[]) => dispatch({type: labelsReducerType, payload: value});

  const addNewValue = (initialValue: string = '', id?: Id) => {
    const arrayOfOrders = [...labels.map((v) => v.order), 0]
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newLabel: CharsLabelDto = {
      id: id || uuidv4(),
      char_group: group.id,
      name: initialValue,
      order: newOrder,
      type_id: 2,
      type_name: 'string',
      unit: null,
      unit_id: null,
      newLabel: true,
    }
    setLabels([...allLabels, newLabel])
  }

  if(page === 'categories' && !charEditing) {
    return (
      <div className={wrapperClassName}>
        {labelsRender}
      </div>
    )
  }

  return (
    <Droppable 
      type={`groupLabel-${group.id}`} 
      droppableId={`char_group-${group.id}`}
    >
      {(provided, snapshot) => 
        <div 
          className={wrapperClassName}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {charEditing ? 
            <h5 className={headerClassName}>Характеристики</h5> 
          : null}

          {labelsRender}
          {provided.placeholder}

          {charEditing ? 
            <div className='char-label char-label__blank'>
              <MyInput
                placeholder={'Добавить характеристику'}
                value={''}
                onFocus={() => addNewValue()}
              />
            </div>
          : null}
        </div>
      }
    </Droppable>
  )
}

export default memo(Labels)
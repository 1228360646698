import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Id } from '../../dto/master-dto/id.dto';
import { useTabIndex } from '../../scripts/hooks/use-tabindex';
import MyButton from './button/button';

interface BubbleProps<T extends BubbleExtends> {
  item: T;
  deleteHandler?: (item: T) => void;
  tooltip?: string
  onClick?: (item: T) => void
}

interface BubbleExtends {
  id: Id;
  name: string;
}

function Bubble<T extends BubbleExtends>({
  item,
  deleteHandler,
  tooltip,
  onClick,
}: BubbleProps<T>) {
  const tabIndex = useTabIndex()

  const className = `bubble ${deleteHandler ? 'bubble--deletable' : ''} ${onClick ? 'clickable' : ''}`;
  return (
    <div className={className}
      {...(tooltip && {
        'data-tip': tooltip,
        'data-for': `bubble-${item.id}`
      })}
      {...(onClick && {
        onClick: () => onClick(item),
        tabIndex
      })}
    >
      <div className='bubble-text'>
        {item.name}
      </div>

      {tooltip ? 
        <ReactTooltip
          id={`bubble-${item.id}`} 
          resizeHide={true} 
          place={'top'} 
          multiline={true} 
          effect={"solid"} 
          isCapture={true}
        />
      : null}

      {deleteHandler ? 
        <MyButton
          removeDefaultClass
          className="bubble-btn"
          onClick={() => deleteHandler(item)}
        >
          <i className='icofont-close'></i>
        </MyButton>

      : null}
    </div>
  );
}

function Bubbles<T extends BubbleExtends> ({
  items,
  deleteHandler,
  tooltipConstructor,
  onClick,
}: {
  items: T[],
  deleteHandler?: (item: T) => void;
  tooltipConstructor?: (item: T) => string
  onClick?: (item: T) => void
}) {
  return (
    <div className='merchery-bubbles'>
      {items.map((item) => (
        <Bubble<T>
          key={item.id}
          deleteHandler={deleteHandler}
          item={item}
          onClick={onClick}
          {...(tooltipConstructor && {
            tooltip: tooltipConstructor(item)
          })}
        />
      ))}
    </div>
  );
}

export default Bubbles
import React, { useContext } from 'react';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import { OrderContext } from '../../order-page';

interface Props {
  headerText: React.ReactNode,
}

function SendLetter({
  headerText,
}: Props) {
  const {RenderButton, RenderPopup, closePopup} = usePopup()

  const {
    order,
  } = useContext(OrderContext)

  const sendMailToClient = async () => {
    order && await mercheryFetch('mail/inform-client', 'POST', {
      order: order.id
    })
    closePopup();
  }
  return (
    <div className='send-letter-wrapper'>
      <RenderButton 
        removeDefaultClass
        className='order__external-btn'
        >
        <i className="icofont-letter"></i>Отправить письмо
      </RenderButton>

      <RenderPopup
        className={'confirm-window fixed-on-center send-letter-popup'}
        withBlackout
        withCloseBtn
        tabIndexDeep={3}
      >
        {headerText}
        {/* <span>
          {selectedItems[0] ? selectedItems[0].name : null} 
          {selectedItems[1] ? ', ' + selectedItems[1].name : null} 
          {selectedItems.length >= 3 ? 'и еще ' + num2str(selectedItems.length - 2, ['товар', 'товара', 'товаров']) : null}
          <span> на {calcPrice(selectedItems)} {ptsansCurrency('₽')}</span>
        </span> */}

        <div className="confirm-window-btn">
          <MyButton 
            className='white-btn' 
            onClick={closePopup}>
            Отменить
          </MyButton>

          <MyButton 
            className="blue-btn" 
            onClick={sendMailToClient}>
            Отправить
          </MyButton>
        </div>
      </RenderPopup>
    </div>
  );
}

export default SendLetter;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useMemo } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { usePopup } from '../../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../../_utility-components/button/button';
import { CharGroupsDto } from '../../../dto/chars.dto';

interface Props {
  cancelHandler: () => void,
  saveHandler: () => void,
  nothingToSave: boolean,
  group: CharGroupsDto,
}

function CharFooter({
  cancelHandler,
  saveHandler,
  nothingToSave,
  group,
}: Props) {
  const groups = useAppSelector(state => state.charGroups)
  const labels = useAppSelector(state => state.labels.filter(l => l.char_group === group.id))
  const msNames = useAppSelector(state => state.multiSelectNames.filter(l => l.category === group.category_id))
  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);

  const {RenderPopup, closePopup, isOpen, openPopup} = usePopup()

  const findNameDublicates = (items: {name: string}[]) => items.filter((item, i, array) => 
    array.findIndex(secondItem => secondItem.name.toLowerCase() === item.name.toLowerCase()) === i
  )

  const groupsHaveSameName = useMemo(() => 
    groups.length !== findNameDublicates(groups).length 
  , [groups]);
  
  const labelsHaveSameName = useMemo(() => 
    labels.length !== findNameDublicates(labels).length
  , [labels]);
  
  const msNamesHaveSameName = useMemo(() => 
    msNames.length !== findNameDublicates(msNames).length
  , [msNames]);
  
  const categoryName = useMemo(() => 
    categories.find(c => c.id === group.category_id)?.name
  , [group, categories]);

  const disabled = !group.name || !labels.length || 
    groupsHaveSameName || labelsHaveSameName || msNamesHaveSameName ||
    (group.newGroup && nothingToSave);

  const saveBtnHandler = useCallback(
    nothingToSave ? saveHandler : openPopup
  , [disabled, group, saveHandler]);
  
  return (
    <div className='characteristics-bottom__btn-wrapper'>

      {isOpen ? 
        <RenderPopup
          className={'confirm-popup fixed-on-center'}
          tabIndexDeep={3}
          withCloseBtn
          withBlackout
        >
          <h3 className='confirm-popup__h header-font-xl'>
            Подтвердите сохранение шаблона
          </h3>

          <div className='confirm-popup__body'>
            <div className='confirm-popup__paragraph'>
              Шаблон группы характеристик «<b>{group.name}</b>» связан с категорией товаров «<b>{categoryName}</b>».
            </div>

            <div className='confirm-popup__paragraph'>
              Шаблон характеристик будет <b>обновлен</b> для всей категории товаров, 
              а значения измененных характеристик будут сброшены для всех товаров, 
              расположенных в категории «<b>{categoryName}</b>» и ее дочерних категориях.
            </div>

            <div className='confirm-popup__paragraph'>
              Вы уверены, что хотите продолжить?
            </div>
          </div>

          <div className='confirm-popup__btn-wrapper'>
            <MyButton className={'white-btn width-228'}
              onClick={closePopup}
            >
              Назад
            </MyButton>

            <MyButton className={`blue-btn width-228`}
              onClick={() => {
                saveHandler();
                closePopup();
              }}
            >
              Да, сохранить изменения
            </MyButton>
          </div>
        </RenderPopup>
      : null}
      
      <MyButton
        disabled={disabled}
        className={'blue-btn btn_side-padding_36'}
        onClick={saveBtnHandler}
      >
        Сохранить
      </MyButton>

      <MyButton
        className={'white-btn btn_side-padding_36'}
        onClick={cancelHandler}
      >
        Отменить
      </MyButton>
    </div>
  )
}

export default memo(CharFooter)
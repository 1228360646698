import React, { useContext } from 'react';
import { dateToRuLocale } from '../../../../../../scripts/utils/date-to-locale';
import { OrderDeliveryContext } from '../delivery';

export function SelectedDeliveryFooter() {
  const {
    delivery
  } = useContext(OrderDeliveryContext);

  if(!delivery) {
    return null
  }

  return <div className='op-delivery-dates'>
    <div className='op-delivery-dates__left-inner'>
      {delivery.registration_date && delivery.shipping_date === null ? 
        <div className='refund-delivery__row refund-received'>
          <div className='status'>дата регистрации</div>
          <div className='date'>{dateToRuLocale(delivery.registration_date) || ''}</div>
        </div>
      : null}

      {delivery.shipping_date ? 
        <div className='refund-delivery__row refund-received'>
          <div className='status'>дата отгрузки</div>
          <div className='date'>{dateToRuLocale(delivery.shipping_date) || ''}</div>
        </div>
      : null}

      {delivery.receiving_date ? 
        <div className='refund-delivery__row refund-sent'>
          <div className='status'>дата получения</div>
          <div className='date'>{dateToRuLocale(delivery.receiving_date) || ''}</div>
        </div>
      : null}
    </div>

    <div className='op-delivery-dates__right-inner'>
      <div className='refund-delivery__row delivery-note'>
        <div className='status'>накладная</div>
        <div className='delivery-note-number'>{delivery.note}</div>
      </div>
    </div>
  </div>;
}

import React from 'react';
import ReactTooltip from 'react-tooltip';
import Picture from 'src/components/_utility-components/picture/picture';
import { ExtractedLinks } from '../../../../../scripts/utils/extractImages';
import MyButton from '../../../../_utility-components/button/button';

interface Props {
  images: ExtractedLinks | null
  removeFiles: () => void
  disableDropzone: boolean
}

function VariantImage({
  images,
  removeFiles,
  disableDropzone,
}: Props) {
  return (
    <div
      className={`variant-dropzone-inner ${disableDropzone ? 'disabled' : ''}`} // eslint-disable-next-line
      {...(disableDropzone && { ['data-tip']: 'Добавление картинки доступно после сохранения вариантов' })}
    >
      <ReactTooltip 
        resizeHide={true} 
        place={'right'} 
        multiline={true} 
        effect={"solid"} 
        isCapture={true} 
      />
      <Picture
        images={images}
      />
      {
        images ? (
          <MyButton
            removeDefaultClass
            className="new-loaded_file-close-btn"
            onClick={(e) => {
              e.stopPropagation()
              removeFiles()
            }}
            onKeyUp={(e) => e.key === 'Enter' ? removeFiles() : false}
          >
            <i className="icofont-close-line-circled"></i>
          </MyButton>
        ) : null
      }
    </div>
  )
}

export default VariantImage;
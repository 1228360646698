import React, { useContext, useEffect } from 'react';
import CdekTariffsLoader from 'src/components/_utility-components/loaders/cdek-tariffs-loaders';
import { num2str, ptsansCurrency } from '../../../../../../scripts/functions';
import useSortableData, { SortableDataConfig } from '../../../../../../scripts/hooks/use-sortable-data';
import { useTabIndex } from '../../../../../../scripts/hooks/use-tabindex';
import MyCheckbox from '../../../../../_utility-components/checkbox/my-checkbox';
import MyTable from '../../../../../_utility-components/common-table/table';
import { OrderDeliveryContext } from '../delivery';
import { CdekDeliveryContext } from './cdek';
import { CdekCalcTariffDto } from './dto/cdek-calc-tariffs-body.api';
import HeaderCell from "../../../../../_utility-components/common-table/header-cell";
import {HeaderRow} from "../../../../../_utility-components/common-table/header-row";
import {BodyCell} from "../../../../../_utility-components/common-table/body-cell";
import {TableBody} from "../../../../../_utility-components/common-table/table-body";
import {BodyRow} from "../../../../../_utility-components/common-table/body-row";

export function CdekTariffs() {
  const {
    tariffsLoading,
    deliveryTariffs
  } = useContext(CdekDeliveryContext)

  const tabIndex = useTabIndex()

  const {
    delivery,
    changeSelectedDelivery
  } = useContext(OrderDeliveryContext)

  const defaultSorting: SortableDataConfig<CdekCalcTariffDto> = {
    key: 'delivery_sum',
    direction: 'ascending',
  }

  const [ tariffs, requestSort, config ] = useSortableData(deliveryTariffs ? [...deliveryTariffs] : [], defaultSorting);

  useEffect(() => {
    if(delivery && !delivery.tariff_id && tariffs.length) {
      const firstTariffCode = tariffs[0].tariff_code;
      const pickPointFirstTariffCode = tariffs.filter(tariff => tariff.delivery_mode)[0]?.tariff_code || firstTariffCode
      const defaultValue = delivery.point_id ? pickPointFirstTariffCode : tariffs[0].tariff_code

      changeSelectedDelivery({
        tariff_id: String(defaultValue)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery?.tariff_id, tariffs])

  if(tariffsLoading) {
    return <CdekTariffsLoader/>
  }

  return deliveryTariffs ?
    <MyTable id={'cdek-tariffs'} myClassName={'cdek-tariffs__table'}>
      <HeaderRow className='cdek-tariffs__header'>
        <HeaderCell<CdekCalcTariffDto>
          children={'тариф'}
          label={'tariff_name'} 
          config={config} 
          handler={requestSort}
        />

        <HeaderCell<CdekCalcTariffDto>
          children={'срок доставки'}
          className={'right-align'}
          label={'calendar_min'} 
          config={config} 
          handler={requestSort}
        />

        <HeaderCell<CdekCalcTariffDto>
          children={'стоимость'}
          className={'right-align'} 
          label={'delivery_sum'} 
          config={config} 
          handler={requestSort}
        />
      </HeaderRow>

      <TableBody>
        {tariffs
        .map((tariff) => {
          const deliveryDateText = 
            !tariff.calendar_max ? 
              ''
            : tariff.calendar_max === tariff.calendar_min ? 
              num2str(tariff.calendar_max, ['день', 'дня', 'дней'])
            : `${tariff.calendar_min}-${num2str(tariff.calendar_max, ['день', 'дня', 'дней'])}`;

          const tariffSelected = String(tariff.tariff_code) === delivery?.tariff_id;

          return (
            <BodyRow 
              key={tariff.tariff_code} 
              className={`cdek-tariffs__row text-font-m ${tariffSelected ? 'active' : ''}`}
              tabIndex={tabIndex}
              onClick={() => {
                changeSelectedDelivery({
                  tariff_id: String(tariff.tariff_code)
                })
              }}
            >
              <BodyCell myClassName='cdek-tariffs__tariff-name-cell'>
                <MyCheckbox
                  isRadio={true}
                  stylePreset={'inner-point'}
                  condition={tariffSelected}
                  actionHandler={() => {}}
                />
                {tariff.tariff_name}
              </BodyCell>

              <BodyCell myClassName={'right-align'}>
                {deliveryDateText}
              </BodyCell>

              <BodyCell myClassName={'right-align'}>
                {tariff.delivery_sum} {ptsansCurrency('₽')}
              </BodyCell>
            </BodyRow>
          );
        })}
      </TableBody>
    </MyTable>
  : null;
}

import React from 'react';
import ArrowBown from '../../../../img/arrow-down.png'
import { Order } from '../../orders/dto/order.dto';
import { OrderStatus } from '../../../../dto/order-status.dto';
import { dateToRuLocale } from 'src/scripts/utils/date-to-locale';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { ClientHistoryOrderTotal } from './client-history-order-total';
import { Link } from 'react-router-dom';
import OpenLink from '../../../../img/open-link.svg'
import ReactTooltip from 'react-tooltip';

interface Props {
  order: Order,
  currentStatus: OrderStatus | undefined,
  orderOpened: boolean,
  openHandler: (bool: boolean) => void
}

function ClientOrderHeader({
  order,
  currentStatus,
  orderOpened,
  openHandler
}: Props) {
  const refunds = useAppSelector(state => state.refunds)

  const orderCancelled = order.status === 4;

  const orderHasRefunded = Boolean( refunds && refunds.some((refund) => refund.order_id === order.id))

  return (
    <div 
      className='client-order__header'
      onClick={() => openHandler(!orderOpened)}
    >
      <div className='client-order__header__left-side'>

        <ArrowSwitcher orderOpened={orderOpened}/>

        <Title order={order}/>

        <ClientOrderStatuses 
          currentStatus={currentStatus} 
          orderHasRefunded={orderHasRefunded}
        />

        <OrderLink order={order}/>

      </div>

      <ClientHistoryOrderTotal
        orderHasRefunded={orderHasRefunded}
        order={order}
        orderCancelled={orderCancelled}
      />

    </div>
  );
}

export default ClientOrderHeader;

function ClientOrderStatuses({ currentStatus, orderHasRefunded }: { currentStatus: OrderStatus | undefined; orderHasRefunded: boolean; }) {
  return <div className='client-order__header__statuses-wrapper header-font-xxs'>
    <div
      className='row-item__refund-status'
      style={currentStatus ? { backgroundColor: currentStatus.color } : undefined}
    >
      {currentStatus?.name}
    </div>

    {orderHasRefunded ?
      <div
        className='row-item__refund-status'
        style={{ backgroundColor: '#4A5267' }}
      >
        возврат
      </div>
      : null}
  </div>;
}

function Title({ order }: { order: Order; }) {
  return <div className='header-font-l'>
    {`Заказ ${order.id} от ${dateToRuLocale(order.date)}`}
  </div>;
}

function ArrowSwitcher({ orderOpened }: { orderOpened: boolean; }) {
  return <div
    className={`show-hide-btn ${!orderOpened ? 'closed' : ''}`}
  >
    <img src={ArrowBown} alt='switcher' />
  </div>;
}

function OrderLink({order}: {order: Order}) {
  return <Link
    to={`/app/orders/${order.id}`}
    style={{ position: 'relative', opacity: .8, color: '#4a5267' }}
    data-tip={`Перейти к заказу`} 
    data-for={'order-link'} 
  >
    <img src={String(OpenLink)} alt={'перейти к заказу'} width={20} height={20}/>
    <ReactTooltip
      id={"order-link"} 
      resizeHide={true} 
      place={'top'} 
      multiline={true} 
      effect={"solid"} 
      isCapture={true}
    />
  </Link>
}
import React, { useEffect, useState } from 'react';
import { Id } from '../../../../dto/master-dto/id.dto';
import { calcPrice, toastUp, uuidv4 } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import { ExtendedProduct } from '../../products/dto/products.dto';
import { ProductVariantExtended } from '../../products/dto/variants.dto';
import { ProductVariantRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import { CanBeAddedProduct } from './can-be-added-product.dto';
import { countOverRemainMessage } from './product-row-to-be-added';

interface VariantRowToBeAddedProps {
  product: ExtendedProduct,
  variant: ProductVariantExtended,
  canBeAddedSelected: CanBeAddedProduct[],
  setCanBeAddedSelected: (items: CanBeAddedProduct[]) => void,
}


function VariantRowToBeAdded({
  product,
  variant,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: VariantRowToBeAddedProps) {
  const showRemain = useAppSelector(state => state.settings.find(setting => setting.callname === 'remainder'))?.value
  const items = useAppSelector(state => state.productItemsInContext);
  const [count, setCount] = useState(0);
  const tabIndex = useTabIndex(2)

  useEffect(() => {
    if(canBeAddedSelectedVariant && canBeAddedSelectedVariant?.count !== count) {
      changeCanBeAddedSelected(canBeAddedSelectedVariant.id, {
        count: count,
        total: +calcPrice([{count, price: product.price}]),
      })
    }
    if(!canBeAddedSelectedVariant && count > 0) {
      addSelected()
    }
    if(canBeAddedSelectedVariant && count === 0) {
      removeSelected()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const varaintPredicate: <T extends {product: Id, variant_id: Id | null},>(value: T, index: number, obj: T[]) => unknown = arrayItem => 
    arrayItem.product === variant.product_id && arrayItem.variant_id === variant.id

  const canBeAddedSelectedVariant = canBeAddedSelected?.find(varaintPredicate);
  const externalIdForLabels = !canBeAddedSelectedVariant ? variant.external_id : canBeAddedSelectedVariant.external_id;
  const remainForLabels = !canBeAddedSelectedVariant ? variant.remain : canBeAddedSelectedVariant.remain;

  const alreadyAdded = items.some(varaintPredicate)
  const disabled = Boolean(alreadyAdded || (!!showRemain && !remainForLabels));

  const addSelected = (variantFromHandlerRow?: ProductVariantExtended) => {
    const variantToBeAdded = variantFromHandlerRow || variant;
    setCanBeAddedSelected([...canBeAddedSelected, productToItem(variantToBeAdded)])
  }

  const checkboxHandler = (variantFromHandlerRow: ProductVariantExtended) => {
    const selected = canBeAddedSelected.some(item => item.variant_id === variantFromHandlerRow.id)

    if(selected) {
      setCount(0)
    } else {
      setCount(1)
    }
  }

  const removeSelected = () => {
    setCanBeAddedSelected(canBeAddedSelected.filter(item => item.product !== product.id))
  }
  
  const changeCanBeAddedSelected = (id: Id, changes: Partial<CanBeAddedProduct>) => {
    setCanBeAddedSelected(canBeAddedSelected.map(item => item.id === id ? {...item, ...changes} : item))
  }

  const itemIsItem = (variant: ProductVariantExtended | CanBeAddedProduct): variant is CanBeAddedProduct => 
    (variant as CanBeAddedProduct).variant_id !== undefined
  

  const productToItem = (variant: CanBeAddedProduct | ProductVariantExtended): CanBeAddedProduct => {
    const isItem = itemIsItem(variant)

    let item: CanBeAddedProduct = isItem ? {
      ...variant,
      count: count,
      price_with_discount: +calcPrice([{count: 1, price: variant.price, cutDigits: 2}]),
      total: +calcPrice([{count, price: variant.price, cutDigits: 2}]),
    } : {
      ...product,
      ...variant,
      attributes: variant.attributes,
      id: uuidv4(),
      src: variant.src.find(image => image.main) || variant.src[0],
      link: null,
      count: count,
      total: +calcPrice([{count, price: variant.price, cutDigits: 2}]),
      returned: 0,
      created: '',
      modified: '',
      variant_id: variant.id,
      price_with_discount: variant.price,
      product: variant.product_id,
    }
    
    return item
  }

  const counter = (newCount: number) => {
    if(canBeAddedSelectedVariant && newCount === canBeAddedSelectedVariant.remain && newCount === canBeAddedSelectedVariant.count) {
      const message = countOverRemainMessage(canBeAddedSelectedVariant.remain)
      toastUp(message)
    }
    setCount(newCount)
  }

  const shownVariantFields = [
    'emptyGap',
    'checkbox',
    'src',
    'attributes',
    { key: 'external_id', options: { remain: remainForLabels, external_id: externalIdForLabels }},
    { key: 'count', options: { count, counter: counter, remain: remainForLabels }},
    'price'
  ] as const;

  return <ProductVariantRow
    item={variant}
    className={'row-item order-add-items-rows--variant  order-add-items-rows--template'}
    shownFields={shownVariantFields}
    changer={undefined}
    disabled={disabled}
    selectHandler={checkboxHandler}
    tabIndex={tabIndex}
    selected={canBeAddedSelectedVariant !== undefined || alreadyAdded} 
    componentsFields={productLikeFields}
  />
}

export default VariantRowToBeAdded;
import React, {memo} from 'react';
import { useDispatch } from 'react-redux';
import { productLikeFields } from 'src/components/main-pages/products/products-items/product-items-utils';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useOrderDisabled } from '../../hooks/use-order-disabled';
import { ProductItem } from '../../../products/dto/product-item.dto';
import { ProductItemRow } from '../../../products/products-items/product-items';
import { Order } from '../../dto/order.dto';

function ListBody({order}: {order: Order}) {
  const items = useAppSelector(state => state.productItemsInContext);
  const selectedItems = useAppSelector(state => state.selectedProductItemsInContext);
  const [disabled, {disabledByStatus}] = useOrderDisabled(order)

  const dispatch = useDispatch()
  const setSelectedItems = (ids: Id[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED', payload: ids})

  const checkboxHandler = (productItem: ProductItem) => setSelectedItems(selectedItems.some(item => item === productItem.id) ? selectedItems.filter(item => item !== productItem.id) : [...selectedItems, productItem.id])
  
  const itemsSorting = (a: ProductItem, b: ProductItem) =>
    (new Date(a.created).getMilliseconds()) -
    (new Date(b.created).getMilliseconds())

  const itemChanger = (item: Partial<ProductItem>) => {
    
  }

  const productShownAttributes = [
    'checkbox', 
    'id', 
    'src', 
    'name', 
    { key: 'price', options: { inlinePriceChange: true } }, 
    'weight', 
    'count',
    'total'
  ] as const

  return (
    <div className='order-items'>
      {items
      .sort(itemsSorting)
      .map((item) => 
        <ProductItemRow
          key={item.id}
          shownFields={productShownAttributes}
          componentsFields={productLikeFields}
          item={item}
          className={disabledByStatus ? 'row-item__disabled--off row-item' : 'row-item'}
          selected={selectedItems.some(id => id === item.id)}
          changer={itemChanger}
          selectHandler={checkboxHandler} 
          disabled={disabled}/>
      )}
    </div>
  );
}

export default memo(ListBody);
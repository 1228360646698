import { Order } from '../dto/order.dto';
import {Id} from "../../../../dto/master-dto/id.dto";
import {OrderDelivery} from "../dto/delivery";

function useOrderDelivery(order: Order | undefined | null, deliveryId?: Id | null) {
  if(!order) {
    return undefined
  }

  if(!deliveryId) {
    deliveryId = order.current_delivery_id
  }
  const delivery: OrderDelivery | undefined = order.deliveries?.find(d => d.id === deliveryId)
  return delivery
}

export default useOrderDelivery;
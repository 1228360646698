import React from 'react';
import { ItemChanger } from 'src/scripts/with-row-components';
import { Price } from '../../../../dto/images/price.dto';
import { calcPrice, numWithSep, ptsansCurrency } from '../../../../scripts/functions';
import MyInput from '../../../_utility-components/input/index';
import { MyInputProps } from '../../../_utility-components/input/input';

export interface ProductItemPriceRequirment {
  price: Price,
}

export interface ProductItemPriceProps <T extends ProductItemPriceRequirment>{
  withInlineChange?: true
  changer?: ItemChanger<T>,
  item: T,
}

function ProductItemPrice <T extends {price: Price}>(props: ProductItemPriceProps<T>) {
  const { changer, withInlineChange, item } = props;
  const { price } = item;

  if(withInlineChange !== undefined && changer !== undefined) {
    if(typeof price !== 'number') {
      console.log(item)
      return null
    }

    const inputProps: MyInputProps = {
      name: "price-inline-input",
      placeholder: "₽",
      myClassName: 'inline-input placeholder--animation-off',
      messagetext: '',
      withBlur: false,
      onChange: (event) => changer({...item, price: +event.target.value}, 'change'),
      type: "number",
      value: price,
      min: 0,
    }

    return <div className='row-item__price'>
      <MyInput {...inputProps}/> 
    </div>
  }

  const priceWithFloat = calcPrice([{price, count: 1, cutDigits: 2}])
  const priceWithSeparator = numWithSep(priceWithFloat)

  return (
    <div className='row-item__price'>
      <div>{priceWithSeparator}</div>
      &nbsp;
      {ptsansCurrency('₽')}
    </div>
  );
}

export default ProductItemPrice;
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useTabIndex } from "../../../../scripts/hooks/use-tabindex";

export const CreateProductPageLink = ({
  prevPage
}: {
  prevPage: string
}) => {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  const tabIndex = useTabIndex()

  return (
    moySkladIntegrationOn ? 
      <div className='small-font'>Включена интеграция с Моим Складом, заказы формируются через МойСклад</div>
    : (
      <Link
        tabIndex={tabIndex} 
        to={{
          pathname: '/app/products/create-product', 
          state: {prevPage: prevPage}
        }} 
        className="products-create-btn"
      >
        Создать товар
      </Link>
    )
  )
}
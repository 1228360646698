import { RefundDto } from "../dto/refund.dto"

interface Props {
  refund: RefundDto,
}

export const renameExportHeaders = ({
  refund,
}: Props): any => {
  return {
    'Заказ': refund.order_id,
    'Получен': refund.order_recieve_date,
    'Возвращен': refund.refund_date,
    'Покупатель': refund.client?.name || '',
    'Сумма возврата': refund.refund_total,
    'Сумма заказа': refund.order_total,
    'Статус': refund.status?.name || '',
  }
}
import React, { useContext, useRef, useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { batch } from 'react-redux';
import withRowComponents, { Field, FieldWithOptions, RowItemChangerActions, RowProps, ShownFields } from 'src/scripts/with-row-components';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import ConfirmPopup from '../../../_utility-components/confirm-popup';
import withAddItemPopup from '../../orders/order-page-popups/add-item-popup';
import { ExtendedProduct } from '../../products/dto/products.dto';
import { withDraggableParentTag, WithDraggableParentTagProps } from '../../products/product-page-modules/product-one-option-modules/conditiaonal-draggable';
import ItemConfig from '../../products/products-items/config';
import { SetDto } from '../../sets/dto/set.dto';
import { CollectionContext } from '../collection-page';
import { CollectionDto, CollectionItem, CollectionItemCommonProps } from '../dto/collections.dto';
import AddSetsInCollection from './add-sets-in-collection';
import CollectionItemEmptyGap from './collection-items-row-cells/empty-gap';
import CollectionItemExternalId from './collection-items-row-cells/external-id';
import CollectionItemGrab from './collection-items-row-cells/grab';
import CollectionItemImage from './collection-items-row-cells/image';
import CollectionItemName from './collection-items-row-cells/name';
import CollectionItemPrice from './collection-items-row-cells/price';
import ProductToBeAddedInCollection from './product-to-be-added-in-collection';
import SetItemShowProducts from './set-row-cells/show-products';

interface Props {
  collectionDispatch: (changedCollection: Partial<CollectionDto>) => void
}

const BaseCollectionItemRow: React.FC<RowProps<CollectionItem>> = () => <></>;
const CollectionItemRow = withRowComponents<RowProps<CollectionItem>>(BaseCollectionItemRow);
const OptimizedDraggableCollectionItemRow = withDraggableParentTag(CollectionItemRow);

const DraggableCollectionItemRow: React.FC<WithDraggableParentTagProps & Omit<RowProps<CollectionItem>, 'shownFields' | 'rowClickConfig' | 'componentsFields'>> = ({ item, ...otherProps }) => {
  const shownFieldsForProduct: ShownFields<ExtendedProduct & CollectionItemCommonProps> = ['grab', 'src', 'name', 'external_id', 'price', 'config'];
  const shownFieldsForSet: ShownFields<SetDto & CollectionItemCommonProps> = ['grab', 'src', 'name', {key: 'products_ids', options: {noButton: true}}, 'emptyGap', 'config'];

  // Decide which fields to show based on the actual item type
  const shownFields = item.item_type === 'product' ? shownFieldsForProduct : shownFieldsForSet;
  const pathname = item.item_type === 'product' ? 'products' : 'sets';
  const preventClickClassNames = ['.grab-zone', '.item__config']

  const rowClickConfig = () => ({
    to: `/app/${pathname}/${item.item_id}`,
  });

  return (
    <OptimizedDraggableCollectionItemRow 
      item={item}
      rowClickConfig={rowClickConfig}
      preventClickClassNames={preventClickClassNames}
      shownFields={shownFields as (Field<CollectionItem> | FieldWithOptions<CollectionItem>)[]}
      componentsFields={collectionItemsFields}
      {...otherProps}
    />
  );
};

function CollectionContent({
  collectionDispatch
}: Props) {
  const _isMounted = useRef(true);
  const {
    collection,
  } = useContext(CollectionContext);
  
  const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
  const [addSetPopupOpen, setAddSetPopupOpen] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState<CollectionItem | false>(false);

  if(!collection) {
    return null
  }

  const sortedItems = [...collection.items]
    .sort((a, b) => a.itemOrder - b.itemOrder);

  const deleteProductItemFromCollection = () => {
    if(confirmDeletion) {
      deleteItems([confirmDeletion])
    }
  }

  const changer = (item: CollectionItem, action: RowItemChangerActions) => {
    if(action === 'delete') {
      setConfirmDeletion(item)
      return;
    }

    // if(action === 'change') {
    //   collectionDispatch({
    //     ...collection,
    //     items: sortedItems.map(p => 
    //       p.id === item.id ? item : p
    //     ),
    //   })
    //   return;
    // }
  }

  const deleteItems = (items: CollectionItem[]) => {
    return mercheryFetch<CollectionDto>('collections/items', 'DELETE', {
      collection_id: collection.id,
      items: items.map((item) => ({
        item_id: item.item_id,
        item_type: item.item_type,
      }))
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      collectionDispatch({
        items: res.records.items
      })
      return true
    })
  }

  const addItems = async <T extends {id: Id},>(items: T[], item_type: string) => {
    return mercheryFetch<CollectionDto>('collections/items', 'POST', {
      collection_id: collection.id,
      items: items.map((item, index) => ({
        item_id: item.id,
        item_type: item_type,
        itemOrder: sortedItems.length + index
      }))
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      collectionDispatch({
        items: res.records.items
      })
      return true
    })
  }

  const reorderItems = (item: CollectionItem, difference: number) => {
    if(!collection) {
      return false
    }

    mercheryFetch<CollectionDto>('collections/items', 'PATCH', {
      collection_id: collection.id,
      item_id: item.item_id,
      item_type: item.item_type,
      difference: difference
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      collectionDispatch({
        items: res.records.items
      })
    })
  }

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if(result.destination?.index === undefined) {
      return false
    }
    
    const newList: CollectionItem[] = JSON.parse(JSON.stringify(sortedItems))
    const [removed] = newList.splice(result.source.index, 1);

    newList.splice(result.destination.index, 0, removed);
    const difference = result.destination.index - result.source.index

    reorderItems(removed, difference)
    collectionDispatch({
      items: newList.map((item, index) => ({
        ...item, 
        itemOrder: index + 1
      }))
    })
  }
  return (
    <section className='collection-page__products'>
      <div className='collection-page__products__wrapper'>

        <div className='col-products__header'>
          <h3 className='header-font-l'>Товары в коллекции</h3>

          <div className='col-products__header-btns'>
            <MyButton className='white-btn collection-page__add-product header-font-xs' 
              onClick={() => setAddItemPopupOpen(true)}
            >
              + Добавить товар
            </MyButton>
            
            <MyButton className='white-btn collection-page__add-product header-font-xs' 
              onClick={() => setAddSetPopupOpen(true)}
            >
              + Добавить комплекты
            </MyButton>
          </div>
        </div>

        {addItemPopupOpen ?
          <AddItemsInCollection 
            popupClose={() => setAddItemPopupOpen(false)}
            addItems={(items) => addItems(items, 'product')} 
            filters={{}}
          />
        : null}

        {addSetPopupOpen ? 
          <AddSetsInCollection
            popupClose={() => setAddSetPopupOpen(false)}
            addItems={(items) => addItems(items, 'set')} 
          />
        : null}

        <div className='collection-page__products__inner'>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId={`items-in-collection`} type={`items-in-collection`}>
              {(provided, snapshot) => (
                <div 
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {sortedItems
                    .map((item, index) => (
                      <DraggableCollectionItemRow 
                        key={item.id}
                        item={item}
                        isDraggable={true}
                        innerHandler={true}
                        dragProps={{
                          draggableId: '' + item.id,
                          index,
                        }}
                        className={'row-item'}
                        disabled={false}
                        changer={changer} 
                      />
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

      </div>
      
      {confirmDeletion ?
        <ConfirmPopup 
          confirmHandler={() => {
            deleteProductItemFromCollection()
            setConfirmDeletion(false)
          }}
          deletion={true}
          children={<>
            <div className='confirm-popup__paragraph'>
              Удаление {confirmDeletion.item_type === 'product' ? 'товара' : 'комплекта'} из коллекции
            </div>
          
            <div className='confirm-popup__paragraph'>
              {confirmDeletion.item_type === 'product' ? 'Товар' : 'Комплект'} <b>{confirmDeletion.name}</b> будет удален из коллекции
            </div>

            <div className='confirm-popup__paragraph'>
              Вы уверены, что хотите продолжить?
            </div>
          </>}
          popupClose={() => setConfirmDeletion(false)}
        /> 
      : null}
    </section>
  );
}

export const AddItemsInCollection = withAddItemPopup<ExtendedProduct>(ProductToBeAddedInCollection)

const collectionItemsFields = {
  grab: CollectionItemGrab,
  src: CollectionItemImage,
  name: CollectionItemName,
  external_id: CollectionItemExternalId,
  price: CollectionItemPrice,
  products_ids: SetItemShowProducts,
  emptyGap: CollectionItemEmptyGap,
  config: ItemConfig,
}

export default CollectionContent;
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import { Client } from '../../../clients/dto/client.dto';
import { Order } from '../../dto/order.dto';
import ClientInfo from '../../order-modules/popups/client-info';
import useOrderFilters from "../../hooks/use-order-filters";
import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

export function OrderRowClient({
  order,
}: {
  order: Order,
}) {
  const _mounted = useRef(true);
  const clients = useAppSelector(state => state.clients);

  const [userLoader, setUserLoader] = useState(false);
  const client = clients?.find(client => client.id === order.client_id)

  const dispatch = useDispatch();

  const { openPopup, RenderPopup, closePopup, isOpen} = usePopup();

  const getUserData = async () => {
    setUserLoader(false)

    try {
      let res = await mercheryFetch<Client>(`client/one/${order.client_id}`, 'GET', {});
      if (!_mounted.current || !validateResponse(res)) return false;

      clientDispatch(res.records)
    } finally {
      _mounted.current && setUserLoader(true)
    }
  };

  const handler = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if(!isOpen) {
      getUserData();
    }
    !isOpen ? openPopup() : closePopup()
  }

  if(!client || !clients) {
    return <div className="tt-buyer"></div>
  }
  
  const clientDispatch = (gotClient: Client) => dispatch({
    type: 'CLIENTS', 
    payload: 
      !client ? 
        [...clients, gotClient]
      : clients.map(client => client.id === gotClient.id ? gotClient : client)
  });

  return <BodyCell myClassName="tt-buyer">
    <div className={'tt-buyer__popup-opener__wrapper'}>
      {client.name}

      <MyButton
        onMouseDown={e => e.button === 0 && handler(e)}
        onKeyUp={e => e.key === 'Enter' && handler(e)}
        className="client-info-popup-open-btn"
      >
        <i className="icofont-id-card"></i>
      </MyButton>

      <RenderPopup
        className={'client-info-popup'}
      >
        <Link
          to={`/app/clients/${client.id}`}
          className="contacts blue-color"
        >
          <h4>{client.name}</h4>
          <div>{client.phone}</div>
          <div>{client.email}</div>
        </Link>

        <div className="separate-line"></div>

        <ClientInfo
          order={order}
          closePopup={closePopup}
          userLoader={userLoader}
          client={client}
          />
      </RenderPopup>
    </div>
  </BodyCell>;
}

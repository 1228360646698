import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { batch } from 'react-redux';
import { RowItemChangerActions } from 'src/scripts/with-row-components';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import ConfirmPopup from '../../../_utility-components/confirm-popup';
import withAddItemPopup from '../../orders/order-page-popups/add-item-popup';
import { ExtendedProduct } from '../../products/dto/products.dto';
import { withDraggableParentTag } from '../../products/product-page-modules/product-one-option-modules/conditiaonal-draggable';
import { ProductRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import { SetDto } from '../dto/set.dto';
import ProductToBeAddedInSet from './product-to-be-added-in-set';

interface Props {
  isNew: boolean,
  set: SetDto,
  setDispatch: (changedSet: Partial<SetDto>) => void
}

function ProductsInSet({
  isNew,
  set,
  setDispatch
}: Props) {
  const _isMounted = useRef(true);
  const shownFields = ['grab', 'src', 'name', 'external_id', 'price', 'config'] as const;
  const { products, products_ids } = set;

  const productsInIdsOrder = products_ids
    .map(id => products && products.find(product => product.id === id))
    .filter(a => a) as ExtendedProduct[]
  
  const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState<ExtendedProduct | false>(false);;

  const deleteProductItemFromSet = () => {
    if(confirmDeletion) {

      deleteProducts([confirmDeletion.id])
    }
  }

  const changer = (item: ExtendedProduct, action: RowItemChangerActions) => {
    if(!set.products) {
      return false
    }

    if(action === 'delete') {
      setConfirmDeletion(item)
      return;
    }
    
    if(action === 'change') {
      setDispatch({
        ...set,
        products: set.products.map(p => p.id === item.id ? item : p),
      })
      return;
    }
  }

  const addProducts = async (items: ExtendedProduct[]) => {
    if(!set.products) {
      return false
    }
    const withNewIds = [...set.products_ids, ...items.map(i => i.id)]
    const withNewProducts = [...set.products, ...items]

    if(!isNew) {
      changeProducts(withNewIds)
    } else {
      setDispatch({
        products_ids: withNewIds,
        products: withNewProducts
      })
    }

    return true
  }

  const deleteProducts = (items_ids: Id[]) => {
    if(!set.products) {
      return false
    }
    const withoutDeletedIds = set.products_ids.filter(id => !items_ids.some(delId => delId === id))
    const withoutDeletedProducts = set.products.filter(product => !items_ids.some(delId => delId === product.id))

    if(!isNew) {
      changeProducts(withoutDeletedIds)
    } else {
      setDispatch({
        products_ids: withoutDeletedIds,
        products: withoutDeletedProducts
      })
    }
  }

  const changeProducts = (items_ids: Id[]) => {
    mercheryFetch<SetDto[]>('sets', 'PATCH', {
      toChange: [{
        id: set.id,
        products_ids: items_ids
      }]
    })
    .then((sets) => {
      if(!_isMounted.current || !validateResponse(sets) || !sets.records?.[0]) return false;

      setDispatch(sets.records[0])
    })
  }

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if(result.destination?.index === undefined) {
      return false
    }
    
    const newList = [...products_ids];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);

    batch(() => {
      setDispatch({products_ids: newList})
      changeProducts(newList)
    })
  }

  const rowClickConfig = (id: Id) => ({
    to: `/app/products/${id}`,
  })
  
  return (
    <section className='collection-page__products'>
      <div className='collection-page__products__wrapper'>

        <div className='col-products__header'>
          <h3 className='header-font-l'>Товары в комплекте</h3>

          <MyButton className='white-btn collection-page__add-product header-font-xs' 
            onClick={() => setAddItemPopupOpen(true)}
          >
            + Добавить товар
          </MyButton>
        </div>

        {addItemPopupOpen ?
          <AddItemsInSet 
            popupClose={() => setAddItemPopupOpen(false)}
            addItems={addProducts} 
           filters={{}}/>
        : null}

        <div className='collection-page__products__inner'>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId={`products-in-collection`} type={`products-in-collection`}>
              {(provided, snapshot) => (
                <div 
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {productsInIdsOrder
                    .map((item, index) => (
                      <DraggableProductRow key={item.id}
                        item={item}
                        isDraggable={true}
                        innerHandler={true}
                        dragProps={{
                          draggableId: '' + item.id,
                          index, 
                        }}
                        className={'row-item'}
                        disabled={false}
                        rowClickConfig={rowClickConfig}
                        componentsFields={productLikeFields}
                        shownFields={shownFields}
                        changer={changer}
                      />
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

      </div>
      
      {confirmDeletion ?
        <ConfirmPopup confirmHandler={() => {
            deleteProductItemFromSet()
            setConfirmDeletion(false)
          }}
          deletion={true}
          children={<>
            <div className='confirm-popup__paragraph'>
              Удаление товара из комплекта
            </div>
          
            <div className='confirm-popup__paragraph'>
              Товар <b>{confirmDeletion.name}</b> будет удален из комплекта
            </div>

            <div className='confirm-popup__paragraph'>
              Вы уверены, что хотите продолжить?
            </div>
          </>}
          popupClose={() => setConfirmDeletion(false)}
        /> 
      : null}
    </section>
  );
}

export const AddItemsInSet = withAddItemPopup<ExtendedProduct>(ProductToBeAddedInSet)

const DraggableProductRow = withDraggableParentTag(ProductRow)

export default ProductsInSet;
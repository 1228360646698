import React from "react";
import { Id } from "../../../../../dto/master-dto/id.dto";
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import { usePopup } from "../../../../../scripts/hooks/use-popup";
import MyButton from "../../../../_utility-components/button/button";
import { ClientGroup } from "../../dto/client-group.dto";
import { Client } from "../../dto/client.dto";

export function SetMassGroup({
  multiChange
}: {
  multiChange: (clientChanges: Partial<Client>) => void
}) {
  const { RenderButton, RenderPopup } = usePopup()
  const groups = useAppSelector(state => state.clientGroups);

  const groupChanger = (groupId: Id) => {
    multiChange({
      group: groupId
    })
  }

  if(!groups) {
    return null
  }

  return <div>
    <RenderButton
      className="selected-top-page-menu-btn"
    >
      <i className="icofont-exit"></i>
      <span>Назначить группу</span>
    </RenderButton>

    <RenderPopup 
      className={'group selected-row status'} 
    >
      <div className="popup-group">
        <GroupOption 
          os={{
            id: 0,
            order: 0,
            name: 'Без группы',
            discount: 0,
            color: ''
          }} 
          changer={groupChanger}
          />
      </div>

      <div className="popup-group">
        {groups.map(
          os => 
            <GroupOption 
              key={os.id} 
              os={os} 
              changer={groupChanger}
              />
        )}
      </div>
      
    </RenderPopup>

  </div>;
}

export function GroupOption({
  os, 
  changer,
}: {
  os: ClientGroup, 
  changer: (id: Id) => void,
}) {
  const clickHandler = () => changer(os.id);

  return (
    <MyButton
      removeDefaultClass
      onClick={clickHandler}
      className={`popup-element`}
    >
      <span>
        {os.name}
        <div 
          style={{ backgroundColor:  os.color || '' }}
        ></div>
      </span>
    </MyButton>
  )
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import MenuIcon from 'src/img/context-menu-dots.svg';
import { Id } from "../../../dto/master-dto/id.dto";
import { mercheryFetch } from "../../../scripts/fetchConstructor";
import { toastUp, validateResponse } from "../../../scripts/functions";
import { useAppSelector } from "../../../scripts/pre-type/use-selector";
import transliterate from "../../../scripts/utils/transliteration";
import useMounted from "../../../scripts/hooks/use-mounted";
import { useTabIndex } from "../../../scripts/hooks/use-tabindex";
import MyButton from "../../_utility-components/button/button";
import MyInput from "../../_utility-components/input/index";
import Popup from "../../_utility-components/popup";
import { Category } from "../products/dto/categories.dto";
import { CategoryFilterChanger } from "./categories-filter-and-control";

export interface CategoryItemProps {
  category: Category,
  categoryHandler: CategoryFilterChanger, 
  addCategory?: (top: Id) => void,
  closePopupParent?: () => void,
  disabledConfig?: boolean,
  filtersMod?: boolean,
}
export const CategoryItem = ({
  category, 
  closePopupParent, 
  addCategory, 
  disabledConfig, 
  filtersMod, 
  categoryHandler
}: CategoryItemProps) => {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const _isMounted = useMounted();
  const inlineNameEditing = useAppSelector(state => state.inlineNameChangingCategory === category.id);
  
  // const apiService = useAppSelector(state => state.apiService);
  const categories = useAppSelector(state => state.categories);
  const tabIndex = useTabIndex()
  const [popupOpened, setPopupOpened] = useState(false);

  const location = useLocation();
  const history = useHistory();
  
  const dispatch = useDispatch();
  const setInlineNameEditing = (id: Id | null) => dispatch({ type: 'INLINE_CHANGING_CATEGORY', payload: id})
  
  const popupClose = useCallback(() => setPopupOpened(false), [])
  
  const categoriesPage = location.pathname.includes('categories')
  
  const selectCategoryDispatch = useCallback((item: Category | undefined) => dispatch({type: 'PRODUCT_SELECTED_CATEGORY', payload: item}), [dispatch])
  const categoriesDispatch = useCallback((categories: Category[]) => dispatch({type: 'CATEGORIES', payload: categories}), [dispatch])

  useEffect(() => {
    setTimeout(() => {
      nameRef.current?.focus()
    }, .04)
  }, [])

  const clearNewCategory = useCallback(() => {
    categories &&
      categoriesDispatch(categories.filter(c => !c.newCategory))
  }, [categories, categoriesDispatch])

  const nameChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(!categories) {
      return false
    }

    if(category.newCategory) {
      const name = e.target.value
      categoriesDispatch(categories.map(c => c.id !== category.id ? c : {
        ...category, 
        name: name,
        nav: transliterate(name).toLowerCase()
      }))
    } else {
      updateCategory(e)
    }
  }

  const unsetSelectedCategory = useCallback(() => {
    selectCategoryDispatch(undefined)
  }, [selectCategoryDispatch])

  const updateCategory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const labelName = event.target.name;
    const value = event.target.value;
    if(!categories || value === category[(labelName as keyof Category)]) {
      return false
    }
    
    mercheryFetch<Category[]>('category', 'PATCH', {
      changes: [{id: category.id, [labelName]: value}]
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      const updatedCategory = res.records.find(c => c.id === category.id);

      updatedCategory && batch(() => {
        selectCategoryDispatch(updatedCategory)
        categoriesDispatch(
          categories.map(c =>
            c.id !== updatedCategory.id ? c : updatedCategory
          )
        )
      })
    })
  }
  
  const createCategory = () => {
    if(!categories) {
      return false
    }

    mercheryFetch<Category>('category', 'POST', {
      toCreate: category
    })
    .then(res => {
      if(!_isMounted.current) return false;
      if(!validateResponse(res)) {
        toastUp(res.message)

        clearNewCategory()
        return false
      } 

      const createdCategory = res.records

      createdCategory && 
        categoriesDispatch(categories.map(c => c.id !== category.id ? c : createdCategory));
      !closePopupParent && 
        history.replace('/app/categories/' + createdCategory.id);
    })
  }
  
  const stopInlineEditing = () => {
    if(category?.newCategory) {

      if(!category.name)
        clearNewCategory()
      else
        createCategory()
    }

    setInlineNameEditing(null)
  }

  const deleteCategory = () => {
    if(!categories) {
      return false
    }

    mercheryFetch<number[]>('category', 'DELETE', {
      id: category.id
    })
    .then(res => {
      if(_isMounted.current && validateResponse(res)) {
        const updatedCategories = categories.filter(c => !res.records.some(deletedId => deletedId === c.id))
        categoriesDispatch(updatedCategories)
      }
    })

    unsetSelectedCategory()
  }

  const mainHandler = useCallback(() => {
    closePopupParent?.()
    selectCategoryDispatch(category)
  }, [category, closePopupParent, selectCategoryDispatch])

  return (
    <div className="category-item">
      {inlineNameEditing ?
        <MyInput inputRef={nameRef}
          value={category.name}
          name={'name'}
          shortInput
          onChange={nameChanger}
          onBlur={stopInlineEditing}
          onEnter={stopInlineEditing}
          onEscape={stopInlineEditing}
        /> 
      : (
        !filtersMod ? 
          <Link className="category-item__btn"
            to={{ pathname: '/app/categories/' + category.id }}
            onClick={() => categoryHandler(category.id)}
          >
            {category.name}

            {category.productCount ? 
              <div className={'items-counter'}>{category.productCount}</div>
            : null}
          </Link>
        :
          <MyButton className="category-item__btn"
            tabIndex={tabIndex}
            removeDefaultClass
            onClick={() => categoryHandler(category.id)}
          > 
            {category.name}
          </MyButton> 
      )} 

      {!disabledConfig && !category.newCategory && !filtersMod && addCategory !== undefined ? 
        <>
          <MyButton
            className="category-item__context-menu-btn" 
            onClick={() => setPopupOpened(true)}
          >
            <img src={String(MenuIcon)} alt={'context-menu'}/>
          </MyButton>

          {popupOpened ?
            <Popup className={'category-menu'}
              popupName={'.category-menu'}
              changingDirection
              popupClose={popupClose}
              {...(closePopupParent && {
                popupParent: '.category-tree-popup',
                popupParentClose: closePopupParent
              })}
            >
              <div className="popup-group">
                <MyButton className={'popup-element header-font-s'}
                  onClick={() => {
                    popupClose()
                    addCategory(category.id)
                  }}
                >
                  <i className="icofont-plus"></i>
                  Добавить подкатегорию
                </MyButton>

                <Link to={`/app/categories/${category.id}`}
                  className={'popup-element header-font-s'}
                  onClick={() => {popupClose(); mainHandler();}}
                >
                  <i className="icofont-settings-alt"></i>
                  Настроить категорию
                </Link>

                {categoriesPage ? null :
                  <MyButton className={'popup-element header-font-s'}
                    removeDefaultClass
                    onClick={() => {
                      setInlineNameEditing(category.id); 
                      popupClose();
                      setTimeout(() => {
                        nameRef.current?.focus()
                      }, .04)
                    }}
                  >
                    <i className="icofont-ui-edit"></i>
                    Переименовать
                  </MyButton>
                }

              </div>
              <div className="popup-group">
                <MyButton 
                  removeDefaultClass
                  className={'popup-element header-font-s red-color'}
                  onClick={deleteCategory}
                >
                  <i className="icofont-trash"></i>
                  Удалить
                </MyButton>
              </div>
            </Popup>
          : null}
        </>
      : null}
    </div>
  )
}
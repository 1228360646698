import React from 'react';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';

interface ProductItemExternalIdProps <T extends {remain: number | null}>{
  item: T,
  external_id?: string | null | undefined,
  showRemain?: boolean
}

function ProductItemExternalId<T extends {remain: number | null}>(props: ProductItemExternalIdProps<T>) {
  const { external_id, item, showRemain: showRemainLocal } = props;
  const {remain} = item;
  const showRemainSettingsRule = useAppSelector(state => state.settings.find(setting => setting.callname === 'remainder'))?.value as boolean | null
 
  const showRemain = Boolean(showRemainLocal !== undefined ? showRemainLocal : showRemainSettingsRule)

  return (
    <div className='row-item__external-id'>
      {external_id ? 
        <div>{external_id}</div> 
      : null}
      
      {remain ? (
        <div className='order-add-items__has-remain'>
          Доступно: <b>{remain}</b>
        </div>
      ) : (
        <div className='order-add-items__no-remain'>
          Нет в наличии
        </div>
      )}
    </div>
  );
}

export default ProductItemExternalId;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { mercheryFetch } from '../../../../../../scripts/fetchConstructor';
import { toastUp, validateResponse } from '../../../../../../scripts/functions';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../../scripts/hooks/use-mounted';
import MyButton from '../../../../../_utility-components/button/button';
import { OrderDeliveryContext, Tariff } from '../delivery';
import { CdekCityAndDimensions } from './cdek-city-and-dimensions';
import { CdekTariffs } from './cdek-tariffs';
import { CdekAddressAndCost } from './cdek-address-and-cost';
import { CdekCalcTariffsBodyApi, CdekCalcTariffsRes, CdekCalcTariffDto } from './dto/cdek-calc-tariffs-body.api';
import { CdekDeliveryModeDto } from './dto/cdek-mode.dto';
import { OrderContext } from '../../../order-page';
import { CdekCityDto } from './dto/cdek.dto';
import {batch} from 'react-redux';
import {OrderDelivery} from "../../../dto/delivery";
import useOrderDelivery from "../../../hooks/use-order-delivery";

export const CdekDeliveryContext = React.createContext<{
  tariffsLoading: boolean,
  deliveryTariffs: CdekCalcTariffDto[] | null,
  setDeliveryTariffs: (tariffs: CdekCalcTariffDto[] | null) => void,
  modes: CdekDeliveryModeDto[],
  setModes: (modes: CdekDeliveryModeDto[]) => void,
  currentTariff: CdekCalcTariffDto | undefined,
  cities: CdekCityDto[],
  setCities: (cities: CdekCityDto[]) => void,
}>({
  tariffsLoading: false,
  deliveryTariffs: null,
  modes: [],
  setModes: () => {},
  setDeliveryTariffs: () => {},
  currentTariff: undefined,
  cities: [],
  setCities: () => {},
});

function Cdek () {
  const _isMounted = useMounted()
  const settings = useAppSelector(state => state.settings)

  const match = useRouteMatch<{orderid: string}>()
  const orderId = +match.params.orderid;

  const cdekDeliveryTariffType = settings.find(setting => setting.callname === 'cdek_delivery_type')?.value;
  const cdekDeliveryCurrency = settings.find(setting => setting.callname === 'cdek_currency_code')?.value;
  const cdekFromCode = settings.find(setting => setting.callname === 'cdek_from_location_city_code')?.value as number | null;

  const [triggerSafe, setTriggerSafe] = useState(false);

  const {
    delivery,
    changeSelectedDelivery,
    safeHandler,
  } = useContext(OrderDeliveryContext);

  const {
    order
  } = useContext(OrderContext)

  const [tariffsLoading, setTariffsLoading] = useState(false);
  const [deliveryTariffs, setDeliveryTariffs] = useState<CdekCalcTariffDto[] | null>(null);
  const [modes, setModes] = useState<CdekDeliveryModeDto[]>([]);
  const [cities, setCities] = useState<CdekCityDto[]>([]);
  const currentDelivery = useOrderDelivery(order)

  const currentTariff = delivery && deliveryTariffs ? 
    deliveryTariffs.find(tariff => 
      String(tariff.tariff_code) === delivery.tariff_id
    ) 
  : undefined;

  const currentTariffMode = useMemo(() =>
    modes.find(
      mode =>
        mode.id === currentTariff?.delivery_mode
    )
  , [currentTariff, modes])

  useEffect(() => {
    const conditionsToGetTariffs = !Number.isNaN(orderId) && 
      delivery?.city && 
      delivery.weight && 
      delivery.length && 
      delivery.height && 
      delivery.width
      
    if(conditionsToGetTariffs) {
      calcTariffs()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    cdekDeliveryModesRequest()
  }, [])

  useEffect(() => {
    if(triggerSafe && delivery) {
      batch(() => {
        setTriggerSafe(false)
        safeHandler()
      })
    }
  }, [delivery, triggerSafe])

  useEffect(() => {
    setDeliveryTariffs(null)
  }, [
    delivery?.length, 
    delivery?.width, 
    delivery?.weight, 
    delivery?.height, 
  ])

  useEffect(() => {
    if(delivery?.city_code === null) {
      setDeliveryTariffs(null)
    }
  }, [ delivery?.city ])
  

  useEffect(() => {
    console.log('[delivery?.city_code]')
    console.log('delivery?.city_code', delivery?.city_code)
    console.log('currentDelivery', currentDelivery)
    console.log('currentDelivery.city_code', currentDelivery?.city_code)

    if(delivery && (currentDelivery && delivery.city_code !== currentDelivery.city_code) && delivery.city_code) {
      changeSelectedDelivery({
        point_address: null,
        point_id: null
      })
    }
  }, [delivery?.city_code]);

  //
  // const cdekDeleteRegisteredOrderNotes = async () => {
  //   try {
  //     if(!order || !order.deliveries) {
  //       return false
  //     }
  //
  //     const registeredCdekDeliveries = order.deliveries.filter(
  //       del =>
  //         del.type === 9 &&
  //         del.delivery_service_uuid
  //     )
  //
  //     if(!registeredCdekDeliveries?.length) {
  //       return false
  //     }
  //
  //     const deleteDeliveriesNotesBody = {
  //       type: 9,
  //       delivery_service_uuid: registeredCdekDeliveries.map(del => del.delivery_service_uuid)
  //     };
  //
  //     const deliveryNotesDeletion = await mercheryFetch<(OrderDelivery | CdekWebhooksInfoRes)[]>(
  //       'integrations/cdek/order',
  //       'delete',
  //       deleteDeliveriesNotesBody
  //     )
  //
  //     if(_isMounted.current && validateResponse(deliveryNotesDeletion)) {
  //       const changedDeliveries = deliveryNotesDeletion.records.filter(
  //         delOrInfo =>
  //           'delivery_service_uuid' in delOrInfo
  //       ) as OrderDelivery[]
  //
  //       orderDispatch({
  //         ...order,
  //         deliveries: order.deliveries.map(initDel =>
  //           changedDeliveries.find(delOrInfo =>
  //             'delivery_service_uuid' in delOrInfo && delOrInfo.id === initDel.id
  //           ) || initDel
  //         )
  //       })
  //     }
  //   } catch (e) {
  //     console.log(e)
  //     throw e
  //   }
  // }

  const pointAddressOrClientAddressNotPresented = useMemo(() => {
    if(!delivery) {
      return true
    }

    if(!currentTariffMode) {
      return true
    }

    if(currentTariffMode.to_the_door && !delivery.address) {
      return true
    }

    if(!currentTariffMode.to_the_door && (!delivery.point_id || !delivery.point_address)) {
      return true
    }

    return false
  }, [modes, currentTariff, delivery])

  if(!delivery) {
    return null
  }

  const cdekDeliveryModesRequest = () => {
    mercheryFetch<CdekDeliveryModeDto[]>('integrations/cdek/modes', 'GET')
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      setModes(res.records)
    })
  }

  const calcTariffs = () => {
    // const shopAddress = settings.find(setting => setting.callname === 'address')?.value;

    if(
      !cdekDeliveryTariffType ||
      !cdekDeliveryCurrency ||
      !cdekFromCode ||
      !delivery.city_code ||
      !delivery.weight ||
      !delivery.length ||
      !delivery.width ||
      !delivery.height
    ) {
      return false;
    }

    const calcTariffsRequest: CdekCalcTariffsBodyApi = {
      type: Number(cdekDeliveryTariffType),
      currency: Number(cdekDeliveryCurrency),
      packages: [
        {
          weight: delivery.weight * 1000,
          length: delivery.length,
          width: delivery.width,
          height: delivery.height,
        }
      ],
      from_location: {
        code: Number(cdekFromCode),
      },
      to_location: {
        code: Number(delivery.city_code),
      },
    };

    setTariffsLoading(true)

    return mercheryFetch<CdekCalcTariffsRes>('integrations/cdek/calc-tariffs', 'POST', calcTariffsRequest)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      const tariffs = res.records.tariff_codes;
      setDeliveryTariffs(tariffs);

      const standardTariffs: Tariff[] = tariffs.map(tariff => ({
        id: tariff.tariff_code,
        price: +tariff.delivery_sum
      }));

      return standardTariffs;
    })
    .finally(() => {
      _isMounted.current && setTariffsLoading(false)
    })
  };

  const registerDelivery = async () => {
    try {
      if(!order?.client) {
        throw Error('no client')
      }

      const body = {
        orderId: order.id,
        delivery: {
          ...delivery,
          id: delivery.newDelivery ? null : delivery.id
        },
        tariff: currentTariff
      };

      const createRes = await mercheryFetch<OrderDelivery>(
        'integrations/cdek/order',
        'POST',
        body
      )

      if(!_isMounted.current || !validateResponse(createRes)) {
        return false
      }

      batch(() => {
        changeSelectedDelivery(createRes.records)
        setTriggerSafe(true);
      })
    } catch (error) {
      if(typeof error === 'string' || error instanceof Error) {
        toastUp(error)
      } 
      console.log(error)
    }
  }

  const calcCostDisabled = !delivery.length || !delivery.height || !delivery.weight || !delivery.width || !delivery.city || !delivery.city_code
  
  const registerDeliveryDisabled = calcCostDisabled || !delivery.tariff_id || pointAddressOrClientAddressNotPresented;
    
  return (
    <CdekDeliveryContext.Provider value={{
      deliveryTariffs,
      tariffsLoading,
      modes,
      setModes,
      setDeliveryTariffs,
      currentTariff,
      cities, 
      setCities,
    }}>
      <div className={`op-delivery-cdek ${delivery.pickpoint_id ? 'sdek-widget' : ''}`}>
        <CdekCityAndDimensions />

        <MyButton
          className='blue-btn'
          disabled={calcCostDisabled}
          onClick={calcTariffs}
        >
          Рассчитать стоимость
        </MyButton>

        <CdekTariffs/>

        <CdekAddressAndCost/>
        
        {currentTariff ?
          <MyButton
            className='blue-btn'
            disabled={registerDeliveryDisabled}
            onClick={registerDelivery}
          >
            Зарегистрировать заказ
          </MyButton>
        : null}
      </div>
    </CdekDeliveryContext.Provider>
  );
}

export default Cdek
import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { batch } from 'react-redux';
import { addMessage } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import FallbackComponent from '../../../../_utility-components/error-boundary';
import Cdek from './cdek/cdek';
import CourierDelivery from './courier-delivery';
import { DifferentStoreTariffs, GetTariffs, OrderDeliveryContext, Tariff } from './delivery';
import { DeliveryItemHandler } from './delivery-item-handler';
import Pickpoint from './pickpoint';
import Pickup from './pickup';
import PochtaEms from './pochta-ems';

export function DeliveriesToSelect() {
  const {
    delivery,
    from,
    changeSelectedDelivery,
    setCurrentTariff,
    setFrom,
    setAllStoreTariffs,
  } = useContext(OrderDeliveryContext)

  const stores = useAppSelector(state => state.stores);
  const deliveries = useAppSelector(state => state.deliveryServices);

  const tariffHandler = (tariff: Tariff) => {
    if(from) {
      batch(() => {
        setCurrentTariff(tariff)
        changeSelectedDelivery({store_id: from.id})
      })
    }
  };
  const fromHandler = (getTariffsFunc: GetTariffs) => {
    if(!delivery) {
      return false
    }

    const {city: delivery_city, point_id} = delivery;
    if(!delivery_city || !point_id) {
      const selector = !delivery_city ? 
        '#delivery-city' 
        : '#delivery-postcode';

      addMessage( selector, 'Обязательно к заполнению', true)
      return false
    }

    getCalcTariffWithStores(getTariffsFunc)

    if(stores.length === 1) 
      setFrom(stores[0])
  }
 
  const getCalcTariffWithStores = async (getTariffs: GetTariffs) => {
    let tariffs: DifferentStoreTariffs = {};

    await Promise.all(stores.flatMap(async (store) => {
      const request = getTariffs(store)
      if(!request) return []
      
      return request
      .then((res) => {
        if(!res || !store) return []

        tariffs[store.id] = res
      })
    }))

    setAllStoreTariffs(tariffs)
    return true
  }

  return <div className="op-delivery-change">
    {deliveries.map((d) => {
      const active = delivery?.type === +d.id;
      return (
        <div
          key={+d.id}
          className={`op-delivery-item ${active ? 'active' : ''}`}
        >
          <DeliveryItemHandler
            delivery={d} 
          />

          {active ? (
            <div className="op-delivery-item-main">
              {+d.id === 1 ?
                <CourierDelivery />
                : null}

              {+d.id === 2 ?
                <PochtaEms
                  tariffHandler={tariffHandler}
                  fromHandler={fromHandler} />
                : null}

              {+d.id === 7 ?
                <Pickpoint
                  tariffHandler={tariffHandler}
                  fromHandler={fromHandler} />
                : null}

              {+d.id === 8 ?
                <Pickup />
                : null}

              {+d.id === 9 ?
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <Cdek />
                </ErrorBoundary>
                : null}
            </div>
          ) : null}
        </div>
      );
    }
    )}
  </div>;
}

// The useRowClick hook is a custom React hook designed to handle row click events in a table-like structure.
// It provides functionality for navigating to a new page when a row is clicked,
// with some conditions for preventing the click event.

import { LocationDescriptorObject, Pathname } from 'history';
import { useState, useEffect, KeyboardEvent, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Id } from '../../dto/master-dto/id.dto';
import { getSelectedText, openInNewTab } from '../functions';

const useRowClick = <S = unknown>(
  config: RowClickConfig<S>,
  preventClickClassNames?: RowClickPreventClick
) => {
  const history = useHistory();
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [activeRowId, setActiveRowId] = useState<Id | null>(null);

  useEffect(() => {
    return () => {
      timeOutId && clearTimeout(timeOutId);
    };
  }, [timeOutId]);


  const rowClick: DivMouseClick = (event, id, alternateActiveId) => {
    if(!config) return false;

    const calculatedConfig = config(id)
    const et = event.target as Element;
    const toClientPage = () => history.push({
      pathname: calculatedConfig.to,
      ...calculatedConfig
    });

    if(preventClickClassNames) {
      const cancelCellsClick = preventClickClassNames.some(cell => et.closest(cell))
      const cancelClick = Boolean(cancelCellsClick || getSelectedText())
  
      if(cancelClick) 
        return false;
    }
    
    if(!eventIsMouseEvent(event)) {
      if(event.key === 'Enter') {
        toClientPage();
      }
      return false;
    }

    const {detail, button} = event;
    if(button === 2 || (alternateActiveId !== undefined ? activeRowId !== alternateActiveId : activeRowId !== id )) {
      return false
    }

    const clickToPage = () => {
      const selectedText = getSelectedText()
      if(selectedText || (et as Element).closest('.prevent-page-transition')) {
        return false;
      }

      if(button === 0) {
        toClientPage()
      } else {
        openInNewTab(calculatedConfig.to)
      }
    }

    setTimeOutId(detail > 1
      ? undefined
      : setTimeout(() =>
        clickToPage()
      , 80)
    )
  };

  return [rowClick, setActiveRowId] as const;
};

export default useRowClick;


const eventIsMouseEvent = (event: MouseEvent | KeyboardEvent)
  : event is MouseEvent => (event as MouseEvent).button !== undefined

export type RowClickConfig<S> = ((id: Id) => LocationDescriptorObject<S> & {to: Pathname}) | undefined
export type RowClickPreventClick = string[]
export type DivMouseClick = (
  event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  id: Id,
  alternateActiveId?: Id
) => false | undefined
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import useRowClick, { RowClickConfig, RowClickPreventClick } from './hooks/use-row-click';
import { TabIndex } from 'src/dto/master-dto/tabindex.dto';

export interface RowProps<Y extends any, S = unknown> {
  item: Y, 
  shownFields: Readonly<ShownFields<Y>>,
  componentsFields: {
    [key in keyof Partial<Y>]: undefined | ((...args: any[]) => React.ReactElement | null)
  },
  changer?: ItemChanger<Y>,
  className?: string
  selectHandler?: (product: Y) => void,
  selected?: boolean,
  disabled?: boolean,
  provided?: DraggableProvided,
  tabIndex?: TabIndex,
  rowClickConfig?: RowClickConfig<S>,
  preventClickClassNames?: RowClickPreventClick,
}

export type ShownFields<T> = (Field<T> | FieldWithOptions<T>)[]

export type FieldWithOptions<T> = {
  key: Field<T>
  options: Record<string, any>
}

export type RowItemChangerActions = 'delete' | 'change';

export type ItemChanger<T> = ((item: T, action: RowItemChangerActions) => void) | undefined;

export const universalFields = ['grab', 'checkbox', 'config', 'count', 'emptyGap'] as const;
export type UniversalFieldsTypes = typeof universalFields[number]

export interface ItemCellProps<T extends any> {
  item: T,
  disabled?: boolean
  changer?: ItemChanger<T>
}


export type Field<T> = keyof T | UniversalFieldsTypes

function isFieldWithOptions<T> (field: Field<T> | FieldWithOptions<T>): field is FieldWithOptions<T> {
  return (field as unknown as FieldWithOptions<T>).key !== undefined
}

export default function withRowComponents<T extends RowProps<any>>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> {
  return (props: T) => {
    const { item, shownFields, changer, selectHandler, selected, className, disabled, provided, rowClickConfig, tabIndex, componentsFields, preventClickClassNames } = props;
    const [ rowClick, setActiveRowId ] = useRowClick(rowClickConfig, preventClickClassNames);

    const fields = shownFields;

    const combinedClassName = `text-font-m ${className ? className : ''} ${selected ? 'active' : ''} ${disabled ? 'disabled' : ''}`;

    return (
      <div 
        className={combinedClassName}
        onMouseUp={(e) => rowClick(e, item.id)}
        onMouseDown={() => setActiveRowId(item.id)}
        onKeyUp={(e) => e.key === 'Enter' ? rowClick(e, item.id) : null}
      >
        {fields
        .map((field, index) => {
          const fieldKey = isFieldWithOptions(field) ? field.key : field

          // @ts-ignore: Unreachable code error
          const Component = componentsFields[fieldKey]
          const optionalProps = isFieldWithOptions(field) ? field.options : {}
          if (!Component) return null;

          return (
            <Component 
              item={item}
              key={index}
              disabled={disabled}
              tabIndex={tabIndex}
              selected={selected}
              selectHandler={selectHandler}
              {...provided && { provided }}
              {...item}
              {...optionalProps}
              {...(changer && { changer: changer })}
            />
          )
        })}
  
        <WrappedComponent {...props} />
      </div>
    );
  };
}
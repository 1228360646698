import React from 'react';
import MyButton from '../button/button';

interface Props {
  handler: () => void,
  // tabIndex?: number,
  text?: string,
  disabled?: boolean,
  className?: string
}

function TrashBtn({
  handler,
  text,
  disabled,
  className
}: Props) {
  return (
    <MyButton 
      tabIndex={-1}
      disabled={disabled}
      className={`trash-btn ${className ? className : ''}`}
      onClick={handler}
    >
      <i className='icofont-trash trash-btn_icon_default'></i>
      <i className='icofont-ui-delete trash-btn_icon_hover'></i>
      {text ? text : ''}
    </MyButton>
  );
}

export default TrashBtn;
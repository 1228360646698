import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { MainRouteChild } from '../main-page';
import { CreateProductPageLink } from './products-page-modules/links';
import ProductsTable from './products-page-modules/table';
import ManagersOnPage from "../../_utility-components/manager-on-page";
import usePageViewers from "src/scripts/hooks/use-page-viewers";

interface Props extends MainRouteChild {}

function Products ({
  setCurrentPage
}: Props) {
  const location = useLocation();
  const pathname = location.pathname;
  const managersOnPage = usePageViewers(pathname)

  useEffect(() => {
    setCurrentPage('products');
  }, []);

  return (
    <div id="products" className="products">
      <div className={`products-header with-filters`}>
        <h1>Товары</h1>

        <div className={'products-header_right-inner'}>

          <ManagersOnPage
            managersOnPage={managersOnPage}
            size={'small'}
          />

          <CreateProductPageLink
            prevPage={location.search}
          />
        </div>
      </div>

      <ProductsTable
      />
    </div>
  );
}

export default Products
import React from 'react';
import VisibilitySelect from '../../../_utility-components/visibility-select/visibility-select';
import { SetDto } from '../dto/set.dto';
import { SetIntoCollections } from './set-into-collections';

interface Props {
  set: SetDto,
  setDispatch: (changes: Partial<SetDto>) => void
}

function SetSide({
  set,
  setDispatch
}: Props) {
  return (
    <div className='collection-page__sidebar'>
      <VisibilitySelect 
        element={set} 
        onChange={setDispatch}
      />

      <SetIntoCollections
        set={set}
      />
    </div>
  );
}

export default SetSide;
import React, { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import {Redirect, Route, RouteComponentProps, useLocation} from "react-router-dom";
import { SiteLocalStore } from '../../dto/shop.dto';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { validateResponse } from '../../scripts/functions';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import useMounted from '../../scripts/hooks/use-mounted';
import { Integration } from '../main-pages/integrations/dto/dto';
import { Setting } from '../main-pages/settings/dto/settings.dto';

interface Props<Path extends string = string> {
  path: Path | readonly Path[] | undefined
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

interface SettignsGetResponse {
  settings: Setting[], 
  integrations: Integration[]
}

function PrivateRoute ({
  component: Component, 
  ...rest
}: Props) {
  const isAuthenticated = useAppSelector(state => state.adminTokenIsValid)
  const [loading, setLoading] = useState(true);

  const location = useLocation()
  const _isMounted = useMounted()
  
  const dispatch = useDispatch()
  const setSettings = (settings: Setting[]) => dispatch({type: 'STORE_SETTINGS', payload: settings})
  const setIntegs = (integs: Integration[]) => dispatch({type: 'STORE_INTEGRATIONS', payload: integs})
  const setStores = (stores: SiteLocalStore[]) => dispatch({type: 'STORE_LIST', payload: stores})
  const setAdminTokenValidStill = (isValid: boolean) => dispatch({ type: 'ADMIN_TOKE_IS_VALID', payload: isValid })

  useEffect(() => {
    setupInitialState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setupInitialState = () => {
    getSettings()
    .then((isSuccess) => {
      const admin = localStorage.getItem('admin');
      const jwt = localStorage.getItem('jwt');

      if(_isMounted.current && isSuccess && admin && jwt) {

        batch(() => {
          setLoading(false)
          setAdminTokenValidStill(true)
          getStores();
        })

        return false
      } 

      batch(() => {
        setLoading(false)
        setAdminTokenValidStill(false)
      })
    })
    .catch((error) => {
      console.log('catched error', error)

      _isMounted.current && batch(() => {
        setLoading(false)
        setAdminTokenValidStill(false)
      })
      // toastUp('Ошибка соединения. Попробуйте позже')
      console.log('private component error', error)
    })
    // .finally(() => {
    //   localStorage.setItem('pathnameFromRedirect', location.pathname)
    // })
  }

  // const logout = () => {
  //   mercheryFetch('auth/logout', 'POST', {
  //     login: localStorage.getItem('admin')
  //   }).then((res) => {
  //     if(res?.success && res.records) {
  //       localStorage.removeItem('jwt')
  //     }
  //   })
  //   .finally(() => {
  //     history.push('/sign-in')
  //   })
  // }

  const getSettings = () =>
    mercheryFetch<SettignsGetResponse>('settings', 'GET', {})
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        setSettings(res.records.settings)
        setIntegs(res.records.integrations)
      }
      return true
    })
    .catch(error => {
      console.log(error)
      return false
    })

  const getStores = () =>
    mercheryFetch<SiteLocalStore[]>('store', "GET", {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res))
        return false

      setStores(res.records)
    });

  return (
    <Route {...rest} render={props => (
      loading ? null : 
      !isAuthenticated ? 
        <Redirect 
          to={{ pathname: '/sign-in' }} 
          /> 
      : <Component {...props} />
    )}/>
  )
}

export default PrivateRoute
// Обертка вокруг useTableFilters для функционирования унифицированных фильтров для товаров

import {useAppSelector} from "../../../../scripts/pre-type/use-selector";
import {useDispatch} from "react-redux";
import useTableFilters from "src/scripts/hooks/use-table-filters";
import {ProductFilters} from "../dto/products-filters.dto";
import {ExtendedProduct} from "../dto/products.dto";
import {arrayOfSelectedFilters, validateResponse} from "../../../../scripts/functions";
import {Brand} from "../../../../dto/brand.dto";
import {useEffect} from "react";
import {mercheryFetch} from "../../../../scripts/fetchConstructor";
import {Category} from "../dto/categories.dto";
import useMounted from "src/scripts/hooks/use-mounted";
import {showDateOptions} from "../products-page-modules/filters";

function useProductsFilters () {
	const _isMounted = useMounted()
	const productFilters = useAppSelector(state => state.productFilters);
	const categories = useAppSelector(state => state.categories);
	const brands = useAppSelector(state => state.brands);

	const dispatch = useDispatch();
	const filtersDispatch = (filters: Partial<ProductFilters>) => dispatch({
		type: 'PRODUCTS_SELECTED_FILTERS',
		payload: filters,
	});
	const brandsDispatch = (items: Brand[]) => dispatch({
		type: 'PRODUCTS_BRANDS',
		payload: items
	});
	const categoriesDispatch = (items: Category[]) => dispatch({
		type: 'CATEGORIES',
		payload: items
	});

	async function initFilters (): Promise<ProductFilters> {
		const top: Category[] | undefined = categories || await categoriesRequest() || undefined;
		const brand: Brand[] | undefined = brands || await brandsRequest() || undefined;

		return {
			top: top ? arrayOfSelectedFilters(top) : undefined,
			show_date: arrayOfSelectedFilters(showDateOptions),
			brand: brandsToFilters(brand),
		}
	}


	const [updateFilters, cleanFilters] = useTableFilters<ExtendedProduct>(
		productFilters,
		filtersDispatch,
		initFilters,
	);


	useEffect((() => {
		if(productFilters && !productFilters.top && categories) {
			filtersDispatch({
				...productFilters,
				top: arrayOfSelectedFilters(categories)
			})
		}
	}), [categories])

	useEffect((() => {
		if(productFilters && !productFilters.brand && brands) {
			filtersDispatch({
				...productFilters,
				brand: arrayOfSelectedFilters(brands)
			})
		}
	}), [brands])


	const brandsToFilters = (brands: Brand[] | null | undefined) => {
		return brands ?
			arrayOfSelectedFilters([
					{
						id: 0,
						name: 'Без бренда',
					},
					...brands
				] as Brand[]
			) : undefined
	}

	const brandsRequest = () =>
		mercheryFetch<Brand[]>('brand', 'GET', {})
		.then((res) => {
			if(!_isMounted.current || !validateResponse(res)) {
				return false
			}

			brandsDispatch(res.records)
			return res.records
		})

	const categoriesRequest = () =>
		mercheryFetch<Category[]>('category', "GET", {})
		.then((res) => {
			if(!_isMounted.current || !validateResponse(res)) {
				return false
			}

			categoriesDispatch(res.records)
			return res.records
		})

	return [productFilters, updateFilters, cleanFilters] as const
}

export default useProductsFilters;
import React from 'react';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { Order } from '../../dto/order.dto';
import SetManager from '../../order-modules/popups/set-manager';
import { ChangeOrderHandler } from '../table';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  order: Order,
  changeOrder: ChangeOrderHandler
}

export function OrderRowManager({
  order,
  changeOrder
}: Props) {
  const changeManager = (managerId: Id | null) => changeOrder([{id: order.id, admin_id: managerId}])

  return <BodyCell myClassName="tt-manager">
    <SetManager 
      admin_id={order.admin_id} 
      changeManager={changeManager}      
    />
  </BodyCell>;
}

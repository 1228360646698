import React from 'react';
import ReactDOM from 'react-dom';
import { Id } from '../../../../dto/master-dto/id.dto';
import { priceWithPennies, priceWithStep } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import Popup from '../../../_utility-components/popup';

interface Props {
  popupClose: () => void,
  selectedOrders: Id[],
  mainActionHandler: () => void
}

const OrdersDeletePopupRender = ({
  selectedOrders, popupClose, mainActionHandler
}: Props) => {
  const orders = useAppSelector(state => state.orders);
  const headerText = selectedOrders.length === 1 ? 'Удаление заказа ' + selectedOrders[0] : 'Удаление заказов ' + selectedOrders.join(', ')
  const selectedOrdersPrice = 
  priceWithStep(
    priceWithPennies(
      orders
        .filter((order) => selectedOrders.some(selected => order.id === selected))
        .reduce((total, order) => total + (order.total || 0), 0)
    )
  )
  const confirmDeletionText = `Подтвердите удаление ${selectedOrders.length === 1 ? 'заказа' : 'заказов'} на ${selectedOrdersPrice} ₽`;

  return (
    <Popup
      className={'confirm-window fixed-on-center order-delete-popup'}
      popupClose={popupClose}
      withBlackout
      withCloseBtn
      tabIndexDeep={3}
    >
      <h2>{headerText}</h2>
      <span>{confirmDeletionText}</span> 

      <div className="confirm-window-btn">
        <MyButton 
          className={'white-btn'} 
          onClick={popupClose}
        >
          Отменить
        </MyButton>
        <MyButton 
          className="red-btn" 
          onClick={() => {
            mainActionHandler()
            popupClose()
          }}
        >
          Удалить
        </MyButton>
      </div>
    </Popup>
  )
}

export default OrdersDeletePopupRender
import React, {HTMLAttributes, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { scrollPageTo, validateResponse } from '../../../../../scripts/functions';
import useRowClick from '../../../../../scripts/hooks/use-row-click';
import { useShiftSelect } from '../../../../../scripts/hooks/use-shift-select';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import { ExtendedProduct } from '../../dto/products.dto';
import { withDraggableParentTag } from '../../product-page-modules/product-one-option-modules/conditiaonal-draggable';
import Row from './table-row/row';
import {ProductsTableContext} from "../table";

const ProductsTableRows = ({
  sortedProducts,
}: {
  sortedProducts: ExtendedProduct[]
}) => {
  const _isMounted = useRef(true);
  const products = useAppSelector(state => state.products);
  const location = useLocation<{toProduct?: Id}>();
  const fromProductId = location.state?.toProduct;

  const {
    disableDraggable,
    newProducts,
  } = useContext((ProductsTableContext))

  const fromProduct = useMemo((): ExtendedProduct | null => {
    const productId = fromProductId;
    if(!productId) return null;

    const productNotExist = products.findIndex((o) => +o.id === +productId) === -1;
    if(productNotExist) return null;

    scrollPageTo(`[productId="${productId}"]`)

    return products.find((p) => +p.id === +productId) || null
  }, [fromProductId, products])
  
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);
  const tabIndex = useTabIndex();
  const searchInput = useAppSelector(state => state.searchInput);
  const selectedProducts = useAppSelector(state => state.selectedProducts);
  
  const dispatch = useDispatch()
  const productsDispatch = (products: ExtendedProduct[]) => dispatch({ type: 'PRODUCTS', payload: products });
  const selectedProductsDispatch = (items: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS', payload: items });
  
  const [productInFocus, setProductInFocus] = useState(fromProduct);
  const [localSelectedProducts, handleSelectProduct, selectAll] = useShiftSelect(products);

  useEffect(() => {
    if(selectedProducts.length !== localSelectedProducts.length) {
      selectAll(selectedProducts)
    }
  }, [selectedProducts])

  const rowCheckboxHandler = (item: ExtendedProduct) => {
    const newCalculatedSelected = handleSelectProduct(item)
    selectedProductsDispatch(newCalculatedSelected)
  }

  const setProductInFocusHandler = (productId: Id) => {
    const product = products.find(s => s.id === productId);
    document.getSelection()?.removeAllRanges();

    if(!_isMounted.current || !product || product.id === fromProductId) {
      return false
    }

    setProductInFocus(product)
  };
  
  const [rowClick, setActiveRowId] = useRowClick(
    (id) => ({
      to: `/app/products/${id}`, 
      state: {prevPage: location.search}
    }),
    ['.op-total', '.op-check', '.op-show-more .my-btn', '.op-price', '.op-config']
  );

  const changeData = (id: Id, changes: Partial<any>) => {
    const requestBody = {
      changes: [{
        id: id,
        ...changes,
      }]
    }
    const path = 'products';

    mercheryFetch<ExtendedProduct[]>(path, 'PATCH', requestBody)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      const changedProduct = res.records.find(o => o.id === id)

      let extendedWithVariantsProducts = changedProduct
        ? products.map(product =>
          changedProduct.id === product.id
            ? changedProduct
            : product)
        : products;

      productsDispatch(extendedWithVariantsProducts)
      return true
    })
  };
  const productDragAndDropIsDisabled = !!(moySkladIntegrationOn || searchInput)

  return <>
    {
      sortedProducts
      .map((el, index) => {
        const checked = selectedProducts.some(id => el.id === id);

        const focused = productInFocus && productInFocus.id === el.id ? 'focused' : '';
        const selected = checked ? 'selected' : '';
        const hidden = !el.show_date || new Date(el.show_date).getTime() > new Date().getTime() ? 'hidden' : '';
        const msItemClass = moySkladIntegrationOn ? 'moy-sklad-item' : '';
        const itemId = el.id;
        const withVariants = el.variants?.length ? 'with-variants' : '';
        const newEntity = newProducts.has(el.id) ? 'new-entity' : '';
      
        const rowWrapperProps: HTMLAttributes<HTMLDivElement> = {
          className: `droppable table-item ${msItemClass} ${selected} ${hidden} ${focused} ${withVariants} ${newEntity}`,
          onFocus: () => setProductInFocusHandler(el.id),
          tabIndex: tabIndex,
          onMouseUp: e => rowClick(e, itemId),
          onMouseDown: () => setActiveRowId(itemId),
          onKeyUp: e => e.key === 'Enter' ? rowClick(e, itemId) : null,
        }

        return (
          <RowWithDraggable 
            key={el.id}
            isDraggable={!disableDraggable}
            innerHandler={true}
            dragProps={{
              index: index,
              isDragDisabled: productDragAndDropIsDisabled,
              draggableId: '' + el.id,
            }}
            product={el}
            checked={checked}
            changeData={changeData}
            productCheckHandler={rowCheckboxHandler}
            wrapperProps={rowWrapperProps}
          />
        )
      })
    }
  </>
}

const RowWithDraggable = withDraggableParentTag(Row<ExtendedProduct>)

export default ProductsTableRows
import React, {useEffect, useMemo, useState} from 'react';
import { batch, useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { Id } from '../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import useMounted from '../../../scripts/hooks/use-mounted';
import { MainRouteChild } from '../main-page';
import { StorageInfoHeaders } from './dto/file-storage-info-res';
import { Setting } from './dto/settings.dto';
import DynamicModulePage from './dynamic-module-page';
import SettingsTopPanel from './top-panel';

export const SettingsContext = React.createContext<{
  fileStorageInfo: StorageInfoHeaders | null,
  setFileStorageInfo: React.Dispatch<React.SetStateAction<StorageInfoHeaders | null>>,
  settingsEdit: boolean, 
  setSettingsEdit: (item: boolean) => void,
  taxForm: Id | null, 
  siteName: string | null, 
  ownerEmail: string | null, 
  ownerPhone: string | null, 
  setTaxForm: React.Dispatch<React.SetStateAction<Id | null>>,
  setSiteName: React.Dispatch<React.SetStateAction<string | null>>,
  setOwnerEmail: React.Dispatch<React.SetStateAction<string | null>>,
  setOwnerPhone: React.Dispatch<React.SetStateAction<string | null>>,
  city: string | null, 
  address: string | null, 
  legalAddress: string | null, 
  companyName: string | null, 
  itn: string | null,
  bankAccount: string | null,
  bic: string | null,
  setCity: React.Dispatch<React.SetStateAction<string | null>>,
  setAddress: React.Dispatch<React.SetStateAction<string | null>>,
  setLegalAddress: React.Dispatch<React.SetStateAction<string | null>>,
  setCompanyName: React.Dispatch<React.SetStateAction<string | null>>,
  setItn: React.Dispatch<React.SetStateAction<string | null>>,
  setBankAccount: React.Dispatch<React.SetStateAction<string | null>>,
  setBic: React.Dispatch<React.SetStateAction<string | null>>,
  autoRenewal: boolean | null,
  setAutoRenewal: React.Dispatch<React.SetStateAction<boolean | null>>,
  timezoneOffset: number | null,
  setTimezoneOffset: React.Dispatch<React.SetStateAction<number | null>>,
}>({
  fileStorageInfo: null,
  setFileStorageInfo: () => {},
  settingsEdit: false, 
  setSettingsEdit: () => {},
  taxForm: null, 
  siteName: null, 
  ownerEmail: null, 
  ownerPhone: null, 
  setTaxForm: () => {},
  setSiteName: () => {},
  setOwnerEmail: () => {},
  setOwnerPhone: () => {},
  city: null,
  address: null,
  legalAddress: null,
  companyName: null,
  itn: null,
  bankAccount: null,
  bic: null,
  setCity: () => {},
  setAddress: () => {},
  setLegalAddress: () => {},
  setCompanyName: () => {},
  setItn: () => {},
  setBankAccount: () => {},
  setBic: () => {},
  autoRenewal: null,
  setAutoRenewal: () => {},
  timezoneOffset: null,
  setTimezoneOffset: () => {},
});

function SettingsContextWrapper(pageProps: MainRouteChild) {
  const _isMounted = useMounted()
  const settings = useAppSelector(state => state.settings);
  const settingsModules = useAppSelector(state => state.settingsModules);
  const prefix = useAppSelector(state => state.staticValues.router_link_prefix || '');

  const [settingsEdit, setSettingsEdit] = useState<boolean>(false);


  // owner fields
  const initSiteName = settings.find(setting => setting.callname === 'site_name_display')?.value as string | null;
  const initOwnerEmail = settings.find(setting => setting.callname === 'owner_email')?.value as string | null;
  const initOwnerPhone = settings.find(setting => setting.callname === 'owner_phone')?.value as string | null;

  const [siteName, setSiteName] = useState(initSiteName);
  const [ownerEmail, setOwnerEmail] = useState(initOwnerEmail);
  const [ownerPhone, setOwnerPhone] = useState(initOwnerPhone);

  useEffect(() => {
    initSiteName !== siteName && setSiteName(initSiteName)
  }, [initSiteName]);

  useEffect(() => {
    initOwnerEmail !== ownerEmail && setOwnerEmail(initOwnerEmail)
  }, [initOwnerEmail]);

  useEffect(() => {
    initOwnerPhone !== ownerPhone && setOwnerPhone(initOwnerPhone)
  }, [initOwnerPhone]);

  // address fields
  const initCity = settings.find(setting => setting.callname === 'city')?.value as string | null;
  const initAddress = settings.find(setting => setting.callname === 'address')?.value as string | null;
  const initLegalAddress = settings.find(setting => setting.callname === 'legal_address')?.value as string | null;
  const initCompanyName = settings.find(setting => setting.callname === 'company_name')?.value as string | null;
  const initItn = settings.find(setting => setting.callname === 'itn')?.value as string | null;
  const initBankAccount = settings.find(setting => setting.callname === 'bank_account')?.value as string | null;
  const initBic = settings.find(setting => setting.callname === 'bic')?.value as string | null;

  const [city, setCity] = useState(initCity);
  const [address, setAddress] = useState(initAddress);
  const [legalAddress, setLegalAddress] = useState(initLegalAddress);
  const [companyName, setCompanyName] = useState(initCompanyName);
  const [itn, setItn] = useState(initItn);
  const [bankAccount, setBankAccount] = useState(initBankAccount);
  const [bic, setBic] = useState(initBic);

  useEffect(() => {initCity !== city && setCity(initCity)}, [initCity]);

  useEffect(() => {initAddress !== address && setAddress(initAddress)}, [initAddress]);

  useEffect(() => {initLegalAddress !== legalAddress && setLegalAddress(initLegalAddress)}, [initLegalAddress]);

  useEffect(() => {initCompanyName !== companyName && setCompanyName(initCompanyName)}, [initCompanyName]);

  useEffect(() => {initItn !== itn && setItn(initItn)}, [initItn]);

  useEffect(() => {initBankAccount !== bankAccount && setBankAccount(initBankAccount)}, [initBankAccount]);

  useEffect(() => {initBic !== bic && setBic(initBic)}, [initBic]);

  // tariff fields
  const initAutoRenewal = settings.find(setting => setting.callname === 'auto_renewal')?.value as boolean | null;
  const [autoRenewal, setAutoRenewal] = useState<boolean | null>(initAutoRenewal);

  useEffect(() => {initAutoRenewal !== autoRenewal && setAutoRenewal(initAutoRenewal)}, [initAutoRenewal]);

  // misc fields
  const initSiteTimezoneOffset = settings.find(setting => setting.callname === 'site_timezone_offset')?.value as number | null;
  const initTaxForm = settings.find(setting => setting.callname === 'tax_form')?.value as Id | null;

  const [timezoneOffset, setTimezoneOffset] = useState(initSiteTimezoneOffset);
  const [taxForm, setTaxForm] = useState<Id | null>(initTaxForm);

  useEffect(() => {initSiteTimezoneOffset !== timezoneOffset && setTimezoneOffset(initSiteTimezoneOffset)}, [initSiteTimezoneOffset]);

  useEffect(() => {initTaxForm !== taxForm && setTaxForm(initTaxForm)}, [initTaxForm]);

  const [fileStorageInfo, setFileStorageInfo] = useState<StorageInfoHeaders | null>(null);

  const settingsChanges = useMemo(() => {
    const changes: {[keyof in Setting['callname']]: Setting['value']} = {}

    if(siteName !== initSiteName) {
      changes['site_name_display'] = siteName
    }
    if(ownerEmail !== initOwnerEmail) {
      changes['owner_email'] = ownerEmail
    }
    if(ownerPhone !== initOwnerPhone) {
      changes['owner_phone'] = ownerPhone
    }

    if(city !== initCity) {
      changes['city'] = city
    }
    if(address !== initAddress) {
      changes['address'] = address
    }
    if(legalAddress !== initLegalAddress) {
      changes['legal_address'] = legalAddress
    }
    if(companyName !== initCompanyName) {
      changes['company_name'] = companyName
    }
    if(itn !== initItn) {
      changes['itn'] = itn
    }
    if(bankAccount !== initBankAccount) {
      changes['bank_account'] = bankAccount ? bankAccount.replace(/\s/g, "") : bankAccount
    }
    if(bic !== initBic) {
      changes['bic'] = bic
    }

    if(autoRenewal !== initAutoRenewal) {
      changes['auto_renewal'] = autoRenewal
    }

    if(timezoneOffset !== initSiteTimezoneOffset) {
      changes['site_timezone_offset'] = timezoneOffset
    }
    if(taxForm !== initTaxForm) {
      changes['tax_form'] = taxForm
    }
    
    return changes
  }, [
    address, autoRenewal, bankAccount, bic, city,
    companyName, initAddress, initAutoRenewal, initBankAccount, 
    initBic, initCity, initCompanyName, initItn,
    initLegalAddress, initOwnerEmail, initOwnerPhone, initSiteName, 
    initSiteTimezoneOffset, initTaxForm, itn, legalAddress, 
    ownerEmail, ownerPhone, siteName, taxForm, timezoneOffset
  ])

  const dispatch = useDispatch()
  const settingsDispatch = (settings: Setting[]) => dispatch({ type: 'STORE_SETTINGS', payload: settings})

  const cancelBtnHandler = () => {
    batch(() => {
      setSettingsEdit(false)

      setTaxForm(initTaxForm)
      setSiteName(initSiteName)
      setOwnerEmail(initOwnerEmail)
      setOwnerPhone(initOwnerPhone)
      setCity(initCity)
      setAddress(initAddress)
      setLegalAddress(initLegalAddress)
      setCompanyName(initCompanyName)
      setItn(initItn)
      setBankAccount(initBankAccount)
      setBic(initBic)
      setAutoRenewal(initAutoRenewal)
      setTimezoneOffset(initSiteTimezoneOffset)
    })
  }

  const saveChanges = async () => {
    try {
      const patchData = {
        toChange: settingsChanges
      }

      await mercheryFetch<Setting[]>('settings/site', 'PATCH', patchData)
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        const changedSettings = settings.map(
          setting =>
            res.records.find(
              changed =>
                changed.callname === setting.callname
            ) || setting
        )

        batch(() => {
          setSettingsEdit(false)
          settingsDispatch(changedSettings)
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <SettingsContext.Provider value={{
      fileStorageInfo, setFileStorageInfo,
      settingsEdit, setSettingsEdit,
      taxForm, setTaxForm,
      siteName, setSiteName,
      ownerEmail, setOwnerEmail,
      ownerPhone, setOwnerPhone,
      city, setCity,
      address, setAddress,
      legalAddress, setLegalAddress,
      companyName, setCompanyName,
      itn, setItn,
      bankAccount, setBankAccount,
      bic, setBic,
      autoRenewal, setAutoRenewal,
      timezoneOffset, setTimezoneOffset,
    }}>
      {settingsModules.map((module) => {
        return (
          <Route
            key={module.id}
            exact
            path={`${prefix}/${module.front_link}`}
            render={(routeProps) => (
              <DynamicModulePage
                module={module}
                {...routeProps}
                {...pageProps}
              />
            )}
          />
        )
      })}

      <SettingsTopPanel
        changes={settingsChanges}
        cancelBtnHandler={cancelBtnHandler}
        saveChanges={saveChanges}
      />
    </SettingsContext.Provider>
  )
}

export default SettingsContextWrapper;
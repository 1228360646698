import React, {useContext} from 'react';
import {num2str, validateResponse} from '../../../../scripts/functions';
import MyCheckbox from '../../../_utility-components/checkbox/my-checkbox';
import TrashBtn from '../../../_utility-components/trash-btn/trash-btn';
import MassVisibilityPopup from "../../../_utility-components/visibility-select/mass-visibility-popup";
import {useAppSelector} from "../../../../scripts/pre-type/use-selector";
import {Id} from "../../../../dto/master-dto/id.dto";
import {batch, useDispatch} from "react-redux";
import {CollectionDto} from "../../collections/dto/collections.dto";
import {mercheryFetch} from "../../../../scripts/fetchConstructor";
import {SetDto} from "../dto/set.dto";
import useMounted from "../../../../scripts/hooks/use-mounted";
import HeaderCell from "../../../_utility-components/common-table/header-cell";
import {SetsContext} from "../sets";

interface Props {
  headerCheckHandler: () => void,
  selectedSetsIds: Id[],
  checkboxIsActive: boolean,
  deleteHandler: () => void,
}

function HeaderSelectedControl({
  checkboxIsActive,
  selectedSetsIds,
  headerCheckHandler,
  deleteHandler,
}: Props) {
  const _isMounted = useMounted()
  const sets = useAppSelector((state => state.sets));
  const selectedCountDisplay = num2str(selectedSetsIds.length, ['комплект', 'комплекта', 'комплектов'], ['Выбран', 'Выбрано', 'Выбрано']);
  const selectedSets =
    (sets || []).filter(coll =>
      selectedSetsIds.some(id => id === coll.id)
    );

  const {
    setSelectedSets
  } = useContext(SetsContext);

  const dispatch = useDispatch();
  const setsDispatch = (sets: SetDto[]) => dispatch({type: 'SETS', payload: sets});

  const onChange = ({show_date}: {show_date: number | null}) => {
    mercheryFetch<CollectionDto[]>('sets', 'PATCH', {
      toChange: selectedSets.map((set) => ({
        id: set.id,
        show_date: show_date,
      })),
    })
    .then((res) => {
      if(_isMounted.current && validateResponse(res) && sets) {
        const changedCollections = sets.map(c => {
          const reorderedColl = res.records.find(reorderedItem => reorderedItem.id === c.id);
          return reorderedColl ? {
            ...c,
            ...reorderedColl
          } : c;
        });

        batch(() => {
          setSelectedSets([])
          setsDispatch(changedCollections)
        })
      }
    })
  }

  return (
    <div className={`collection__table-header--control`}>
      <HeaderCell className='right-align'
        children={
          <MyCheckbox text={''} 
            condition={checkboxIsActive}
            actionHandler={headerCheckHandler}
          />
        }
      />

      <HeaderCell className={'header-font-s'} 
        children={selectedCountDisplay}
      />

      <HeaderCell
        className={`right-align`}
        children={
          <div style={{
            display: 'flex',
            columnGap: '32px'
          }}>
            <MassVisibilityPopup
              onChange={onChange}
              elements={selectedSets}
              entityNameInHeader={'коллекций'}
            />

            <TrashBtn
              className={'header-font-s'}
              handler={deleteHandler}
              text={'Удалить'}
            />
          </div>
        }/>
    </div>
  );
}

export default HeaderSelectedControl;
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Id } from '../../../dto/master-dto/id.dto';
import { MainRouteChild } from '../main-page';
import SetsTable from './modules/table';

interface Props extends MainRouteChild {

}

interface SetsContextDto {
  selectedSets: Id[],
  setSelectedSets: (ids: Id[]) => void
}

export const SetsContext = createContext<SetsContextDto>({
  selectedSets: [],
  setSelectedSets: () => {}
})

function Sets ({ 
  setCurrentPage
}: Props) {
  const [selectedSets, setSelectedSets] = useState<Id[]>([]);

  useEffect(() => {
    setCurrentPage('sets')
  }, [])
  
  const toCreation = useMemo(() => ({
    pathname: '/app/sets/new',
  }), [])

  const providerValue = {
    selectedSets,
    setSelectedSets
  }

  return (
    <SetsContext.Provider value={providerValue}>
      <div className='collections product-sets'>
        <div className="collections__header product-sets__header">
          <h1>Комплекты</h1>

          <Link 
            className='my-btn blue-btn'
            to={toCreation}
          >
            Собрать комплект
          </Link>
        </div>

        <SetsTable/>
      </div>
    </SetsContext.Provider>
  );
}

export default Sets;
import React from 'react';
import { useCallback } from 'react';
import UnitOfMeasurement from '../../../../../../dto/units-of-measurment.dto';
import { CharsLabelDto } from '../../../dto/chars.dto';
import ProductUnitsSelect from '../../units-select';
import CharLabelTypeSelect from './type-select';

interface Props {
  labelChangeByField: (changes: Partial<CharsLabelDto>) => void;
  label: CharsLabelDto;
}

const CharLabelBodyEditing = ({
  label,
  labelChangeByField,
}: Props) => {
  const selectHandler = useCallback(({name, id}: UnitOfMeasurement) => {
    return labelChangeByField({
      unit: name,
      unit_id: id,
    })
  }, [labelChangeByField])

  return (
    <>
      <CharLabelTypeSelect
        label={label}
        labelChangeByField={labelChangeByField}
      />

      {label.type_id === 1 ? <ProductUnitsSelect
        selectHandler={selectHandler}
        currentUnitId={label.unit_id }
      /> : null}
    </>
  )
}

export default CharLabelBodyEditing
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import MenuIcon from 'src/img/context-menu-dots.svg';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import { Order } from '../../dto/order.dto';
import OrdersDeletePopupRender from '../../popups/delete-popup';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

export function OrderRowConfig({
  linkProps,
  order,
  deleteOrder
}: {
  linkProps: (id: Id) => {
    to: string,
  },
  order: Order,
  deleteOrder: (ids?: Id[]) => void
}) {
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const history = useHistory()

  const { RenderButton, RenderPopup, isOpen, closePopup } = usePopup()

  const editHandler = () => {
    const locationData = linkProps(order.id)

    closePopup()

    history.push({
      ...locationData,
      pathname: locationData.to
    })
  }

  useEffect(() => {
    if(deletePopupOpened && isOpen) {
      closePopup()
    }
  }, [deletePopupOpened])
  
  return <BodyCell myClassName="tt-config">
    <div className='tt-config-wrapper config-popup-wrapper'>
      <RenderButton
        removeDefaultClass
      >
        <img src={String(MenuIcon)} alt={'context-menu'}/>
      </RenderButton>

      <RenderPopup
        className='config-popup'
      >
        <div className='popup-element'
          onClick={editHandler}
        >
          Редактировать
        </div>

        <div className='popup-element'
          onClick={() => setDeletePopupOpened(true)}
        >
          Удалить
        </div>
      </RenderPopup>

      {deletePopupOpened ?
        ReactDOM.createPortal(
          <OrdersDeletePopupRender
            popupClose={() => setDeletePopupOpened(false)}
            selectedOrders={[order.id]}
            mainActionHandler={() => deleteOrder([order.id])}
          />
        , document.body)
      : null}
    </div>
  </BodyCell>;
}

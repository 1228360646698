import React, { useContext } from 'react';
import { batch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { scrollPageTo } from '../../../../../scripts/functions';
import useOrderDelivery from '../../hooks/use-order-delivery';
import MyButton from '../../../../_utility-components/button/button';
import { OrderContext } from '../../order-page';
import { OrderDeliveryContext } from './delivery';

export function OrderDeliveryChangeStateSwitcher() {
  const {
    order,
    orderDeleted,
    isOrderUnchangeable
  } = useContext(OrderContext);
  const {
    changeSelectedDelivery,
    setChangeDeliveryState,
  } = useContext(OrderDeliveryContext);
  const delivery = useOrderDelivery(order);

  if(!order || orderDeleted || isOrderUnchangeable) {
    return null
  }

  const initialDeliveryChangeHandler = async (delivery_type: Id) => {
    
    batch(() => {
      changeSelectedDelivery({type: delivery_type})
      setChangeDeliveryState(true)
    })
    scrollPageTo('.op-delivery-item.active')
  }

  return (
    <MyButton
      className="op-delivery-top-edit-btn hide-for-print"
      removeDefaultClass
      onClick={() => initialDeliveryChangeHandler(delivery?.type || 0)}
    >
      <i className="icofont-ui-edit"></i>
    </MyButton>
  )
}

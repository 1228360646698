import React from 'react';
import { withLoader } from '../../../../../scripts/with-loader';
import CurrencyAndPrice from '../../../../_utility-components/currency-and-price/currency-and-price';
import { Client } from '../../../clients/dto/client.dto';
import { Order } from '../../dto/order.dto';
import useOrderFilters from "../../hooks/use-order-filters";

interface ClientInfoProps {
  order: Order,
  userLoader: boolean,
  client: Client | null,
  closePopup: () => void
}

function ClientInfo(props: ClientInfoProps) {
  return (
    <ClientHistory 
      condition={props.userLoader} 
      {...props}
    />
  );
}

export default ClientInfo;

const ClientHistory = withLoader((
  { order, client, closePopup}: ClientInfoProps
) => {
  const [, filterChanger] = useOrderFilters();

  if(!client) {
    return <div>Нет данных</div>
  }

  const historyHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    closePopup()
    return filterChanger({
      itemIds: [order.client_id],
      filterName: 'client_id',
      cleanPrev: true
    });
  }

  return (
    <div className="history">
      <div className="div-container">
        <div className="title-div flex-center">
          Заказы
          <div className='items-counter'>{client.ordersCount}</div>
        </div>
        <div
          className={`text-div ${client.ordersCount !== 0 ? `orders-by-user` : ''}`}
          onMouseUp={historyHandler}
        >
          {/* <span className="bold">
            {num2str(client.ordersCount, ['заказ на сумму', 'заказа на сумму', 'заказов на сумму'])}
          </span> */}
          <div>
            <CurrencyAndPrice
              cur='RUB'
              balance={client.ordersTotal}
              withPennies={true}
            />
          </div>
        </div>
      </div>
      
      <div className="div-container">
        <div className="title-div">Возвраты</div>
        <div className="text-div">
          <CurrencyAndPrice
            cur='RUB'
            balance={client.refundTotal}
            withPennies={true}
          />
        </div>
      </div>

      <div className="div-container">
        <div className="title-div">Выкуп</div>
        <div className="text-div">
          <span className="bold">{client.buyout}</span> %
        </div>
      </div>
    </div>
  )
})

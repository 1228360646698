import React, { useState } from 'react';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';
import MyButton from '../../../_utility-components/button/button';
import TopPagePanel from '../../../_utility-components/top-page-panel';
import arrowLeft from '../../../../img/arrow-left-white.png';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import { addMessage, validateResponse } from '../../../../scripts/functions';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { ExtendedProduct } from '../dto/products.dto';
import { useProductComparison } from '../../../../scripts/hooks/use-product-comparison';
import { useLoad } from '../../../../scripts/hooks/use-load';
import { ProductsAndImages } from './dto/product-and-images.dto';
import { batch, useDispatch } from 'react-redux';
import { ProductsAttrWithValues } from '../dto/attributes-create-response.dto';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { ProductsAttrValues, ProductOption } from './dto/options.dto';
import { ProductAttributes } from '../dto/attributes.dto';
import { ProductVariantExtended } from '../dto/variants.dto';
import { Id } from '../../../../dto/master-dto/id.dto';
import useUnload from '../../../../scripts/hooks/use-unload';

function ProductTopPanel({
  pageIsCreatePage,
  initProduct,
}: {
  pageIsCreatePage: boolean,
  initProduct: React.MutableRefObject<ExtendedProduct | undefined>,
}) {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  const location = useLocation<{prevPage?: string}>()
  const tabIndex = useTabIndex(2)
  const history = useHistory()
  const [load, setLoad] = useLoad()
  const _isMounted = useMounted()
  const [creationEnded, setCreationEnded] = useState(!pageIsCreatePage);

  const dispatch = useDispatch()
  const productDispatch = (product: ExtendedProduct | undefined) => dispatch({ type: 'PRODUCT_ITEM', payload: product })

  const optionsDispatch = (options: ProductOption[]) => dispatch({ type: 'PRODUCTS_OPTIONS', payload: options })
  const initOptionsDispatch = (options: ProductOption[]) => dispatch({ type: 'PRODUCTS_INIT_OPTIONS', payload: options })

  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })

  const setDescriptionEditing = (bool: boolean) => dispatch({type: 'PRODUCT_DETAILED_DESCRIPTION_EDITING', payload: bool});
  // const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const {compareOptions, compareProducts, compareVariants, compareOptionsValues} = useProductComparison(initProduct)
  const product = useAppSelector(state => state.product)
  const options = useAppSelector(state => state.productOptions)
  const initOptions = useAppSelector(state => state.initOptions)
  const variants = useAppSelector(state => state.productVariants)
  const initVariants = useAppSelector(state => state.initVariants)

  const cancelBtnHandler = () => {
    batch(() => {
      productDispatch(initProduct.current)
      optionsDispatch(initOptions)
      variantsDispatch(initVariants)
    })
  }
  // const integrationsOn = moySkladIntegrationOn;
  // const variantImageResize = this.props.state.staticValues.product_page_variants_resize
  // const productResize = extractImages(product.src, variantImageResize)

  const productChanges = compareProducts();
  const optionsChanges = compareOptions();
  const variantsChanges = compareVariants();
  const optionsValuesChanges = compareOptionsValues()

  const productHasChanges = !pageIsCreatePage && (
    (productChanges && productChanges.updated.length) ||
    optionsChanges.updated.length ||
    optionsChanges.added.length ||
    optionsChanges.removed.length ||
    variantsChanges.updated.length ||
    variantsChanges.added.length ||
    variantsChanges.removed.length ||
    optionsValuesChanges.added.length ||
    optionsValuesChanges.removed.length ||
    optionsValuesChanges.updated.length
    // variantsChanges.reordered.length
  );

  const topPanelOpened = !!(!moySkladIntegrationOn && ((!load && productHasChanges) || !creationEnded))

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, topPanelOpened);

  const saveProduct = async () => {
    if(moySkladIntegrationOn || !productChanges || !productChanges.updated.length) {
      return false
    }
    
    const res = await mercheryFetch<ExtendedProduct[]>('products', 'PATCH', {
      changes: productChanges.updated
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    const updatedProduct = res.records[0]

    batch(() => {
      initProduct.current = updatedProduct
      productDispatch(updatedProduct)
      setDescriptionEditing(false)
    })

    return updatedProduct
  }

  const createNotExistAttributes = async () => {
    const newAttributes = optionsChanges.added
      .filter(option => !option.notSaved)
      .map((o) => ({
        title: o.title,
        values: optionsValuesChanges.added.filter(added => added.optionId === o.id)
      }));

    if(!newAttributes.length) {
      return false
    }

    const res = await mercheryFetch<ProductsAttrWithValues[]>('products/attributes', 'POST', {
      newAttributes: newAttributes
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }
    return res
  }

  const createNotExistAttributesValues = async (attrNamesIds: { [key: string]: Id}) => {
    const newValues = optionsValuesChanges.added.map(added => ({
      ...added,
      name_id: attrNamesIds[added.name_id]
    }))

    if(!newValues.length) {
      return false
    }

    const res = await mercheryFetch<ProductsAttrValues[]>('products/attributes/value', 'POST', {
      values: newValues
    })
    
    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    return res
  }

  const saveOptions = async (optionsWithUpdatedValues: ProductOption[], product_id: Id) => {
    try {
      if(moySkladIntegrationOn) {
        return false
      }

      let records: ProductOption[] | true = true
      const filters = {product_id};

      if(optionsChanges.added?.length) {
        const withGetRecords = !optionsChanges.updated?.length && !optionsChanges.removed?.length

        const newOptions = optionsWithUpdatedValues 
          .filter(chOp => 
            !chOp.notSaved && 
            optionsChanges.added.some(oAdd => chOp.id === oAdd.id)
          )
          .map(o => ({
            ...o,
            product_id: product_id,
            values_ids: o.values.map(v => v.id)
          }))

        if(newOptions.length) {
          const res = await mercheryFetch<ProductOption[] | true>('products/options', 'POST', {
            newOptions: newOptions,
            ...(withGetRecords && {
              filters: filters
            })
          })

          if(validateResponse(res)) {
            records = res.records
          }
        } 
      }

      if(optionsChanges.updated?.length) {
        const withGetRecords = !optionsChanges.removed?.length
        const res = await mercheryFetch<ProductOption[] | true>('products/options', 'PATCH', {
          options: optionsChanges.updated,
          ...(withGetRecords && {
            filters: filters
          })
        })
        if(validateResponse(res)) {
          records = res.records
        }
      }
      
      if(optionsChanges.removed?.length) {
        const res = await mercheryFetch<ProductOption[] | true>('products/options', 'DELETE', {
          id: optionsChanges.removed.map(r => r.id),
          filters
        })
        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(!_isMounted.current) {
        return false
      }

      if(records !== true) {
        const options = records
        batch(() => {
          optionsDispatch(options)
          initOptionsDispatch(options)
        })
      } else {
        const changedOptionsToStore = JSON.parse(JSON.stringify(optionsWithUpdatedValues))
        batch(() => {
          optionsDispatch(changedOptionsToStore)
          initOptionsDispatch(changedOptionsToStore)
        })
      }

      return true
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const saveVariants = async (variantsWithUpdatedValues: ProductVariantExtended[], product_id: Id) => {
    try {
      let records: ProductVariantExtended[] | true = true;
      const getRecordsFlag = {filters: {product_id: product_id}};

      const mutatedVariantsToCreate = variantsWithUpdatedValues.filter(v => variantsChanges.added.some(a => a.id === v.id))

      if(mutatedVariantsToCreate.length) {
        const withGetRecords = !variantsChanges.removed?.length && !variantsChanges.updated?.length
        const res = await mercheryFetch<ProductVariantExtended[] | true>('products/variants', 'POST', {
          newVariants: mutatedVariantsToCreate.map(variant => ({...variant, product_id: product_id})),
          ...(withGetRecords && getRecordsFlag)
        })
        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(variantsChanges.updated?.length) {
        const withGetRecords = !variantsChanges.removed?.length

        const res = await mercheryFetch<ProductVariantExtended[] | true>('products/variants', 'PATCH', {
          changes: variantsChanges.updated,
          ...(withGetRecords && getRecordsFlag)
        })

        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(variantsChanges.removed?.length) {
        const res = await mercheryFetch<ProductVariantExtended[] | true>('products/variants', 'DELETE', {
          id: variantsChanges.removed.map(r => r.id),
          filters: {
            product_id: product_id
          }
        })

        if(validateResponse(res)) {
          records = res.records
        }
      }

      if(records !== true && _isMounted.current) {
        variantsDispatch([...records])
        initVariantsDispatch([...records])
      }
      
      return true
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const saveChanges = async () => {
    try {
      if(!product) {
        return false
      }
      setLoad(true)

      let productId = product.id
      let mutableOptions = options;
      let mutableVariants = variants;
      let attrNamesIds: { [key: string]: Id} = Object.fromEntries(optionsValuesChanges.added.map(o => [o.name_id, o.name_id]));

      if(pageIsCreatePage) {
        if(!product?.name) {
          addMessage('.inputs-row.name .merchery-label', 'Название обязательно к заполнению')
          return false
        }
    
        const requestBody: ExtendedProduct = {...product}
    
        const createRes = await mercheryFetch<ProductsAndImages>('products/create', 'POST', requestBody)
        
        if(!_isMounted.current || !validateResponse(createRes)) {
          return false
        }
    
        productId = createRes.records.products.id;
      } else {
        await saveProduct()
      }

      const createAttrRes = await createNotExistAttributes() 
      if(createAttrRes && validateResponse(createAttrRes) && createAttrRes.records) {
        mutableOptions = mutableOptions.map((option) => {
          const attrWithValuesIndex: number | undefined = createAttrRes.records.findIndex(attr =>
            option.title === attr.title
          );

          if(attrWithValuesIndex === -1) {
            return option
          }

          const attr = createAttrRes.records[attrWithValuesIndex]
          attrNamesIds[option.name_id] = attr.name_id

          return {
            ...option,
            product_id: productId,
            name_id: attr.name_id,
          }
        })
      }


      if(optionsValuesChanges.added.length) {
        const createAttrValueRes = await createNotExistAttributesValues(attrNamesIds)
        if(createAttrValueRes) {
          const newValues = createAttrValueRes.records

          mutableOptions = mutableOptions.map((option) => ({
            ...option,
            product_id: productId,
            values: option.values.map(
              (value) => 
                newValues.find((nValue) => nValue.name_id === attrNamesIds[value.name_id] && nValue.value === value.value) || value
            )
          }))
          
          for (let cIndex = 0; cIndex < newValues.length; cIndex++) {
            const element = newValues[cIndex];
            
            mutableVariants = mutableVariants.map((variant) => {
              return {
                ...variant,
                attributes: replaceVariantAttributes(variant.attributes, [element])
              }
            })
          }
        } else {
          return false
        }
      }


      if(optionsValuesChanges.removed.length) {
        const optionsWithValuesChanges = 
          options
          .filter(option => 
            !option.notCreated && 
            optionsValuesChanges.removed.some(r => r.optionId === option.id ) )
          .map(option => ({
            id: option.id, 
            values_ids: option.values.map(v => v.id)
          }))
        
          
        if(optionsWithValuesChanges) {
          await mercheryFetch('products/options', 'PATCH', {
            options: optionsWithValuesChanges
          })
        }
      }


      if(optionsValuesChanges.updated.length) {
        const optionsWithChangedValues = 
          options
          .filter(option => 
            !option.notCreated && 
            optionsValuesChanges.updated.some(r => r.optionId === option.id ) )
          .map(option => ({
            id: option.id, 
            values_ids: 
              [...option.values]
              .sort((a,b) => a.order - b.order)
              .map(v => v.id)
          }))

        if(optionsWithChangedValues) {
          await mercheryFetch('products/options', 'PATCH', {
            options: optionsWithChangedValues
          })
        }
      }

      await saveOptions(mutableOptions, productId)

      await saveVariants(mutableVariants, productId)
    
      batch(() => {
        setCreationEnded(true)
        setDescriptionEditing(false)
        const productToDispatch: ExtendedProduct = {
          ...product,
          newProduct: false,
          id: productId
        };
        
        initProduct.current = productToDispatch
        productDispatch(productToDispatch)
      })

      if(pageIsCreatePage) {
        history.replace(`/app/products`);
      }

    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  // const completeCreate = async () => {
  //   if(!product?.name) {
  //     addMessage('.inputs-row.name .merchery-label', 'Название обязательно к заполнению')
  //     return false
  //   }

  //   const requestBody: ExtendedProduct = {...product}

  //   const createRes = await mercheryFetch<ProductsAndImages>('products/create', 'POST', requestBody)
    
  //   if(!_isMounted.current || !validateResponse(createRes)) {
  //     return false
  //   }

  //   const id = createRes.records.products.id;

  //   batch(() => {
  //     setCreationEnded(true)
  //     const productToDispatch = {
  //       ...product,
  //       id
  //     };

  //     initProduct.current = productToDispatch
  //     productDispatch(productToDispatch)
  //   })

  //   let mutableOptions = [...options]
  //   let mutableVariants = [...variants]

  //   setDescriptionEditing(false)

  //   if(optionsChanges.added.length) {
  //     const createAttrRes = await createNotExistAttributes()

  //     if(createAttrRes && createAttrRes.records) {
  //       createAttrRes.records.forEach(element => {
  //         const optionIndex: number | undefined = options.findIndex(o => o.title === element.title);
  
  //         if(optionIndex !== -1) {
  //           mutableOptions[optionIndex].product_id = id
  //           mutableOptions[optionIndex].name_id = element.name_id;
  //           mutableOptions[optionIndex].values = mutableOptions[optionIndex].values.map(v => element.values.find(ev => v.value === ev.value) || v);
  //         }
  
  //         mutableVariants.forEach(variant => {
  //           variant.attributes = replaceVariantAttributes(variant.attributes, element.values)
  //           variant.product_id = id
  //         })
  //       });
  //     }
  //   }

  //   if(optionsChanges.added.length) {
  //     await saveOptions(mutableOptions)
  //   }

  //   if(variantsChanges.added.length) {
  //     await saveVariants(mutableVariants)
  //   }

  //   history.replace(`/app/products`);
  //   // this.startProductComponent()
  // }

  return (
    <TopPagePanel fixed
      topPanelOpened={topPanelOpened}
    >
      <Prompt
        when={topPanelOpened}
        message='Остались несохраненные изменения, покинуть страницу?'
      />

      <div className="left">
        {pageIsCreatePage ? 
          <Link tabIndex={tabIndex} 
            to={{
              pathname: '/app/products', 
              search: location.state?.prevPage || ''
            }} 
            className="icon-div"
          >
            <img alt="назад" src={arrowLeft} />
          </Link>
        : null}
        
        <div className="text-div">
          {productHasChanges ? `Несохраненные изменения` : 'Несохраненный товар'}
        </div>
      </div>

      <div className="right">
        {!pageIsCreatePage ?
          <MyButton
            id={'product-cancel-btn'}
            className="dark-btn"
            onClick={cancelBtnHandler}
          >
            {'Отменить'}
          </MyButton>
        : null}

        <MyButton
          id={'product-confirm-btn'}
          className="blue-btn"
          onClick={saveChanges}
        >
          {pageIsCreatePage ? 'Создать товар' : 'Сохранить'}
        </MyButton>
      </div>
      
    </TopPagePanel>
  );
}

export default ProductTopPanel;

function replaceVariantAttributes(variantAttributes: ProductAttributes[], optionsNewValues: ProductsAttrValues[]): ProductAttributes[] {
  const optionsLookup = Object.fromEntries(optionsNewValues.map((value) => [value.value, value]));

  const result: ProductAttributes[] = [];
  for (const attr of variantAttributes) {
    const { value } = attr;
    const a = optionsLookup[value];
    if (a === undefined) {
      result.push(attr);
      continue;
    }
    if (attr.value_id === a.id && attr.attr === a.name_id) {
      result.push(attr);
      continue;
    }
    result.push({ ...attr, value_id: a.id, attr: a.name_id });
  }

  return result;
}

// {/* {!moySkladIntegrationOn ?
//   <RenderPopup
//     className={'confirm-window fixed-on-center'}
//     blackout
//     withCloseBtn
//   >
//     <h2>Удаление</h2>

//     <ul>
//       {getOptionsDeleted.length ?
//         <li>
//           <h3>
//             опций {getOptionsDeleted.map(s => `"${s.title}"`).join(', ')}
//           </h3>
//         </li>
//       : null}

//       {getOptionValuesDeleted.length ?
//         <li>
//           <h3>
//             значений {getOptionValuesDeleted.map(s => `"${s.value}"`).join(', ')}
//           </h3>
//         </li>
//       : null}

//       {getVariantsDeleted.length ?
//         <li>
//           <h3>
//             вариантов {getVariantsDeleted.map(s => `"${s.attributes.map(attr => attr.value).join(' / ')}"`).join(', ')}
//           </h3>
//         </li>
//       : null}
//     </ul>

//     <h2>
//     </h2>
//     {/* <h2>{selectedProducts.length === 1 ? 'Удаление товара ' + selectedProducts[0].name : 'Удаление товаров ' + selectedProducts.map(s => s.name).join(', ')}</h2>
//     <span>{`Подтвердите удаление ${selectedProducts.length} ${selectedProducts.length === 1 ? 'товара' : 'товаров'}`}</span>  */}

//     {/* <div className="confirm-window-btn">
//       <MyButton 
//         tabIndex={tabIndex} 
//         className={'white-btn'} 
//         onClick={closePopup}>
//         Отменить
//       </MyButton>

//       <MyButton 
//         tabIndex={tabIndex} 
//         className="red-btn" 
//         onClick={saveChanges}>
//         Удалить
//       </MyButton>
//     </div>
//   </RenderPopup>  */}
// {/* : null} */}
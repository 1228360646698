import React from 'react';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useVatValues from '../../../../scripts/hooks/use-vat-values';
import SelectInput from '../../../_utility-components/select-input/select-input';
import { ProductInputHandler } from '../product';

function VatValueSelect({
  inputHandler,
}: {
  inputHandler: ProductInputHandler
}) {
  const vatValues = useVatValues();
  const product = useAppSelector(state => state.product);
  
  if(!vatValues) {
    return null
  }

  return (
    <SelectInput 
      selectedItem={vatValues.find(value => value.id === product?.vat)}
      items={vatValues} 
      inputProps={{
        shortInput: true,
      }}
      valueLabelName={'value'}
      selectHandler={value => inputHandler('vat', value.id)}
    />
  )
}

export default VatValueSelect;
import React, { useMemo, useState } from 'react';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { Order } from '../../orders/dto/order.dto';
import ClientOrderHeader from './client-order-header';
import OrderDeliveryView from '../../orders/order-modules/order-delivery-view';
import { ProductItem } from '../../products/dto/product-item.dto';
import { ProductItemRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import { ShownFields } from '../../../../scripts/with-row-components';

interface Props {
  order: Order,
  index: number
}

const productShownAttributes: ShownFields<ProductItem> = [
  'src', 
  'name',
  'returned', 
  {key: 'external_id', options: {showRemain: false}}, 
  'price', 
  'count', 
  'total'
]

function ClientOrder({
  order,
  index
}: Props) {
  const items = useAppSelector(state => state.productItemsInContext);
  const filteredItems = items.filter(item => item.order === order.id)
  const statuses = useAppSelector(state => state.orderStatus);
  const currentStatus = useMemo(() =>
      statuses && statuses.find(s => s.id === order.status) || undefined
  , [order.status, statuses]);

  const [orderOpened, setOrderOpened] = useState(index === 0);

  return (
    <div className='client-order'>

      <ClientOrderHeader order={order}
        currentStatus={currentStatus}
        openHandler={setOrderOpened}
        orderOpened={orderOpened}
      />

      <div className={`client-order__items ${!orderOpened ? 'display-none' : ''}`}>
        {filteredItems.map(item => (
          <ProductItemRow
            key={item.id}
            className={'row-item'}
            shownFields={productShownAttributes}
            componentsFields={productLikeFields}
            item={item}
            changer={undefined} 
            disabled={false}
            />
        ))}
      </div>

      <OrderDeliveryView 
        orderOpened={orderOpened}
      />
    </div>
  );
}

export default ClientOrder;
import React from 'react';
import { priceWithPennies } from '../../../../../scripts/functions';
import CurrencyAndPrice from '../../../../_utility-components/currency-and-price/currency-and-price';
import { Order } from '../../dto/order.dto';
import GreenPaid from '../../../../../img/green-paid.svg'

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  order: Order
}

export function OrderRowCost({
  order
}: Props) {
  return <BodyCell myClassName="tt-cost">
    {order.price ? 
      <CurrencyAndPrice
        cur='RUB'
        balance={priceWithPennies(order.total)}
      />
    : 'Цена не указана'}

    {order.paid ? 
      <div className='order-row__paid-status'>
        <img src={String(GreenPaid)} alt={'paid'}/>
      </div>
    : <div></div>}
  </BodyCell>;
}

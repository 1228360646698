import React, { useContext, useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import MyInput from '../../../../../_utility-components/input/index';
import { OrderDeliveryContext } from '../delivery';
import { CdekDeliveryContext } from './cdek';
import { CdekCalcTariffDto } from './dto/cdek-calc-tariffs-body.api';
import { CdekWidjetAddress } from './dto/cdek-widget.dto';
import staticValues from 'src/data/static-values.json';

let cdekWidgetInstance: any | null = null;

export function CdekWidjetComponent() {
  const {
    delivery,
    changeSelectedDelivery
  } = useContext(OrderDeliveryContext);

  const {
    modes, 
    deliveryTariffs,
    cities, 
    setDeliveryTariffs,
  } = useContext(CdekDeliveryContext);

  const settings = useAppSelector(state => state.settings)

  const [
    pointTariffs,
    setPointTariffs
  ] = useState<CdekCalcTariffDto[]>([]);

  const [
    randomDateToTriggerTariffsChange,
    setRandomDateToTriggerTariffsChange
  ] = useState<null | Date>(null);

  const tariffsNotToTheDoor = useMemo(() => 
    deliveryTariffs?.filter(tariff =>
      !modes.find(mode => tariff.delivery_mode === mode.code)?.to_the_door
  ), [deliveryTariffs, modes])

  const tariffsToTheDoor = useMemo(() => 
    deliveryTariffs?.filter(tariff =>
      modes.find(mode => tariff.delivery_mode === mode.code)?.to_the_door
  ), [deliveryTariffs, modes])

  const cdekCityData = useMemo(() =>
    delivery && cities.find(
      city =>
        String(city.code) === delivery?.city_code
    )
  , [delivery, cities])

  useEffect(() => {
    if(pointTariffs.length) {
      setDeliveryTariffs(pointTariffs)
    }
  }, [randomDateToTriggerTariffsChange])

  console.log(cdekWidgetInstance)

  const openWidjet = async () => {
    const apiKey = staticValues.yandex_maps_api_key;
    
    try {
      if(delivery && cdekCityData) {
        const widjetConfigAndCalcData = {
          from: settings.find(setting => setting.callname === 'city')?.value,
          root: 'cdek-map',
          apiKey: apiKey,
          canChoose: true,
          servicePath: 'https://front2.merchery.ru/service.php',
          defaultLocation: delivery?.city,
          // hideFilters: {
          //   have_cashless: false,
          //   have_cash: false,
          //   is_dressing_room: false,
          //   type: false,
          // },
          hideDeliveryOptions: {
            door: true,
          },
          popup: true,
          // debug: true,
          goods: [
            {
              width: delivery.width,
              height: delivery.height,
              length: delivery.length,
              weight: delivery.weight,
            },
          ],
          lang: 'rus',
          currency: 'RUB',
          tariffs: {
            office: tariffsNotToTheDoor ? tariffsNotToTheDoor.map(tariff => tariff.tariff_code) : [],
            door: tariffsToTheDoor ? tariffsToTheDoor.map(tariff => tariff.tariff_code) : [],
          },
          onCalculate: (tariffs: {
            [key in 'office' | 'door' | 'pickup']: CdekCalcTariffDto[]
          }, address: { code?: number; address?: string }) => {
            console.log('onCalculate', tariffs, address)
            const allPointTariffs = Object.values(tariffs).flat()
            console.log('allPointTariffs', allPointTariffs)
            setPointTariffs(allPointTariffs)
          },
          onChoose: <T extends 'door' | 'office'>(mode: T, tariff: CdekCalcTariffDto, address: CdekWidjetAddress<T>) => {
            console.log('onChoose', mode, tariff, address)

            if('city_code' in address) {
              batch(() => {
                changeSelectedDelivery({
                  tariff_id: String(tariff.tariff_code),
                  point_address: address.address,
                  point_id: address.code,
                  city_code: String(address.city_code),
                  city: address.city,
                })
                setRandomDateToTriggerTariffsChange(new Date())
              })
            } else {
            }
            cdekWidgetInstance?.close()
            cdekWidgetInstance = null
          }
        };

        console.log(widjetConfigAndCalcData)
  
        if(cdekWidgetInstance) {
          cdekWidgetInstance?.open()
        } else {
          // @ts-ignore: Unreachable code error
          cdekWidgetInstance = await new window.CDEKWidget(widjetConfigAndCalcData)
          cdekWidgetInstance?.open()
        }
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  useEffect(() => {
    return () => {
      cdekWidgetInstance?.close()
      cdekWidgetInstance = null
    }
  }, [])
  
  return (
    <MyInput
      id="delivery-point-address"
      name="delivery-point-address"
      myClassName='delivery-point-address-input'
      style={{
        cursor: 'pointer'
      }}
      placeholder={delivery?.point_id || 'Выбрать пункт выдачи'}
      value={delivery?.point_address || ''}
      readOnly={true}
      onClick={openWidjet}
      innerChildren={
        <i className="icofont-ui-edit merchery-input__icon"></i>
      }
    />
  )
}

import React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

const CdekTariffsLoader = (props: IContentLoaderProps) => {
  const viewBox = "0 0 1150 40";
  const speed = 2;
  const backgroundColor = 'rgba(0, 0, 0, 0.1)' // #f3f3f3
  const foregroundColor = '#ecebeb';

  return (
    <div className='common-table-loader'>
      
      <ContentLoader
        speed={speed}
        width={1150}
        height={32}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="8" rx="4" ry="4" width="52" height="20" /> 
        <rect x="750" y="8" rx="4" ry="4" width="95" height="20" /> 
        <rect x="1050" y="8" rx="4" ry="4" width="80" height="20" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="20" y="14" rx="4" ry="4" width="1110" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="20" y="14" rx="4" ry="4" width="1110" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="20" y="14" rx="4" ry="4" width="1110" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="20" y="14" rx="4" ry="4" width="1110" height="16" /> 
      </ContentLoader>
    </div>
  )
}

export default CdekTariffsLoader
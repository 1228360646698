import {arrayOfSelectedFilters} from "../../../../scripts/functions";
import {OrdersFilters} from "../dto/filters";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../scripts/pre-type/use-selector";
import {useEffect} from "react";
import {Order} from "../dto/order.dto";
import {Admin} from "../../../../dto/admin.dto";
import useTableFilters from "../../../../scripts/hooks/use-table-filters";

function useOrderFilters () {
	const ordersFilters = useAppSelector(state => state.ordersFilters);
	const { clients, admins, status } = useAppSelector(state => ({
		clients: state.clients,
		admins: state.admin,
		status: state.orderStatus,
	}));

	const dispatch = useDispatch();
	const ordersFiltersDispatch = (filters: Partial<OrdersFilters>) => dispatch({
		type: 'ORDERS_SELECTED_FILTERS',
		payload: filters,
	});

	async function initFilters (): Promise<OrdersFilters> {
		return {
			client_id: clients ? arrayOfSelectedFilters(clients) : undefined,
			status: status ? arrayOfSelectedFilters(status) : undefined,
			admin_id: adminsToFilters(admins),
		}
	}

	const [updateFilters, cleanFilters] = useTableFilters<Order>(
		ordersFilters,
		ordersFiltersDispatch,
		initFilters,
	);

	useEffect((() => {
		if(ordersFilters && !ordersFilters.client_id && clients) {
			ordersFiltersDispatch({
				...ordersFilters,
				client_id: arrayOfSelectedFilters(clients)
			})
		}
	}), [clients])

	useEffect((() => {
		if(ordersFilters && !ordersFilters.status && status) {
			ordersFiltersDispatch({
				...ordersFilters,
				status: arrayOfSelectedFilters(status)
			})
		}
	}), [status])

	useEffect((() => {
		if(ordersFilters && !ordersFilters.admin_id && admins) {
			ordersFiltersDispatch({
				...ordersFilters,
				admin_id: adminsToFilters(admins)
			})
		}
	}), [admins])

	const adminsToFilters = (admins: Admin[] | null) => {
		return admins ?
			arrayOfSelectedFilters([
				{
					id: null,
					name: 'Без менеджера'
				},
				...admins
			] as Admin[]
		) : undefined
	}

	return [ordersFilters, updateFilters, cleanFilters] as const
}

export default useOrderFilters;

import React from "react"
import { Id } from "../../../../../dto/master-dto/id.dto"
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector"
import { ProductVariantFilter } from "../../dto/variants.dto"
import { ProductsAttrValues } from "../dto/options.dto"

interface Props {
  variantsFilterHandler: (name_id?: Id, value?: ProductsAttrValues) => any
}

export function VariantsTableFilters({
  variantsFilterHandler
}: Props) {
  const options = useAppSelector(state => state.productOptions)
  const selectedVariantsFilters = useAppSelector(state => state.selectedVariantsFilters)

  return (
    <div className='table-filters-list product-variants-filters'>
      <div className='filters'>
        {selectedVariantsFilters
          .map((filter) => {
            const thisOption = options.find(o => o.name_id === filter.name_id)
            if(!thisOption) {
              return null
            }
            return thisOption.values.map((el) => 
              <Filter
                key={el.id}
                el={el} 
                filter={filter}
                variantsFilterHandler={variantsFilterHandler}
              />)
          })
        }
      </div>
    </div>
  )
}

function Filter ({
  filter,
  el,
  variantsFilterHandler,
}: {
  filter: ProductVariantFilter,
  el: ProductsAttrValues,
  variantsFilterHandler: (name_id?: Id, value?: ProductsAttrValues) => any
}) {
  const value = filter.values.find(v => v.value === el.value)
  if(!value) {
    return null
  }
  return (
    <div>
      {value.value}
      <i className="icofont-close" onClick={() => variantsFilterHandler(filter.name_id, value)}></i>
    </div>
  )
}
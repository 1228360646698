
import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggableProps } from 'react-beautiful-dnd';

export interface WithDraggableParentTagProps {
  isDraggable: boolean;
  dragProps: Omit<DraggableProps, 'children'>;
  dragClassName?: string; 
  innerHandler?: true
}

export const withDraggableParentTag = <P extends object, >(WrappedComponent: React.ComponentType<P & {provided?: DraggableProvided}>) => {
  const DraggableParentTag: React.FC<WithDraggableParentTagProps & P> = ({ 
    isDraggable, 
    dragProps, 
    dragClassName,
    innerHandler,
    ...restProps 
  }) => {
    const myClassName = dragClassName ? dragClassName : '';

    if (isDraggable && dragProps) {
      return (
        <Draggable {...dragProps}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <div
              className={myClassName}
              ref={provided.innerRef}
              {...(!innerHandler && provided.dragHandleProps)}
              {...provided.draggableProps}
            >
              <WrappedComponent 
                {...restProps as P}
                provided={provided}
              />
            </div>
          )}
        </Draggable>
      );
    }

    return (
      <div className={myClassName}>
        <WrappedComponent
          provided={undefined}
          {...restProps as P}
          />
      </div>
    );
  }
  return DraggableParentTag;
};
import React from 'react';
import { memo } from 'react';
import { Id } from '../../../../../dto/master-dto/id.dto';
import MySwitcher from '../../../../_utility-components/switcher/switcher';
import { ProductsAttrValues } from '../dto/options.dto';

interface Props {
  items: ProductsAttrValues[];
  handler: (valueId: Id) => void;
  selectedScopeValueId: Id | undefined
}

function CharOptionSwitcher({
  items,
  handler,
  selectedScopeValueId
}: Props) {
  if(items.some(item => item === null) || !selectedScopeValueId) {
    return null
  }

  return (
    <div
      className='characteristic-options-switcher__wrapper side-padding-16'>

      {items.length ? 
        <MySwitcher<ProductsAttrValues>
          items={items}
          className={'characteristic-options-switcher'}
          textField={'value'}
          selectedItemId={selectedScopeValueId}
          itemHandler={handler}
        />
      : null}
    </div>
  );
}

export default memo(CharOptionSwitcher);
import React from 'react';
import MyButton from '../../../../_utility-components/button/button';
import SearchInput from '../../../../_utility-components/search-input/search-input';
import arrowLeft from '../../../../../img/arrow-left.png';
import magnifier from '../../../../../img/magnifier.png';
import RefundSearchFilters from './search-filters';
import { RefundFilterChanger } from '../refunds-header';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { batch, useDispatch } from 'react-redux';
import { RefundFilters } from '../dto/refund-filters.dto';

interface RefundSearchProps {
  searchState: boolean;
  setSearchState: React.Dispatch<React.SetStateAction<boolean>>; 
}

export function RefundSearch({ 
  searchState, 
  setSearchState, 
}: RefundSearchProps) {
  const refundFilters = useAppSelector(state => state.refundFilters);
  const searchInput = useAppSelector(state => state.ordersSearch);
  
  const dispatch = useDispatch()
  const searchInputDisptch = (text: string) => dispatch({ type: 'ORDERS_SEARCH_INPUT_VALUE', payload: text });
  const filtersDispatch = (filters: RefundFilters) => dispatch({ type: 'ORDERS_REFUNDS_FILTERS', payload: filters });

  const filterChanger: RefundFilterChanger = async ({
    itemIds,
    filterName,
    cleanPrev = false,
    concreteSelected,
    cleanOthers,
  }) => {
    if(!refundFilters) return false;

    let sf = {...refundFilters};

    if(cleanOthers) { // @ts-ignore: Unreachable code error
      sf = Object.fromEntries(
        Object.entries(sf)
        .map(([key, value]) => ([
          key, 
          key === filterName ? 
            value : 
            value.map(f => ({
              ...f, 
              selected: false}
            ))
        ]))
      )
    }

    if(filterName && sf[filterName]) {
      if(cleanPrev) {
        sf[filterName] = sf[filterName].map(f => ({
          ...f, 
          selected: false
        }))
      }
  
      const cleanOrIncorrect = !itemIds || itemIds[0] === null || itemIds[0] === undefined;
      if(cleanOrIncorrect) {
        sf[filterName] = sf[filterName].map((filter) => ({
          ...filter, 
          selected: false
        }))
      } else {
        sf[filterName] = sf[filterName].map((filter) => {
          const oneOfChecked = !itemIds.some(itemId => filter.id === itemId)
          if(oneOfChecked) {
            return filter
          }
          return {
            ...filter,
            selected: concreteSelected !== undefined ? concreteSelected : !filter.selected
          }
        })
      }
    } else {
      for (const [, items] of Object.entries(sf)) {
        items.forEach((filter) => {
          filter.selected = concreteSelected !== undefined ? concreteSelected : !filter.selected
        })
      }
    }
    
    filtersDispatch(sf)
  };

  const searchHandler = async (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    target.blur()

    setSearchState(true)

    setTimeout(() => {
      document.querySelector<HTMLElement>('#refund-search')?.focus()
      return;
    }, 4)
  };
  
  const exitSearchHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    (e.target as HTMLElement).blur();
    batch(() => {
      searchInputDisptch('')
      setSearchState(false);
    })
  };

  return <div className={`search-and-filters-bar ${searchState ? 'search-state' : ''}`}>
    {searchState ? (
      <>
        <MyButton className="search-button arrow-left-icon"
          onClick={exitSearchHandler}
        >
          <img alt="Назад" src={arrowLeft} />
        </MyButton>

        <SearchInput
          id='refund-search'
          searchInput={searchInput}
          applySearch={searchInputDisptch}
        />
      </>
    ) : (
      refundFilters ?
        <>
          <MyButton 
            className="search-button search-icon" 
            onClick={searchHandler}
          >
            <img alt="Поиск" src={magnifier} />
          </MyButton>
        
          <RefundSearchFilters
            filterChanger={filterChanger} 
          />
        </>
        : null
    )}
  </div>;
}
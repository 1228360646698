import React, { KeyboardEvent, MouseEvent } from 'react';
import { TabIndex } from '../../../dto/master-dto/tabindex.dto';
import { useTabIndex } from '../../../scripts/hooks/use-tabindex';

export interface MyCheckboxProps {
  condition: boolean
  actionHandler: (event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void

  className?: string
  disabled?: boolean
  text?: string
  tabIndex?: TabIndex
  isRadio?: boolean
  stylePreset?: 'inner-point' | 'check-icon' | 'none' | 'switch'
}

function MyCheckbox({
  condition,
  stylePreset = 'check-icon',
  actionHandler,
  className = '',
  text,
  disabled,
  tabIndex,
  isRadio,
}: MyCheckboxProps) {
  const pressEnter = (e: React.KeyboardEvent<HTMLButtonElement>) => e.key === 'Enter' && actionHandler(e)
  const active = condition ? 'active' : '';
  const disabledClass = disabled ? 'disabled' : ''

  const defaultTabindex = useTabIndex();

  const combinedClassName = `${className} clickable checkbox-wrapper`;
  
  return (
    <button
      className={`${className} clickable checkbox-wrapper`}
      tabIndex={tabIndex !== undefined ? tabIndex : defaultTabindex}
      onClick={actionHandler} 
      disabled={disabled}
      onKeyUp={pressEnter}
    >
      <div className={`checkbox ${active} ${disabledClass} ${isRadio ? 'checkbox-radio' : ''} ${stylePreset === 'switch' ? 'checkbox__switch' : ''}`}>
        {stylePreset === 'check-icon' ? 
          <i className='icofont-check-alt'></i>
        : stylePreset === 'inner-point' ? 
          <div className='checkbox__inner-point'></div>
        : stylePreset === 'switch' ?
          <div className='checkbox__switch-circle'></div>
        : null}
      </div>
      
      {text ? <div className="sub-text">{text}</div> : null}
    </button>
  );
}

export default MyCheckbox;
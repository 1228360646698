import React, { useContext, useEffect, useRef } from 'react';
import { batch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { addMessage, ptsansCurrency, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import MyInput from '../../../../_utility-components/input/index';
import { OrderDelivery } from '../../dto/delivery';
import DeliveryFromSelect from '../popups/delivery-from-select';
import DeliveryPriceAndMode from '../popups/delivery-price-and-mode';
import { GetTariffs, OrderDeliveryContext, Tariff, TariffHandler } from './delivery';

interface PochtaEmsTariff {
  code: number;
  locality_from: string;
  locality_to: string;
  city_from: string;
  city_to: string;
  region_from: string;
  region_to: string;
  simple_letter: number;
  reg_letter: number;
  val_letter: number;
  simple_parcel: number; // use
  reg_parcel: number; // use
  val_parcel: number;
  pkg: number;
  letter_reg_1class: number;
  letter_val_1class: number;
  reg_parcel1class: number; // use
  val_parcel1class: number;
  pkg_1class: number; // use
  pkg_val_1class: number;
  cod: number;
}

const tariffNames: {[key: Id]: string} = {
  simple_letter:	'простое письмо',
  reg_letter:	'заказное письмо',
  val_letter:	'ценное письмо',
  simple_parcel:	'простая бандероль',
  reg_parcel:	'заказная бандероль',
  val_parcel:	'ценная бандероль',
  pkg:	'посылка',
  letter_reg_1class:	'заказное письмо 1 класса',
  letter_val_1class:	'ценная письмо 1 класса',
  reg_parcel1class:	'заказная бандероль 1 класса',
  val_parcel1class:	'ценная бандероль 1 класса',
  pkg_1class:	'заказная посылка 1 класса',
  pkg_val_1class:	'ценная посылка 1 класса',
}

function breakPochtaTariff (tariff: PochtaEmsTariff): Tariff[] {
  return [{
    id: 'simple_letter',
    price: tariff.simple_parcel
  }, {
    id: 'reg_parcel',
    price: tariff.reg_parcel
  }, {
    id: 'reg_parcel1class',
    price: tariff.reg_parcel1class
  }, {
    id: 'pkg_1class',
    price: tariff.pkg_1class
  }]
}

const codeToMessage: {[key: number]: string} = {
  100: 'Расчет завершен успешно',
  101: 'Некорректное значение массы отправления',
  102: 'Некорректный индекс отправителя',
  103: 'Некорректный индекс получателя',
  104: 'Отправка в данное ОПС невозможна',
  105: 'Исчерпан лимит запросов'
}

interface Props {
  tariffHandler: TariffHandler,
  fromHandler: (func: GetTariffs) => void,
}

function PochtaEms ({
  tariffHandler,
  fromHandler,
}: Props) {
  const _isMounted = useRef(true);
  const items = useAppSelector(state => state.productItemsInContext);
  const {
    delivery,
    changeSelectedDelivery,
    currentTariff,
    setCurrentTariff,
    setFrom
  } = useContext(OrderDeliveryContext)

  const tabIndex = useTabIndex();

  useEffect(() => {
    fromHandler(getTariffs)
  }, []);

  const getTariffs: GetTariffs = async (store) => {
    if(!delivery?.point_id || !store) return false

    let mass = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      mass += element.weight || 0
    }

    const calcTariffParams = {
      from: store.external_id,
      to: delivery.point_id,
      mass: mass,
    }

    return mercheryFetch<PochtaEmsTariff>('external', 'POST', {
      url: `https://postprice.ru/engine/russia/api.php`,
      query: calcTariffParams
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      if(res.records.code !== 100) {
        addMessage('#pochta-error-container', codeToMessage[res.records.code])
        return false
      }
      
      return breakPochtaTariff(res.records)
    })
  };

  const handleChange = ( 
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    label: keyof OrderDelivery 
  ) => {
    const value = e.target.value;

    batch(() => {
      setFrom(null)
      changeSelectedDelivery({[label]: value})
      setCurrentTariff(null)
    })
  };

  return (
    <div className="op-delivery-simple op-delivery-extended">
      <div className="wide-text-font-xxs" id="pochta-error-container">адрес доставки</div>
      <div className="delivery-address-inputs">
        <MyInput
          id="delivery-city"
          myClassName='delivery-city-input'
          placeholder="Наименование города"
          value={delivery?.city || ''}
          onChange={(e) => handleChange(e, 'city')}
          tabIndex={tabIndex}/>

        <MyInput
          id="delivery-postcode"
          myClassName='delivery-postcode-input'
          placeholder="Индекс"
          value={delivery?.postcode || ''}
          onChange={(e) => handleChange(e, 'postcode')}
          tabIndex={tabIndex}/>

        <MyInput 
          name="delivery-address" 
          myClassName='delivery-address-input'
          placeholder="Адрес" 
          required={false}
          value={delivery?.address || ''} 
          onChange={(e) => handleChange(e, 'address')} 
          tabIndex={tabIndex}/>

        {/* <MyInput autocomplete='off' name="delivery-address" placeholder="Адрес" value={this.state.address} onChange={(e) => this.handleChange(e, 'address')} /> */}
      </div>

      <div className="flex-wrapper">
        <div className="wide-text-font-xxs">
          место отправления
        </div>
        <div className="wide-text-font-xxs">
          цена доставки
        </div>
      </div>

      <div className="flex-wrapper">
        <DeliveryFromSelect
          fromHandler={() => fromHandler(getTariffs)}
          />

        <DeliveryPriceAndMode
          tariffHandler={tariffHandler} 
          MainActionBtnText={PochtaTariffHandler}
          openBtnText={currentTariff?.price ?
            <span>{tariffNames[currentTariff.id]}</span>
          : <span className="blue-color">Выбрать тариф</span>}
          />

      </div>
    </div>
  )
}

export default PochtaEms

function PochtaTariffHandler(tariff: Tariff) {
  return (
    <div className="delivery-tariff-row-header">
      {tariffNames[tariff.id]} {tariff.price} {ptsansCurrency('₽')}
    </div>
  );
}

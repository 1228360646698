import React, {useEffect, useState} from 'react';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import { useLoad } from 'src/scripts/hooks/use-load';
import useMounted from 'src/scripts/hooks/use-mounted';
import MyButton from './button/button';

function ActualizeButton<T extends any>({
  actualizeUrlPath: urlPath,
  dispatchHandler
}: {
  actualizeUrlPath: string,
  dispatchHandler: (records: T) => any
}) {
  const _isMounted = useMounted()
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const[, setLoad] = useLoad()

  useEffect(() => {
    return () => {
      setLoad(false)
    }
  }, [])

  const actualizeRequest = () => {
    setLoad(true)
    
    return mercheryFetch<T>(urlPath, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      setButtonDisabled(true);
      setTimeout(() => _isMounted.current && setButtonDisabled(false), 10000); // 10 seconds
      return dispatchHandler(res.records)
    })
    .finally(() => {
      _isMounted.current && setLoad(false)
    })
  }

  return (
    <MyButton
      style={{ opacity: .8, color: '#4a5267' }}
      className={`wide-header-font-xs`}
      disabled={isButtonDisabled}
      onClick={actualizeRequest}
    >
      {!isButtonDisabled ? 'Обновить данные' : 'Данные обновлены'}
    </MyButton>
  );
}

export default ActualizeButton;
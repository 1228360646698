import { LoyaltyDto } from 'src/components/main-pages/clients/dto/client-loyalty.dto';
import { ImageExtended } from 'src/dto/images/images.dto';
import { ClientsFilters } from '../components/main-pages/clients/dto/client-filters.dto';
import { ClientGroup } from '../components/main-pages/clients/dto/client-group.dto';
import { Client } from '../components/main-pages/clients/dto/client.dto';
import { CollectionDto } from '../components/main-pages/collections/dto/collections.dto';
import { Integration } from '../components/main-pages/integrations/dto/dto';
import { Delivery } from '../components/main-pages/orders/dto/delivery';
import { OrdersFilters } from '../components/main-pages/orders/dto/filters';
import { Order } from '../components/main-pages/orders/dto/order.dto';
import { RefundCause } from '../components/main-pages/orders/refunds/dto/refund-cause.dto';
import { RefundFilters } from '../components/main-pages/orders/refunds/dto/refund-filters.dto';
import { RefundStatus } from '../components/main-pages/orders/refunds/dto/refund-status.dto';
import { RefundDto } from '../components/main-pages/orders/refunds/dto/refund.dto';
import { ProductAttributes } from '../components/main-pages/products/dto/attributes.dto';
import { Category } from '../components/main-pages/products/dto/categories.dto';
import { CharGroupsDto, CharScopeDto, CharsDto, CharsLabelDto, CharsTypesDto, MultiSelectDto, MultiSelectValueDto, MultiSelectValueName } from '../components/main-pages/products/dto/chars.dto';
import { DeliveryCords } from '../components/main-pages/products/dto/delivery-note.dto';
import { ProductItem } from '../components/main-pages/products/dto/product-item.dto';
import { ProductTag } from '../components/main-pages/products/dto/product-tag.dto';
import { ProductFilters } from '../components/main-pages/products/dto/products-filters.dto';
import { ExtendedProduct } from '../components/main-pages/products/dto/products.dto';
import { ProductVariantExtended, ProductVariantFilter } from '../components/main-pages/products/dto/variants.dto';
import { ProductOption } from '../components/main-pages/products/product-page-modules/dto/options.dto';
import { ProductType } from '../components/main-pages/products/product-page-modules/dto/product-type.dto';
import { SetDto } from '../components/main-pages/sets/dto/set.dto';
import { StorageInfoHeaders } from '../components/main-pages/settings/dto/file-storage-info-res';
import { SettingsModuleDto } from '../components/main-pages/settings/dto/settings-module.dto';
import { Setting } from '../components/main-pages/settings/dto/settings.dto';
import { StaticValues } from '../data/static-values.dto';
import staticValues from '../data/static-values.json';
import { Admin } from '../dto/admin.dto';
import { Brand } from '../dto/brand.dto';
import { Id } from '../dto/master-dto/id.dto';
import { OrderStatus } from '../dto/order-status.dto';
import { Paymethod } from '../dto/paymethod.dto';
import { Promocode } from '../dto/promocode';
import { SiteLocalStore } from '../dto/shop.dto';
import { SiteTariff } from '../dto/site-tariff';
import { SortingItem } from '../dto/sorting.dto';
import { TaxForm } from '../dto/tax-form.dto';
import UnitOfMeasurement from '../dto/units-of-measurment.dto';
import { VatValue } from '../dto/vat-value.dto';

export interface InitStateDto {
  adminTokenIsValid: boolean,
  blackout: boolean,
  admin: Admin[] | null,
  load: boolean,
  settings: Setting[],
  settingsModules: SettingsModuleDto[]
  integrations: Integration[],
  stores: SiteLocalStore[],
  tabIndexLevel: number,
  currentPage: string,
  taxForms: TaxForm[] | null,
  vatValues: VatValue[] | null
  siteTariffs: SiteTariff[] | null
  fileStorageInfo: StorageInfoHeaders | null
  siteLogo: ImageExtended | null,

  staticValues: StaticValues.RootObject,

  orders: Order[],
  ordersCount: number,
  ordersFilters: OrdersFilters | undefined,
  deliveryServices: Delivery[]
  promocodes: Promocode[]
  ordersSearch: string
  selectedProductItemsInContext: Id[]

  deliveryCords: DeliveryCords,

  refunds: RefundDto[] | null,
  refundFilters: RefundFilters | undefined,
  refundStatuses: RefundStatus[] | null,
  refundCauses: RefundCause[]

  uiPromoCreateState: boolean,

  charGroups: CharGroupsDto[],
  chars: CharsDto[],
  scopes: CharScopeDto[],
  labels: CharsLabelDto[],
  labelTypes: CharsTypesDto[],
  multiSelect: MultiSelectDto[],
  multiSelectValues: MultiSelectValueDto[],
  multiSelectNames: MultiSelectValueName[],
  topPanelShown: boolean,
  
  products: ExtendedProduct[],
  selectedProducts: Id[],
  selectedVariants: Id[],
  currentProductsCount: number,
  allProductsCount: number,
  productFilters: ProductFilters | undefined,

  productOptions: ProductOption[],
  initOptions: ProductOption[],

  productVariants: ProductVariantExtended[],
  initVariants: ProductVariantExtended[],

  selectedVariantsAttributes: ProductAttributes[][],
  selectedVariantsFilters: ProductVariantFilter[],
  productTypes: ProductType[]

  product: ExtendedProduct | undefined,
  categoriesAssociatedWithProduct: Category[], 
  additionalOptions: boolean,
  detailedDescChangeActive: boolean,

  categories: Category[] | null,
  selectedCategory: Category | undefined,
  inlineNameChangingCategory: Id | null,
  searchInput: string
  sorting: SortingItem[] | undefined

  // orderPaidStatus: PaidStatus[]
  orderStatus: OrderStatus[] | null,
  paymethod: Paymethod[],
  units: UnitOfMeasurement[],
  brands: Brand[] | null,

  clients: Client[] | null,
  clientGroups: ClientGroup[] | null,
  clientsFilters: ClientsFilters | undefined,
  clientSearchInput: string,
  selectedClientsIds: Id[],
  productItemsInContext: ProductItem[]
  clientLoyalty: LoyaltyDto | null

  collections: CollectionDto[] | null;

  sets: SetDto[] | null

  productTags: ProductTag[]
}

const initialState: InitStateDto = {
  adminTokenIsValid: true,

  blackout: false,
  // general app datas
  admin: null,
  load: false,
  settings: [],
  settingsModules: [],
  integrations: [],
  stores: [],
  tabIndexLevel: 1,
  currentPage: 'orders',
  taxForms: null,
  vatValues: null,
  siteTariffs: null,
  fileStorageInfo: null,
  siteLogo: null,
  
  topPanelShown: false,

  staticValues: staticValues,

  // order pages and modules
  orders: [],
  ordersCount: 0,
  ordersFilters: undefined,
  deliveryServices: [],
  promocodes: [],
  ordersSearch: '',
  selectedProductItemsInContext: [],

  deliveryCords: {
    cod: 0,
    height: 0,
    depth: 0,
    width: 0,
  },

  refunds: null,
  refundFilters: undefined,
  refundStatuses: null,
  refundCauses: [],

  uiPromoCreateState: false,

  // product characteristics
  charGroups: [],
  chars: [],
  scopes: [],
  labels: [],
  labelTypes: [],
  multiSelect: [],
  multiSelectValues: [],
  multiSelectNames: [],

  // products 
  products: [],
  selectedProducts: [],
  selectedVariants: [],
  currentProductsCount: 0,
  allProductsCount: 0,
  productFilters: undefined,

  // one product 
  product: undefined,
  categoriesAssociatedWithProduct: [],
  additionalOptions: false,
  detailedDescChangeActive: false,
  categories: null,
  selectedCategory: undefined,
  inlineNameChangingCategory: null,
  sorting: [],
  productOptions: [],
  initOptions: [],
  productVariants: [],
  initVariants: [],
  selectedVariantsAttributes: [],
  selectedVariantsFilters: [],
  productTypes: [],

  // order and product 
  // orderPaidStatus: [],
  orderStatus: null,
  paymethod: [],
  searchInput: '',
  units: [],
  brands: null,

  // client 
  clients: null,
  clientGroups: null,
  clientsFilters: undefined,
  clientSearchInput: '',
  selectedClientsIds: [],
  productItemsInContext: [],
  clientLoyalty: null,

  // collections 
  collections: null,

  // sets
  sets: null,

  productTags: []
};

const reducer = (state = initialState, action: {type: string, payload: any}) => {
  
  const stateUpdate = (label: keyof InitStateDto) => (state) = {...state, [label]: action.payload}
  
  switch(action.type) {
    case 'ADMIN_TOKE_IS_VALID': return stateUpdate('adminTokenIsValid')

    case 'BLACKOUT': return stateUpdate('blackout')
    case 'ADMIN': return stateUpdate('admin')
    case 'LOADING': return stateUpdate('load')
    
    case 'STORE_LIST': return stateUpdate('stores')
    case 'STORE_SETTINGS': return stateUpdate('settings')
    case 'STORE_SETTINGS_MODULES': return stateUpdate('settingsModules')
    case 'TAB_LEVEL': return stateUpdate('tabIndexLevel')
    case 'CURRENT_PAGE': return stateUpdate('currentPage')
    case 'STORE_INTEGRATIONS': return stateUpdate('integrations')
    case 'TAX_FORMS': return stateUpdate('taxForms')
    case 'VAT_VALUES': return stateUpdate('vatValues')
    case 'SITE_TARIFFS': return stateUpdate('siteTariffs')
    case 'FILE_STORAGE_INFO': return stateUpdate('fileStorageInfo')
    case 'SITE_LOGO': return stateUpdate('siteLogo')

    case 'ORDERS': return stateUpdate('orders')
    case 'ORDERS_COUNT': return stateUpdate('ordersCount')
    case 'ORDERS_SELECTED_FILTERS': return stateUpdate('ordersFilters')
    case 'ORDERS_DELIVERIES': return stateUpdate('deliveryServices')
    case 'ORDERS_PROMOCODES': return stateUpdate('promocodes')
    case 'ORDERS_SEARCH_INPUT_VALUE': return stateUpdate('ordersSearch')
    case 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED': return stateUpdate('selectedProductItemsInContext')
    
    case 'ORDERS_REFUNDS': return stateUpdate('refunds')
    case 'ORDERS_REFUNDS_FILTERS': return stateUpdate('refundFilters')
    case 'ORDERS_REFUND_STATUSES': return stateUpdate('refundStatuses')
    case 'ORDERS_REFUND_CAUSES': return stateUpdate('refundCauses')

    case 'UI_PROMO_CREATE_STATE': return stateUpdate('uiPromoCreateState')

    case 'DELIVERY_CORDS': return stateUpdate('deliveryCords')

    case 'STATIC_VALUES': return stateUpdate('staticValues')

    case 'CHARS_GROUPS': return stateUpdate('charGroups')
    case 'CHARS': return stateUpdate('chars')
    case 'CHARS_SCOPES': return stateUpdate('scopes')
    case 'CHARS_LABELS': return stateUpdate('labels')
    case 'CHARS_LABELS_TYPES': return stateUpdate('labelTypes')
    case 'CHARS_LABELS_MULTISELECT': return stateUpdate('multiSelect')
    case 'CHARS_LABELS_MULTISELECT_VALUES': return stateUpdate('multiSelectValues')
    case 'CHARS_LABELS_MULTISELECT_NAMES': return stateUpdate('multiSelectNames')

    case 'PRODUCTS': return stateUpdate('products')
    case 'SELECTED_PRODUCTS': return stateUpdate('selectedProducts')
    case 'SELECTED_PRODUCTS_VARIANTS': return stateUpdate('selectedVariants')
    case 'PRODUCTS_COUNT': return stateUpdate('currentProductsCount')
    case 'PRODUCTS_ALL_COUNT': return stateUpdate('allProductsCount')
    case 'PRODUCTS_SELECTED_FILTERS': return stateUpdate('productFilters')

    case 'PRODUCT_ITEM': return stateUpdate('product')
    case 'PRODUCT_ASSOCIATED_CATEGORIES': return stateUpdate('categoriesAssociatedWithProduct')
    case 'PRODUCT_ADDITIONAL_OPTIONS_ACTIVE': return stateUpdate('additionalOptions')
    case 'PRODUCT_DETAILED_DESCRIPTION_EDITING': return stateUpdate('detailedDescChangeActive')
    case 'PRODUCT_TYPES': return stateUpdate('productTypes')

    case 'PRODUCTS_OPTIONS': return stateUpdate('productOptions')
    case 'PRODUCTS_INIT_OPTIONS': return stateUpdate('initOptions')

    case 'PRODUCTS_VARIANTS': return stateUpdate('productVariants')
    case 'PRODUCTS_INIT_VARIANTS': return stateUpdate('initVariants')
    case 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES': return stateUpdate('selectedVariantsAttributes')
    case 'SELECTED_PRODUCTS_VARIANTS_FILTERS': return stateUpdate('selectedVariantsFilters')

    case 'PRODUCTS_BRANDS': return stateUpdate('brands')
    case 'CATEGORIES': return stateUpdate('categories')
    case 'INLINE_CHANGING_CATEGORY': return stateUpdate('inlineNameChangingCategory')
    case 'PRODUCTS_SEARCH_INPUT_VALUE': return stateUpdate('searchInput')
    case 'PRODUCT_SELECTED_CATEGORY': return stateUpdate('selectedCategory')

    // case 'ORDER_PAID_STATUSES': return stateUpdate('orderPaidStatus')
    case 'ORDER_STATUSES': return stateUpdate('orderStatus')
    case 'PAYMETHOD': return stateUpdate('paymethod')
    case 'UNITS_OF_MEASUREMENT': return stateUpdate('units')
    case 'SORTING_ITEMS': return stateUpdate('sorting')

    case 'CLIENTS': return stateUpdate('clients')
    case 'CLIENT_GROUPS': return stateUpdate('clientGroups')
    case 'CLIENTS_FILTERS': return stateUpdate('clientsFilters')
    case 'CLIENTS_SEARCH_INPUT_VALUE': return stateUpdate('clientSearchInput')
    case 'CLIENTS_SELECTED': return stateUpdate('selectedClientsIds')
    case 'PRODUCT_ITEMS_IN_CONTEXT': return stateUpdate('productItemsInContext')
    case 'CURRENT_CLIENT_LOYALTY': return stateUpdate('clientLoyalty')

    case 'COLLECTIONS': return stateUpdate('collections')
    case 'SETS': return stateUpdate('sets')
    
    case 'CURRENT_PRODUCT_TAGS': return stateUpdate('productTags')
    
    case 'TOP_PANEL_SHOWN_STATUS': return stateUpdate('topPanelShown')
    
    default: return state
  }
}

export type RootState = ReturnType<typeof reducer>

export default reducer;
import React, { useContext } from 'react';
import { OrderDeliveryContext } from '../delivery';

export function SelectedDeliveryAddressAndCost() {
  const {
    delivery
  } = useContext(OrderDeliveryContext)

  if(!delivery) {
    return null
  }

  const isPvz = delivery.point_id && delivery.point_address;

  return <div className="op-delivery-address">
    <div>Стоимость</div>

    {isPvz ?
      <div>
        <span>{delivery.point_id}</span>
        {delivery.city} {delivery.point_address}
      </div>
    :
      <div>
        <span>{delivery.type !== 1 ? delivery.point_id : null} {delivery.city}</span>
        &nbsp;
        {delivery.address}
      </div>
    }

    <div>
      <b>{delivery.register_cost} ₽</b>
    </div>
  </div>;
}

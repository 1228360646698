import React from "react";
import { batch, useDispatch } from "react-redux";
import MyInput from "src/components/_utility-components/input";
import { Id } from "../../../../../../../dto/master-dto/id.dto";
import { uuidv4 } from "../../../../../../../scripts/functions";
import { useAppSelector } from "../../../../../../../scripts/pre-type/use-selector";
import { MultiSelectDto, MultiSelectValueName } from "../../../../dto/chars.dto";

function MultiSelectAddNew ({
  labelId,
  categoryId,
}: {
  labelId: Id,
  categoryId: Id
}) {
  const names = useAppSelector(state => state.multiSelectNames);
  const multiSelects = useAppSelector( state => state.multiSelect );
  const thisMultiSelect = multiSelects.find(ms => ms.label_id === labelId)
  // const newValueNamePrevState = useRef('');
  // const [newValue, setNewValue] = useState('');

  // useEffect(() => { // add new value && clear create input 
  //   let timeOutId = setTimeout(() => {
  //     newValueNamePrevState.current = newValue
  //     if(newValue && newValue === newValueNamePrevState.current) {
  //       addNewValue(newValue)
  //       setNewValue('')
  //     }
  //     return false
  //   }, 200);
  //   return () => clearTimeout(timeOutId);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newValue]);

  const msReducerType = 'CHARS_LABELS_MULTISELECT';
  const msNamesReducerType = 'CHARS_LABELS_MULTISELECT_NAMES';
  const dispatch = useDispatch();
  const setMultiSelect = (value: MultiSelectDto) => dispatch({type: msReducerType, payload: multiSelects.map(ms => ms.id !== value.id ? ms : value)})
  const setNames = (names: MultiSelectValueName[]) => dispatch({type: msNamesReducerType, payload: names});


  const addNewValue = (initialValue: string) => {
    if(!thisMultiSelect) {
      return false
    }

    const newName: MultiSelectValueName = {
      id: uuidv4(),
      name: initialValue,
      newName: true,
      category: categoryId,
    }

    const updatedMultiSelect = {
      ...thisMultiSelect, 
      names: [
        ...thisMultiSelect.names, 
        newName.id
      ]
    }

    batch(() => {// dispatch values
      setMultiSelect(updatedMultiSelect)
      setNames([...names, newName])
    })
  }

  return (
    <div className='product-page-additional-options-one-option-values-blank-value char-label_sub-label'>
      <MyInput
        placeholder={'Добавить еще одно значение'}
        myClassName={'product-page-option-values'}
        value={''}
        onFocus={() => addNewValue('')}
        // value={newValue}
        // onInput={(e) => setNewValue(e.target.value)}
        required={false}
      />
    </div>
  )
}

export default MultiSelectAddNew
import React from "react"
import { Id } from "../../../../../dto/master-dto/id.dto"
import { usePopup } from "../../../../../scripts/hooks/use-popup"
import MyButton from "../../../../_utility-components/button/button"
import { ProductsAttrValues } from "../dto/options.dto"

interface Props {
  possibleValues: ProductsAttrValues[]
  addNewValue: (initialValue: string, id?: Id | undefined) => void
}

export function ProductOneOptionPossibleValues({
  possibleValues = [], 
  addNewValue
}: Props) {
  const firstPart = possibleValues.slice(0, 5);
  const secondPart = possibleValues.slice(5);

  const { RenderButton, RenderPopup, closePopup } = usePopup();
  
  return (
    <div className="product-page-option-possible-values">
      {firstPart?.map((v) => {
        return (
          <MyButton
            key={v.id}
            onClick={() => addNewValue(v.value, v.id)}
            tabIndex={-1}
            className='product-page-option-possible-value product-page-option-possible-value--blue'
          >
            {v?.value || null}
          </MyButton>
        )
      })}
      
      {secondPart.length ? 
        <RenderButton
          tabIndex={-1}
          className={'product-page-option-possible-value product-page-option-possible-value--blue'}
        >
          <i className="icofont-simple-down"></i>
        </RenderButton> 
      : null}
      
      <RenderPopup
        className={'product-option-possible-values'}
      >
        <div className="popup-group">
          {secondPart?.map((v) => {
            return (
              <MyButton 
                key={v.id}
                removeDefaultClass
                onClick={() => {
                  closePopup()
                  addNewValue(v.value, v.id)
                }}
                tabIndex={-1} 
                className='product-page-option-possible-value popup-element'
              >
                {v?.value || null}
              </MyButton>
            )
          })}
        </div>
      </RenderPopup>
    </div>
  )
}
import React, { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import SelectInput from '../../../_utility-components/select-input/select-input';
import { PlainProduct } from '../dto/products.dto';
import { ProductType } from './dto/product-type.dto';

export function ProductTypeComponent ({
  productChange
}: {
  productChange: (changes: Partial<PlainProduct>) => void
}) {
  const product = useAppSelector(state => state.product);
  const _isMounted = useMounted()
  const types = useAppSelector(state => state.productTypes)
  const [typesLoaded, setTypesLoaded] = useState(false);

  const dispatch = useDispatch()
  const typesDispatch = (types: ProductType[]) => dispatch({ type: 'PRODUCT_TYPES', payload: types})

  const selectedType = useMemo(() => product && types.find(type => type.id === product.type), [product, types])

  const selectHandler = (type: ProductType) => productChange({type: type.id})
  
  useEffect(() => {
    if(product && !typesLoaded) {
      mercheryFetch<ProductType[]>(`products/types`, 'GET')
      .then(res => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        batch(() => {
          types && typesDispatch(types)
          setTypesLoaded(true)
        })
      })
    }
  }, [product])

  // const addTypeToProduct = async (type: ProductType) => {
  //   if(!type || !product) {
  //     return false
  //   }

  //   const newProductsIds = [...new Set([...type.products_ids, product.id])]

  //   const cancelAddition = (
  //     JSON.stringify(newProductsIds) === JSON.stringify(type.products_ids)
  //   )
    
  //   let res: MyResponse<ProductType[], false> | false = false;

  //   if(!cancelAddition) {
  //     res = await mercheryFetch<ProductType[]>('types', 'PATCH', {
  //       toChange: [{
  //         id: type.id,
  //         products_ids: newProductsIds
  //       }]
  //     })
  //   }

  //   batch(() => {
  //     if(_isMounted.current && res && validateResponse(res)) {
  //       setSearch('')
  //       setShowData(false)

  //       setTypesWithThisProduct([
  //         ...typesWithThisProduct,
  //         res.records[0]
  //       ])
  //     }
  //   })

  //   return res
  // }

  // const removeProductFromType = async (typeToRemoveFrom: ProductType) => {
  //   if(!typeToRemoveFrom || !product) {
  //     return false
  //   }

  //   const newProductsIds = typeToRemoveFrom.products_ids.filter(id => id !== product.id)
  //   const cancelAddition = (
  //     JSON.stringify(newProductsIds) === JSON.stringify(typeToRemoveFrom.products_ids)
  //   )
    
  //   let res: MyResponse<ProductType[], false> | false = false;

  //   if(!cancelAddition) {
  //     res = await mercheryFetch<ProductType[]>('types', 'PATCH', {
  //       toChange: [{
  //         id: typeToRemoveFrom.id,
  //         products_ids: newProductsIds
  //       }]
  //     })
  //   }
  //   if(_isMounted.current && res && validateResponse(res)) {
  //     setTypesWithThisProduct(
  //       typesWithThisProduct.filter(
  //         type => type.id !== typeToRemoveFrom.id
  //       )
  //     )
  //   }
  // }

  // const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
  //   setSearch(e.target.value)
  // };

  // const createType = (name: string) => {

  //   const newType: ProductType = {
  //     id: uuidv4(),
  //     name: name,
  //     products_ids: [],
  //     products: [],
  //     newType: true
  //   }

  //   setLoaded(true)

  //   mercheryFetch<ProductType[]>('types', 'POST', {
  //     toCreate: [
  //       newType
  //     ]
  //   })
  //   .then((res) => {
  //     if(!_isMounted.current || !validateResponse(res)) return false;

  //     const typesWithoutNew = types.filter(c => !c.newType)

  //     batch(() => {
  //       typesDispatch([...typesWithoutNew, ...res.records])
  //     })
  //     return res.records[0]
  //   })
  //   .then((type) => {
  //     type && addTypeToProduct(type)
  //   })
  //   .finally(() => {
  //     setLoaded(false)
  //   })
  // }

  return <div className='product-page__right-side-bar__label'>
    <h5 className="header-font-s">
      Тип товара
    </h5>

    <SelectInput 
      items={types}
      selectedItem={selectedType}
      selectHandler={selectHandler}    
      searchable={false}
      valueLabelName={'name'}
    />

    {/* <MyInput
      value={typeText}
      onChange={(e) => setTypeText(e.target.value)} /> */}
  </div>;
}
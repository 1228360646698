import React, {useContext, useMemo} from "react";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import CategoryRows from "./categories-of-products-rows";
import {ProductsTableContext} from "../table";

const ProductsTableBody = () => {
  const {
    uniqueCategories
  } = useContext(ProductsTableContext)

  return <>{(
    uniqueCategories.map((category) =>
      <CategoryRows
        key={category}
        category={category}
      />
    )
  )}</>
}

export default ProductsTableBody
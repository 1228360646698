import React from 'react';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import Picture from '../../_utility-components/picture/picture';

export function MSProductImages () {
  const images = useAppSelector(state => state.product)?.src.map(img => img.moySkladSrc || null)
  // const reloadImage = (e) => {
  //   e.target.src = NoImage
  // }
  return (
    <div className="product-page-element product-page-dropzone-header">
      <h3 className="product-page-h3">Медиа</h3>

      {images?.length ? <div className="ms-product-media">
        {
          images.map((img, index) => {
            return (
              // <div className="photos-container-photo" key={img.id}>
              //   <div className='new-loaded_file-image-container' title={img.src}>
              //     <div className="new-loaded_file-image-wrapper">
                    <Picture key={index} images={img}/>
                    // {/* <img src={img.src} alt={img.id} onError={reloadImage}/> */}
              //     </div>
              //   </div>
              // </div>
            )
          })
        }
      </div> : <>Товар не имеет изображений</>}
    </div>
  )
}
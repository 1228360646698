import React, { useState } from 'react';
import { useRef } from 'react';
import MyButton from '../../../_utility-components/button/button';
import { useEffect } from 'react';
import { toastUp } from '../../../../scripts/functions';
import { withDraggableParentTag } from './product-one-option-modules/conditiaonal-draggable';
import { ProductOneOptionHeader } from './product-one-option-modules/header';
import { ProductOneOptionValues } from './product-one-option-modules/values'
import { ProductOption } from './dto/options.dto';
import { UpdateOptionsHandler } from './options';

export interface OneOptionProps {
  elementIndex: number,
  option: ProductOption,
  updateOption: UpdateOptionsHandler,
};

export interface OneOptionPropsRender {
  edit: boolean,
  warning: boolean,
  setWarning: (value: boolean) => void
  option: ProductOption,
  updateOption: UpdateOptionsHandler,
}

function ProductOneOption({
  elementIndex,
  option, 
  updateOption,
}: OneOptionProps) {
  const [warning, setWarning] = useState(false);
  const edit = Boolean(!option.title || option.notSaved);

  useEffect(() => {
    if(warning) {
      setTimeout(() => {
        setWarning(false)
      }, 8000);
    }
  }, [warning])

  const className = `product-page-additional-options-one-option ${warning
    ? 'product-page-additional-options-one-option--warning' : ''}`;

  return (
    <ConditionalDraggable
      isDraggable={!option.notSaved}
      dragClassName={className}
      dragProps={{
        draggableId: '' + option.order,
        isDragDisabled: option.notSaved || edit,
        index: elementIndex
      }}
      option={option} 
      edit={edit}
      warning={warning}
      setWarning={setWarning}
      updateOption={updateOption}/>
  );
}

const ConditionalDraggable = withDraggableParentTag<OneOptionPropsRender>(({
  provided,
  option, 
  updateOption,
  edit,
  warning,
  setWarning,
}) => {
  const titleInput = useRef<HTMLInputElement | null>(null)
  const [previewOption, setPreviewOption] = useState<ProductOption>(option);

  const [needCallPossibleValues, setNeedCallPossibleValues] = useState(false)

  useEffect(() => {
    if(!option.title) {
      setTimeout(() => {
        titleInput.current?.focus()
      }, 0)
    }
    return () => {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(edit) {
      updateOption(option.id, {...option, notSaved: true})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])

  const changeOption = (changes: Partial<ProductOption>) => {
    console.log(changes)
    setPreviewOption({...previewOption, ...changes})
  }
  // console.log('option', previewOption)

  const deleteOption = () => updateOption(option.id);
  const setEdit = (bool: boolean) => updateOption(option.id, {...option, notSaved: bool})

  const saveHandler = () => {
    console.log(option)
    const noTitle = !previewOption.title
    const noValues = !previewOption.values?.length
    const duplicates = (new Set(previewOption.values.map(v => v.value))).size !== previewOption.values.length
    const emptyValues = previewOption.values.some((v) => !v?.value)

    if(noTitle || noValues || duplicates || emptyValues) {
      toastUp(
        noTitle ? 'Название обязательно' : 
        noValues ? 'Опция без значений недопостима' : 
        duplicates ? 'Повторяющиеся значения недопустимы' : 
        'Пустые значения недопостимы'
      )

      console.log('noTitle', noTitle)
      console.log('noValues', noValues)
      
      if(noTitle) {
        titleInput.current?.focus()
      } else if(noValues) {
        (document.querySelector(`.product-page-additional-options-one-option-values-blank-value .merchery-label__input`) as HTMLElement)?.focus()
      }

      setWarning(true)
      return false
    }

    if(warning) setWarning(false)

    console.log('preview before save:', previewOption)
    updateOption(option.id, {...previewOption, notSaved: false})
  }
  
  useEffect(() => {
    setPreviewOption(option)
  }, [option])

  return (
    <>
      <ProductOneOptionHeader
        edit={edit}
        setEdit={setEdit}
        changeOption={changeOption}
        titleInput={titleInput}
        setNeedCallPossibleValues={setNeedCallPossibleValues}
        option={previewOption}
        deleteOption={deleteOption}
        dragProvided={provided}
      />

      <ProductOneOptionValues
        edit={edit}
        option={previewOption}
        changeOption={changeOption}
        warning={warning}
        setWarning={setWarning}
        needCallPossibleValues={needCallPossibleValues}
        setNeedCallPossibleValues={setNeedCallPossibleValues}
      />

      {edit ? (
        <div className='one-option__save-btn__wrapper'>
          <MyButton
            className={'white-btn'}
            onClick={saveHandler}
          >
            Применить
          </MyButton>
        </div>
      ) : null}
    </>
  )
})

export default ProductOneOption;
import React from 'react';
import MyButton from '../../../_utility-components/button/button';
import PaymentIcon from '../../../../img/module-kassa.svg'

function PaymentPaymethod() {
  return (
    <div className='order-page__payment__body'>

      <div className='order-page__paymethod'>

        <div className='order-page__paymethod__icon'>
          <img src={String(PaymentIcon)} alt={'payment'}/> 
        </div>

        <div className='order-page__paymethod__name text-font-xs'>
          Модульбанк: интернет-эквайринг
        </div>

      </div>

      <MyButton 
        className='order-page__check header-font-xs'
        removeDefaultClass
      >
        Чек по операции
      </MyButton>
    </div>
  );
}

export default PaymentPaymethod;
import React from 'react';
import { useTabIndex } from '../../scripts/hooks/use-tabindex';
import { HeaderAdminProfilePopup } from './header-admin-profile-popup';
import ManagersOnPage from "../_utility-components/manager-on-page";
import usePageViewers from "../../scripts/hooks/use-page-viewers";

function Header () {
  const tabIndex = useTabIndex()

  const pathname = 'app'
  const managersOnPage = usePageViewers(pathname)
  
  return (
    <div className="merchery-header hide-for-print">
      <div className="merchery-header-inner">
        <div className={'merchery-header_left-inner'}>
          <div className='header-sidebar-popup-wrapper'>
          </div>

          <label className="merchery-header-search">
            <i className="icofont-search-1 input-search-icon"></i>
            <input placeholder="Поиск в магазине" tabIndex={tabIndex}/>
          </label>
        </div>

        <div className="merchery-header_right-inner">
          <ManagersOnPage managersOnPage={managersOnPage}/>

          <HeaderAdminProfilePopup/>
        </div>
      </div>
    </div>
  );
}

export default Header



import React from 'react';
import { numWithSep, priceWithPennies, ptsansCurrency } from '../../../../../scripts/functions';
import { ItemCellProps } from '../../../../../scripts/with-row-components';
import { RefundProductItem } from '../../../products/dto/product-item.dto';

type ProductItemTotalPropsWithTotal = {
  refundTotal: number
}

function RefundTotalField(props: ProductItemTotalPropsWithTotal & ItemCellProps<RefundProductItem>) {
  let totalPriceText = numWithSep(priceWithPennies(props.refundTotal))
  const currencyText = ptsansCurrency('₽')

  return (
    <div className='row-item__total'>
      <span style={{textAlign: 'right'}}>
      {totalPriceText}&#160;{currencyText}

      </span>
    </div>
  );
}

export default RefundTotalField;

import qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SortableList from 'react-easy-sort';
import { withErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { ImageCreate } from '../../../../dto/images/image-create.dto';
import { ImageFile, ImageReorderedResponse, ImageExtended } from '../../../../dto/images/images.dto';
import { Id } from '../../../../dto/master-dto/id.dto';
import {mercheryFetch, MyResponse} from '../../../../scripts/fetchConstructor';
import { addMessage, toastUp, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../scripts/hooks/use-load';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import MyDropzone from '../../../_utility-components/dropzone';
import AppLoader from '../../../_utility-components/loaders/app-loader';
import { ExtendedProduct } from '../dto/products.dto';
import ProductMediaItem from './media-item';

const ProductImagesDropzone = () => {
  const _dropzoneMaxFiles = 10;
  const [newVideoLink, setNewVideoLink] = useState('');
  const [files, setFiles] = useState<ImageExtended[]>([]);
  const [fetchingImages, setFetchingImages] = useState(false);
  const [ , setLoad] = useLoad()
  const [mainId, setMainId] = useState<Id | null>(null);
  const initialFiles = useRef<ImageExtended[]>([])
  const product = useAppSelector(state => state.product)
  const productId = useRouteId('productid')

  const _isMounted = useMounted()
  const [loadedFilesAwaiting, setLoadedFilesAwaiting] = useState(false);

  const { RenderButton, RenderPopup, closePopup, isOpen } = usePopup()

  const dispatch = useDispatch()
  const productDispatch = (product: ExtendedProduct) => dispatch({ type: 'PRODUCT_ITEM', payload: product })

  useEffect(() => {
    // websocketListener()
    getImages()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reorderSendRequest = (changes: {id: Id, order: number}[]) => {
    return mercheryFetch<ImageReorderedResponse[]>('images', 'PATCH', {
      id: productId,
      module: 'Catalog',
      changes: changes
    })
  }

  const getImages = async () => {
    setLoad(true)

    const params = qs.stringify({
      module_id: productId,
      module: 'Catalog',
    }, {arrayFormat: 'comma'})

    setFetchingImages(true)

    return mercheryFetch<ImageExtended[]>(`images?${params}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      const images = res.records;
      const newInitialMainId = images.find(r => r.main)?.id || null

      initialFiles.current = images

      batch(() => {
        setFiles(images)
        setMainId(newInitialMainId)
      })
      return images
    })
    .finally(() => {
      _isMounted.current && batch(() => {
        setLoad(false)
        setFetchingImages(false)
      })
    })
  }

  const externalLinkToVideoValidate = () => {
    const url = newVideoLink;
    if (url !== undefined || url !== '' ) {
      const isYoutubeLink = url.includes('youtu')
      const isVimeoLink = url.includes('vimeo')
      if(isYoutubeLink || isVimeoLink) {
        const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
        const regExpVimeo = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/
        const match = url.match(regExpYoutube) || url.match(regExpVimeo);
        return {match, isYoutubeLink, isVimeoLink}
      }
    }
    return false
  }

  const uploadNewImages = useCallback(async (filesToAdd: ImageCreate[]) => {
    if(!product) {
      return undefined
    }

    const res = await mercheryFetch<ImageExtended[]>('products/create/image', 'POST', {
      id: product.id,
      newImages: filesToAdd,
    }, {
      withoutToastUp: true
    })

    if(!_isMounted.current || !validateResponse(res)) {
      return res
    }
    const createdImages = res.records
  
    productDispatch({
      ...product,
      src: [...product.src, ...createdImages]
    })
    
    return res
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const addVideo = async () => {
    try {
      const validated = externalLinkToVideoValidate()
      if(!validated) {
        toastUp('Некорректная ссылка на видеоресурс')
        return false
      }
      
      const {match, isYoutubeLink, isVimeoLink} = validated
      if(!match) {
        toastUp('Видео не найдено, попробуйте другой формат ссылки')
        return false
      }

      const id = match.at(-1)
      if(!id) {
        return false
      }

      let previewPath: string, imageName: string;

      if(isYoutubeLink) {

        previewPath = `https://img.youtube.com/vi/${id}/sddefault.jpg`;
        imageName = id

      } else if(isVimeoLink) {

        const videoDataRes = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`)
        if(!videoDataRes.ok) {
          toastUp('Невозможно загрузить изображение предварительного просмотра')
          return false
        }

        const videoData = await videoDataRes.json()
        previewPath = videoData.thumbnail_url
        imageName = videoData.title

      } else {
        toastUp('Невозможно загрузить изображение предварительного просмотра')
        return false
      }

      const lastOrder = files.reduce((maxId, item) => Math.max(maxId, item.order), 0)

      const image: ImageCreate = {
        videoSource: isYoutubeLink ? 'youtube' : isVimeoLink ? 'vimeo' : null,
        videoLink: match.input,
        main: false,
        order: lastOrder + 1,
        imageName,
        image: previewPath
      }

      const res = await uploadNewImages([image])

      if(_isMounted.current && res && res.success) {
        const newMain = res.records.find(img => img.main)

        closePopup()

        batch(() => {
          newMain && setMainId(newMain.id)
          setFiles([
            ...files.flatMap(f => f.newFile ? [] : f), 
            ...res.records
          ])
        })
      }

      return res
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Ссылка не прошла валидацию'
      addMessage('.add-by-link-popup .merchery-label', message)
    }
  }

  const filesStateChanger = (newFiles: ImageFile[]): Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>> => {
    setLoadedFilesAwaiting(true)

    return uploadNewImages(newFiles)
    .then(res => {
      if(_isMounted.current && res && res.success) {
        const images = res.records
        const newMain = images.find(img => img.main)
        setFiles([
          ...files.flatMap(f => f.newFile ? [] : f), 
          ...images
        ])
        newMain && setMainId(newMain.id)
        return res
      }
      return res
    })
    .finally(() => {
      setLoadedFilesAwaiting(false)
    })
  };

  const onDragEnd = (oldIndex: number, newIndex: number) => {
    const newList = JSON.parse(JSON.stringify(files)) as ImageExtended[];
    const [removed] = newList.splice(oldIndex, 1);
    newList.splice(newIndex, 0, removed);

    const updatedFiles: ImageExtended[] = newList.map((i, index) => ({...i, order: index + 1}));

    const changedFiles = updatedFiles 
      .filter(updatedFile => {
        const originalFile = files.find(file => file.id === updatedFile.id);
        return originalFile && originalFile.order !== updatedFile.order;
      })
      .map(i => ({id: i.id, order: i.order}))
    
    setFiles(updatedFiles)
    reorderSendRequest(changedFiles)
  };

  useEffect(() => {
    if(loadedFilesAwaiting && isOpen) {
      closePopup()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedFilesAwaiting])

  const addVideoPopupOpener = () => {
    return setTimeout(() => {
      const element = document.querySelector('.add-by-link-popup .merchery-label__input') as HTMLElement | null;
      element?.focus();
    }, 5)
  };

  return (
    <div className="product-page-element product-page-dropzone side-padding-24">
      <div className="product-page-dropzone-header">
        <h3 className="product-page-h3">Медиа</h3>
        <div style={{position: 'relative'}}>
          <RenderButton
            className="blue-text"
            disabled={loadedFilesAwaiting}
            removeDefaultClass
            onClick={addVideoPopupOpener}
          >
            Добавить видео по ссылке
          </RenderButton>

          <RenderPopup
            className={'select-category add-by-link-popup'}
          >
            <div className="popup-group">
              <MyInput
                required={false}
                placeholder={'Вставьте ссылку на видео с Vimeo или Youtube'}
                value={newVideoLink}
                onChange={(e) => {
                  const value = e.target.value
                  setNewVideoLink(value)
                }}
              />

              <div className='flex-wrapper'>
                <MyButton
                  className="popup-element white-btn"
                  onClick={closePopup}
                >
                  Отменить
                </MyButton>

                <MyButton
                  disabled={!newVideoLink}
                  className="popup-element blue-btn"
                  onClick={addVideo}
                >
                  Сохранить
                </MyButton>
              </div>
            </div>
          </RenderPopup>
        </div>
      </div>

      <MyDropzone
        className='product-page-dropzone__zone'
        files={files}
        isLoading={loadedFilesAwaiting}
        optionalProps={{ maxFiles: _dropzoneMaxFiles }}
        fileHandler={filesStateChanger}
        contentRender={
          fetchingImages ? 
            <AppLoader/> 
          : 
            <SortableList onSortEnd={onDragEnd} 
              className="photos-container-photo-wrapper" 
              draggedItemClassName="dragged"
            >
              {files
                .sort((a,b) => (a.newOrder || a.order) - (b.newOrder || b.order))
                .map((photo) => 
                  <ProductMediaItem
                    initialFiles={initialFiles}
                    key={photo.md5 || photo.src}
                    photo={photo}
                    mainId={mainId} 
                    files={files} 
                    setMainId={setMainId} 
                    setFiles={setFiles}
                  />
              )}
            </SortableList>
        }
      />
    </div>
  )
}

export default withErrorBoundary(ProductImagesDropzone, {FallbackComponent: FallbackComponent})
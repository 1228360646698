import React, { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import {scrollPageTo, validateResponse} from '../../../../../scripts/functions';
import useRowClick from '../../../../../scripts/hooks/use-row-click';
import { useShiftSelect } from '../../../../../scripts/hooks/use-shift-select';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import { ExtendedProduct } from '../../dto/products.dto';
import { ProductVariantExtended } from '../../dto/variants.dto';
import Row from './table-row/row';

const ProductsVariantsTableRows = ({
  product,
}: {
  product: ExtendedProduct
}) => {
  const _isMounted = useRef(true);
  const products = useAppSelector(state => state.products);
  const location = useLocation<{toProduct?: Id}>();
  const fromProductId = location.state?.toProduct

  const fromProduct = useMemo((): ExtendedProduct | null => {
    const productId = fromProductId;
    if(!productId) return null;

    const productNotExist = products.findIndex((o) => +o.id === +productId) === -1;
    if(productNotExist) return null;

    scrollPageTo(`[productId="${productId}"]`)

    return products.find((p) => +p.id === +productId) || null
  }, [fromProductId, products])

  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);
  const tabIndex = useTabIndex()
  const selectedVariants = useAppSelector(state => state.selectedVariants);
  
  const dispatch = useDispatch()
  const selectedVariantsDispatch = (items: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS', payload: items})
  const variantToProductsDispatch = (updatedVariants: ProductVariantExtended[]) => {
    const newProducts = products.map(item => {
      if(item.id !== product.id) {
        return item
      }

      return {
        ...item,
        variants: item.variants.map(variant =>
          updatedVariants.find(updVar =>
            variant.id === updVar.id
          ) || variant
        ),
      }
    })

    dispatch({
      type: 'PRODUCTS',
      payload: newProducts
    })
  }

  const [productInFocus, setProductInFocus] = useState(fromProduct);

  const [selectedVariantsIds, handleSelectVariant] = useShiftSelect(product.variants, selectedVariants);

  useEffect(() => {
    selectedVariantsDispatch(selectedVariantsIds)
  }, [selectedVariantsIds])

  const setProductInFocusHandler = (productId: Id) => {
    const product = products.find(s => s.id === productId);
    document.getSelection()?.removeAllRanges();

    if(!_isMounted.current || !product || product.id === fromProductId) {
      return false
    }

    setProductInFocus(product)
  };
  
  const [rowClick, setActiveRowId] = useRowClick(
    (id) => ({
      to: `/app/products/${id}`, 
      state: {prevPage: location.search}
    }),
    ['.op-total', '.op-check', '.op-show-more .my-btn', '.op-price', '.op-config']
  );

  const changeData = (id: Id, changes: Partial<any>) => {
    const requestBody = {
      changes: [{
        id: id,
        ...changes,
      }],
    }

    const path = 'products/variants';

    mercheryFetch<ProductVariantExtended[]>(path, 'PATCH', requestBody)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      variantToProductsDispatch(res.records)
      return true
    })
  };

  return <>
    {product.variants.map((el, index) => {
      const {product_id} = el
      const focused = productInFocus && productInFocus.id === el.id ? 'focused' : '';
      const selected = selectedVariants.filter(s => s === el.id).length ? 'selected' : '';
      const hidden = !product.show_date || new Date(product.show_date).getTime() > new Date().getTime() ? 'hidden' : '';
      const variantClassName = 'variant-table-item'
      const msItemClass = moySkladIntegrationOn ? 'moy-sklad-item' : ''
      const itemId = product_id
      const checked = selectedVariants.some(id => el.id === id)

      const rowWrapperProps: HTMLAttributes<HTMLDivElement> = {
        // productid: el.id,
        // link: el.link,
        // height: this.tableItemHeight,
        className: `droppable table-item ${msItemClass} ${selected} ${hidden} ${focused} ${variantClassName}`,
        onFocus: () => setProductInFocusHandler(el.id),
        tabIndex: tabIndex,
        onMouseUp: e => rowClick(e, itemId),
        onMouseDown: () => {
          setActiveRowId(itemId)
        },
        onKeyUp: e => e.key === 'Enter' ? rowClick(e, itemId) : null,
      }

      return (
        <Row<ProductVariantExtended>
          key={el.id}
          product={el}
          checked={checked}
          changeData={changeData}
          productCheckHandler={handleSelectVariant}
          wrapperProps={rowWrapperProps}
        />
      )
    })}
  </>
}

export default ProductsVariantsTableRows

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import MyInput from 'src/components/_utility-components/input';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { MultiSelectValueName } from '../../../../dto/chars.dto';

interface Props {
  msName: MultiSelectValueName,
  deleteHandler: () => void
}

function MultiSelectNameEditing ({
  msName,
  deleteHandler
}: Props) {
  const names = useAppSelector(state => state.multiSelectNames);
  const [inputValue, setInputValue] = useState(msName.name);

  const msNamesReducerType = 'CHARS_LABELS_MULTISELECT_NAMES';
  const dispatch = useDispatch();
  const setNames = (names: MultiSelectValueName[]) => dispatch({type: msNamesReducerType, payload: names});

  const nameInputBlurHandler = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if( event.relatedTarget === null && !inputValue ) {
      deleteHandler()
    }
  }, [deleteHandler, inputValue, ])
  
  useEffect(() => { // apply name change
    let timeOutId = setTimeout(() => {
      if(inputValue !== msName.name) {
        const changedNames = names.map(n => n.id !== msName.id ? n : {
          ...msName,
          name: inputValue
        })
        setNames(changedNames)
      }
    }, 200);
    return () => clearTimeout(timeOutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => { // synch with input on external change
    if(msName && msName.name !== inputValue) {
      setInputValue(msName.name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msName])

  const anotherMSNAmeHaveSameName = useMemo(() => {
    const namesWithThisName = names
      .filter(n => n.category === msName.category)
      .find(n => n.name.toLowerCase() === inputValue.toLowerCase());
    return namesWithThisName && namesWithThisName.id !== msName.id
  }, [names, inputValue, msName])

  
  return (
    <div className='multiselect-item__name'>
      <MyInput
        value={inputValue}
        // placeholder={'Название поля'}
        hasError={anotherMSNAmeHaveSameName}
        placeholder={anotherMSNAmeHaveSameName ? 'Повторяющееся название в одной категории' : ' '}
        myClassName={`${!anotherMSNAmeHaveSameName ? `placeholder--animation-off` : ''} multiselect-header__label`}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={nameInputBlurHandler}
      />
    </div>
  )
} 

export default MultiSelectNameEditing
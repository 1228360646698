import {FiltersArgs} from "./use-init-filters";

function useInfiniteScrollShowMore({
  getSize,
  allCount,
  currentCount,
  getItems,
}: {
  getSize: number,
  allCount: number,
  currentCount: number,
  getItems: EntityGetterWithPagination,
}): [boolean, () => void] {
  const hasMore = Boolean(allCount && allCount > currentCount);

  const showMore = () => {
    const page = Math.ceil(currentCount / getSize);
    getItems({
      page,
      pageSize: getSize,
    })
  }

  return [hasMore, showMore]
}

export type PaginationArgs = {
  page?: number,
  pageSize: number
};

export type EntityGetterWithPagination = (args: FiltersArgs & PaginationArgs) => void;

export default useInfiniteScrollShowMore;
import React from 'react';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import { RefundStatus } from '../dto/refund-status.dto';
import { RefundDto } from '../dto/refund.dto';

interface Props {
  refund: RefundDto,
  className?: string,
  btnClassName?: string,
  withArrow?: true,
  tabIndex?: number,
  statusHandler: (statusId: Id) => void,
  disabled?: boolean
}

function SetStatus({
  refund,
  className, 
  btnClassName,
  withArrow,
  tabIndex,
  statusHandler,
  disabled,
}: Props) {
  const statuses = useAppSelector(state => state.refundStatuses);
  const { RenderButton, RenderPopup, closePopup } = usePopup()

  if(!statuses) {
    return null
  }

  const refundStatus = statuses.find(p => p.id === refund.status_id);

  const popupElementHandler = (status: RefundStatus) => {
    if(disabled) {
      return false
    }
    statusHandler(status.id)
    closePopup();
  }

  return (
    <div className={`${className ? className : ''} select-btn`} >
      <RenderButton
        {...(tabIndex !== undefined && {
          tabIndex: tabIndex,
        })}
        disabled={disabled}
        removeDefaultClass
        style={{
          backgroundColor: refundStatus ? refundStatus.color || '#E4E4E4' : '#E4E4E4',
          color: refundStatus?.color ? '#FFF' : '',
        }}
        className={`select-btn-inner ${btnClassName ? btnClassName : ''}`}
      >
        {refundStatus?.name || ''} 
        {withArrow ? <i className='icofont-simple-down'/> : null}
      </RenderButton>

      <RenderPopup
        changingDirection={true} 
        className={'table-popup status'}
      >
        {statuses.map((os) => 
          <div className="popup-group" key={os.id}>
            <StatusBtn 
              status={os}
              btnHandler={popupElementHandler}
            />
          </div>
        )}
      </RenderPopup>
    </div>
  );
}

interface StatusBtnProps {
  status: RefundStatus | undefined
  btnHandler: (status: RefundStatus) => void,
}

function StatusBtn ({
  status,
  btnHandler,
}: StatusBtnProps) {
  if(!status) {
    return null
  }
  const bgColorStyle = status.color ? status.color : '#E4E4E4';

  return (
    <MyButton className="popup-element" 
      key={status.id} 
      removeDefaultClass
      onClick={() => btnHandler(status)}
    >
      <span className={'status_filter-btn__text'}>
        {status.name} 
        <div style={{backgroundColor: bgColorStyle}}></div>
      </span>
    </MyButton>
  )
}

export default SetStatus;
import {useContext, useEffect} from 'react';
import {WebSocketContext} from "../scripts/web.socket";
import {WsResponse} from "../dto/ws-responses/ws-response.dto";
import {createOrder, deleteOrder, updateOrder} from "../constants/ws-events";
import {useDispatch} from "react-redux";
import {CreateOrdersWsDto, DeleteOrdersWsDto, OrderChangeWs, UpdateOrdersWsDto} from "../dto/ws-responses/orders.dto";
import {useAppSelector} from "../scripts/pre-type/use-selector";
import {createDispatch, deleteDispatch, updateDispatch} from "./dispatches";

const ordersDispatchType = 'ORDERS'

function useOrdersChangesListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);
	const orders = useAppSelector(state => state.orders);

	const dispatch = useDispatch()

	const updateItemsDispatch = updateDispatch(orders, dispatch, ordersDispatchType)
	const deleteItemsDispatch = deleteDispatch(orders, dispatch, ordersDispatchType)
	const createItemsDispatch = createDispatch(orders, dispatch, ordersDispatchType)

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateOrdersWsDto>;

			if (message.event === updateOrder &&
				message.data.changes
			) {
				const changes = message.data.changes;
				console.log('changes', changes)

				updateItemsDispatch<OrderChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteOrdersWsDto>;

			if (message.event === deleteOrder &&
				message.data.id
			) {
				const ids = message.data.id;

				deleteItemsDispatch(ids)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateOrdersWsDto>;

			if (message.event === createOrder &&
				message.data.order
			) {
				const order = message.data.order;

				createItemsDispatch(order)
			}
		}
	}, [lastMessage]);
}

export default useOrdersChangesListener;
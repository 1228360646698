import React, { useContext, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "src/components/_utility-components/error-boundary";
import { useTabIndex } from "src/scripts/hooks/use-tabindex";
import withRowComponents, { RowProps } from "src/scripts/with-row-components";
import ProductItemEmptyGap from "../../products/products-items/empty-gap";
import ProductItemImage from "../../products/products-items/image";
import ProductItemName from "../../products/products-items/name";
import ProductItemPrice from "../../products/products-items/price";
import { ProductRow } from "../../products/products-items/product-items";
import { SetDto } from "../../sets/dto/set.dto";
import { CollectionContext } from "../collection-page";
import SetProductItemExternalId from "./set-row-cells/set-product-row-external-id";
import { setRowCells } from "./set-row-cells/set-row-cells";

function SetToBeAddedInCollection ({
  set,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: {
  set: SetDto,
  canBeAddedSelected: SetDto[],
  setCanBeAddedSelected: (items: SetDto[]) => void,
}) {
  const {
    collection
  } = useContext(CollectionContext);
  const tabIndex = useTabIndex(2);
  const [showedProducts, setShowedProducts] = useState(false);

  const addSelected = (setFromHandlerRow: SetDto) => {
    const setToBeAdded = setFromHandlerRow || set;
    const selected = canBeAddedSelected.some(item => item.id === setToBeAdded.id)

    if(selected) {
      removeSelected()
      return false
    } 
    
    setCanBeAddedSelected([...canBeAddedSelected, setFromHandlerRow])
  }

  const removeSelected = () => {
    setCanBeAddedSelected(canBeAddedSelected.filter(item => item.id !== set.id))
  }

  const canBeAddedSelectedSet = canBeAddedSelected?.find(arrayItem => arrayItem.id === set.id)

  const items = collection?.items.filter(item => item.item_type === 'set') || [];
  const alreadyAdded = items.some(arrayItem => arrayItem.item_id === set.id)
  const disabled = alreadyAdded;

  const shownSetFields = [
    'checkbox',
    'src',
    'name',
    { key: 'products_ids', options: {
      showedProducts: showedProducts,
      showHandler: () => setShowedProducts(!showedProducts)
    }},
  ] as const;

  const shownProductFields = [
    'emptyGap',
    'src',
    'name',
    'external_id',
    'price'
  ] as const;
  
  return (
    <>
      <SetRow
        item={set}
        shownFields={shownSetFields}
        componentsFields={setRowCells}
        className={`set-row collections__add-items-rows--template`}
        changer={undefined}
        selectHandler={addSelected}
        disabled={disabled}
        tabIndex={tabIndex}
        selected={canBeAddedSelectedSet !== undefined || alreadyAdded}
      />
        
      {showedProducts && set.products && set.products.length ? 
        set.products.map((product) => 
          <ProductRow
            key={product.id}
            item={product}
            shownFields={shownProductFields} 
            className={`row-item set-products__add-items-rows--template`}
            changer={undefined}
            disabled={disabled}
            tabIndex={tabIndex}
            componentsFields={setProductRowsFileds}
          />
        )
      : null}
    </>
  )
}

const setProductRowsFileds = {
  'emptyGap': ProductItemEmptyGap,
  'src': ProductItemImage,
  'name': ProductItemName,
  'external_id': SetProductItemExternalId,
  'price': ProductItemPrice,
}

const SetRow: React.FC<RowProps<SetDto>> = withRowComponents<RowProps<SetDto>>(() => <></>);

export default withErrorBoundary(SetToBeAddedInCollection, {FallbackComponent: FallbackComponent})
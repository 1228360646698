// Компонент для загрузки файлов

import React, { useRef} from 'react';
import Dropzone, { DropEvent, DropzoneProps, DropzoneRef, ErrorCode, FileRejection } from 'react-dropzone';
import { ImageFile, ImageExtended } from '../../dto/images/images.dto';
import UploadImage from '../../img/upload-image';
import {addMessage, formatBytes, toastUp} from '../../scripts/functions';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import LocalLoader from './loaders/local-loader';
import {MyResponse} from "../../scripts/fetchConstructor";

interface Props {
  files: (ImageExtended | ImageFile)[],
  fileHandler: (newFiles: ImageFile[]) => Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>,
  contentRender: React.ReactElement<any, any> | JSX.Element,
  optionalProps?: Partial<DropzoneProps & React.RefAttributes<DropzoneRef>>,
  className?: string,
  disabled?: boolean,
  isLoading?: boolean,
  emptyRender?: React.ReactElement<any, any> | JSX.Element,
}

const postBase64 = (file: File): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
  try {                             
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  } catch (error) {
    reject(error)
  }
});

function MyDropzone ({
  files,
  fileHandler,
  optionalProps,
  className,
  contentRender,
  disabled,
  isLoading,
  emptyRender,
}: Props) {
  const dropzoneRef = useRef<HTMLDivElement>(null)
  const maxSize = useAppSelector(state => state.staticValues.dropzone_file_max_size || undefined)
  const errorMessage = useAppSelector(state => state.staticValues.dropzone_error_message || '')

  const onDropAccepted = async (newFiles: File[], event: DropEvent) => {
    if(newFiles?.length) {
      let base64Images: ImageFile[] = []

      const filesLength = files.length

      for (const f of newFiles) {
        const imageBase64 = await postBase64(f)
        if(!imageBase64) continue

        const img: ImageFile = {
          image: String(imageBase64),
          imageName: f.name,
          imageType: f.type.split('/').at(-1),
          order: filesLength + 1,
          src: URL.createObjectURL(f),
          newFile: true,
          main: false,
        }

        base64Images.push(img)
      }

      fileHandler(base64Images)
      .then(res => {
        if(res && 'message' in res) {
          toastUp(res.message);

          dropzoneRef.current &&
          addMessage(dropzoneRef.current as Element, res.message);
        }
        return res
      })
    }
  }

  const onDropRejected = async (fileRejections: FileRejection[], event: DropEvent) => {
    for (const {errors, file} of fileRejections) {
      const messagePrefix = `Файл ${file.name} не загружен:`;

      for (const err of errors) {
        const message = (() => {switch (err.code) {
          case ErrorCode.FileInvalidType: 
            return `${messagePrefix} неподдерживаемый формат изображения`;
          case ErrorCode.FileTooLarge: 
            return `${messagePrefix} больше допустимоего размера в ${maxSize} бит ${maxSize ? '(' + formatBytes({ bytes: maxSize }) + ')' : ''}`;
          default: return errorMessage;
        }})()
        
        toastUp(message)
      }
    }
  }

  if(isLoading) {
    return (
      <div 
        className={`dropzone dropzone--loading ${className ? className : ''}`}
      >
        <LocalLoader 
          className={''}
          height={32}
          width={32}
        />
      </div>
    )
  }

  return (
    <Dropzone
      accept={'image/*, application/pdf'}
      multiple={optionalProps?.multiple || true}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      maxSize={maxSize}
      disabled={disabled}
      maxFiles={optionalProps?.maxFiles || 10}
      {...optionalProps}
    >
      {({getRootProps, getInputProps}) => (
        <div
          className={`dropzone ${className ? className : ''}`}
          {...getRootProps()}
          ref={dropzoneRef}
        >
          <input {...getInputProps({id: `dropzone-input`})}/>

          {files?.length ? (
            contentRender
          ) : null}

          {!files?.length ?
            !emptyRender ? (
              <div className="without-files">
                <div className="files-load-image">
                  <UploadImage/>
                </div>
                <div className="files-load-image files-load-image__has-error">
                  <UploadImage color='#4A5267'/>
                </div>

                <div className={'without-files__text'}>
                  <h4>Загрузить изображения</h4>
                  <div className="dropzone-text large">или перетащите файлы в это поле для загрузки .jpg, .png, .webp, .gif</div>
                  <div className="dropzone-text small">.jpg, .png, .webp, .gif</div>
                </div>
              </div>
            ) : <>{emptyRender}</>
          : null}
        </div>
      )}
    </Dropzone>
  )
}

// function fileGuard(image: ImagesExtended | File): image is ImagesExtended {
//   return (image as ImagesExtended).id !== undefined
// }

export default MyDropzone
import React from 'react';
import { useDispatch } from 'react-redux';
import arrowLeft from '../../../../img/arrow-left.png';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import { CurrentPromoCode } from './promo';

interface NewPromoProps {
  isPercent: boolean;
  popupClose: () => void;
  promoSwitch: () => void;
  createHandler: () => Promise<false | void>;
  newPromoCode: CurrentPromoCode
  setNewPromoCode: (data: CurrentPromoCode) => void
}

export function NewPromo({
  popupClose, 
  isPercent, 
  newPromoCode,
  promoSwitch, 
  createHandler, 
  setNewPromoCode
}: NewPromoProps) {
  const tabIndex = useTabIndex(2)
  const dispatch = useDispatch();
  const setCreateNewCodeState = (value: boolean) => dispatch({ type: 'UI_PROMO_CREATE_STATE', payload: value })

  return (
    <>
      <div className="discount-promo-header promo">
        <MyButton
          tabIndex={tabIndex}
          removeDefaultClass
          className="cancel-create"
          onClick={() => setCreateNewCodeState(false)}>
          <img src={arrowLeft} alt="Назад" />
        </MyButton>
        <h2>Новый промокод</h2>
      </div>

      <form id='create-promo' className="discount-promo-set-discount-promo">
        <MyInput
          tabIndex={tabIndex}
          myClassName='promo-name'
          value={newPromoCode.code}
          onChange={(e) => setNewPromoCode({...newPromoCode, code: e.target.value})}
          placeholder="Название"
          name="promo-name"
          withBind='confirm-promo'
          required />

        <div className="discount-promo-set-discount-promo-top promo">
          <MyInput
            tabIndex={tabIndex}
            placeholder="Номинал"
            name="promo-value"
            withBind='confirm-promo'
            value={newPromoCode.price}
            onChange={(e) => setNewPromoCode({...newPromoCode, price: +e.target.value})}
            required />

          <MyButton 
            tabIndex={tabIndex}
            className={`discount-by percent ${isPercent ? "blue-btn" : "white-btn"}`}
            onClick={promoSwitch}>
            %
          </MyButton>

          <MyButton
            tabIndex={tabIndex}
            className={`discount-by currency ${!isPercent ? "blue-btn" : "white-btn"}`}
            onClick={promoSwitch}>
            ₽
          </MyButton>
        </div>
        <div className="discount-promo-btns">
          <MyButton
            tabIndex={tabIndex}
            type="button"
            className="white-btn"
            onClick={popupClose}>
            Отменить
          </MyButton>

          <MyButton
            tabIndex={tabIndex}
            id="confirm-promo"
            type="button"
            className="blue-btn"
            onClick={createHandler}>
            Создать
          </MyButton>
        </div>
      </form>
    </>
  );
}

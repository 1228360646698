import { ClientGroup } from "../dto/client-group.dto";
import { Client } from "../dto/client.dto";

interface Props {
  client: Client
  groups: ClientGroup[]
}

export const renameExportHeaders = ({
  client,
  groups
}: Props): any => {
  const group: string = (client.group && groups.find(group => group.id === client.group)?.name) || ''

  return {
    'Email': client.email,
    'Имя': client.name,
    'Номер телефона': client.phone,
    'Группа клиента': group,
    "карта лояльности": client.loyalty?.loyalty_card || '',
  }
}
import React, { useState } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import Popup from '../../../_utility-components/popup';
import { StatusFilterOption } from '../orders-modules/header-status-filter';
import {Order} from "../dto/order.dto";
import {MultiChanger} from "../../../../scripts/utils/entity-filters";

export function SetMassStatus({
  multiChange
}: {
  multiChange: MultiChanger<Order>
}) {
  const [popupOpened, setPopupOpened] = useState(false);
  return <div>
    <MyButton
      className="selected-top-page-menu-btn header-font-s"
      onMouseDown={() => setPopupOpened(true)}
    >
      <i className="icofont-exit"></i>
      <span>Установить статус</span>
    </MyButton>

    {popupOpened ?
      <StatusMultiRowsPopup
        popupClose={() => setPopupOpened(false)}
        multiChange={multiChange}
        withoutCheckbox={true}
      />
    : null}

  </div>;
}

interface StatusMultiRowsPopupProps {
  popupClose: () => void,
  multiChange: MultiChanger<Order>,
  withoutCheckbox?: true
}

const StatusMultiRowsPopup = ({
  popupClose, 
  multiChange, 
  withoutCheckbox
}: StatusMultiRowsPopupProps) => {
  const status = useAppSelector(state => state.orderStatus);

  if(!status) {
    return null
  }

  return (
    <Popup 
      popupClose={popupClose} 
      className={'status selected-row'} 
    >
      <div className="popup-group">
        {status.map(
          os => os.id < 3 ? 
            <StatusFilterOption 
              key={os.id} 
              os={os} 
              withoutCheckbox={withoutCheckbox} 
              filterChanger={multiChange}
              />
          : null
        )}
      </div>
      
      {status.map(
        os => os.id >= 3 ? 
          <StatusFilterOption 
            key={os.id} 
            os={os} 
            withoutCheckbox={withoutCheckbox} 
            filterChanger={multiChange}
            />
        : null
      )}
    </Popup>
  );
};

export default StatusMultiRowsPopup;
// The useRowFocus hook is a hook that manages the focus state of a row in a table.
// It accepts an initial value and returns an object containing the currently focused item,
// a function to handle focus changes, the previously checked item,
// and a function to set the previously checked item.

import { useState, useEffect } from 'react';
import { Id } from 'src/dto/master-dto/id.dto';
import useMounted from './use-mounted';

const useRowFocus = <T extends {id: Id}>(initialValue: T | null) => {
  const _isMounted = useMounted();
  const [focusedItem, setFocusedItem] = useState<T | null>(initialValue);
  const [prevChecked, setPrevChecked] = useState<Id | undefined>(undefined);

  const focusHandler = (id: Id | null, items: T[]) => {
    if(!id) {
      setFocusedItem(null);
      return false
    }

    const item = items.find(o => o.id === id);
    if (!item || focusedItem?.id === item.id) return false;

    setFocusedItem(item);
    document.body.addEventListener('mousedown', unfocus);
  };

  const unfocus = function(event: globalThis.MouseEvent) {
    if (!event.shiftKey && _isMounted.current) {
      setFocusedItem(null);
      setPrevChecked(undefined)
      document.body.removeEventListener('mousedown', unfocus);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to remove event listener when component is unmounted
      document.body.removeEventListener('mousedown', unfocus);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  return { focusedItem, focusHandler, prevChecked, setPrevChecked };
};

export default useRowFocus;

import React, { useMemo } from 'react';
import {batch, useDispatch} from 'react-redux';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { num2str, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import MyCheckbox from '../../../_utility-components/checkbox/my-checkbox';
import TrashBtn from '../../../_utility-components/trash-btn/trash-btn';
import { CollectionDto } from '../dto/collections.dto';
import MassVisibilityPopup from "../../../_utility-components/visibility-select/mass-visibility-popup";
import HeaderCell from "../../../_utility-components/common-table/header-cell";

interface Props {
  headerCheckHandler: () => void,
  selectedCollectionsIds: Id[],
  checkboxIsActive: boolean,
  deleteHandler: () => void,
  setSelectedCollectionsIds: (ids: Id[]) => void
}

function HeaderSelectedControl({
  checkboxIsActive,
  selectedCollectionsIds,
  headerCheckHandler,
  deleteHandler,
  setSelectedCollectionsIds,
}: Props) {
  const _isMounted = useMounted();
  const collections = useAppSelector(state => state.collections);

  const sortedCollections = useMemo(() => 
    [...(collections || [])].sort((a, b) => a.order - b.order)
  , [collections])

  const selectedCollections = 
    sortedCollections.filter(coll => 
      selectedCollectionsIds.some(id => id === coll.id)
    );

  const selectedCountDisplay = num2str(
    selectedCollectionsIds.length, 
    ['коллекция', 'коллекции', 'коллекций'], 
    ['Выбрана', 'Выбрано', 'Выбрано']
  );
  
  const dispatch = useDispatch();
  const collectionsDispatch = (colls: CollectionDto[]) => dispatch({type: 'COLLECTIONS', payload: colls});

  const onChange = ({show_date}: {show_date: number | null}) => {
    mercheryFetch<CollectionDto[]>('collections', 'PATCH', {
      toChange: selectedCollections.map((coll) => ({
        id: coll.id,
        show_date: show_date,
      })),
      returnExtended: true
    })
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        const changedCollections = sortedCollections.map(c => {
          const reorderedColl = res.records.find(reorderedItem => reorderedItem.id === c.id);
          return reorderedColl ? { ...c, ...reorderedColl } : c;
        });

        batch(() => {
          setSelectedCollectionsIds([])
          collectionsDispatch(changedCollections)
        })
      }
    })
  }

  return (
    <div className={`collection__table-header--control`}>
      <HeaderCell 
        className='right-align'
        children={
          <MyCheckbox 
            text={''} 
            condition={checkboxIsActive}
            actionHandler={headerCheckHandler}
          />
        }
      />

      <HeaderCell
        className={'header-font-s'}
        children={
          <>
            {selectedCountDisplay}
            {/*<MultiVisibilitySelect */}
            {/*  elements={selectedCollections}*/}
            {/*  onChange={onChange}*/}
            {/*/>*/}
          </>
        }
      />

      <HeaderCell 
        className={`right-align`}
        children={
          <div style={{
            display: 'flex',
            columnGap: '32px'
          }}>
            <MassVisibilityPopup
              onChange={onChange}
              elements={selectedCollections}
              entityNameInHeader={'коллекций'}
            />

            <TrashBtn
              className={'header-font-s'}
              handler={deleteHandler}
              text={'Удалить'}
            />
        </div>
        }/>
    </div>
  );
}

export default HeaderSelectedControl;
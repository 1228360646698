import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
  // @ts-ignore: Unreachable code error
import contentCSS from 'tinymce/skins/content/default/content.min.css';
  // @ts-ignore: Unreachable code error
import contentUiCss from 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js. 
import 'tinymce/plugins/link';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/table';
import 'tinymce-i18n/langs5/ru.js'

interface Props {
  description: string
  handleEditorChange: (text: string) => void
  // editorId: string
} 

function ProductEditor({ 
  description,
  handleEditorChange,
  // editorId
}: Props) {
  return (
    <Editor
      id={'product-description-editor'}
      value={description}
      onEditorChange={handleEditorChange}
      tinymceScriptSrc={'node_modules/tinymce/tinymce.min.js'}
      plugins='link table insertdatetime charmap anchor wordcount'
      init={{
        skin: false,
        content_css: false,
        content_style: [contentCSS, contentUiCss].join('\n'),
        
        toolbar: [
          {name: 'history', items: [ 'undo', 'redo' ]},
          {name: 'styles', items: [ 'styleselect' ]},
          {name: 'formatting', items: [ 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']},
          {name: 'indentation', items: [ 'outdent', 'indent' ]}
        ],
        style_formats: [
          { title: 'Headings', items: [
            { title: 'Heading 1', format: 'h1' },
            { title: 'Heading 2', format: 'h2' },
            { title: 'Heading 3', format: 'h3' },
            { title: 'Heading 4', format: 'h4' },
            { title: 'Heading 5', format: 'h5' },
            { title: 'Heading 6', format: 'h6' }
          ]},
          { title: 'Inline', items: [
            { title: 'Bold', format: 'bold' },
            { title: 'Italic', format: 'italic' },
            { title: 'Underline', format: 'underline' },
            { title: 'Strikethrough', format: 'strikethrough' },
            { title: 'Superscript', format: 'superscript' },
            { title: 'Subscript', format: 'subscript' },
            { title: 'Code', format: 'code' }
          ]},
          { title: 'Blocks', items: [
            { title: 'Paragraph', format: 'p' },
            { title: 'Blockquote', format: 'blockquote' },
            { title: 'Div', format: 'div' },
            { title: 'Pre', format: 'pre' }
          ]},
        ],
        language: 'ru',
        menu: {
          file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
          insert: { title: 'Insert', items: 'image link media codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes lineheight | removeformat' },
          tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
          table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
        },
        init_instance_callback: (editor) => {
          editor.setContent(description || ''); // Задает начальное значение и добавляет в историю ненужный шаг инициализации
          editor.undoManager.clear()            // очищает этот шаг
        },
      }}
    />
  );
}

export default ProductEditor;
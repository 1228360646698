/* eslint-disable */
import React, {  } from 'react';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import MyButton from './button/button';

const Playground = () => {
  const get = () => {
    mercheryFetch('ms', 'GET')
    .then((res) => {
      console.log(res)
    })
  }

  return (
    <div>
      <MyButton
        onClick={get}
      >
        get
      </MyButton>
    </div>
  );
}

export default Playground;
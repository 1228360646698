import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SiteLocalStore } from '../../../../../dto/shop.dto';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import { OrderDeliveryContext } from './delivery';

function Pickup () {
  const stores = useAppSelector(state => state.stores);

  const {
    from,
    changeSelectedDelivery,
    setFrom
  } = useContext(OrderDeliveryContext)

	const storeHandler = (store: SiteLocalStore) => {
    closePopup()
    setFrom(store)
    changeSelectedDelivery({
      point_id: null,
      address: store.address,
      cost: 0,
      city: store.city,
      note: null,
    })
  };

  const {RenderButton, RenderPopup, closePopup} = usePopup()
  
  return (
    <div className="op-delivery-pickup">
      <div className="pickup-data">
        <div className="title-div">пункт самовывоза</div>

        <div className='pickup-data__right-side'>
          {from?.city ? 
            <RenderButton 
              removeDefaultClass 
              className={stores.length !== 1 ? 'blue-text text-div' : 'text-div'}
            >
              <span>
                {from.city}&nbsp;
              </span> 
              {from.address} &nbsp;
              {from.worktime}
            </RenderButton> 
          : 
            <RenderButton 
              removeDefaultClass 
              className={'open-widget-btn pickup'}
            >
              Выбрать пункт самовывоза
            </RenderButton>
          }

          <RenderPopup
            className={'pickpoint-tariff'}
          >
            <div className='popup-group'>
              {stores.map((store) => (
                <MyButton 
                  className="delivery-tariff-row popup-element" 
                  key={store.id} 
                  onClick={() => storeHandler(store)}>
                  <div className="delivery-tariff-row-header">{store.city}</div>
                  <div>{store.address} {store.worktime}</div>
                </MyButton>
              ))}
            </div>
          </RenderPopup>
        </div>
      </div>
    </div>
  );
}

export default Pickup
import Tree from 'rc-tree';
import "rc-tree/assets/index.css";
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id } from '../../../dto/master-dto/id.dto';
import ArrowBown from '../../../img/arrow-down.png';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import { Category } from '../products/dto/categories.dto';
import { CategoryFilterChanger } from './categories-filter-and-control';
import { CategoryItem } from './category-item';

interface Props {
  categoryHandler: CategoryFilterChanger,
  addCategory?: (top: Id) => Category | false,
  closePopupParent?: () => void,
  disabledReordering?: boolean,
  disabledConfig?: boolean,
  filtersMod?: boolean
}

function CategoriesTree({
  closePopupParent, 
  categoryHandler,
  addCategory, 
  disabledConfig, 
  disabledReordering,
  filtersMod = false
}: Props) {
  const _isMounted = useRef(true);
  const mountedCategories = useRef<Id[]>([]);
  const categories = useAppSelector(state => state.categories);
  const firstCategory = useMemo(() => categories && categories.find(c => c.top === 0), [categories]);
  const selectedCategory = useAppSelector(state => state.selectedCategory);
  const inlineNameEditing = useAppSelector(state => state.inlineNameChangingCategory);
  const selectedKeys = useMemo(() => selectedCategory ? ['' + selectedCategory.id] : [], [selectedCategory]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([
    '1',
    ...(categories || [])
      .filter(c => c.top === 1)
      .map(c => '' + c.id)
  ]);
  const [loading, setLoading] = useState(false);

  mountedCategories.current = []
  
  const dispatch = useDispatch()
  const setInlineNameEditing = (id: Id | null) => dispatch({ type: 'INLINE_CHANGING_CATEGORY', payload: id})
  const categoriesDispatch = useCallback((items: Category[]) => dispatch({ type: 'CATEGORIES', payload: items}), [dispatch])
  // const categoryDispatch = useCallback((category: Category) => dispatch({type: 'PRODUCT_SELECTED_CATEGORY', payload: category}), [dispatch])

  useEffect(() => {
    _isMounted.current = true
    return () => { _isMounted.current = false }
  }, [])

  const onDrop = (info: NodeDragEventParams<any> & {
    dragNode: EventDataNode<any>;
    dragNodesKeys: Key[];
    dropPosition: number;
    dropToGap: boolean;
  }) => {
    if(!categories) {
      return false
    }

    const dropKey = +info.node.props.eventKey;
    const dragKey = +info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const nodeId = +info.dragNodesKeys[0]
    const dragCategory = categories.find(c => c.id === dragKey)
    const dropTop = categories.find(c => c.id === dropKey)?.top;
    const newTop = dropPosition ? dropTop : dropKey;

    if(!newTop || !dragCategory) return false
    const newTopChildren = categories.filter(c => c.top === newTop);

    const dropIndex = dropPosition ? newTopChildren.findIndex(c => c.id === dropKey) + 1 : 0;

    reorder({id: nodeId, index: dropIndex, top: newTop})

    const changedCategories: Category[] = [...categories.sort((a,b) => {
      if (typeof a.top === 'number' && typeof b.top === 'number') {
        return a.top - b.top || a.order - b.order;
      } else if (typeof a.top === 'string' && typeof b.top === 'string') {
        return a.top.localeCompare(b.top) || a.order - b.order;
      } else {
        return 0;
      }
    })]

    for (const [index, categ] of changedCategories.entries()) {
      let changes = undefined
      if(categ.id === dragKey) {
        changes = { top: newTop, order: dropIndex + 1 }
      } 
      else if(categ.top === dragCategory.top && categ.order > dragCategory.order) {
        changes = { order: categ.order - 1 }
      } 
      else if(newTopChildren.findIndex(c => c.id === categ.id) >= dropIndex) {
        changes = { order: categ.order + 1 }
      }

      if(changes) {
        changedCategories[index] = {...categ, ...changes}
      }
    }

    categoriesDispatch(changedCategories)
  };

  const addCategoryAndInitRename = (top: Id) => {
    if(disabledConfig || addCategory === undefined) 
      return false

    const newCategory = addCategory(top)
    if(newCategory) {
      batch(() => {
        if(expandedKeys.indexOf('' + top) === -1) {
          setExpandedKeys([...expandedKeys, '' + top])
        }
        
        setInlineNameEditing(newCategory.id)
      })
    }
  }

  const reorder = useCallback(async (changes: CategoryReorder) => {
    if(!categories) {
      return false
    }
    setLoading(true)

    return mercheryFetch<Category[]>('category/reorder', 'PATCH', {changes})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      const changedCategories = res.records;
      categoriesDispatch(
        categories.map(c =>
          changedCategories.find(cc => cc.id === c.id) || c
        )
      )

    })
    .finally(() => {
      setLoading(false)
    })
  }, [categories, categoriesDispatch])

  const onExpand = (expandedKeys: Key[]) => {
    batch(() => {
      setExpandedKeys(expandedKeys)
      setAutoExpandParent(false)
    })
  }

  const onDragEnter = (info: NodeDragEventParams<any, HTMLDivElement> & {
    expandedKeys: Key[];
  }) => {
    console.log("enter", info);
    setExpandedKeys(info.expandedKeys)
  };

  const buildTree = (top: Id): DataNode[] => {
    if(!categories) {
      return []
    }

    return categories
      .filter(category => category.top === top)
      .sort((a, b) => a.order - b.order)
      .map(category => ({
        title: <CategoryItem
            category={category}
            addCategory={addCategoryAndInitRename}
            closePopupParent={closePopupParent}
            disabledConfig={disabledConfig}
            filtersMod={filtersMod}
            categoryHandler={categoryHandler}
        />,
        disabled: inlineNameEditing === category.id || category.newCategory,
        key: category.id,
        isLeaf: categories.every(cat => cat.top !== category.id),
        children: buildTree(category.id),
      }));
  };

  let treeData;
  if (firstCategory) {
    treeData = buildTree(firstCategory.top);
  }

  return (
    <div className='categories-tree'>
      <Tree
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        autoExpandParent={autoExpandParent}
        draggable
        disabled={disabledReordering || loading}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        switcherIcon={<img src={ArrowBown} alt='switcher'/>}
        showIcon={false}
        selectedKeys={selectedKeys}
        treeData={treeData}
      />
        {/* {firstCategory && loop(firstCategory)} */}
      {/* </Tree> */}
    </div>
  );
}

export default CategoriesTree;

interface CategoryReorder {
  id: Id, 
  top: Id, 
  index: number
}
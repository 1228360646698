import React from 'react';
import MyButton from '../../../_utility-components/button/button';
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { Id } from '../../../../dto/master-dto/id.dto';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import { ExtendedProduct } from '../dto/products.dto';
import ReactDOM from 'react-dom';
import { validateResponse } from '../../../../scripts/functions';
import {useLoad} from "../../../../scripts/hooks/use-load";

function ProductsDeletePopupRender () {
  const {RenderPopup, RenderButton, closePopup} = usePopup()

  const products = useAppSelector(state => state.products);
  const selectedProductsIds = useAppSelector(state => state.selectedProducts);

  const [, setLoad] = useLoad()
  const dispatch = useDispatch()

  const selectedProductsDispatch = (items: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS', payload: items})

  const selectedProducts = selectedProductsIds.map(sp => products.find(pr => pr.id === sp))

  const popupHeadText = 
    selectedProducts.length === 1 ? 
      'Удаление товара ' + selectedProducts[0]?.name 
    : 'Удаление нескольких товаров';

  const deleteProduct = async () => {    
    setLoad(true)

    mercheryFetch<ExtendedProduct[]>('products', 'DELETE', {
      id: selectedProductsIds
    })
      .then((res) => {
        if(!validateResponse(res) || !res.success) {
          return false
        }
        batch(() => {
          closePopup();
          selectedProductsDispatch([])
        })
      })
      .finally(() => {
        setLoad(false)
      })
  };

  const body = document.body

  return <>
      <RenderButton 
        removeDefaultClass
        className="red-color header-font-s"
      >
        <i className='icofont-trash'></i>
        Удалить
      </RenderButton>

    {ReactDOM.createPortal(
      <RenderPopup
        tabIndexDeep={3}
        className={'confirm-window fixed-on-center'}
        withBlackout
        withCloseBtn
      >
        <h2>{popupHeadText}</h2>
        <span>Подтвердите удаление <b>{selectedProductsIds.length}</b> {selectedProductsIds.length === 1 ? 'товара' : 'товаров'}</span>

        <div className="confirm-window-btn">
          <MyButton
            className={'white-btn'} 
            onClick={closePopup}
          >
            Отменить
          </MyButton>
          <MyButton
            removeDefaultClass
            className="red-btn" 
            onClick={deleteProduct}
          >
            Удалить
          </MyButton>
        </div>
      </RenderPopup>
    , body)}
  </>
}

export default ProductsDeletePopupRender
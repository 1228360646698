import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import Popup from '../../../_utility-components/popup';
import { Client, ClientCreation } from '../dto/client.dto';

function ClientCreate() {
  const _isMounted = useRef(true)
  const [openedPopup, setOpenedPopup] = useState(false);;
  const clients = useAppSelector(state => state.clients);

  const dispatch = useDispatch()
  const clientsDispatch = (client: Client) => dispatch({type: "CLIENTS", payload: [...(clients || []), client]})

  const [newClient, setNewClient] = useState<ClientCreation>({
    name: '',
    email: '',
    phone: '',
  });

  const updateClient = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const labelName = event.target.name;
    const value = event.target.value;

    setNewClient({...newClient, [labelName]: value })
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])
  
  const createClient = () => {
    mercheryFetch<Client>('client', 'POST', {
      createData: newClient
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      setOpenedPopup(false)
      clientsDispatch(res.records)
    })
  }

  return (
    <div>
      <MyButton className='blue-btn'
        onClick={() => setOpenedPopup(true)}
      >
        Добавить покупателя
      </MyButton>

      {openedPopup ? (
        <Popup 
          tabIndexDeep={3}
          withBlackout
          withCloseBtn
          className='fixed-on-center client-create-popup'
          popupClose={() => setOpenedPopup(false)} 
        >
          <h3 className='header-font-l'>
            Добавление покупателя
          </h3>
          <div className='client-page__main-labels'>
            <MyInput myClassName={'grid-element'}
              name={'name'}
              required={false}
              onChange={updateClient}
              onEnter={createClient}
              value={newClient.name}
            >
              <h5 className="char-label__header header-font-s">ФИО</h5>
            </MyInput>

            <MyInput myClassName={'grid-element'}
              name={'email'}
              required={false}
              onChange={updateClient}
              onEnter={createClient}
              value={newClient.email}
            >
              <h5 className="char-label__header header-font-s">Email</h5>
            </MyInput>

            <MyInput myClassName={'grid-element'}
              name={'phone'}
              required={false}
              onChange={updateClient}
              onEnter={createClient}
              value={newClient.phone}
            >
              <h5 className="char-label__header header-font-s">Телефон</h5>
            </MyInput>
          </div>

          <div className='client-create-popup__btn-wrapper'>
            <MyButton className='blue-btn'
              onClick={createClient}
            >
              Сохранить
            </MyButton>

            <MyButton className='white-btn'
              onClick={() => setOpenedPopup(false)}
            >
              Отменить
            </MyButton>
          </div>
        </Popup>
      ) : null}
    </div>
  );
}

export default ClientCreate;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../../../scripts/fetchConstructor';
import { getObjectsDiffsByFields, querify, uuidv4, validateResponse } from '../../../../../../scripts/functions';
import { Mutable } from '../../../../../../scripts/pre-type/mutable';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../../../scripts/hooks/use-popup';
import { useTabIndex } from '../../../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../../../_utility-components/button/button';
import MyInput from '../../../../../_utility-components/input/index';
import SearchInput from '../../../../../_utility-components/search-input/search-input';
import { Client } from '../../../../clients/dto/client.dto';
import { Order } from '../../../dto/order.dto';
import { OrderChangeHandler, OrderContext } from '../../../order-page';

interface EditClientInOrderProps {
    order: Order,
    changeData: OrderChangeHandler,
}

export function EditClientInOrder({
  order,
  changeData,
}: EditClientInOrderProps) {
  const _isMounted = useMounted()
  const clients = useAppSelector(state => state.clients)
  const orders = useAppSelector(state => state.orders)
  const { RenderButton, RenderPopup, closePopup, isOpen } = usePopup();
  const tabIndexLevel = 3
  const tabIndex = useTabIndex(tabIndexLevel);
  const searchInput = useAppSelector(state => state.clientSearchInput);

  const {
    orderDeleted
  } = useContext(OrderContext)

  const orderClient = order && clients && clients.find(c => c.id === order.client_id) || undefined
  
  const defaultClientData = useMemo(() => ({
    id: orderClient?.id || null,
    name: orderClient?.name || '',
    email: orderClient?.email || '',
    phone: orderClient?.phone || '',
    address: orderClient?.address || '',
    newClient: orderClient?.id ? false : true
  } as const), [orderClient])

  const [clientData, setClientData] = useState<Mutable<typeof defaultClientData>>(defaultClientData);
  const [searchedClients, setSearchedClients] = useState<Client[]>([]);
  
  useEffect(() => {
    if(isOpen) {
      getOrderClient()
    }
  }, [isOpen])
  
  useEffect(() => {
    setClientData(defaultClientData)
  }, [defaultClientData])

  const getOrderClient = () => {
    if(!order || !order.client_id) {
      return false
    }
    
    const query = querify({filters: {id: order.client_id}})

    mercheryFetch<Client[]>(`client?${query}`, 'GET')
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      clientsDispatch(res.records[0])
    })
  }

  const dispatch = useDispatch()
  const clientsDispatch = (client: Client) => dispatch({
    type: 'CLIENTS',
    payload: clients && clients.some(c => c.id === client.id) ?
      clients.map(c => c.id === client.id ? client : c)
    : [...(clients || []), client]
  });
  const orderClientDispatch = (client: Client) => order && dispatch({
    type: 'ORDERS',
    payload: orders.map(item => 
      item.id === order.id ? ({
        ...item,
        client: client,
      }) 
      : item
    ),
  }) 
  const setSearchText = (search: string) => dispatch({
    type: 'CLIENTS_SEARCH_INPUT_VALUE',
    payload: search
  });

  const saveChanges = async () => {
    const {newClient, ...clientDataChanges} = clientData;
    const changes = getObjectsDiffsByFields(clientDataChanges, orderClient, Object.keys(defaultClientData))
    const clientDataChanged = order && clientData.id && order.client_id && Object.values(changes).length;
    const newClientCreated = newClient;
    const orderClientIdChanged = order && order.client_id !== clientData.id;

    let ok = true;

    console.log(clientDataChanged)
    console.log(newClientCreated)
    console.log(orderClientIdChanged)

    if(clientDataChanged) {
      ok = await updateClientData()
    }

    if(newClientCreated && ok) {
      ok = await createClient()
    }

    if(orderClientIdChanged && ok) {
      ok = await replaceClientInOrder()
    }

    if(ok) {
      closePopup();
    }
  };

  const replaceClientInOrder = () => {
    return changeData({
      client_id: clientData.id
    })
  }

  const updateClientData = async () => {
    const {newClient, id, ...clientDataChanges} = clientData;
    const diffs = getObjectsDiffsByFields(clientDataChanges, orderClient, Object.keys(defaultClientData))
    
    if(!id) {
      return false
    }

    const patchRes = await mercheryFetch<Client>('client', 'PATCH', { 
      changes: [{
        ...diffs,
        id: id,
      }]
    })

    if(!_isMounted.current || !validateResponse(patchRes)) {
      return false
    }

    const updatedOrderClient = patchRes.records

    batch(() => {
      changeData({client_id: updatedOrderClient.id})
      clientsDispatch(updatedOrderClient)
      orderClientDispatch(updatedOrderClient)
    })

    return true
  }

  const clientDataHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: keyof typeof defaultClientData) => {
    const value = e.target.value;

    setClientData({ 
      ...clientData, 
      [label]: value 
    });
  };

  const getClientsWithFilters = () => {
    if(!searchInput) {
      return false
    }

    const query = querify({search: searchInput})

    mercheryFetch<Client[]>(`client?${query}`, 'GET')
    .then((res) => {
      if(_isMounted.current && validateResponse(res))
        setSearchedClients(res.records)
    })
  }

  useEffect(() => {
    getClientsWithFilters()
  }, [searchInput])

  const clearClientData = () => {
    setClientData({
      id: null,
      name: '',
      email: '',
      phone: '',
      address: '',
      newClient: false
    })
  }

  const createClient = async () => {
    const createRes = await mercheryFetch<Client>('client', 'POST', {
      createData: clientData
    })

    if(!_isMounted.current || !validateResponse(createRes)) 
      return false;

    const updatedOrderClient = createRes.records;
    
    batch(() => {
      changeData({client_id: updatedOrderClient.id})
      clientsDispatch(updatedOrderClient)
      orderClientDispatch(updatedOrderClient)
    })
    return false
  }

  const selectedExistClient = (client: Client) => {
    const {id, name, phone, email, address} = client;

    batch(() => {
      setClientData({
        id,
        name,
        phone,
        email,
        address,
        newClient: false
      })
      setSearchText('')
    })
  }

  const initCreation = () => {
    const newClientData = clientData.id ? {
      ...clientData,
      name: searchInput || clientData.name,
      newClient: false
    } : {
      id: uuidv4(),
      name: searchInput || '',
      phone: '',
      email: '',
      address: '',
      newClient: true
    } 

    batch(() => {
      setClientData(newClientData)
      setSearchText('')
    })
  }

  if(!order || orderDeleted || orderClient?.deletedAt || order.client?.deletedAt) {
    return null
  }

  return (
    <>
      <RenderButton
        removeDefaultClass
        className="order-page-side-panel-edit-btn hide-for-print"
      >
        <i className="icofont-ui-edit"></i>
      </RenderButton>

      <RenderPopup
        className={'client-edit'}
        withBlackout
        withCloseBtn
        tabIndexDeep={3}
      >
        <div className="client-edit-header header-font-xl client-edit__content--padding">
          Покупатель
        </div>

        <div className='client-edit_search-inner client-edit__content--padding'>
          <SearchInput 
            searchInput={searchInput} 
            applySearch={setSearchText}
            />
        </div>

        {searchInput.length ? 
          <div className='searched-clients__wrapper'>
            {searchedClients.map((client => 
              <MyButton key={client.id}
                onClick={() => selectedExistClient(client)}
                className='searched-client searched-client--clickable client-edit__content--padding'
                removeDefaultClass
              >
                <div className='searched-client__name'>
                  {client.name}
                </div>
                <div className='searched-client__phone'>
                  {client.phone}
                </div>
                <div className='searched-client__email'>
                  {client.email}
                </div>
              </MyButton>
              ))}

            {searchInput.length && !searchedClients.length ?
              <div className='searched-client client-edit__content--padding'>
                Покупателей не найдено
              </div>
            : null}

            {searchInput.length ?
              <MyButton
                tabIndexLevel={tabIndexLevel}
                removeDefaultClass
                className='text-link client-edit__create-new searched-client--clickable client-edit__content--padding'
                onClick={initCreation}
              >
                Создать нового покупателя
              </MyButton>
            : null}
          </div>
        :
          <div className="client-edit-inputs-container client-edit__content--padding">
            <div className='client-edit-inputs__header'>
              <div className='header-font-m'>Данные покупателя</div>
              {order.client_id ? 
                <MyButton 
                  tabIndexLevel={tabIndexLevel}
                  removeDefaultClass 
                  className="text-link"
                  onClick={clearClientData}
                >
                  Очистить
                </MyButton> 
              : null}
            </div>

            <MyInput
              name="name"
              placeholder="ФИО"
              onChange={(e) => clientDataHandler(e, 'name')}
              value={clientData.name}
              withBind='confirm-edit'
              tabIndex={tabIndex} 
              />

            <MyInput
              name="phone"
              placeholder="Телефон"
              value={clientData.phone}
              onChange={(e) => clientDataHandler(e, 'phone')}
              withBind='confirm-edit'
              required={false}
              tabIndex={tabIndex} 
              />

            <MyInput
              name="email"
              placeholder="Email"
              value={clientData.email}
              onChange={(e) => clientDataHandler(e, 'email')}
              withBind='confirm-edit'
              required={false}
              tabIndex={tabIndex} 
              />

            <MyInput
              name="address"
              placeholder="Адрес"
              value={clientData.address}
              onChange={(e) => clientDataHandler(e, 'address')}
              withBind='confirm-edit'
              required={false}
              tabIndex={tabIndex} 
              />
          </div>
        }

        {searchInput.length ? null :
          <div className='client-edit-footer client-edit__content--padding'>
            <div></div>

            <div className='client-edit-footer__right'>
              <MyButton
                tabIndexLevel={tabIndexLevel}
                className="white-btn"
                onClick={closePopup}>
                Отменить
              </MyButton>

              <MyButton
                id="confirm-edit"
                className="blue-btn"
                tabIndexLevel={tabIndexLevel}
                onClick={saveChanges}>
                Сохранить
              </MyButton>
            </div>
          </div>
        }
      </RenderPopup>
    </>
  );
}

import { dateToRuLocale } from "src/scripts/utils/date-to-locale";
import { Admin } from "../../../../dto/admin.dto";
import { OrderStatus } from "../../../../dto/order-status.dto";
// import { PaidStatus } from "../../../../dto/paid-items";
import { Paymethod } from "../../../../dto/paymethod.dto";
import { Delivery } from "../dto/delivery";
import { Order } from "../dto/order.dto";

interface Props {
  order: Order
  statuses: OrderStatus[] | null
  deliveries: Delivery[]
  paymethod: Paymethod[]
  admins: Admin[] | null
  // paidStatuses: PaidStatus[],
  // ordersCount: number
}

export const renameExportHeaders = ({
  order,
  statuses,
  deliveries,
  paymethod,
  admins,
  // paidStatuses,
  // ordersCount,
}: Props): any => {
  const status = statuses && statuses.find(s => s.id === order.status);
  const delivery = order.deliveries?.find(d => d.id === order.current_delivery_id);
  const deliveryService = delivery && deliveries.find(d => d.id === delivery.type);
  const orderPaymethod = paymethod.find(s => s.id === order.status);
  const admin = admins && admins.find(s => s.id === order.status);
  // const paid = paidStatuses.find(paid => paid.id === order.paid);

  return {
    // newObj['получатель_не_покупатель':obj.another_recipient === 'Y' ? "Да" : "Нет"
    'Дата создания': dateToRuLocale(order.date),
    'Id': order.id,
    'Статус заказа': status?.name || '',
    'Клиент': order.client?.name || '',
    'Номер телефона': order.client?.phone || '',
    'Email': order.client?.email || '',
    'Адрес получателя': order.client?.address || '',
    'Город получения': delivery?.city || '',
    'Почтовый индекс': delivery?.postcode || '',
    'Служба доставки': deliveryService?.name,
    'Пункт выдачи': delivery?.point_id || '',
    'Адрес пункта выдачи': delivery?.address || '',
    'Сроки доставки': delivery?.term  || '',
    'Накладная': delivery?.note || '',
    'Способ оплаты': orderPaymethod?.name || '',
    'Менеджер': admin?.name || '',
    // 'Оплачено': paid?.name || '',
    'Стоимость доставки': delivery?.cost || '',
    'Скидка': order.discount,
    'Сумма': order.price,
    'Дата изменения': order.modified || '',
    'Комментарий': order.comment,
    "Оборот клиента": order.client?.ordersTotal,
    // "Заказы клиента": ordersCount,
    "Возвраты клиента": order.client?.refundTotal,
    "Выкуп клиента": order.client?.buyout,
  }
}
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CategoryId, Id } from '../../../../../dto/master-dto/id.dto';
import { uuidv4 } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyButton from '../../../../_utility-components/button/button';
import { CharGroupsDto } from '../../dto/chars.dto';

interface Props {
  categoryId: CategoryId,
  charEditingId: Id | null
  setCharEditingId: (id: Id | null) => void
}
function CharsBottom({
  categoryId,
  charEditingId,
  setCharEditingId,
}: Props) {
  const charGroupsReducerType = 'CHARS_GROUPS';
  const charGroups = useAppSelector( state => state.charGroups );

  const dispatch = useDispatch();

  const addNewCharGroups = useCallback(() => {
    if(!categoryId) {
      return false
    }
    
    const newCharGroup: CharGroupsDto = {
      id: uuidv4(),
      category_id: categoryId,
      name: '',
      newGroup: true,
    }

    setCharEditingId(newCharGroup.id)
    dispatch({
      type: charGroupsReducerType, 
      payload: [...charGroups, newCharGroup]
    })
  } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [charGroups, categoryId])

  // if(!options || !options.length) {
  //   return null
  // }
  return (
    <div className='characteristics-bottom side-padding-24'>
      <div className='characteristics-bottom__left-side'>
        <MyButton className={'characteristics-bottom__btn header-font-m'}
          onClick={addNewCharGroups}
          removeDefaultClass
          disabled={charEditingId !== null}
        >
          <i className="icofont-plus"></i> Добавить группу характеристик
        </MyButton>
      </div>

      {/* <div
        className='characteristics-bottom__right-side'>
        <MyButton className={'characteristics-bottom__btn header-font-m'}>
          Сохранить шаблон
        </MyButton>
      </div> */}
    </div>
  );
}

export default CharsBottom;
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SiteTariff } from "../../dto/site-tariff";
import { mercheryFetch } from "../fetchConstructor";
import { validateResponse } from "../functions";
import { useAppSelector } from "../pre-type/use-selector";
import useMounted from "./use-mounted";

export type TariffMode = 'month' | 'halfYear' | 'year'

type CurrentTariffData = false | {
  name: string;
  price: number;
  mode: TariffMode;
  storageLimit: number;
};

function useCurrentTariff(tariffId: number | null, tariffMode: TariffMode | null): CurrentTariffData {
  const _isMounted = useMounted()
  const tariffs = useAppSelector(state => state.siteTariffs)

  const dispatch = useDispatch()
  const tariffsDispatch = (tariffs: SiteTariff[]) => dispatch({ type: 'SITE_TARIFFS', payload: tariffs })

  useEffect(() => {
    if(!tariffs) {
      mercheryFetch<SiteTariff[]>('site/tariffs', 'GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }
  
        tariffsDispatch(res.records)
      })
    }
  }, [tariffs])

  const currentTariff = tariffs && tariffs.find((tariff) => tariff.id === tariffId)

  if(!tariffs || !currentTariff || !tariffId || !tariffMode) {
    return false
  }

  return {
    name: currentTariff.name,
    price: currentTariff[tariffMode],
    storageLimit: currentTariff.file_storage_limit,
    mode: tariffMode
  }
}

export default useCurrentTariff;
import {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../scripts/web.socket";
import {useAppSelector} from "../scripts/pre-type/use-selector";
import {batch, useDispatch} from "react-redux";
import {updateDispatch} from "./dispatches";
import {WsResponse} from "../dto/ws-responses/ws-response.dto";
import {
	createProduct,
	deleteProduct, reorderProduct,
	updateProduct
} from "../constants/ws-events";
import {
	CreateProductsWsDto,
	DeleteProductsWsDto,
	ProductChange, ReorderProductsWsDto,
	UpdateProductsWsDto
} from "../dto/ws-responses/products.dto";
import {ExtendedProduct} from "../components/main-pages/products/dto/products.dto";
import sortExtendedProducts from "../components/main-pages/products/products.utils";
import { ProductsTableContext } from "src/components/main-pages/products/products-page-modules/table";

const productsDispatchType = 'PRODUCTS';

function useProductsChangesListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);
	const {
		newProducts,
    setNewProducts
	} = useContext(ProductsTableContext);

	const products = useAppSelector(state => state.products);

	const [changesInQueue, setChangesInQueue] = useState<(ReorderProductsWsDto | CreateProductsWsDto | DeleteProductsWsDto)[]>([]);

	const dispatch = useDispatch()

	const reorderProductsDispatch = (products: ExtendedProduct[]) =>
		dispatch({ type: productsDispatchType, payload: products })
	const updateProductsDispatch = updateDispatch(products, dispatch, productsDispatchType);

	const applyRenderChanges = (changes?: typeof changesInQueue) => {
		let reorderedProducts: ExtendedProduct[] = [...products];
    const newProductsShallowCopy = new Set(newProducts)

		if(!changes?.length) {
			changes = changesInQueue
		}

		changes.forEach((data) => {
			if('product' in data) {
				reorderedProducts.push(data.product)
        newProductsShallowCopy.add(data.product.id)
			} else if('changes' in data) {
				data.changes.forEach((productReorderWs) => {
					const productIndex = reorderedProducts.findIndex(product => product.id === productReorderWs.id);

					if (productIndex !== -1) {
						reorderedProducts[productIndex] = {
							...reorderedProducts[productIndex],
							...productReorderWs
						}
					}
				})
			} else {

				reorderedProducts = reorderedProducts.filter(
					product => Array.isArray(data.id)
						? !data.id.some(id => product.id === id)
						: product.id !== data.id
				)
			}
		})

		batch(() => {
      setNewProducts(newProductsShallowCopy)
      reorderProductsDispatch(sortExtendedProducts(reorderedProducts))
			setChangesInQueue([]);
		})
	}


	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<ReorderProductsWsDto>;

			if (message.event === reorderProduct &&
				message.data.changes &&
				message.data.initiatorId
			) {
				const currentAdminId = localStorage.getItem('admin')
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateProductsWsDto>;
			const changes = message.data.changes;

			if (message.event === updateProduct &&
				message.data.changes
			) {
				updateProductsDispatch<ProductChange>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteProductsWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === deleteProduct &&
				message.data.id
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateProductsWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === createProduct &&
				message.data.product
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	return [changesInQueue, applyRenderChanges] as const;
}

export default useProductsChangesListener
import React, { useState, HTMLAttributes } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import MyButton from '../../../../../_utility-components/button/button';
import { ExtendedProduct } from '../../../dto/products.dto';
import { ProductVariantExtended } from '../../../dto/variants.dto';
import ProductsVariantsTableRows from '../table-variants-rows';
import CellCheckbox from './checkbox';
import OpExternalId from './op-external-id';
import OpImage from './op-image';
import { OpRemains, OpTotal } from './op-remains-or-total';
import OpTitle from './op-title';
import VariantsShowMoreBtn from './variants-show-more-btn';
import ReactTooltip from "react-tooltip";

export function itemIsVariant (item: ExtendedProduct | ProductVariantExtended): item is ProductVariantExtended {
  return (item as ProductVariantExtended).product_id !== undefined
}

function Row <T extends ExtendedProduct | ProductVariantExtended>({
  product,
  provided,
  checked,
  changeData,
  productCheckHandler,
  wrapperProps,
}: {
  product: T,
  provided?: DraggableProvided,
  checked: boolean,
  changeData: <T extends ExtendedProduct | ProductVariantExtended>
    (id: Id, changes: T extends ExtendedProduct ? Partial<ExtendedProduct> : Partial<ProductVariantExtended>) => void,
  productCheckHandler: (item: T) => void
  wrapperProps: HTMLAttributes<HTMLDivElement>,
}) {
  const [changes, setChanges] = useState<Partial<T>>({});
  const [showVariants, setShowVariants] = useState(false);
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);

  const isVariant = itemIsVariant(product)

  const confirmInlineChange = (label: keyof T) => {
    const {[label]: changedLabel} = changes;
    if(changedLabel && changedLabel != product[label]) {
      changeData<T>(product.id, {[label]: changedLabel});
    }
  }
  const showVariantsRows = !isVariant && showVariants && product.variants && product.variants.length;
  const productShowChanger = !moySkladIntegrationOn || 'product_id' in product;
  const productHasVariants = !isVariant && product.variants && product.variants.length;

  return (
    <>
      <div {...wrapperProps}>

        {!isVariant 
          ? <CellCheckbox
            provided={provided}
            checked={checked}
            productCheckHandler={() => productCheckHandler(product)}
          /> 
          : <div></div>}

        <OpImage images={product.src}/>

        <OpTitle product={product}/>
        
        {productHasVariants ? 
          <VariantsShowMoreBtn
            product={product}
            showVariantsHandler={() => setShowVariants(!showVariants)} 
            showVariants={showVariants}
            />
        : <>
          <OpExternalId external_id={product.external_id}/>
          <OpRemains
            product={product}
            changes={changes}
            setChanges={setChanges}
            confirmInlineChange={confirmInlineChange}
          />
          {/* <OpWeight weight={el.weight}/> */}
          <OpTotal
            product={product}
            changes={changes}
            setChanges={setChanges}
            confirmInlineChange={confirmInlineChange}
          />
        </>}
        {productShowChanger ?
          <div className="op-config">
            <HideableEntityHideBtn
              changeData={changeData}
              entity={product as ExtendedProduct}
            />
          </div>
        : null}
      </div>

      {showVariantsRows ?
        <div 
          className='product-variants-rows-wrapper'>
          <ProductsVariantsTableRows
            product={product}
          />
        </div>
      : null}
    </>
  )
}

export default Row;

export function HideableEntityHideBtn<T extends {id: Id, show_date: number | null}>({
  changeData,
  entity
}: {
  changeData: (id: Id, changes: {show_date: number | null}) => void;
  entity: T;
}) {

  const showProduct = entity.show_date ? new Date(entity.show_date).getTime() < new Date().getTime() : null;
  const hided = showProduct === null;
  const planned = !showProduct;

  if(planned && entity.show_date) {
    const showDate = new Date(entity.show_date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    } as const;

    const dateString = showDate.toLocaleString('ru-RU', options);

    return <MyButton
      onClick={e => {
        e.stopPropagation();
        changeData(entity.id, { show_date: null });
      }}
    >
      <i className={'icofont-sand-clock'}
        data-tip={dateString}
        data-for={`product-show-${entity.id}`}
      ></i>

      <ReactTooltip
        id={`product-show-${entity.id}`}
        resizeHide={true}
        place={'left'}
        multiline={true}
        effect={"solid"}
        isCapture={true}
      />
    </MyButton>
  }

  return (
    <MyButton
      name={(showProduct) ? 'hide-product' : 'show-product'}
      onClick={e => {
        e.stopPropagation();
        changeData(entity.id, { show_date: hided ? new Date().getTime() : null });
      }}
      title={!hided || !planned ? 'Показать' : 'Скрыть'}
    >
      {!hided ?
        <i className="icofont-eye"></i>
        : <i className="icofont-eye-blocked"></i>}
    </MyButton>
  )
}

import React, { useContext } from 'react';
import { batch } from 'react-redux';
import MyButton from 'src/components/_utility-components/button/button';
import Popup from 'src/components/_utility-components/popup';
import TransferComplete from 'src/img/transfer_complete.svg'
import { ProductsTableContext } from './table';

function MultiChangeCompletePopup() {
  const {
    multiProductsActionCompletedParams,
    setMultiProductsActionCompletedParams,
    applyFilters,
  } = useContext(ProductsTableContext);

  if(multiProductsActionCompletedParams === null) {
    return null
  }

  const {
    filters,
    completeText,
  } = multiProductsActionCompletedParams;

  const popupClose = () => {
    setMultiProductsActionCompletedParams(null)
  }
  
  return (
    <Popup
      className={`confirm-popup fixed-on-center multi-products_change-complete_popup`}
      tabIndexDeep={3}
      withCloseBtn
      popupClose={popupClose}
    >
      <div className='multi-products_change-complete_popup_inner'>
        <img src={String(TransferComplete)} alt={'complete'} style={{
          width: 126,
          height: 105,
        }}/>
        <div className='header-font-xl'>{completeText}</div>
      </div>

      <div className="flex-wrapper confirm-popup_footer--with-btns">
        <MyButton
          className={'white-btn'}
          onClick={popupClose}
        >
          Закрыть окно
        </MyButton>

        <MyButton
          removeDefaultClass
          className="blue-btn"
          onClick={() => {
            batch(() => {
              applyFilters(...filters);
              popupClose()
            })
          }}
        >
          Показать товары
        </MyButton>
      </div>
    </Popup>
  );
}

export default MultiChangeCompletePopup;
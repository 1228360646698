import React, { memo, useEffect, useRef, useState } from 'react';
import {useDispatch} from 'react-redux';

import { bodyOnMouseDown, bodyOffBoundFunc, getCoords, bodyOnMouseOut, bodyOnEscEnter } from '../../scripts/functions';
import MyButton from './button/button';
import close from '../../img/close.svg'
import { TabIndex } from '../../dto/master-dto/tabindex.dto';
import { useAppSelector } from '../../scripts/pre-type/use-selector';

export interface PopupProps {
  popupClose: () => void,
  children: React.ReactNode

  className?: string,
  popupName?: string,
  withBlackout?: boolean,
  propBodyOnMouseOut?: string,
  tabIndexDeep?: number,
  withCloseBtn?: boolean,
  popupParentClose?:() => void,
  popupParent?: string,
  changingDirection?: boolean
}

function Popup (props: PopupProps) {
  const {
    popupName, 
    withBlackout, 
    propBodyOnMouseOut, 
    popupClose, 
    tabIndexDeep, 
    children, 
    withCloseBtn, 
    popupParentClose,
    popupParent,
    className,
    changingDirection,
  } = props;

  const popup = useRef<HTMLDivElement>(null);
  const prevTabIndexLevel = useRef(1)

  const [direction, setDirection] = useState<'up' | 'down' | null>(null);
  const [curTabIndexLevel, setCurTabIndexLevel] = useState(1);
  const tabIndexLevel = useAppSelector(state => state.tabIndexLevel)
  const load = useAppSelector(state => state.load)
  const tabIndex: TabIndex = tabIndexLevel === curTabIndexLevel && !load ? 0 : -1;

  const dispatch = useDispatch()
  const changeTabLevel = (tabLabel: number) => dispatch({type: 'TAB_LEVEL', payload: tabLabel})
  const setBlackout = (blackout: boolean) => dispatch({type: 'BLACKOUT', payload: blackout})

  const calcDirection = () => {
    if(popup.current && changingDirection) {
      const windowBottom = window.scrollY + window.innerHeight,
      popupBox = getCoords(popup.current),
      upsideDown = windowBottom - popupBox.bottom,
      defaultDirection = popupBox.top - popupBox.height - window.scrollY;

      const newDirection = upsideDown < defaultDirection ? 'up' : 'down';
      return newDirection
    } else {
      return 'down'
    }
  }

  if(changingDirection) {
    const newDirection = calcDirection()
    if(newDirection !== direction) {
      setDirection(newDirection)
    }
  }

  useEffect(() => {
    const thisPopup = (popupName || '') + '.general-popup';
    if(withBlackout) setBlackout(true);

    prevTabIndexLevel.current = tabIndexLevel || 1;
    setCurTabIndexLevel(tabIndexDeep || 1)
    changeTabLevel(tabIndexDeep || 1);

    if(propBodyOnMouseOut) bodyOnMouseOut(popupClose, thisPopup, propBodyOnMouseOut);
    else bodyOnMouseDown(popupClose, thisPopup);

    bodyOnEscEnter(popupClose);

    calcDirection()

    return () => {
      if(withBlackout) setBlackout(false);
      changeTabLevel(prevTabIndexLevel.current);
  
      if(popupParent && popupParentClose) {
        bodyOffBoundFunc(() => {
          popupClose();
          const thisPopup = popupParent ? popupParent + '.general-popup' : '.general-popup';
  
          bodyOnMouseDown(popupParentClose, thisPopup);
          bodyOnEscEnter(popupParentClose);
        });
      } else bodyOffBoundFunc();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const localButton = withCloseBtn ?
    <MyButton
      tabIndex={tabIndex}
      className="close-btn"
      onClick={popupClose}>
      <img alt="" src={String(close)}/>
    </MyButton>
  : null;

  let combinedClassName = `general-popup ${className || ''} ${direction === 'up' ? 'general-popup-upside-down' : ''}`;

  return (
    <div className={combinedClassName}
      ref={popup}
      style={{
        zIndex: tabIndexLevel,
      }}
    >
      {children}
      {localButton}
    </div>
  );
}

export default memo(Popup);
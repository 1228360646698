import React from 'react';
import { dateToRuLocale } from 'src/scripts/utils/date-to-locale';
import { Order } from '../../dto/order.dto';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  order: Order
}

export function OrderRowDate({
  order
}: Props) {
  return <BodyCell myClassName="tt-date">
    <>{dateToRuLocale(order.date)}</>
  </BodyCell>;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from '../../../../../_utility-components/button/button';
import Popup from '../../../../../_utility-components/popup';
import { CharGroupsDto } from '../../../dto/chars.dto';

interface Props {
  saveHandler: () => any | Promise<any>,
  popupClose: () => void,
  group: CharGroupsDto,
}

const CharGroupDeletePopup = ({ 
  saveHandler,
  popupClose,
  group,
}: Props) => {
  const groupName = group.name;
  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);

  const categoryName = useMemo(() => 
    categories.find(c => c.id === group.category_id)?.name
  , [group, categories]);

  const extendedSaveHandler = () => {
    saveHandler();
    popupClose();
  };

  return (
    <Popup
      className={'confirm-popup fixed-on-center'}
      popupClose={popupClose}
      tabIndexDeep={3}
      withCloseBtn
      withBlackout
    >
      <h3 className='confirm-popup__h header-font-xl'>
        Подтвердите удаление шаблона
      </h3>

      <div className='confirm-popup__body'>
        <div className='confirm-popup__paragraph'>
          Шаблон группы характеристик «<b>{groupName}</b>» связан с категорией товаров «<b>{categoryName}</b>».
        </div>

        <div className='confirm-popup__paragraph'>
          Шаблон характеристик будет <b>удален</b> для всей категории товаров, 
          а значения характеристик будут удалены для всех товаров, 
          расположенных в категории «<b>{categoryName}</b>» и ее дочерних категориях.
        </div>

        <div className='confirm-popup__paragraph'>
          Вы уверены, что хотите продолжить?
        </div>
      </div>

      <div className='confirm-popup__btn-wrapper'>
        <MyButton className={'white-btn width-228'}
          onClick={popupClose}
        >
          Назад
        </MyButton>

        <MyButton className={`red-btn width-228`}
          onClick={extendedSaveHandler}
        >
          Да, сохранить изменения
        </MyButton>
      </div>
    </Popup>
  )
}

export default CharGroupDeletePopup;

import React, { useContext } from 'react';
import { calcPrice, priceToPennies } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyCheckbox from '../../../../_utility-components/checkbox/my-checkbox';
import { ProductItem } from '../../../products/dto/product-item.dto';
import { RefundDto } from '../../refunds/dto/refund.dto';
import ProductRowToBeRefunded from './refund-product-item-row';
import { RefundStartContext } from './refund-start';
import HeaderCell from "../../../../_utility-components/common-table/header-cell";

function useItemsSortingAndFiltering(): ProductItem[] {
  const items = useAppSelector(state => state.productItemsInContext);
  const refunds = useAppSelector(state => state.refunds);

  return (
    items
      .sort(itemsSorting)
      .flatMap(item => itemsFilter(item, refunds))
  )
}

export function OrderItemsToRefund() {

  const { selectedItems, setSelectedItems } = useContext(RefundStartContext)

  const items = useItemsSortingAndFiltering()

  const allSelected = Boolean(selectedItems.length && items.length === selectedItems.length)

  const headerCheckHandler = () => {
    if(allSelected) {
      setSelectedItems([])
    } else {
      setSelectedItems(
        items.map(item => 
          selectedItems.find(selected => selected.id === item.id) || 
          ({
            ...item, 
            refundCount: 1, 
            refundTotal: priceToPennies(+calcPrice([{price: item.price, count: 1}]))
          })
        )
      )
    }
  }

  return <div className="refund-registration__items">

    <div className="refund-registration__items-header wide-text-font-xxs row-item">
      <HeaderCell 
        children={
          <MyCheckbox text={''} 
            condition={allSelected}
            actionHandler={headerCheckHandler} />
        }/>
      <HeaderCell children={'Фото'} className="center-align" />
      <HeaderCell children={'товар'} />
      <HeaderCell children={'заказано'} />
      <HeaderCell children={'к возврату'} />
      <HeaderCell children={'Сумма'} />
    </div>

    <div className="refund-registration__items-wrapper">
      {items
      .map((item) => 
        <ProductRowToBeRefunded
          key={item.id}
          item={item}
        /> 
      )}
    </div>

  </div>
}

const itemsSorting = (a: ProductItem, b: ProductItem) =>
  (new Date(a.created).getMilliseconds()) -
  (new Date(b.created).getMilliseconds());

const itemsFilter = (item: ProductItem, refunds: RefundDto[] | null): ProductItem[] | [] => {
  let totalReturnedCount = 0;
  if(!refunds) {
    return []
  }
  refunds.forEach(refund => {
    refund.returned_items.forEach(returnedItem => {
      if (returnedItem.id === item.id) {
        totalReturnedCount += returnedItem.count;
      }
    });
  });
  const refundableQuantity = item.count - totalReturnedCount;
  if (refundableQuantity > 0) {
    return [{ ...item, count: refundableQuantity }];
  } else {
    return [];
  }
}
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import useInfiniteScrollShowMore from 'src/scripts/hooks/use-infinite-scroll-show-more';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { uniqByKeepLast, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import SearchInput from '../../../_utility-components/search-input/search-input';
import AddItemsLoader from '../../../_utility-components/loaders/add-items-loader';
import Popup from '../../../_utility-components/popup';
import CategoriesFilterAndControl from '../../categories/categories-filter-and-control';
import { Category } from '../../products/dto/categories.dto';
import { ExtendedProduct } from '../../products/dto/products.dto';
import { ProductOption } from '../../products/product-page-modules/dto/options.dto';
import { AddItemsFooter } from './items-in-order-footer';

export interface Props <T>{
  popupClose: () => void, 
  addItems: (items: T[]) => Promise<boolean>, 
  filters: Partial<ExtendedProduct>,
  withTotalSum?: true,
}

function withAddItemPopup<T>(WrappedComponent: React.ComponentType<{
  product: ExtendedProduct,
  canBeAddedSelected: T[],
  setCanBeAddedSelected: (items: T[]) => void,
}>) {
  return (props: Props<T>) => {
    const {
      popupClose, 
      addItems, 
      withTotalSum,
      filters: defaultFilters,
    } = props;
    const _isMounted = useMounted();
    const itemsContainer = useRef(null);

    const products = useAppSelector(state => state.products);
    const productOptions = useAppSelector(state => state.productOptions);

    const categories = useAppSelector(state => state.categories || []);
    const selectedCategory = useAppSelector(state => state.selectedCategory);

    const currentProductsCount = useAppSelector(state => state.currentProductsCount);
    const allProductsCount = useAppSelector(state => state.allProductsCount);
    
    const tabIndex = useTabIndex(2);
    const { RenderButton, RenderPopup, closePopup, } = usePopup();

    const [searchInput, setSearchInput] = useState('');
    const [canBeAddedSelected, setCanBeAddedSelected] = useState<T[]>([]);

    const productsItemsGetSize = 25;

    const dispatch = useDispatch()
    const categoryDispatch = (category: Category | undefined) => dispatch({type: 'PRODUCT_SELECTED_CATEGORY', payload: category})
    const productsDispatch = useCallback((products: ExtendedProduct[]) => dispatch({ type: 'PRODUCTS', payload: products}), [dispatch])
    const productsCountDispatch = useCallback((count: number) => dispatch({ type: 'PRODUCTS_COUNT', payload: count}), [dispatch])
    const allProductsCountDispatch = useCallback((count: number) => dispatch({ type: 'PRODUCTS_ALL_COUNT', payload: count}), [dispatch])
    const categoriesDispatch = useCallback((items: Category[]) => dispatch({type: 'CATEGORIES', payload: items}), [dispatch])
    const setSelectedItems = (selectedItemsIds: Id[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED', payload: selectedItemsIds})
    
    const optionsDispatch = (options: ProductOption[]) => dispatch({ type: 'PRODUCTS_OPTIONS', payload: [...productOptions, ...options].reduce<ProductOption[]>((uniqueArray, option) => {
      if (!uniqueArray.find(o => o.id === option.id)) {
        uniqueArray.push(option);
      }
      return uniqueArray;
    }, []).filter(option => options.some(o => o.id === option.id))})


    useEffect(() => {
      if(!categories?.length) {
        getCategories()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const categoryHandler = (id: Id | undefined) => {
      closePopup()
      categoryDispatch(categories.find(c => c.id === id))
      getProducts({
        filters: id ? { top: id } : {}, 
        clearCount: true
      })
    }

    const getCategories = () => {
      mercheryFetch<Category[]>('category','GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) return false;

        categoriesDispatch(res.records)
      })
    }

    const getProductsOptions = (products: ExtendedProduct[]) => {
      const params = qs.stringify({
        product_id: products.map(p => p.id),
      }, {arrayFormat: 'comma'})
      
      mercheryFetch<ProductOption[]>('products/options?' + params, 'GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) return false

        optionsDispatch(res.records)
      })
    }

    const getProducts = (props: {
      filters?: Partial<ExtendedProduct>,
      page?: number, 
      clearCount?: boolean, 
    } = {}) => {
      const { page = 0, clearCount, filters } = props;

      const params = qs.stringify({
        filters: {
          // deleted: false,
          ...(filters && {
            ...defaultFilters,
            ...filters
          })
        },
        pagination: {
          size: productsItemsGetSize,
          page,
        },
        ...(searchInput && {search: searchInput}),
      }, {arrayFormat: 'comma'})

      return mercheryFetch<ExtendedProduct, true>(`products?${params}`, "GET", {})
      .then((res) => {
        if(!_isMounted.current || !validateResponse<ExtendedProduct, true>(res)) return false

        const gettedProducts = clearCount ?
          res.records.rows :
          uniqByKeepLast<ExtendedProduct>([ ...products, ...res.records.rows ], p => p.id) || [];
        
        const count = res.records.count;

        productsDispatch(gettedProducts || [])
        productsCountDispatch(gettedProducts?.length || 0)
        allProductsCountDispatch(count || 0)
        return gettedProducts
      });
    };

    const [ hasMore, showMore] = useInfiniteScrollShowMore({
      getItems: getProducts,
      currentCount: currentProductsCount,
      getSize: productsItemsGetSize,
      allCount: allProductsCount,
    })

    useEffect(() => {
      getProducts({
        filters: selectedCategory ? { top: selectedCategory.id } : {}, 
        clearCount: true
      })
      .then((products) => {
        if(products) {
          getProductsOptions(products)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, selectedCategory])

    const cancelHandler = () => {
      setSelectedItems([])
      popupClose()
    }

    const confirmHandler = async () => {
      await addItems(canBeAddedSelected)
      .then(res => {
        if(res === true) {
          popupClose();
        }
      }) 
    }

    return (
      <Popup
        popupClose={popupClose} 
        className={`hide-for-print items-in-order`} 
        popupName={'.items-in-order'}
        withBlackout
        withCloseBtn
        tabIndexDeep={2}
      >
        <div className="items-in-order-header">
          <div className="title-div">Добавление товара</div>

          <div className="search-item">

            <SearchInput 
              searchInput={searchInput}
              tabIndex={tabIndex}
              applySearch={setSearchInput} 
              />

            <RenderButton
              tabIndex={tabIndex}
              className="categories-btn"
            >
              {selectedCategory?.name || 'Категории'}
              <i className="icofont-caret-down"></i>
            </RenderButton>

            <RenderPopup
              popupName={'.categories-filters__popup'}
              className={'categories-filters__popup'}
              popupParent={'.items-in-order'}
              popupParentClose={popupClose}
              tabIndexDeep={3}
            >
              <CategoriesFilterAndControl 
                categoryHandler={categoryHandler}
                filtersMod={true} 
                withNoCategory={true}
                />
            </RenderPopup>
            {/* <SelectInput
              className={'characteristic-header__category--input'}
              valueLabelName={'name'}
              items={categories}
              selectedItem={selectedCategory}
              selectHandler={categoryHandler}
              itemStyle={itemStyle}
            /> */}
          </div>
          <div className="text-div">Введите часть наименования товара или выберите категорию</div>
        </div>

        <div id="row-items-add-list" 
          ref={itemsContainer}
          className="items-in-order-body"
        >
          {itemsContainer.current ? 
            <InfiniteScroll
              dataLength={products.length}
              next={showMore}
              hasMore={hasMore}
              scrollableTarget={itemsContainer.current}
              loader={
                Array(3)
                .map((e, i) => 
                  <AddItemsLoader key={i}/>)
              }
            >
              <div className="items-in-order-container">
                {products.map((item) => 
                  <WrappedComponent
                    key={item.id}
                    product={item} 
                    canBeAddedSelected={canBeAddedSelected} 
                    setCanBeAddedSelected={setCanBeAddedSelected} 
                    />
                )}
              </div>
            </InfiniteScroll> 
          : null}
        </div>

        <AddItemsFooter
          canBeAddedSelected={canBeAddedSelected}
          tabIndex={tabIndex}
          cancelHandler={cancelHandler}
          confirmHandler={confirmHandler}
          withTotalSumm={withTotalSum || undefined}
          />
      </Popup>
    );
  }
}

export default withAddItemPopup
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import { MultiSelectDto, MultiSelectValueDto, MultiSelectValueName } from '../../../dto/chars.dto';
import MultiSelectAddNew from './multi-select-editing-modules/add-new';
import MultiSelectTemplateEditingRow from './multi-select-editing-modules/value';

interface Props {
  thisMultiSelect: MultiSelectDto,
  labelId: Id,
  categoryId: Id,
}

function MultiSelectEditing({
  labelId,
  thisMultiSelect,
  categoryId,
}: Props) {
  const newAddedNames = useRef<MultiSelectValueName[]>([]);

  const msReducerType = 'CHARS_LABELS_MULTISELECT';
  const valuesReducerType = 'CHARS_LABELS_MULTISELECT_VALUES';
  const msNamesReducerType = 'CHARS_LABELS_MULTISELECT_NAMES';

  const multiSelects = useAppSelector(state => state.multiSelect);
  const names = useAppSelector(state => state.multiSelectNames);
  const values = useAppSelector(state => state.multiSelectValues);

  const dispatch = useDispatch();
  const setMultiSelect = useCallback((value: MultiSelectDto) => dispatch({type: msReducerType, payload: multiSelects.map(ms => ms.id !== value.id ? ms : value)}), [dispatch])
  const setMultiSelectValues = (value: MultiSelectValueDto[]) => dispatch({type: valuesReducerType, payload: value});
  const setNames = (names: MultiSelectValueName[]) => dispatch({type: msNamesReducerType, payload: names});

  const thisMultiSelectNames = useMemo(() =>  // filter ms values by ms and remove dublicates from scopes
    thisMultiSelect.names.flatMap(msName => names.find(name => msName === name.id) || [])
  , [names, thisMultiSelect])

  useEffect(() => { //focus on new row
    const newValues = thisMultiSelectNames.filter(l => l.newName)
    if(newValues.length > newAddedNames.current.length) {
      const lastNewAddedLabel = newValues[newValues.length - 1]
      const newLabelInDOM = document.querySelector<HTMLInputElement>(`[valueid='value-${lastNewAddedLabel.id}'] .multiselect-header__label .merchery-label__input`)
      newLabelInDOM?.focus()
      newAddedNames.current = newValues
    }
    newAddedNames.current = newValues
  }, [thisMultiSelectNames])

  const deleteValue = (name_id: Id) => { // delete value from multiselect
    newAddedNames.current = newAddedNames.current.filter(n => n.id !== name_id)
    const valueName = names.find(n => n.id === name_id)

    batch(() => {
      if(valueName?.newName) { // vanish not created name
        const filteredNames = names.filter(n => n.id !== name_id)
        setNames(filteredNames)
      }

      const updatedMultiSelect = {
        ...thisMultiSelect, 
        names: thisMultiSelect.names.filter(nameId => nameId !== name_id)
      }
      const filteredValues = values.filter(value => value.name_id !== name_id)

      setMultiSelect(updatedMultiSelect)
      setMultiSelectValues(filteredValues)
    })
  }

  const wrapperClassName = `characteristic-labels multiselect-items-wrapper flex-gap-16 side-padding-16`;
  
  return (
    <Droppable
      type={`multiselect-${labelId}`}
      droppableId={`label_id-${labelId}`}
    >
      {(provided, snapshot) => 
        <div 
          className={wrapperClassName}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h5 className="header-font-s char-label_sub-label characteristic-labels__header">Значения множественного выбора</h5>
          
          {thisMultiSelectNames.map((msName, index) => 
            <MultiSelectTemplateEditingRow
              key={msName.id}
              msName={msName}
              index={index}
              deleteName={() => deleteValue(msName.id)}
            />
          )}
          {provided.placeholder}

          <MultiSelectAddNew
            labelId={labelId}
            categoryId={categoryId}
          />

        </div>
      }
    </Droppable>
  );
}

export default MultiSelectEditing;
import React from 'react';
import { Order } from '../../dto/order.dto';
import RefundIcon from '../../../../../img/refund-icon.svg'
import ReactTooltip from 'react-tooltip';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  order: Order,
}

function OrderRowRefund({order}: Props) {
  const toolTipPlace = window.outerWidth > 1024 ? 
    (window.outerWidth > 1200 ? 'right' : 'top') 
  : (window.outerWidth < 501 ? 'top' : 'right');

  return <BodyCell myClassName="tt-refund-flag">
    <div
      className='refund-flag__icon-wrapper'
      data-tip={'Возвращен'}
      data-for={`order-refund`}
    >
      {order.refunded ? 
        <img src={String(RefundIcon)} alt={'refund'}/> 
      : null}
    </div>

    {order.refunded ?
      <ReactTooltip
        id={`order-refund`}
        resizeHide={true}
        place={toolTipPlace}
        multiline={true}
        effect={"solid"}
        isCapture={true}
        className={'fff'}
      />
    : null}
  </BodyCell>;
}

export default OrderRowRefund;
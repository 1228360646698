import React, {  } from 'react';
import NoImage from '../../../img/no-image.png';
import { ExtractedLinks } from '../../../scripts/utils/extractImages';

// const ImageNotFound = require('../../../img/image-not-found.svg');

interface PictureProps { 
  images: ExtractedLinks | string | null
}

function Picture({ images }: PictureProps) {
  return (
    <picture className="my-picture">
      {typeof images !== "string" && images ? (
        <>
          <source srcSet={images["jpeg"]} type="image/jpeg"/>
          <source srcSet={images["webp"]} type="image/webp"/>
        </>
      ) : null}

      <img className="default-img" src={typeof images !== "string" && images ? images.default : String(NoImage)} alt="not found" />
    </picture>
  );
}

export default Picture;
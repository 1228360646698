import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {Promocode} from '../../../../dto/promocode';
import {mercheryFetch} from '../../../../scripts/fetchConstructor';
import {validateResponse} from '../../../../scripts/functions';
import {useAppSelector} from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import {useTabIndex} from '../../../../scripts/hooks/use-tabindex';
import {OrdersLocationState} from './table';
import useOrderFilters from "../hooks/use-order-filters";
import usePageViewers from "../../../../scripts/hooks/use-page-viewers";
import ManagersOnPage from "../../../_utility-components/manager-on-page";

interface Props {
}

function OrdersHeader({
}: Props) {
  const _isMounted = useMounted()

  const [ordersFilters] = useOrderFilters();

  const searchInput = useAppSelector(state => state.ordersSearch);

  const tabIndex = useTabIndex();

  const location = useLocation<OrdersLocationState>()
  const pathname = location.pathname;
  const managersOnPage = usePageViewers(pathname)

  const dispatch = useDispatch()
  const promoListDispatch = (promocodes: Promocode[]) => dispatch({type: 'ORDERS_PROMOCODES', payload: promocodes})

  useEffect(() => {
    getPromocodes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPromocodes = () => {
    mercheryFetch<Promocode[]>('promocode', 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false

      promoListDispatch(res.records)
    });
  }

  const withFiltersClassName = searchInput && ordersFilters && (
    (ordersFilters.client_id && ordersFilters.client_id.some(mf => mf.selected)) ||
    (ordersFilters.status && ordersFilters.status.some(sf => sf.selected))
  )

  return (
    <div className={`orders-header ${withFiltersClassName ? 'with-filters' : ''}`}>
      <h1>Заказы</h1>

      <div className={'orders-header_right-inner'}>
        <ManagersOnPage
          managersOnPage={managersOnPage}
          size={'small'}
        />

        <Link
          className="order-create-btn hide-for-print"
          tabIndex={tabIndex}
          to={{
            pathname: '/app/orders/new',
            state: {prevPage: location.search}
          }}
        >
          Создать заказ
        </Link>
      </div>
    </div>
  );
}

export default OrdersHeader;


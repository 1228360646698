import React, {useEffect, useMemo, useState} from "react";
import ReactTooltip from "react-tooltip";
import {ImageExtended, ImageFile} from "../../../dto/images/images.dto";
import { TabIndex } from "../../../dto/master-dto/tabindex.dto";
import { SortingItem } from "../../../dto/sorting.dto";
import QuestionIcon from '../../../img/question-icon.svg';
import {ExtractedLinks, extractImages} from "../../../scripts/utils/extractImages";
import { uuidv4 } from "../../../scripts/functions";
import { useAppSelector } from "../../../scripts/pre-type/use-selector";
import MyButton from "../../_utility-components/button/button";
import MyInput from "../../_utility-components/input/index";
import Picture from "../../_utility-components/picture/picture";
import SelectInput from "../../_utility-components/select-input/select-input";
import VisibilitySelect from "../../_utility-components/visibility-select/visibility-select";
import MyDropzone from "../../_utility-components/dropzone";
import { CategoryImageModules } from "../products/category";
import { Category } from "../products/dto/categories.dto";
import { withDescription } from "./description";
import {MyResponse} from "../../../scripts/fetchConstructor";
import {withLoader} from "../../../scripts/with-loader";
import {useTabIndex} from "../../../scripts/hooks/use-tabindex";
import DropzoneContent from "../../_utility-components/dropzone-content";

interface Props {
  categoryChanger: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  categoryDispatch: (categoryProps: Partial<Category>) => void, 
  sendImage: (newFiles: ImageFile[], module: CategoryImageModules) => Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>>,
  deleteImage: (module: CategoryImageModules) => Promise<false | undefined>
}

export default function Summary({
  categoryChanger,
  categoryDispatch,
  deleteImage,
  sendImage,
}: Props) {
  const selectedCategory = useAppSelector(state => state.selectedCategory)
  const sorting = useAppSelector(state => state.sorting);
  const [loading, setLoading] = useState<boolean>(false);
  const domain = useAppSelector(state => state.settings.find(s => s.callname === 'site_front_domain'));
  
  const extractedImages = useMemo(() => {
    return selectedCategory?.src ? extractImages(selectedCategory.src, 'small') : null
  }, [selectedCategory?.src])

  if(!selectedCategory) {
    return null
  }

  const urlToClipBoard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if(domain) {
      navigator.clipboard.writeText(domain.value + '/app/categories/' + selectedCategory.nav);
    }
  }

  const fileHandler = async (files: ImageFile[]) => {
    setLoading(true);
    const res = await sendImage(files, 'Category')
    setLoading(false);
    return res
  }

  return <section className='category-page__summary'>
    <h2 className='header-font-l category-page__summary__title'>{selectedCategory.name}</h2>
    <div className='category-page__summary__inputs-wrapper'>
      <MyInput myClassName={'grid-element'}
        name={'name'}
        required={false}
        onChange={categoryChanger}
        type={"url"}
        disabled={selectedCategory.top === 0}
        value={selectedCategory.name}
      >
        <h5 className="char-label__header header-font-s">Наименование</h5>
      </MyInput>

      <SelectInput<SortingItem> 
        className={'grid-element'}
        items={sorting || []}
        selectedItem={sorting?.find(v => v.id === selectedCategory.sorting_id)}
        selectHandler={(value: SortingItem) => categoryDispatch({ sorting_id: value.id })}
        inputProps={{
          styleLabel: { userSelect: 'none' },
          children: <h5 className="char-label__header header-font-s">Сортировка товаров</h5>,
        }} 
      />

      <MyInput myClassName={'grid-element'}
        name={'nav'}
        type={'nav'}
        required={false}
        onChange={categoryChanger}
        value={selectedCategory.nav}
        maxLength={45}
      >
        <h5 className="char-label__header header-font-s">URL</h5>
        <div className='possible-url text-font-s'
          onClick={urlToClipBoard}
        >
          /app/categories/<span>{selectedCategory.nav}</span>
        </div>
      </MyInput>

      <div className={'grid-element'}>
        <VisibilitySelect 
          element={selectedCategory} 
          onChange={categoryDispatch}
        />
      </div>
    </div>

    <div className='category-page__summary__image'>
      <MyDropzone
        files={selectedCategory.src ? [selectedCategory.src] : []}
        optionalProps={{
          multiple: false,
          maxFiles: 1,
          disabled: selectedCategory.newCategory
        }}
        isLoading={loading}
        disabled={selectedCategory?.newCategory}
        fileHandler={fileHandler}
        contentRender={
          <DropzoneContent
            extractedImages={extractedImages}
            deleteImage={() => deleteImage('Category')}
            fallBackLink={selectedCategory.og_src?.src || null}
          />
        }
      />
    </div>

    <CategoryDescription
      description={selectedCategory.description}
      descriptionHandler={
        (desc) => categoryDispatch({ description: desc })
      }
    />

    <div className='category-page__summary__navs'>
      <MyInput myClassName={'grid-element'}
        required={false}
        disabled={true}
        value={selectedCategory.id}
      >
        <h5 className="char-label__header header-font-s">Код категории</h5>
      </MyInput>

      <MyInput myClassName={'grid-element'}
        name={'UUID'}
        required={false}
        onChange={categoryChanger}
        value={selectedCategory.UUID || ''} // unique
      >
        <div className='uuid-input__header char-label__header'>
          <h5 className="uuid-input__header-text header-font-s">
            Внешний код

            <img className='uuid-input__header-info'
              src={String(QuestionIcon)}
              alt="info"
              data-tip={'Используется для синхронизации с внешними <br /> сервисами и товароучетными системами'}
              data-for={`uuid-input__info`}
            />
            
            <ReactTooltip
              id={"uuid-input__info"}
              resizeHide={true}
              place={'top'}
              multiline={true}
              effect={"solid"}
              isCapture={true}
              />
          </h5>

          <MyButton className={'text-btn uuid-input__header-generate-btn header-font-s'}
            onClick={() => categoryDispatch({ UUID: uuidv4() })}
            removeDefaultClass
          >
            Сгенерировать
          </MyButton>
        </div>
      </MyInput>
    </div>
  </section>;
}

const CategoryDescription = withDescription(({
  children
}) => {
  return (
    <div className='category-page__summary__description'>
      {children}
    </div>
  )
})
import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import { batch, useDispatch } from 'react-redux';
import { uuidv4, validateResponse } from 'src/scripts/functions';
import qs from 'qs';

import * as tinymce from 'tinymce'; // IMPORTANT, REQUIRED FOR DESCRIPTION COMPONENT
import { MSProductImages } from '../../integrations-modules/moysklad/ms-product-images';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import ProductDescription from './product-page-modules/product-description/description';
import ProductHeader from './product-page-modules/header';
import ProductMainLabels from './product-page-modules/main-labels';
import ProductImagesDropzone from './product-page-modules/media';
import ProductOptions from './product-page-modules/options';
import ProductProperties from './product-page-modules/properties';
import ProductTopPanel from './product-page-modules/top-panel';
import ProductVariants from './product-page-modules/variants';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import RightSideBar from './product-page-modules/right-side-bar';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import useMounted from '../../../scripts/hooks/use-mounted';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import useRouteId from '../../../scripts/hooks/use-route-id';
import { MainRouteChild } from '../main-page';
import { ExtendedProduct } from './dto/products.dto';
import { useLoad } from '../../../scripts/hooks/use-load';
import { ProductVariantExtended } from './dto/variants.dto';
import { ProductAttributes } from './dto/attributes.dto';
import usePageViewers from "../../../scripts/hooks/use-page-viewers";
import {useLocation} from "react-router-dom";
import {Id} from "../../../dto/master-dto/id.dto";
import {WebSocketContext} from "../../../scripts/web.socket";
import {WsResponse} from "../../../dto/ws-responses/ws-response.dto";
import {UpdateProductsWsDto} from "../../../dto/ws-responses/products.dto";
import {updateProduct} from "../../../constants/ws-events";

interface ProductPageProps extends MainRouteChild {
  pageState: 'change' | 'create'
}

export type ProductInputHandler = <T extends keyof ExtendedProduct>(label: T, value: ExtendedProduct[T]) => false | undefined

export const ProductPageContext = React.createContext<{
  managersOnPage: Array<Id>,
  optionsLoaded: boolean,
  setOptionsLoaded: (_loaded: boolean) => void,
}>({
  optionsLoaded: false,
  setOptionsLoaded: (_loaded: boolean) => {},
  managersOnPage: []
});

function Product ({
  pageState,
  setCurrentPage
}: ProductPageProps) {
  const notFoundNotification = useAppSelector(state => state.staticValues.not_found_product_page_notification)
  
  const notfoundContextLinks = [
    {
      to: '/app/products',
      text: 'К списку товаров',
    }
  ]

  const isCreate = pageState === 'create'
  const productid = useRouteId('productid')
  const initProduct = useRef<ExtendedProduct | undefined>(undefined)

  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const [, setLoad] = useLoad()
  const [notFound, setNotFound] = useState(false);
  const product = useAppSelector(state => state.product)
  const _isMounted = useMounted()
  const dispatch = useDispatch()
  const productDispatch = (product: ExtendedProduct | undefined) => dispatch({type: 'PRODUCT_ITEM', payload: product,})
  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })
  const selectedVariantAttributesDispatch = (attrs: ProductAttributes[][]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })

  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const setLoaded = (loaded: boolean) => setOptionsLoaded(loaded);
  const {
    lastMessage,
  } = useContext(WebSocketContext);

  useEffect(() => {
    if (lastMessage?.data) {
      const message = JSON.parse(lastMessage.data) as WsResponse<UpdateProductsWsDto>;

      if (message.event === updateProduct &&
        product &&
        message.data.changes && (
          !Array.isArray(message.data.changes) ||
          message.data.changes.length === 1
        )
      ) {
        const changes = Array.isArray(message.data.changes) ? message.data.changes[0] : message.data.changes;

        if(changes.id === product.id) {
          batch(() => {
            productDispatch({...product, ...changes})
            if(initProduct.current) {
              initProduct.current = {...initProduct.current, ...changes}
            }
          })
        }
      }
    }
  }, [lastMessage]);

  const location = useLocation()
  const pathname = location.pathname;
  const viewersCount = usePageViewers(pathname)

  useEffect(() => {
    setCurrentPage('products');

    if(isCreate) {
      initCreation()
    } else {
      // websocketListener()
      startProductComponent()
    }

    return () => {
      productDispatch(undefined)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initCreation = () => {
    const newProduct: ExtendedProduct = {
      id: uuidv4(),
      src: [],
      variants: [],
      anons: '',
      brand: 0,
      country: '',
      deleted: null,
      description: '',
      discount: 0,
      external_id: null,
      barcode: null,
      hit_sales: false,
      is_order: false,
      keywords: '',
      multiplicity: 0,
      name: '',
      nav: '',
      new_items: false,
      category_order: 0,
      main_page_order: 0,
      price: 0,
      price_old: 0,
      remain: 0,
      type: 1,
      purchase_price: 0,
      rate: 0,
      show_date: null,
      title: '',
      top: null,
      weight: 0,
      price_old_or_discount: 'discount',
      unit_of_measurement: 0,
      package_amount: null,
      ship_in_packages: false,
      price_per_pack: false,
      newProduct: true,
      vat: null
    }
    
    batch(() => {
      productDispatch(newProduct)
    })
  }

  const inputHandler: ProductInputHandler = <T extends keyof ExtendedProduct,>(label: T, value: ExtendedProduct[T]) => {
    if(!product) {
      return false
    }

    productDispatch({
      ...product, 
      [label]: value
    })
  }

  const startProductComponent = async () => {
    setLoad(true)

    await getProduct()

    setLoad(false)
  }

  const getProduct = async () => {
    const params = qs.stringify({
      id: productid,
    }, {arrayFormat: 'comma'})

    return mercheryFetch<ExtendedProduct>(`products/get_one?${params}`, 'GET')
    .then(async (res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      if(res.statusCode === 204 || res.statusCode === 502) {
        setNotFound(true)
        return false
      }

      const product = res.records;

      batch(() => {
        initProduct.current = product
        productDispatch(product)
        variantsDispatch(product.variants)
        initVariantsDispatch(product.variants)
        selectedVariantAttributesDispatch([])
      })

      return res
    })
  }

  if(!product) {
    return null
  } else if(notFound) {
    return (
      <NotFoundLocalApp
        optionalMessage={notFoundNotification}
        contextLinks={notfoundContextLinks}
      />
    )
  // } else if(productDeleted) {
  //   return (
  //     <>Товар был удален</>
  //   )
  } else {
    return (
      <ProductPageContext.Provider value={{
          optionsLoaded,
          setOptionsLoaded,
          managersOnPage: viewersCount,
        }}>
        <div className="product-page">

          <ProductHeader
            pageIsCreatePage={isCreate}
          />

          <RightSideBar/>

          <div className='product-page__main-labels-and-media__wrapper'>
            <ProductMainLabels
              inputHandler={inputHandler}
            />

            {isCreate ?
              null
            : moySkladIntegrationOn ?
              <MSProductImages />
            :
              <ProductImagesDropzone/>
            }
          </div>

          <ErrorBoundary
            FallbackComponent={FallbackComponent}
          >
            <div className='product-page__options-and-variants__wrapper'>

                {!moySkladIntegrationOn ? (
                  <ProductOptions
                    isCreate={isCreate}
                    inputHandler={inputHandler}
                  />
                ) : null}

                {!moySkladIntegrationOn ? (
                  <ProductVariants
                  />
                ) : null}
            </div>
          </ErrorBoundary>

          <ProductProperties
            inputHandler={inputHandler}
            isCreatePage={isCreate}
            />

          <ProductDescription
            pageIsCreatePage={isCreate}
            initProduct={initProduct}
            />

          <ProductTopPanel
            initProduct={initProduct}
            pageIsCreatePage={isCreate}
            />
        </div>
      </ProductPageContext.Provider>
    );
  }
}

export default memo(Product)
import React, { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { RefundFilters } from './dto/refund-filters.dto';
import { Id } from '../../../../dto/master-dto/id.dto';
import ExportButton from '../../../_utility-components/export-btn/export-btn';
import { RefundDto } from './dto/refund.dto';
import { renameExportHeaders } from './refunds-modules/rename-export-headers';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { arrayOfSelectedFilters, validateResponse } from '../../../../scripts/functions';
import { RefundStatus } from './dto/refund-status.dto';
import { RefundSearch } from './refunds-modules/refund-search';
import { CommonFiltersLoader } from '../../../_utility-components/loaders/common-table-loader';

interface FilterChangerProps {
  itemIds: (Id | null)[] | undefined;
  filterName: (keyof RefundFilters) | undefined;
  cleanPrev?: boolean | undefined;
  concreteSelected?: boolean,
  cleanOthers?: true,
}

export type RefundFilterChanger = (props: FilterChangerProps) => void

function RefundHeader() {
  const refunds = useAppSelector(state => state.refunds);
  const searchInput = useAppSelector(state => state.ordersSearch);
  const [searchState, setSearchState] = useState(Boolean(searchInput));
  const _isMounted = useMounted();
  
  const dispatch = useDispatch()
  const filtersDispatch = (filters: RefundFilters) => dispatch({ type: 'ORDERS_REFUNDS_FILTERS', payload: filters});
  const refundStatusesDispatch = (statuses: RefundStatus[]) => dispatch({ type: 'ORDERS_REFUND_STATUSES', payload: statuses});

  useEffect(() => {
    mercheryFetch<RefundStatus[]>('orders/refund/status', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      
      batch(() => {
        refundStatusesDispatch(res.records)
        filtersDispatch({
          status_id: arrayOfSelectedFilters(res.records)
        })
      })
    })
  }, [])

  return (
    <div className={`orders-header ${searchState ? 'with-filters' : ''}`}>
      <h1>Возвраты</h1>
      <div></div>

      {refunds ? 
        <>
          <RefundSearch 
            searchState={searchState} 
            setSearchState={setSearchState}
          />

          <ExportButton<RefundDto>
            text={'экспорт'}
            renameExportHeaders={refund => renameExportHeaders({
              refund,
            })}
            items={refunds}
          />
        </>
      : <CommonFiltersLoader/>}
    </div>
  );
}

export default RefundHeader;
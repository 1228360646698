import {Id} from "../../../../../dto/master-dto/id.dto";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import React, {useContext, useMemo} from "react";
import {Droppable} from "react-beautiful-dnd";
import ProductsTableRows from "./table-rows";
import {ExtendedProduct} from "../../dto/products.dto";
import {ProductsTableContext} from "../table";

export default function CategoryRows ({
	category,
}: {
	category: Id | null,
}) {
	const searchInput = useAppSelector(state => state.searchInput)
	const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);
	const productDragAndDropIsDisabled = !!(moySkladIntegrationOn || searchInput)

	const {
		sortedProducts
	} = useContext(ProductsTableContext);

	const filteredSortedProducts = useMemo(() =>
			[...sortedProducts]
			.filter((p) => p.top === category)
		, [sortedProducts, category]);

	return (
		<Droppable
			isDropDisabled={productDragAndDropIsDisabled}
			droppableId={`${category}`}
			type={`PRODUCTS-${category}`}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					<ProductsTableRows
						sortedProducts={filteredSortedProducts}
					/>
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}
import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../../dto/master-dto/id.dto';
import { getTextWidth } from '../../../../../../scripts/functions';
import MyButton from '../../../../../_utility-components/button/button';
import SelectInput from '../../../../../_utility-components/select-input/index';
import { CharGroupsDto } from '../../../dto/chars.dto';
import { SelectOption } from '../one-char';
import { CharacteristicsPage } from '../product-characteristics';
import CategoryBindingSelect from './char-header-modules/category-binding-select';
import OneCharDropDownMenu from './char-header-modules/drop-down-menu';
import HeaderTitle from './char-header-modules/title';

interface Props {
  thisCharGroup: CharGroupsDto,
  selectedItem: SelectOption,
  selectItems: SelectOption[],
  charEditing: Id | null,
  page: CharacteristicsPage,
  clearCharData: () => void,
  setEditHandler: () => void,
  setCharScope: (scope: SelectOption) => void,
  initDeletionHandler: (group: CharGroupsDto) => void
}

function OneCharHeader({
  thisCharGroup,
  selectedItem,
  selectItems,
  charEditing,
  page,
  setCharScope,
  setEditHandler,
  clearCharData,
  initDeletionHandler,
}: Props) {
  const selectOptionFont = '400 14px Open Sans';
  const pageTopPanelShown = useAppSelector(state => state.topPanelShown);
  const categories = useAppSelector(state => state.categories)
  const groups = useAppSelector(state => state.charGroups);
  const charsTop = document.getElementById('chars-top');

  const headerStyle = useMemo(() => {
    const charsTopHeight = charsTop?.clientHeight;
    const charsTopTopRuleValue = charsTop?.style?.top;
    const charHeaderTopRuleValue = charsTopHeight ? charsTopHeight + (pageTopPanelShown && charsTopTopRuleValue ? +charsTopTopRuleValue.slice(0, charsTopTopRuleValue.length - 2) : -1) : 0;
  
    return {
      top: charHeaderTopRuleValue + 'px'
    }
  }, [charsTop, pageTopPanelShown]);

  const groupsType = 'CHARS_GROUPS';

  const dispatch = useDispatch();

  const charGroupsDispatch = (changes: Partial<CharGroupsDto>) => {
    console.log('changes:', changes, 'thisCharGroup:', thisCharGroup);
    dispatch({
      type: groupsType, 
      payload: groups.map(group => {
        console.log('group:', group);
        console.log('group is mathed', group.id !== thisCharGroup.id)
        return group.id !== thisCharGroup.id ? group : ({
          ...group,
          ...changes
        });
      })
    })
  }

  const selectWidth = useMemo(() => {
    const textWidth = selectItems.map(({text}) => getTextWidth(text, selectOptionFont));
    const largestWidth = Math.max.apply(Math, textWidth);
    const paddingsPlusBorders = 12 + 32 + 1 + 1;
    return largestWidth + paddingsPlusBorders;
  }, [selectItems])

  return (
    <div className='characteristic-header__wrapper'
      style={headerStyle}
    >
      {charEditing === thisCharGroup.id ?
        <h4 className='header-font-m side-padding-16'>
          {thisCharGroup.newGroup ? 
          'Новая группа характеристик'
          : 'Группа характеристик'}
        </h4>
      : null}

      <div className='characteristic-header side-padding-16'>
        <HeaderTitle
          thisCharGroup={thisCharGroup}
          charEditing={charEditing === thisCharGroup.id}
          charGroupsDispatch={charGroupsDispatch}
        />

        {charEditing === thisCharGroup.id && page === 'product' ?
          <CategoryBindingSelect
            thisCharGroup={thisCharGroup}
            charGroupsDispatch={charGroupsDispatch}
          />
        : null}

        <div className={'characteristic-header__right-part ' + (page === 'product' ? 'flex-gap-18' : 'flex-gap-24')}>
          {charEditing !== thisCharGroup.id && page === 'product' ? 
            <SelectInput
              inputProps={{
                style: {
                  width: selectWidth,
                  maxWidth: '100%',
                },
              }}
              items={selectItems}
              selectedItem={selectedItem}
              className={'char-header__select-input'}
              selectHandler={setCharScope}
            /> 
          : null}

          {charEditing === null && page === 'product' ? 
            <OneCharDropDownMenu
              setEditHandler={setEditHandler}
              clearCharData={clearCharData}
            />
          : null}

          {charEditing !== thisCharGroup.id && page === 'categories' ?
            <React.Fragment>
              <MyButton
                disabled={charEditing !== null}
                onClick={setEditHandler}
                removeDefaultClass
              >
                <i className='icofont-ui-edit'></i>
              </MyButton>
              <MyButton
                disabled={charEditing !== null}
                onClick={() => initDeletionHandler(thisCharGroup)}
                removeDefaultClass
              >
                <i className='icofont-trash'></i>
              </MyButton>
            </React.Fragment>
          : null}
        </div>
      </div>
    
      {charEditing === thisCharGroup.id ? (
        <div className='side-padding-16 text-font-s'>
          Блок характеристик будет применен ко всем товарам выбранной категории
        </div>
      ) : null}
    </div>
  );
}

export default memo(OneCharHeader);
import React from 'react';
import { num2str } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import { ProductVariantExtended } from '../dto/variants.dto';

interface ShowVariantsProps <T extends {variants: ProductVariantExtended[]}> {
  tabindex?: number
  item: T,
  showedVariants: boolean,
  showHandler: () => void,
  disabled: boolean,
}

function ShowVariants<T extends {variants: ProductVariantExtended[]}> ({
  tabindex,
  item,
  showedVariants,
  showHandler,
  disabled,
}: ShowVariantsProps<T>) {
  
  return (
    <div className="row-item__show-variants">
      <MyButton
        tabIndex={tabindex}
        disabled={disabled}
        className={`show-variants__btn ${showedVariants ? '' : 'show-variants__btn--shown'}`}
        onClick={showHandler}
      >
        {num2str(item.variants.length, ['вариант', 'варианта', 'вариантов'])} товара<i className="icofont-simple-down"></i>
      </MyButton>
    </div>
  );
}

export default ShowVariants;
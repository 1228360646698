import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch, MyResponse } from '../../../../../scripts/fetchConstructor';
import { getArrayOfObjectsChanges, objOfArraysHasProps, TwoArraysDiffs, uuidv4, validateResponse } from '../../../../../scripts/functions';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { CharGroupsDto, CharScopeDto, CharsDto, CharsLabelDto, MultiSelectDto, MultiSelectValueName } from '../../dto/chars.dto';
import { ProductOption, ProductsAttrValues } from '../dto/options.dto';
import CharGroupDeletePopup from './char-modules/confirm-popup';
import CharFooter from './char-modules/footer';
import OneCharHeader from './char-modules/header';
import Labels from './char-modules/labels';
import CharOptionSwitcher from './char-option-switcher';
import { CharacteristicsPage } from './product-characteristics';

interface Props {
  options: ProductOption[]

  thisCharGroup: CharGroupsDto
  initialCharGroups: CharGroupsDto[]
  charGroupsDiff: TwoArraysDiffs<CharGroupsDto>
  setInitialCharGroups: (groups: CharGroupsDto[]) => void

  labelsDiff: TwoArraysDiffs<CharsLabelDto>
  initialLabels: CharsLabelDto[]
  setInitialLabels: (labels: CharsLabelDto[]) => void

  clearDeletedCharData: (char: CharsDto) => void,
  page: CharacteristicsPage,
  charEditingId: Id | null,
  setCharEditingId: (id: Id | null) => void,
}

function OneChar({
  thisCharGroup,
  initialCharGroups,
  setInitialCharGroups,
  labelsDiff,
  setInitialLabels,
  options,
  initialLabels,
  charGroupsDiff,
  clearDeletedCharData,
  page,
  charEditingId, 
  setCharEditingId, 
}: Props) {
  const _isMounted = useMounted()

  const newAddedLabels = useRef<CharsLabelDto[]>([]);
  const defaultScopeValue = 0;
;
  const charGroups = useAppSelector(state => state.charGroups);
  const chars = useAppSelector(state => state.chars);
  const allLabels = useAppSelector( state => state.labels);
  const allMultiSelect = useAppSelector( state => state.multiSelect);
  const scopes = useAppSelector( state => state.scopes);
  const char = useMemo(() => chars.find(ch => ch.char_group === thisCharGroup.id) || null, [chars, thisCharGroup]);
  const productId = useAppSelector(state => state.product?.id);
  const multiSelectNames = useAppSelector(state => state.multiSelectNames);

  const labels = useMemo(() => 
    allLabels
    .filter(label => label.char_group === thisCharGroup.id)
    .sort((a,b) => a.order - b.order),
  [allLabels, thisCharGroup] )
  const multiSelect: MultiSelectDto[] = useMemo(() => allMultiSelect.filter(ms => labels.some(l => l.id === ms.label_id)), [allMultiSelect, labels])
  const [toDelete, setToDelete] = useState(false);
  
  const initialCharGroup = useMemo(() => initialCharGroups.find(gr => gr.id === thisCharGroup.id), [initialCharGroups, thisCharGroup]);
   
  const [charScope, setCharScope] = useState(char?.scope || null);

  const [initialMultiSelect, setInitialMultiSelect] = useState<MultiSelectDto[]>([]);
  const [initialMultiSelectNames, setInitialMultiSelectNames] = useState<MultiSelectValueName[]>([]);
  
  const multiSelectDiff = useMemo(() => getArrayOfObjectsChanges(multiSelect.map(ms => ({...ms, names: ms.names.toString()})), initialMultiSelect.map(ms => ({...ms, names: ms.names.toString()})), ['names']), [multiSelect, initialMultiSelect]);
  const multiSelectNamesDiff = useMemo(() => getArrayOfObjectsChanges(multiSelectNames, initialMultiSelectNames, ['name']), [multiSelectNames, initialMultiSelectNames]);
  const dispatch = useDispatch();
  
  useEffect(() => {
    batch(() => {
      setInitialMultiSelect(multiSelect)
      setInitialMultiSelectNames(multiSelectNames)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const postReq = useCallback(<T,>(link: string, added: {}[]): Promise<MyResponse<T>> | undefined => 
    added.length ? 
      mercheryFetch<T>(link, 'POST', { toCreate: added })
    : undefined
  , [])
  
  const patchReq = useCallback(<T,>(link: string, changes: { [key: string]: any }[]): Promise<MyResponse<T>> | undefined => 
    changes.length ? 
      mercheryFetch<T>(link, 'PATCH', { changes }) 
    : undefined
  , [])

  const deleteReq = useCallback((link: string, deleted: Id[]): Promise<MyResponse<number[]>> | undefined => 
    deleted.length ? 
      mercheryFetch(link, 'DELETE', { toDelete: {id: deleted} }) 
    : undefined
  , [])

  const nothingToSave = useMemo(() => {
    return charGroupsDiff && ![Object.values(charGroupsDiff), Object.values(labelsDiff)].flat(2).length
  }, [charGroupsDiff, labelsDiff])

  const charsGroupsReducertype = 'CHARS_GROUPS';
  const charsReducerType = 'CHARS';
  const labelsReducerType = 'CHARS_LABELS';
  const scopesReducerType = 'CHARS_SCOPES';
  const msReducerType = 'CHARS_LABELS_MULTISELECT';
  const multiselectNamesReducerType = 'CHARS_LABELS_MULTISELECT_NAMES';

  const msRoutePath = 'characteristics/multiselects';
  const msNamesRoutePath = 'characteristics/multiselects/names';

  const setCharsGroups = useCallback((value: CharGroupsDto[]) => dispatch({type: charsGroupsReducertype, payload: value}), [dispatch]);
  const setChars = useCallback((value: CharsDto[]) => dispatch({type: charsReducerType, payload: value}), [dispatch]);
  const setLabels = useCallback((value: CharsLabelDto[]) => dispatch({type: labelsReducerType, payload: value}), [dispatch]);
  const setScopes = useCallback((scopes: CharScopeDto[]) => dispatch({type: scopesReducerType, payload: scopes}), [dispatch]);
  const setMS = useCallback((value: MultiSelectDto[]) => dispatch({type: msReducerType, payload: value}), [dispatch]);
  const setMultiSelectNames = useCallback((value: MultiSelectValueName[]) => dispatch({type: multiselectNamesReducerType, payload: value}), [dispatch])
  
  const charGroupsRoutePath = 'characteristics/groups';
  const labelsRoutePath = 'characteristics/labels';

  const charsDispatch = useCallback((changedChar: CharsDto) => 
    setChars(chars.map(char => char.id !== changedChar.id ? char : changedChar))
  , [setChars, chars])

  const charChangeByField = useCallback((changes: Partial<CharsDto>) => 
    char && charsDispatch({...char, ...changes})
  , [charsDispatch, char])

  const labelsDispatch = useCallback((changedLabel: CharsLabelDto) => {
    return setLabels(allLabels.map(label => label.id !== changedLabel.id ? label : changedLabel))
  }, [allLabels, setLabels])

  const removeLabelAndDispatch = useCallback((labelForRemove: CharsLabelDto) => {
    batch(() => {
      newAddedLabels.current = newAddedLabels.current.filter(label => label.id !== labelForRemove.id)
      setLabels(allLabels.filter(label => label.id !== labelForRemove.id))
      if(labelForRemove.newLabel) {
        setScopes(scopes.filter(scope => scope.label_id !== labelForRemove.id))
      }
    })
  }, [allLabels, scopes, setLabels, setScopes]);

  const selectOptions: SelectOption[] = useMemo(() => (
    options ? [
      {id: null, text: 'Общее для всех вариантов', values: []},
      ...options.map(o => ({ 
        id: o.id,
        text: `Если выбран ${o.title}`,
        values: o.values
      })),
    ] : []
  ), [options]);

  const selectedOption: SelectOption = useMemo(() => 
    selectOptions.find(i => i.id === charScope) || selectOptions[0]
  , [charScope, selectOptions]);
  
  const [selectedOptionValue, setSelectedOptionValue] = useState(getCurrentOptionValue({valueId: charScope, selectedOption}));

  useEffect(() => {
    if(_isMounted.current && selectedOption) {
      if(selectedOption.id === char?.scope) {
        setSelectedOptionValue(getCurrentOptionValue({valueId: defaultScopeValue, selectedOption}))
      } 
      
      if(selectedOption.id !== null) {
        setSelectedOptionValue(getCurrentOptionValue({selectedOption}))
      } 

      if(selectedOption.id === null) {
        setSelectedOptionValue(null)
      }
    }
  }, [selectedOption, char]);
  
  const selectScopeValueHandler = useCallback((valueId: Id) =>
    setSelectedOptionValue(getCurrentOptionValue({valueId: valueId, selectedOption}))
  , [selectedOption]);

  useEffect(() => {
    if(charScope !== null) {
      let newChar: CharsDto | false | undefined = undefined;
      batch(() => {
        if(!char?.id) {
          newChar = createChar()
        } else {
          charChangeByField({scope: charScope})
        }
  
        if((char?.id && char?.scope !== charScope) || (newChar && newChar.id)) {
          const thisCharLabelsIds = labels.map(l => l.id);
          const deleteThisCharScopes = scopes.filter(scope => !thisCharLabelsIds.some((labelId) => scope.label_id === labelId))
          setScopes(deleteThisCharScopes)
        }
      })
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charScope])

  useEffect(() => {
    if(_isMounted.current && char && char.scope !== charScope) {
      setCharScope(char.scope || null)
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [char])

  useEffect(() => {
    const newLabels = labels.filter(l => l.newLabel)

    if(newLabels.length > newAddedLabels.current.length) {
      const lastNewAddedLabel = newLabels[newLabels.length - 1]
      const newLabelSelector = `[valueid='group-${thisCharGroup.id}_label-${lastNewAddedLabel.order}'] .char-label__header--editing .merchery-label__input`
      const newLabelInDOM = document.querySelector<HTMLInputElement>(newLabelSelector)
      newLabelInDOM?.focus()
      newAddedLabels.current = newLabels
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels])
  
  const setEditHandler = () => setCharEditingId(charEditingId === null ? thisCharGroup.id : null);

  const selectScopeHandler = useCallback((scope: SelectOption) => {
    setCharScope(scope.id)
  }, [setCharScope]);

  const createChar = () => {
    if(!productId) return false
    const thisProductChars = chars.filter(ch => ch.product_id === productId);
    const arrayOfOrders = [...thisProductChars.map((v) => v.order), 0];
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newChar: CharsDto = {
      id: uuidv4(),
      char_group: thisCharGroup.id,
      newChar: true,
      order: newOrder,
      product_id: productId,
      scope: charScope
    };
    dispatch({
      type: charsReducerType, 
      payload: [...chars, newChar]
    })
    return newChar
  }

  const saveCharGroup = async () => {
    const createGroup = thisCharGroup.newGroup && thisCharGroup;
    const groupChanges = charGroupsDiff?.changes.find(gr => gr.id === thisCharGroup.id);
    const labelsToCreate = labelsDiff.added.filter(l => l.char_group === thisCharGroup.id);
    const labelsChanges = labelsDiff.changes.filter(chl => labels.find(l => l.id === chl.id));
    const labelsToDelete = labelsDiff.deleted.filter(labelToDelete => initialLabels.some(l => l.id === labelToDelete));

    let updatedListOfGroups: CharGroupsDto[] | undefined = undefined;
    let updatedLabels = labels;
    let updatedMultiSelect = multiSelect;
    let updatedMultiSelectNames = multiSelectNames;
    
    let labelIdsForReplace: {[id: Id]: Id | undefined} = {};
    let namesIdsForReplace: {[id: Id]: Id | undefined} = {};

    if(createGroup) {
      const response = await postReq<CharGroupsDto[]>(charGroupsRoutePath, [thisCharGroup]);
      if(!_isMounted.current || !response?.success) return false
      
      updatedListOfGroups = [...charGroups.filter(gr => gr.id !== thisCharGroup.id), response.records[0]]
    }
    
    if(labelsToCreate.length) {
      const labelsWithReplacedIds = labelsToCreate.map(value => ({
        ...value, 
        char_group: updatedListOfGroups ? updatedListOfGroups[updatedListOfGroups.length - 1].id : value.char_group
      }))
      const res = await postReq<CharsLabelDto[]>(labelsRoutePath, labelsWithReplacedIds)
      if(!_isMounted.current || !res?.success) return false;
      
      for (const label of labelsToCreate) {
        labelIdsForReplace[label.id] = res.records.find(createdLabel => createdLabel.name === label.name)?.id
      }
      updatedLabels = updatedLabels.map(label => res.records.find(nl => nl.order === label.order) || label)
    }

    if(!createGroup && groupChanges) {
      const res = await patchReq<CharGroupsDto[]>(charGroupsRoutePath, [groupChanges])
      if(!_isMounted.current || !res?.success) return false

      updatedListOfGroups = charGroups.map(gr => gr.id !== thisCharGroup.id ? gr : res.records[0])
    }

    if(labelsChanges.length) {
      const res = await patchReq<CharsLabelDto[]>(labelsRoutePath, labelsChanges);
      if(!_isMounted.current || !res?.success) return false;

      updatedLabels = updatedLabels.map(label => res.records.find(nl => nl.id === label.id) || label);
    }
    
    if(labelsToDelete.length) {
      const res = await deleteReq(labelsRoutePath, labelsToDelete);
      if(!_isMounted.current || !res?.success) return false

      updatedLabels = updatedLabels.filter(label => !labelsToDelete.some(nl => nl === label.id) || label)
    }

    const msNamesToCreateValidated = multiSelectNamesDiff.added.filter(msName => msName.name)

    if(msNamesToCreateValidated.length) {
      const namesResponse = await postReq<MultiSelectValueName[]>(msNamesRoutePath, msNamesToCreateValidated);
      if(!_isMounted.current || !namesResponse?.success) return false

      for (const name of msNamesToCreateValidated) {
        const createdNameFromDb = namesResponse.records.find(createdName => createdName.name === name.name)

        if(createdNameFromDb?.id) {
          namesIdsForReplace[name.id] = createdNameFromDb.id
        }
      }
      updatedMultiSelectNames = [...updatedMultiSelectNames.filter(name => !name.newName), ...namesResponse.records]
    }

    if(multiSelectNamesDiff.changes.length) {
      const res = await patchReq<MultiSelectValueName[]>(msNamesRoutePath, multiSelectNamesDiff.changes);
      if(!_isMounted.current || !res?.success) return false

      updatedMultiSelectNames = updatedMultiSelectNames.map(name => res.records.find(nl => nl.id === name.id) || name)
    }

    if(multiSelectDiff.added.length) {
      const msWithUpdatedNameIds: MultiSelectDto[] = multiSelectDiff.added.map(value => ({
        ...value, 
        names: 
          value.names.split(',')
          .map(name => 
            Number(
              namesIdsForReplace[name] || name
            )
          ),
        label_id: labelIdsForReplace[value.label_id] || value.label_id,
      }))
      const res = await postReq<MultiSelectDto[]>(msRoutePath, msWithUpdatedNameIds)
      if(!_isMounted.current || !res?.success) return false;

      updatedMultiSelect = [...updatedMultiSelect.filter(ms => !ms.newMultiSelect), ...res.records]
    }

    if(multiSelectDiff.changes.length) {
      const msWithUpdatedNameIds = multiSelectDiff.changes.map(value => ({
        ...value, 
        ...(value.names &&
          {names: 
            value.names
            .split(',')
            .map(name => 
              Number( namesIdsForReplace[name] || name )
            )
            .filter(a => !isNaN(a))
          }
        ),
      }))

      const res = await patchReq<MultiSelectDto[]>(msRoutePath, msWithUpdatedNameIds);
      if(!_isMounted.current || !res?.success) return false;

      updatedMultiSelect = updatedMultiSelect.map(label => res.records.find(nl => nl.id === label.id) || label);
    }
    
    if(multiSelectDiff.deleted.length) {
      const res = await deleteReq(msRoutePath, multiSelectDiff.deleted);
      if(!_isMounted.current || !res?.success) return false

      updatedMultiSelect = updatedMultiSelect.filter(name => !multiSelectDiff.deleted.some(nl => nl === name.id) || name)
    }

    batch(() => {
      if(objOfArraysHasProps(charGroupsDiff) && updatedListOfGroups) {
        setCharsGroups(updatedListOfGroups)
        setInitialCharGroups(updatedListOfGroups)
      }

      if(objOfArraysHasProps(labelsDiff)) {
        setLabels(allLabels.map(l => updatedLabels.find(upl =>labelIdsForReplace[l.id] === upl.id) || l))
        setInitialLabels(updatedLabels)
      }

      if(objOfArraysHasProps(multiSelectDiff)) {
        setMS(updatedMultiSelect)
        setInitialMultiSelect(updatedMultiSelect)
      }

      if(objOfArraysHasProps(multiSelectNamesDiff)) {
        setMultiSelectNames(updatedMultiSelectNames)
        setInitialMultiSelectNames(updatedMultiSelectNames)
      }

      setCharEditingId(null)
    })
  }

  const cancelCharGroup = () => {
    if(charGroups.filter(g => g.newGroup)) {
      setCharsGroups(
        charGroups.filter(group => 
          !group.newGroup 
        )
      ) 
    } else if(objOfArraysHasProps(charGroupsDiff)) {
      const updatedList = charGroups.map(group => group.id !== thisCharGroup.id ? group : {
        ...group,
        ...initialCharGroup
      })
      setCharsGroups(updatedList)
    }

    if(objOfArraysHasProps(labelsDiff)) {
      const labelsWithoutChangesInThisGroup = allLabels.flatMap(l => {
        if(l.char_group === thisCharGroup.id && l.newLabel) return []
        if(l.char_group === thisCharGroup.id) return initialLabels.find(initL => initL.id === l.id) || []
        return l
      })
      labelsWithoutChangesInThisGroup.push(...initialLabels.flatMap(initl => labelsDiff.deleted.some(deleted => initl.id === deleted) ? initl : []))
      setLabels(labelsWithoutChangesInThisGroup)
    }

    if(objOfArraysHasProps(multiSelectDiff)) {
      const multiSelectsWithoutChangesInThisGroup = allMultiSelect.flatMap(ms => {
        if(labels.some(l => l.char_group === thisCharGroup.id && l.id === ms.label_id)) {
          if(ms.newMultiSelect) return []
          return initialMultiSelect.find(initMs => initMs.id === ms.id) || []
        }
        return ms
      })

      multiSelectsWithoutChangesInThisGroup.push(
        ...initialMultiSelect.flatMap(initms =>
          multiSelectDiff.deleted.some(deleted => 
            initms.id === deleted
          ) ? initms : []
        )
      )

      setMS(multiSelectsWithoutChangesInThisGroup)
    }

    if(multiSelectNames.filter(name => name.newName).length) {
      setMultiSelectNames(
        multiSelectNames.filter(name => 
          !name.newName
        )
      )
    }

    setCharEditingId(null)
  }

  const showOptionsSwitcher = useMemo(() => charEditingId !== thisCharGroup.id && selectedOption?.values?.length, [charEditingId, thisCharGroup.id, selectedOption?.values?.length])

  const charClassName = useMemo(() =>
    `characteristic ${charEditingId === thisCharGroup.id ? `characteristic--editing` : ''}`
  , [charEditingId, thisCharGroup.id]);

  const clearCharData = () => char && clearDeletedCharData(char);

  const deleteCharGroup = async () => {
    const res = await patchReq(charGroupsRoutePath, [{
      id: thisCharGroup.id,
      deleted: true
    }])
    
    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    batch(() => {
      setCharsGroups(charGroups.filter(g => g.id !== thisCharGroup.id));
      setInitialCharGroups(initialCharGroups.filter(g => g.id !== thisCharGroup.id));
    })
  }

  return (
    <div className={charClassName}>
      <OneCharHeader
        thisCharGroup={thisCharGroup} 
        charEditing={charEditingId}
        selectItems={selectOptions}
        selectedItem={selectedOption}
        setCharScope={selectScopeHandler}
        setEditHandler={setEditHandler}
        clearCharData={clearCharData}
        initDeletionHandler={() => setToDelete(true)}
        page={page}
      />
      
      {showOptionsSwitcher ? 
        <CharOptionSwitcher
          selectedScopeValueId={selectedOptionValue?.id}
          items={selectedOption.values.filter(value => value !== undefined) as ProductsAttrValues[]}
          handler={selectScopeValueHandler}
        />
      : null}
      
      <Labels
        page={page}
        labels={labels}
        charEditing={charEditingId === thisCharGroup.id}
        charScope={charScope}
        group={thisCharGroup}
        labelsDispatch={labelsDispatch}
        removeLabelAndDispatch={removeLabelAndDispatch}
        charScopeValue={selectedOptionValue?.id || null}
      />

      {charEditingId === thisCharGroup.id ? (
        <CharFooter
          nothingToSave={nothingToSave}
          cancelHandler={cancelCharGroup}
          saveHandler={saveCharGroup}
          group={thisCharGroup}
        />
      ) : null}

      {toDelete ? 
        <CharGroupDeletePopup
          popupClose={() => setToDelete(false)}
          saveHandler={deleteCharGroup}
          group={thisCharGroup}
        />
      : null}
    </div>
  );
}

export interface SelectOption {
  id: Id | null
  text: string
  values: (ProductsAttrValues | undefined)[]
}

function getCurrentOptionValue ({valueId, selectedOption}: {valueId?: Id | null, selectedOption: SelectOption}): ProductsAttrValues | null { 
  if(!selectedOption?.values?.length) {
    return null
  }
  if(valueId) {
    return selectedOption.values.find(i => i && i.id === valueId) || null
  }

  return (selectedOption.values[0] as ProductsAttrValues)
};

export default memo(OneChar);
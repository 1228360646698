import {useCallback, useContext, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import {WebSocketContext} from "../web.socket";
import {enterPage, forceReenterViewers, leavePage, pageViewersCount} from "../../constants/ws-events";
import {Id} from "../../dto/master-dto/id.dto";
import {PageViewersDataDto} from "../../dto/ws-responses/page-viewers-data.dto";
import {WsResponse} from "../../dto/ws-responses/ws-response.dto";
import {EnterPageData} from "../../dto/ws-requests/page-viewers.dto";

const usePageViewers = (pageId: string): Array<Id> => {
	const {
		sendMessage,
		lastMessage,
	} = useContext(WebSocketContext);

	const [managersIds, setManagersIds] = useState<Array<Id>>([]);
	const [adminId] = useState<Id | null>(localStorage.getItem('admin'))

	const enterPageRequest = useCallback(() => {
		if(adminId) {
			sendMessage(JSON.stringify({
				event: enterPage,
				data: {
					pageId,
					adminId
				}
			}));
		}
	}, [pageId, sendMessage, adminId])

	useEffect(() => {
		enterPageRequest()

		return () => {
			if(adminId) {
				const data: EnterPageData = {
					pageId,
					adminId,
				}
				sendMessage(JSON.stringify({
					event: leavePage,
					data: data
				}));
			}
		};
	}, [pageId, sendMessage, adminId]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<PageViewersDataDto>;

			if (message.event === pageViewersCount &&
				message.data.pageId === pageId &&
				message.data.adminsOnPage
			) {
				const idsSet = message.data.adminsOnPage.map(id => +id);
				setManagersIds(idsSet);
			}
		}
	}, [lastMessage, pageId]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<PageViewersDataDto>;

			if (message.event === forceReenterViewers &&
				message.data.pageId === pageId
			) {
				setManagersIds([])
				enterPageRequest()
			}
		}
	}, [lastMessage, pageId]);

	return managersIds;
};

export default usePageViewers;

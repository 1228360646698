import React, { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { num2str, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../../scripts/hooks/use-load';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import MyButton from '../../../../_utility-components/button/button';
import ExportButton from '../../../../_utility-components/export-btn/export-btn';
import TopPagePanel from '../../../../_utility-components/top-page-panel';
import { Client } from '../../dto/client.dto';
import { renameExportHeaders } from '../../utils/rename-export-fields';
import ClientsDeletePopupRender from './client-delete-popup';
import { SetMassGroup } from './clients-set-group';

function ClientsTopPanel() {
  const _isMounted = useMounted()
  const clients = useAppSelector(state => state.clients)
  const clientGroups = useAppSelector(state => state.clientGroups)
  const selectedClients = useAppSelector(state => state.selectedClientsIds);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const [, setLoad] = useLoad()
  const dispatch = useDispatch()

  const clientsDispatch = (clients: Client[]) => dispatch({ type: 'CLIENTS', payload: clients })
  const setSelectedClientsDispatch = (ids: Id[]) => dispatch({ type: 'CLIENTS_SELECTED', payload: ids })

  const countText = num2str(selectedClients.length, ['клиент выбран', 'клиента выбрано', 'клиентов выбрано'])
  
  const deleteHandler = () => {
    if(!clients) {
      return false
    }
    
    setLoad(true)

    const idsToDelete = selectedClients

    mercheryFetch<boolean>('client', 'DELETE', {
      id: idsToDelete
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      batch(() => {
        setSelectedClientsDispatch([])
        clientsDispatch(
          clients.filter(client => 
            !idsToDelete.some(id => id === client.id)
          )
        )
      })
    })
    .finally(() => {
      setLoad(false)
    })
  };

  const multiChange = (changes: Partial<Client>) => {
    if(!clients) {
      return false
    }

    const idsToUpdate = selectedClients

    mercheryFetch<Client[]>('client', 'PATCH', {
      changes: idsToUpdate.map((id) => ({
        id: id,
        ...changes,
      }))
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      const updatedClients = res.records

      clientsDispatch(
        clients.map(client => 
          updatedClients.find(updated => updated.id === client.id) || client
        )
      )
    })
  }

  if(!clients || !selectedClients.length || !clientGroups) {
    return null
  }

  return (
    <div className="selected-top-page-menu">
      <TopPagePanel 
        topPanelOpened={Boolean(selectedClients.length)} 
        className={'selected-top-page-menu-inner'}
      >
        <div className="left">
          <MyButton
            className="uncheck-all-items selected-top-page-menu-btn" 
            onClick={() => setSelectedClientsDispatch([])}
          >
            {countText} 
            <div><div></div></div>
          </MyButton>

          <SetMassGroup 
            multiChange={multiChange}            
          />

          <div>
            <ExportButton<Client>
              text={'Выгрузить'}
              className={'selected-top-page-menu-btn'}
              items={clients.filter(o => selectedClients.some(so => so === o.id))}
              renameExportHeaders={client => renameExportHeaders({
                client,
                groups: clientGroups
              })}
            />
          </div>
        </div>
        
        <MyButton 
          className="right" 
          onClick={() => setDeletePopupOpened(true)}
        >
          <div><i className="icofont-trash"></i> Удалить</div>
        </MyButton>

        {deletePopupOpened ? 
          <ClientsDeletePopupRender 
            parentPopupClose={() => setDeletePopupOpened(false)} 
            selectedClients={selectedClients} 
            mainActionHandler={deleteHandler}
          />
        : null} 
        
      </TopPagePanel>
    </div>
  );
}

export default ClientsTopPanel;
import React, { useContext, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { numWithSep, priceWithPennies, ptsansCurrency, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { OrderContext } from '../../order-page';
import { RefundStatus } from '../../refunds/dto/refund-status.dto';
import { RefundDto } from '../../refunds/dto/refund.dto';
import SetStatus from '../../refunds/refunds-modules/set-status';
import PaymentPaymethod from '../payment-paymethod';
import RefundDelivery from './refund-delivery';
import RefundedItems from './refunded-items';

interface Props {
  refund: RefundDto
}

function OneRefundSection({
  refund
}: Props) {
  const _isMounted = useMounted();
  const refunds = useAppSelector(state => state.refunds)

  const {
    order, orderDeleted, 
  } = useContext(OrderContext)

  const dispatch = useDispatch();
  const refundsDispatch = (refunds: RefundDto[]) => dispatch({ type: 'ORDERS_REFUNDS', payload: refunds });
  const refundStatusesDispatch = (statuses: RefundStatus[]) => dispatch({ type: 'ORDERS_REFUND_STATUSES', payload: statuses});

  useEffect(() => {
    mercheryFetch<RefundStatus[]>('orders/refund/status', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      
      batch(() => {
        refundStatusesDispatch(res.records)
      })
    })
  }, [])

  const changeRefund = (id: Id, changes: Partial<RefundDto>) => {
    if(!refunds) {
      return false
    }

    mercheryFetch<RefundDto[]>('orders/refund', 'PATCH', {
      changes: [{
        id: id,
        ...changes
      }]
    })
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        const updatedRefunds = res.records
        const refundsWithUpdated = refunds.map((r) => updatedRefunds.find(ur => ur.id === r.id) || r)
        
        refundsDispatch(refundsWithUpdated)
      }
    })
  }

  const statusHandler = (statusId: Id) => 
    changeRefund(refund.id, { status_id: statusId });

  return (
    <section className='order-page__refund order-page__section'>
      <div className='order-page__refund__header'>

        <h3 className='header-font-m'>Возврат</h3>
        
        <SetStatus 
          className='header-font-xxs'
          refund={refund} 
          disabled={!order || orderDeleted}
          statusHandler={statusHandler}          
        />

      </div>

      <div className='order-page__refund__body'>
      </div>

      <RefundedItems 
        refund={refund}
      />

      <div className='order-page__refund__footer'>
        <h3 className='header-font-m'>Итого к возврату</h3>
        
        <div className='order-page__refund-total header-font-m'>
          – {numWithSep(priceWithPennies(refund.refund_total))} {ptsansCurrency('₽')}
        </div>
      </div>

      <RefundDelivery/>

      <PaymentPaymethod/>

    </section>
  );
}

export default OneRefundSection;
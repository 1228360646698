import React, { useMemo } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from "../../../../_utility-components/button/button";

interface Props {
  charsHaveChanges: boolean,
  saveHandler: () => void,
  cancelHandler: () => void,
  disabled?: boolean
}

export default function CharsTop ({
  charsHaveChanges,
  saveHandler,
  cancelHandler,
  disabled
}: Props) {
  const pageTopPanelShown = useAppSelector(state => state.topPanelShown)
  const topPanelHeight = useAppSelector(state => state.staticValues.top_panel_height)
  
  const topStyle = useMemo(() => ({
    ...(pageTopPanelShown && {
      top: +topPanelHeight - 1 + 'px'
    })
  }), [pageTopPanelShown, topPanelHeight]);

  return (
    <div
      id='chars-top'
      className='characteristics-top side-padding-24'
      style={topStyle}
    >
      <h3 className="product-page-h3 section-title">Характеристики</h3>

      {charsHaveChanges ?
        <div className='characteristics-top__btn-wrapper'>
          <MyButton
            className={'white-btn btn_side-padding_36'}
            onClick={cancelHandler}
          >
            Отменить
          </MyButton>
          <MyButton
            {...(disabled !== undefined && {disabled})}
            className={'blue-btn btn_side-padding_36'}
            onClick={saveHandler}
          >
            Сохранить
          </MyButton>
        </div>
      : null}
    </div>
  )
}
import React, { useCallback, useEffect, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import FallbackComponent from '../../../_utility-components/error-boundary';
import { PlainProduct } from '../dto/products.dto';
import { ProductIntoCollections } from './product-into-collections';
import { ProductTypeComponent } from './type';
import SelectProductCategory from './select-product-category';
import { TagsOfProduct } from './tags-of-product';
import MyCheckbox from '../../../_utility-components/checkbox/my-checkbox';
import VisibilitySelect from '../../../_utility-components/visibility-select/visibility-select';
import VisualSeparatorLine from "../../../_utility-components/visual-separator-line";

function RightSideBar() {
  const product = useAppSelector(state => state.product);
  const dispatch = useDispatch();

  const productChange = useCallback((changes: Partial<PlainProduct>) =>
    dispatch({type: 'PRODUCT_ITEM', payload: {...product, ...changes}})
  , [dispatch, product])

  if(!product) {
    return null
  }

  return (
    <section className='product-page-element product-page__right-side-bar side-padding-24'>

      <SelectProductCategory 
        selectedCategoryId={product.top}
        topChangeHandler={productChange}
      />

      <VisibilitySelect
        element={product}
        onChange={productChange}
      />

      <VisualSeparatorLine/>

      <ProductTypeComponent
        productChange={productChange}
      />

      <ProductIntoCollections />

      <TagsOfProduct/>
      
    </section>
  );
}

export default withErrorBoundary(RightSideBar, { FallbackComponent: FallbackComponent });

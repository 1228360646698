import qs from 'qs';
import React from 'react';
import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { batch } from 'react-redux';
import { Id } from '../../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { toastDown, uuidv4, validateResponse } from "../../../../../scripts/functions";
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import MyButton from '../../../../_utility-components/button/button';
import MyInput from "../../../../_utility-components/input/index";
import { ProductsAttrValues, ProductOption } from '../dto/options.dto';
import { ProductOneOptionPossibleValues } from "./possible-value";
import draggable from '../../../../../img/draggable.svg';

interface Props {
  option: ProductOption,
  edit: boolean,
  changeOption: (changes: Partial<ProductOption>) => void,
  warning: boolean,
  setWarning: (bool: boolean) => void,
  needCallPossibleValues: boolean,
  setNeedCallPossibleValues: (bool: boolean) => void,
}

export function ProductOneOptionValues({
  edit,
  option,
  changeOption,
  warning,
  setWarning,
  needCallPossibleValues,
  setNeedCallPossibleValues
}: Props) {
  const [iniitalPossibleValues, setIniitalPossibleValues] = useState<ProductsAttrValues[]>([]);
  const [newValue, setNewValue] = useState<string>('');
  const _isMounted = useMounted()
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  // const options = useAppSelector(state => state.productOptions)
  const values = useMemo(() => [...option.values], [option.values])

  const possibleValues = useMemo(() => {
    if(!iniitalPossibleValues.length) {
      return []
    }
    if(!values.length) {
      return iniitalPossibleValues
    }

    return iniitalPossibleValues
      .filter(r => 
        !values
        .some(v => v?.value === r?.value)
      )
  }, [iniitalPossibleValues, values])

  useEffect(() => {
    if(edit) {
      getAttributeValue()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])

  useEffect(() => {
    let timeOutId = setTimeout(() => {
      return newValue ? addNewValue(newValue) : false
    }, 10);
    return () => clearTimeout(timeOutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue]);

  useEffect(() => {
    if(needCallPossibleValues) {
      getAttributeValue()
      setNeedCallPossibleValues(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needCallPossibleValues])

  const addNewValue = useCallback((initialValue: string, id: Id | undefined = undefined) => {
    const maxOrder = Math.max.apply(null, values.map((v) => v.order))
    const valueOrder = (maxOrder < 1 ? values.length : maxOrder) + 1

    const newValue: ProductsAttrValues = {
      id: id || uuidv4(),
      name_id: option.name_id,
      order: valueOrder,
      value: initialValue || '',
      add_info: '',
      ...(id === undefined && {
        newValue: true
      })
    }

    batch(() => {
      setNewValue('')
      changeOption({
        values: [
          ...values, 
          newValue,
        ]
      })
    })
    
    setTimeout(() => {
      const inputElement = document.querySelector(`.product-page-additional-options-one-option-values-input-wrapper[id='option-value-${option.order}-${valueOrder}'] .merchery-label__input`) as HTMLElement | null;
      inputElement?.focus()
    }, 0.4)

  }, [values, option.name_id, option.order, changeOption])

  const deleteOptionValue = useCallback((valueId: Id) => {
    changeOption({
      values: 
        values.filter((v) => valueId !== v.id)
          .sort((a,b) => a.order - b.order)
          .map((v, index) => ({...v, order: index + 1}))
    });
  }, [values, changeOption])

  const valueChange = useCallback((valueId: Id, value: ProductsAttrValues) => {
    if(warning) {
      toastDown('.merchery-wrapper')
      setWarning(false)
    }
    changeOption({
      values: values.map((v) => valueId === v.id ? value : v)
    })
  }, [values, setWarning, changeOption, warning])

  const getAttributeValue = () => {
    if(option.newName) {
      return false
    }
    const params = qs.stringify({
      name_id: option.name_id,
    }, {arrayFormat: 'comma'})
    
    mercheryFetch<ProductsAttrValues[]>(`products/attributes/get_attr_values?${params}`, 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      setIniitalPossibleValues(res.records)
    })
  }

  if(!edit) {
    return null
  }
  
  const onDragStart = () => {
    if(moySkladIntegrationOn) return false
    const elemnet = document?.activeElement as HTMLElement | null
    elemnet?.blur()
  }

  const onDragEnd: OnDragEndResponder = (result) => {
    try {
      if(moySkladIntegrationOn || !result.destination) {
        return false
      }

      if (result.type.includes("value") && result.source.index !== result.destination.index) {
        const updatedValues = [...values]

        const [removed] = updatedValues.splice(result.source.index, 1);
        updatedValues.splice(result.destination.index, 0, removed);
      
        changeOption({
          values: updatedValues.map((item, index) => ({ ...item, order: index + 1 }))
        });
      }

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='product-page-additional-options-one-option-values-wrapper'>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <div className='product-page-additional-options-one-option-values'>

          <div className='product-page-additional-options-one-option-values-h-wrapper header-font-s'>
            <div>
              Значения
            </div>

            {possibleValues?.length ? (
              <ProductOneOptionPossibleValues
                possibleValues={possibleValues}
                addNewValue={addNewValue}
              />
            ) : null}
          </div>

          <div className='product-page-additional-options-one-option-values-inputs'>
            <Droppable type={`values`} droppableId={`${option.order}-value`}>
              {(provided, snapshot) => (
                <div 
                  className={`product-page-additional-options-one-option-values-inputs-wrapper`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {values
                  .sort((a,b) => a.order - b.order)
                  .map((value, index) => {
                    return (
                      <Draggable
                        key={value.id}
                        draggableId={'' + value.id} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className='product-page-additional-options-one-option-values-input-wrapper' 
                            id={`option-value-${option.order}-${value.order}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className={`product-page-additional-options-one-option-values-input-left`}>
                              <div 
                                {...provided.dragHandleProps} 
                                tabIndex={-1}
                                className={`grab-zone`}>
                              </div>

                              <MyInput
                                maxLength={124}
                                value={value?.value || ""}
                                myClassName={'product-page-option-values'}
                                onChange={(e) => 
                                  valueChange(value.id, {
                                    ...value,
                                    value: e.target.value
                                  })}
                                noPlaceholder
                              />

                            </div>
                            
                            <MyButton
                              tabIndex={-1} 
                              onClick={() => deleteOptionValue(value.id)}
                              className={`product-page-additional-options-one-option-context-btn delete-btn`}
                            >
                              <i className="icofont-ui-delete"></i>
                            </MyButton>
                          </div>
                        )}
                      </Draggable>
                    )}
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <div className='product-page-additional-options-one-option-values-blank-value'>
              <MyInput
                placeholder={'Добавить еще одно значение'}
                myClassName={'product-page-option-values'}
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                required={false}
              />
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  )
}
import React from 'react';
import { ptsansCurrency } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';

interface PromocodeStatusProps {
  value: string | number;
  isPercent: boolean;
  createCodeHandler: () => void
}
export function PromocodeStatus({
  value, 
  isPercent,
  createCodeHandler
}: PromocodeStatusProps) {

  return (
    <>
      <div className={value ? '' : 'no-code'}>
        {value ?
          <>
            Номинал: {value}
            &nbsp;
            {isPercent ? '%' : ptsansCurrency('₽')}
          </>
          : 'Код не существует'}
      </div>

      <MyButton
        removeDefaultClass
        className="promo-create-btn"
        onClick={createCodeHandler}>
        {value ? 'Очистить' : 'Создать код'}
      </MyButton>
    </>
  );
}

import React from 'react';
import withRowComponents, { RowProps } from 'src/scripts/with-row-components';
import { ProductItem } from '../dto/product-item.dto';
import { ExtendedProduct } from '../dto/products.dto';
import { ProductVariantExtended } from '../dto/variants.dto';

const ProductItemRow: React.FC<RowProps<ProductItem>> = withRowComponents<RowProps<ProductItem>>(() => <></>);

const ProductRow: React.FC<RowProps<ExtendedProduct>> = withRowComponents<RowProps<ExtendedProduct>>(() => <></>);

const ProductVariantRow: React.FC<RowProps<ProductVariantExtended>> = withRowComponents<RowProps<ProductVariantExtended>>(() => <></>);

export {
  ProductItemRow,
  ProductRow,
  ProductVariantRow
};


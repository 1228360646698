import React, { useState } from 'react';
import triangleDown from '../../../../img/triangle-down.png';
import MyButton from 'src/components/_utility-components/button/button';
import Popup from 'src/components/_utility-components/popup';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { OrderStatus } from '../../../../dto/order-status.dto';
import useOrderFilters from "../hooks/use-order-filters";
import {Order} from "../dto/order.dto";
import {FilterChanger} from "../../../../scripts/utils/entity-filters";

interface Props {
}

function HeaderStatusFilter({
}: Props) {
  const status = useAppSelector(state => state.orderStatus);
  const [, filterChanger, cleanFilters] = useOrderFilters();
  const [popupOpened, setPopupOpened] = useState(false);
  const popupClose = () => setPopupOpened(false);

  if(!status) {
    return null
  }

  return (
    <div className="filter-btn status_filter-btn">
      <MyButton
        className="filter-btn-inner" 
        removeDefaultClass
        onMouseDown={e => e.button === 0 && setPopupOpened(!popupOpened)}
        onKeyUp={e => e.key === 'Enter' && setPopupOpened(!popupOpened)}
      >
        Статусы <img alt="" src={triangleDown}/>
      </MyButton>

      {popupOpened ? (
        <Popup
          popupClose={popupClose}
          className={'status left-8px'}
        >
          <div className="popup-group">
            {status.map((os) => 
              <StatusFilterOption 
                key={os.id} 
                os={os}
                filterChanger={filterChanger}
              />
            )}
          </div>
          <div className="popup-group">
            <MyButton className="popup-element general-popup-clean-btn" 
              onClick={() => {
                cleanFilters('status')
                popupClose()
              }}
            >
              <div>Очистить</div>
            </MyButton>
          </div>
        </Popup>
      ) : null}

    </div>
  );
}

export default HeaderStatusFilter;

export function StatusFilterOption({
  os,
  filterChanger,
  withoutCheckbox
}: {
  os: OrderStatus,
  filterChanger: FilterChanger<Order>
  withoutCheckbox?: true
}) {
  const [filters, ] = useOrderFilters();

  if(!filters) {
    return null;
  }

  const thisFilterItem = filters.status && filters.status.find(s => s.id === os.id);
  const clickHandler = () => filterChanger({ itemIds: [os.id], filterName: 'status' });
  const isActive = thisFilterItem?.selected

  return (
    <div className="popup-group">
      <MyButton
        removeDefaultClass
        onClick={clickHandler}
        className={`popup-element ${isActive ? 'active' : ''}`}
      >
        {!withoutCheckbox ? 
          <div className="checkbox">
            <i className="icofont-check-alt"></i>
          </div>
        : null}
        <span className={'status_filter-btn__text'}>
          {os.name}
          <div 
            className={os.type === 'New' ? 'grayColor' : ''}
            style={{ backgroundColor: os.type === 'New' ? '' : os.color }}
          ></div>
        </span>
      </MyButton>
    </div>
  )
}

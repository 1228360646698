import React, { useContext } from 'react';
import { ptsansCurrency } from '../../../../../scripts/functions';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import { OrderDeliveryContext, Tariff } from '../delivery/delivery';

interface Props {
  className?: string
  tariffHandler: (tariff: Tariff) => void
  openBtnText: JSX.Element | string,
  MainActionBtnText: (tariff: Tariff) => JSX.Element
}

function DeliveryPriceAndMode({
  className,
  tariffHandler,
  openBtnText,
  MainActionBtnText
}: Props) {
  const {RenderButton, RenderPopup, closePopup} = usePopup();

  const {
    from,
    currentTariff,
    allStoreTariffs,
  } = useContext(OrderDeliveryContext)

  const tariffsForCurrentDeparture = from ? allStoreTariffs[from.id] : null

  const tariffSelectAndClose = (tariff: Tariff) => {
    tariffHandler(tariff)
    closePopup()
  }

  return (
    <div className={className ? className : "delivery-price-and-mode header-font-m"}>
      <RenderButton
        removeDefaultClass
        className="delivery-mode" 
        disabled={!tariffsForCurrentDeparture?.length}
      >
        {openBtnText}
      </RenderButton>

      {currentTariff && currentTariff.price !== undefined ?
        <div className="delivery-price delivery-tariff-row-header">
          {currentTariff.price} {ptsansCurrency('₽')}
        </div>
      : null}

      <RenderPopup
        className={'delivery-tariff'}>
        <div className='popup-group'>
          {tariffsForCurrentDeparture ? 
            tariffsForCurrentDeparture.map((tariff) => (
              <MyButton
                className='delivery-tariff-row popup-element' 
                key={tariff.id} 
                onClick={() => tariffSelectAndClose(tariff)}
              >
                {MainActionBtnText(tariff)}
              </MyButton>
            ))
          : null}
        </div>
      </RenderPopup>
    </div>
  );
}

export default DeliveryPriceAndMode;
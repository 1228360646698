import React, {KeyboardEvent, MouseEvent, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import useRowFocus from 'src/scripts/hooks/use-row-focus';
import { Id } from '../../../../dto/master-dto/id.dto';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import {validateResponse} from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import MyTable from '../../../_utility-components/common-table/table';
import ConfirmPopup from '../../../_utility-components/confirm-popup';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import { SetReorder } from '../dto/set-reorder.api';
import { SetDto, SetReorderedItem } from '../dto/set.dto';
import { SetsContext } from '../sets';
import SetTableHeader from './header';
import HeaderSelectedControl from './header-selected-control';
import {useLocation} from "react-router";
import {useTabIndex} from "../../../../scripts/hooks/use-tabindex";
import {BodyCell} from "../../../_utility-components/common-table/body-cell";
import DragElement from "../../products/products-page-modules/table-body/table-row/drag-element";
import MyCheckbox from "../../../_utility-components/checkbox";
import Picture from "../../../_utility-components/picture/picture";
import {extractImages} from "../../../../scripts/utils/extractImages";
import useRowClick from "../../../../scripts/hooks/use-row-click";
import {HideableEntityHideBtn} from "../../products/products-page-modules/table-body/table-row/row";

function SetsTable() {
  const _isMounted = useMounted()
  const timeOutId = useRef<NodeJS.Timeout | undefined>(undefined);
  const activeRowId = useRef<Id | null>(null);
  const sets = useAppSelector(state => state.sets);

  const { focusedItem, focusHandler } = useRowFocus<SetDto>(null);
  const {
    selectedSets,
    setSelectedSets
  } = useContext(SetsContext);
  const [popupOpened, setPopupOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const setsDispatch = (sets: SetDto[]) => dispatch({type: 'SETS', payload: sets});

  const tabIndex = useTabIndex()
  const location = useLocation()

  const categoryPage = (id: Id) => ({
    to: `/app/sets/${id}`,
    state: {prevPage: location.search}
  })
  const [rowClick, setActiveRowId] = useRowClick(
    categoryPage,
    ['.prevent-page-transition', ]
  );

  useEffect(() => {
    getSets()

    return () => {
      clearTimeout(timeOutId.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedSets = useMemo(() => 
    [...(sets || [])].sort((a, b) => a.order - b.order)
  , [sets])

  const getSets = () => {
    setLoading(true)
    mercheryFetch<SetDto[]>(`sets`, 'GET')
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false

      setsDispatch(res.records)
    }) 
    .finally(() => {
      setLoading(false)
    })
  };

  const rowCheckHandler = (id: Id) => {
    activeRowId.current = id;

    const updatedSelectedIds = selectedSets.some(sId => sId === id) ? selectedSets.filter(sId => sId !== id) : [...selectedSets, id]
    setSelectedSets(updatedSelectedIds);
  };

  const headerCheckHandler = () => {
    if(!sortedSets) {
      return false
    }
    
    setSelectedSets(selectedSets.length !== sortedSets.length ? sortedSets.map(c => c.id) : [])
  };
  
  const deletedSets = () => {
    if(!sortedSets) {
      return false
    }
    
    const setsBackup = [...sortedSets]

    setsDispatch(sortedSets.filter(c => !selectedSets.some(id => id === c.id)))

    mercheryFetch<boolean>('sets', 'DELETE', {
      id: selectedSets
    })
    .then((res) => {
      if(!_isMounted.current) return false;
      if(!validateResponse(res) || !res.records) {
        setsDispatch(setsBackup)
      } else {
        setSelectedSets(selectedSets.filter(id => !setsBackup.some(c => c.id === id)))
      }
    })
    .finally(() => {
      setPopupOpened(false)
    })
  }

  const reorder = (reorderData: SetReorder) => {
    if(!sortedSets) {
      return false
    }

    mercheryFetch<SetReorderedItem[]>('sets/reorder', 'PATCH', reorderData)
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        
        setsDispatch(sortedSets.map(c => {
          const reorderedSet = res.records.find(reorderedItem => reorderedItem.id === c.id)
          return reorderedSet ? { ...c, ...reorderedSet } : c;
        }))
      }
    })
  }

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if(!sortedSets || result.destination?.index === undefined) {
      return false
    }
    
    const newList = [...sortedSets];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    
    setsDispatch(newList.map((item, index) => ({...item, order: index + 1})))

    reorder({
      id: +result.draggableId,
      difference: result.destination.index - result.source.index
    })
  }

  const changeData = (id: Id, changes: Partial<SetDto>) => {
    const requestBody = {
      toChange: [{
        id: id,
        ...changes,
      }]
    }

    const path = 'sets';

    mercheryFetch<SetDto[]>(path, 'PATCH', requestBody)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      const sets = sortedSets.map(c => {
        const reorderedColl = res.records.find(reorderedItem => reorderedItem.id === c.id)
        return reorderedColl ? { ...c, ...reorderedColl } : c
      })

      setsDispatch(sets)

      return true
    })
  }


  if(!sortedSets || loading) {
    return <CommonTableLoader/>
  }

  if(!sortedSets.length) {
    return <div>
      Комплекты отсутствуют
    </div>
  }

  const checkboxIsActive = sortedSets.length > 0 && sortedSets.length === selectedSets.length;

  return (
    <MyTable id="sets-table" myClassName='sets__table collections__table'>
      <div className='table__inner'>

        {!selectedSets.length ?
          <SetTableHeader headerCheckHandler={headerCheckHandler} 
            checkboxIsActive={checkboxIsActive}
          />
        :
          <HeaderSelectedControl 
            headerCheckHandler={headerCheckHandler} 
            selectedSetsIds={selectedSets}
            checkboxIsActive={checkboxIsActive} 
            deleteHandler={() => setPopupOpened(true)}
          />
        }

        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId={`sets`} type={`sets`}>
            {(provided, snapshot) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sortedSets
                  .map((set, index) => {
                    const focused = focusedItem?.id === set.id ? 'focused' : ''; // eslint-disable-next-line
                    const active = selectedSets.some(s => s == set.id) ? 'active' : '';

                    const thisCollectionNameOrCollectionsCount = !set.setCollectionItems
                      ? 0
                      : set.setCollectionItems.length === 1
                        ? set.setCollectionItems[0].collectionItem.name
                        : set.setCollectionItems.length === 0
                          ? ''
                          : set.setCollectionItems?.length;

                    return (
                      <Draggable
                        draggableId={'' + set.id}
                        index={index}
                        key={set.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={active + ' table__row sets__row ' + focused}
                            onFocus={() => focusHandler(set.id, sortedSets)}
                            onMouseUp={e => rowClick(e, set.id)}
                            onMouseDown={() => setActiveRowId(set.id)}
                            onKeyUp={e => e.key === 'Enter' ? rowClick(e, set.id) : null}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            tabIndex={tabIndex}
                          >
                            <BodyCell myClassName='control-cell prevent-page-transition'>
                              <DragElement provided={provided} />
                              <MyCheckbox text={''}
                                          condition={selectedSets.some(sId => sId === set.id)}
                                          actionHandler={() => rowCheckHandler(set.id)} />
                            </BodyCell>

                            <BodyCell myClassName='image__cell'>
                              <Picture images={extractImages(set.src, 'small') || null} />
                            </BodyCell>

                            <BodyCell>
                              {set.name}
                            </BodyCell>

                            <BodyCell>
                              {thisCollectionNameOrCollectionsCount}
                            </BodyCell>

                            <BodyCell myClassName='center-align'>
                              {set.products_ids.length}
                            </BodyCell>

                            <BodyCell myClassName={'prevent-page-transition'}>
                              <HideableEntityHideBtn
                                changeData={changeData}
                                entity={set}
                              />
                            </BodyCell>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {popupOpened ?
        <ConfirmPopup confirmHandler={deletedSets}
          deletion={true}
          children={
            <>
              <div className='confirm-popup__paragraph'>
                Удаление комплектов
              </div>
            
              <div className='confirm-popup__paragraph'>
                Следующие комплекты будут удалены: <br/>
                <b>
                  {sortedSets.filter(c => selectedSets.some(id => id === c.id))
                    .map(c => c.name)
                    .join(", ")
                  }
                </b>
              </div>

              <div className='confirm-popup__paragraph'>
                Вы уверены, что хотите продолжить?
              </div>
            </>
          }
          popupClose={() => setPopupOpened(false)}
        />
      : null}
    </MyTable>
  );
}

export default SetsTable;


const eventIsMouseEvent = (event: MouseEvent | KeyboardEvent): event is MouseEvent => {
  return (event as MouseEvent).button !== undefined
}
import React from 'react';
import PaymentPaymethod from './payment-paymethod';

function OrderPayment() {
  // const paidSum = priceWithPennies(order.paid_sum)
  // const successStatus = order.total && order.paid_sum >= order.total

  return (
    <section className='order-page__payment order-page__section'>
      <div className='order-page__payment__header'>

        <h3 className='header-font-m'>Оплата</h3>

        {/* <div className={`header-font-m order-page__payment-sum ${successStatus ? 'success' : ''}`}>
          {paidSum} {ptsansCurrency('₽')}
        </div> */}

      </div>

      <PaymentPaymethod/>
    </section>
  );
}

export default OrderPayment;
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { querify, uuidv4, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import transliterate from '../../../../scripts/utils/transliteration';
import { useLoad } from '../../../../scripts/hooks/use-load';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { PossibleValuesPopup, usePossibleValues } from '../../../../scripts/hooks/use-possible-values';
import Bubbles from '../../../_utility-components/bubbles';
import MyInput from '../../../_utility-components/input/index';
import { CollectionCreate } from '../../collections/dto/collection-create.api';
import { CollectionFiltersApi } from '../../collections/dto/collection.filters.api';
import { CollectionDto } from '../../collections/dto/collections.dto';

export function ProductIntoCollections() {
  const product = useAppSelector(state => state.product);
  const _isMounted = useMounted()
  const inputRef = useRef<HTMLInputElement | null>(null);

  const collections = useAppSelector(state => state.collections)
  const [collectionsLoaded, setCollectionsLoaded] = useState(false);
  const [collectionsWithThisProduct, setCollectionsWithThisProduct] = useState<CollectionDto[]>([]);

  const dispatch = useDispatch()
  const collectionsDispatch = (collections: CollectionDto[]) => dispatch({ type: 'COLLECTIONS', payload: collections})

  const [, setLoaded] = useLoad()
  
  const {setSearch, search, setShowData, dataLoading, data, showData} = usePossibleValues<CollectionDto>({
    urlPath: 'collections',
  });

  useEffect(() => {
    if(product && !product.newProduct && !collectionsLoaded) {
      const query = querify({
        filters: { product: product?.id },
        ...(search && {search})
      });

      mercheryFetch<CollectionDto[]>(`collections?${query}`, 'GET')
      .then(res => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        batch(() => {
          setCollectionsWithThisProduct(res.records)
          setCollectionsLoaded(true)
        })
        return res.records
      })
    }
  }, [product])

  const addProductToCollection = async (collection: CollectionDto) => {
    if(!collection || !product) {
      return false
    }

    let res = await mercheryFetch<CollectionDto>('collections/items', 'POST', {
      collection_id: collection.id,
      items: [{
        item_id: product.id,
        item_type: 'product',
      }]
    })

    batch(() => {
      if(_isMounted.current && res && validateResponse(res)) {
        setSearch('')
        setShowData(false)
        setCollectionsWithThisProduct([
          ...collectionsWithThisProduct,
          res.records
        ])
      }
    })

    return res
  }

  const removeProductFromCollection = async (collectionToRemoveFrom: CollectionDto) => {
    if(!collectionToRemoveFrom || !product) {
      return false
    }

    let res = await mercheryFetch<CollectionDto[]>('collections/items', 'DELETE', {
      collection_id: collectionToRemoveFrom.id,
      items: [{
        item_id: product.id,
        item_type: 'product'
      }]
    })

    if(_isMounted.current && res && validateResponse(res)) {
      setCollectionsWithThisProduct(
        collectionsWithThisProduct.filter(
          coll => coll.id !== collectionToRemoveFrom.id
        )
      )
    }
  }

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearch(e.target.value)
  };

  const createCollection = (name: string) => {
    if(!collections) {
      return false
    }
    
    const newCollection: CollectionCreate = {
      id: uuidv4(),
      name: name,
      url: transliterate(name).toLowerCase(),
      show_date: null,
      sorting_id: 1,
      template_id: null,
      description: null,
      seo_title: null,
      seo_description: null,
      og_title: null,
      og_description: null,
      newCollection: true
    }

    setLoaded(true)

    mercheryFetch<CollectionDto>('collections', 'POST', {
      ...newCollection,
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      const collectionsWithoutNew = collections.filter(c => !c.newCollection)

      batch(() => {
        collectionsDispatch([...collectionsWithoutNew, res.records])
      })
      return res.records
    })
    .then((coll) => {
      coll && addProductToCollection(coll)
    })
    .finally(() => {
      setLoaded(false)
    })
  }

  return (
    <div className='product-page__right-side-bar__label product-page__collections'>
      <h5 className="header-font-s">
        Коллекции
      </h5>

      <MyInput
        inputRef={inputRef}
        value={search}
        onChange={inputHandler}
      />
      
      <PossibleValuesPopup
        clickHandler={addProductToCollection} 
        dataLoading={dataLoading} 
        search={search}
        withAddNew={createCollection}
        data={
          data.filter(coll => 
            !collectionsWithThisProduct.some(added => 
              added.id === coll.id
            )
          )
        } 
        showData={showData} 
        setShowData={setShowData}
      />

      <Bubbles<CollectionDto>
        deleteHandler={removeProductFromCollection}
        items={collectionsWithThisProduct}
      />
    </div>
  )
}

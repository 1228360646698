import React, { useMemo } from 'react';
import { ImageExtended } from '../../../../../../dto/images/images.dto';
import { extractImages } from '../../../../../../scripts/utils/extractImages';
import Picture from '../../../../../_utility-components/picture/picture';

function OpImage({images}: {
  images: ImageExtended[]
}) {
  const extractedImages = useMemo(() => {
    return extractImages(images.find(i => i.main) || images[0], 'small') || null
  }, [images])
  
  return (
    <div className="op-image">
      <Picture images={extractedImages}/>
    </div>
  );
}

export default OpImage;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Id } from '../../../dto/master-dto/id.dto';
import { usePopup } from '../../../scripts/hooks/use-popup';
import useTabBlur from '../../../scripts/hooks/use-tab-blur';
import { useTabIndex } from '../../../scripts/hooks/use-tabindex';
import MyInput from '../input/index';
import { MyInputProps } from '../input/input';


export interface SelectProps<T extends {[name: string]: any}> extends Partial<SelectItemProps<T>> {
  items: T[]
  selectedItem?: T
  selectHandler: (item: T) => void
  idLabelName?: keyof T
  valueLabelName?: keyof T
  className?: string
  tabIndexLevel?: number
  children?: (selectContext: SelectInputContext<T>) => JSX.Element
  searchable?: boolean
  inputProps?: Partial<MyInputProps> 
  openListEffect?: () => Promise<void>
}

interface SelectItemProps<T extends {[name: string]: any}> {
  shownItems: T[]
  idLabelName: keyof T
  valueLabelName: keyof T
  focused: (index: number) => string
  selectHandler: (item: T) => void
  closePopup: () => void
  itemStyle?: (item: T, index: number) => CSSProperties
}

export interface SelectInputContext<T> {
  searchText: string;
  input: React.RefObject<HTMLInputElement>;
  shownItems: T[];
  items: T[];
  focused: (index: number) => "" | "focused";
  groupProps: {
    className: string;
  };
  elementProps: {
    className: string;
  }
}

export interface СertainSelectProps<T> {
  selectedItemId: Id | null,
  selectHandler: (item: T) => void,
}

function SelectInput<T extends {[name: string | number]: any}>({
  items,
  selectedItem,
  tabIndexLevel = 1,
  selectHandler,
  idLabelName = 'id',
  valueLabelName = 'text',
  className = '',
  searchable = false,
  inputProps = {},
  children = undefined,
  openListEffect = undefined,
  ...itemsProps
}: SelectProps<T>) {
  const input = useRef<HTMLInputElement>(null);
  
  const tabIndex = useTabIndex(tabIndexLevel)

  const { RenderPopup, openPopup, closePopup, isOpen} = usePopup()

  const [currentItem, setCurrentItem] = useState(selectedItem);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  
  const shownItems = searchable && searchText ? filteredItems : items
  const [focusIndex, setFocusIndex] = useState(0);

  useEffect(() => {
    if(searchable) {
      !isOpen && searchText && showList()

      const matches = items.filter(i => i[valueLabelName].toLowerCase().includes(searchText.toLowerCase()))
      setFilteredItems(matches)
    }
  }, [searchText, items, searchable])

  useEffect(() => {
    searchable && setSearchText('')
    setCurrentItem(selectedItem)
  }, [selectedItem]);

  useEffect(() => {
    setFocusIndex(getFocusedListItem())
  }, [currentItem]);

  function getFocusedListItem () {
    return (currentItem && shownItems.findIndex(item => item?.[idLabelName] === currentItem[idLabelName])) || 0
  }

  function showList () {
    if(!openListEffect) {
      openPopup()
      return false
    }

    openListEffect()
    .then(
      openPopup
    )
  }

  const focused = (index: number) => focusIndex === index ? 'focused' : '';

  const arrowNav = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const keyId = e.keyCode;

    if(keyId !== 38 && keyId !== 40) return false

    e.preventDefault()

    if(!isOpen) {
      showList()
      return false
    }

    if(keyId === 38) { // arrow up 
      const prevIndexOrLast = focusIndex > 0 ? focusIndex - 1 : shownItems.length - 1
      setFocusIndex(prevIndexOrLast)
    } else if(keyId === 40) { // arrow down 
      const nextIndexOrFirst = shownItems.length - 1 !== focusIndex ? focusIndex + 1 : 0
      setFocusIndex(nextIndexOrFirst)
    }
  }

  useTabBlur(input, () => {
    closePopup()
  });

  const groupProps = {
    className: "popup-group"
  }
  const elementProps = {
    className: "popup-element my-select-option",
  }

  const selectContext: SelectInputContext<T> = {
    searchText,
    input,
    shownItems,
    items,
    focused,
    groupProps,
    elementProps,
  }

  const searchInputOnEnterHandler = () => {
    if(!isOpen) return false
    selectHandler(shownItems[focusIndex])
    input.current?.blur()
    closePopup()
  }

  const itemValue = currentItem && currentItem?.[valueLabelName]

  return (
    <div className={`my-select-input ${className}`}>
      <MyInput
        innerChildren={
          <div className='my-select-input__down-icon'>
            <i className='icofont-simple-down'/>
          </div>
        }
        {...(!searchable && {
          style: {
            pointerEvents: 'none', 
            userSelect: 'none'
          }
        })}
        inputRef={input}
        required={false}
        tabIndex={tabIndex}
        noPlaceholder={true}
        readOnly={!searchable}
        myClassName={`${!searchable ? 'cursor_pointer' : ''}`}
        onEnter={searchInputOnEnterHandler}
        onKeyDown={arrowNav}
        onFocus={showList}
        onMouseDown={(e) => e.button === 0 && showList()}
        title={itemValue}
        value={itemValue || ''}
        {...inputProps}
        {...(searchable && {
          onChange: (e) => setSearchText(e.target.value),
          value: searchText,
          placeholder: itemValue || 'Не выбрано',
        })}
      />

      <RenderPopup
        popupName={'.my-select-options'}
        className={'my-select-options'}
        changingDirection={true}
        tabIndexDeep={tabIndexLevel + 1}
      >
        {shownItems.length ? 
          <SelectInputItems
            shownItems={shownItems}
            focused={focused}
            closePopup={closePopup}
            idLabelName={idLabelName}
            valueLabelName={valueLabelName}
            selectHandler={selectHandler}
            {...itemsProps}
          />
        : 
          <div className='popup-group'>
            <div className='popup-element' style={{cursor: 'unset', opacity: .7}}>
              Список пуст
            </div>
          </div>
        }

        {children?.(selectContext) || <></>}
      </RenderPopup>
    </div>
  );
}

function SelectInputItems<T extends {[name: string]: any}>({
  shownItems,
  idLabelName,
  valueLabelName,
  focused,
  selectHandler,
  closePopup,
  itemStyle,
  ...itemProps
}: SelectItemProps<T>) {
  function getSelectedListItem (id: Id) {
    const found = shownItems.find(item => item[idLabelName] === id)
    if(!found) throw new Error()
    return found
  }

  const optionHandler = (id: Id) => {
    selectHandler(getSelectedListItem(id))
    closePopup()
  }

  return (
    <div className="popup-group my-select-options-group">
      {shownItems.map((item, index) => 
        <SelectOption
          key={item[idLabelName]}
          optionHandler={optionHandler}
          id={item[idLabelName]}
          text={item[valueLabelName]}
          className={focused(index)}
          style={itemStyle ? itemStyle(item, index) : undefined}
          {...itemProps}
        />
      )}
    </div>
  );
}

interface SelectOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  optionRef?: any
  optionHandler: (id: Id) => void
  id: string
  text: string
  className: string
}

function SelectOption({
  optionRef,
  optionHandler,
  id,
  text,
  className = '',
  ...rest
}: SelectOptionProps) {
  return (
    <div 
      ref={optionRef}
      className={`my-select-option ${className}`}
      onClick={() => optionHandler(id)}
    >
      <div {...rest}>
        {text}
      </div>
    </div>
  );
}

export default SelectInput;
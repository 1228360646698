import { SetDto } from "../../sets/dto/set.dto";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { querify, uniqByKeepLast, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import SearchInput from '../../../_utility-components/search-input/search-input';
import AddItemsLoader from '../../../_utility-components/loaders/add-items-loader';
import Popup from '../../../_utility-components/popup';
import { SetFiltersQuery } from "../../sets/dto/set-filters.api";
import useInfiniteScrollShowMore from "src/scripts/hooks/use-infinite-scroll-show-more";
import SetToBeAddedInCollection from "./set-to-be-added-in-collection";
import { AddItemsFooter } from "../../orders/order-page-popups/items-in-order-footer";

export interface Props {
  popupClose: () => void, 
  addItems: (items: SetDto[]) => Promise<boolean>, 
}

function AddSetsInCollection (props: Props) { 
  const {
    popupClose,
    addItems, 
  } = props;
  const _isMounted = useMounted()

  const sets = useAppSelector(state => state.sets);
  const itemsContainer = useRef(null);
  const [currentCount, setCurrentCount] = useState(0);
  const [allCount, setAllCount] = useState(0);

  const tabIndex = useTabIndex(2);

  const [searchInput, setSearchInput] = useState('');
  const [canBeAddedSelected, setCanBeAddedSelected] = useState<SetDto[]>([]);

  const setsItemsGetSize = 25;

  const dispatch = useDispatch()
  const setsDispatch = useCallback((sets: SetDto[]) => dispatch({ type: 'SETS', payload: sets}), [dispatch])
  
  const getSets = (props: SetFiltersQuery & {clearCount?: boolean, page?: number} = {}) => {
    const { filters, clearCount, page = 0 } = props;

    const query = querify({
      ...(filters && {filters}),
      pagination: {
        size: setsItemsGetSize,
        page,
      },
      ...(searchInput && {searchInput}),
    })

    return mercheryFetch<SetDto, true>(`sets/extend?${query}`, "GET", {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse<SetDto, true>(res)) return false

      const gettedSets = clearCount ?
        res.records.rows :
        uniqByKeepLast<SetDto>([ ...(sets || []), ...res.records.rows ], p => p.id) || [];
      
      const count = res.records.count;

      setsDispatch(gettedSets || [])
      setCurrentCount(gettedSets?.length || 0)
      setAllCount(count || 0)
      return gettedSets
    });
  };

  const [ hasMore, showMore] = useInfiniteScrollShowMore({
    getItems: getSets,
    currentCount: currentCount,
    getSize: setsItemsGetSize,
    allCount: allCount
  })


  useEffect(() => {
    getSets({
      clearCount: true
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  const cancelHandler = () => {
    popupClose()
  }

  const confirmHandler = async () => {
    await addItems(canBeAddedSelected)
    .then(res => {
      if(res === true) {
        popupClose();
      }
    }) 
  }

  if(!sets) {
    return null
  }

  return (
    <Popup
      popupClose={popupClose} 
      className={`hide-for-print items-in-order`} 
      popupName={'.items-in-order'}
      withBlackout
      withCloseBtn
      tabIndexDeep={2}
    >
      <div className="items-in-order-header">
        <div className="title-div">Добавление комплекта</div>

        <div className="search-item">
          <SearchInput 
            searchInput={searchInput}
            tabIndex={tabIndex}
            applySearch={setSearchInput} 
            />
        </div>
        <div className="text-div">Введите часть наименования комплекта или выберите категорию</div>
      </div>

      <div id="row-items-add-list" 
        ref={itemsContainer}
        className="items-in-order-body"
      >
        {itemsContainer.current ? 
          <InfiniteScroll
            dataLength={sets.length}
            next={showMore}
            hasMore={hasMore}
            scrollableTarget={itemsContainer.current}
            loader={
              Array(3)
              .map((e, i) => 
                <AddItemsLoader key={i}/>)
            }
          >
            <div className="items-in-order-container">
              {sets.map((item) => 
                <SetToBeAddedInCollection
                  key={item.id}
                  set={item} 
                  canBeAddedSelected={canBeAddedSelected} 
                  setCanBeAddedSelected={setCanBeAddedSelected} 
                />
              )}
            </div>
          </InfiniteScroll> 
        : null}
      </div>

      <AddItemsFooter
        canBeAddedSelected={canBeAddedSelected}
        tabIndex={tabIndex}
        cancelHandler={cancelHandler}
        confirmHandler={confirmHandler}
        selectedItemsTextOptions={['комплект', 'комплекта', 'комплектов']}
        noSelectedText={'Не выбрано ни одного комплекта'}
        />
    </Popup>
  );
}

export default AddSetsInCollection
import React, {useContext} from 'react';
import { useMemo } from 'react';
import { memo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import ManagersOnPage from '../../../_utility-components/manager-on-page';
import { ExtendedProduct } from '../dto/products.dto';
import {ProductPageContext} from "../product";

interface Props {
  pageIsCreatePage: boolean,
}

function ProductHeader({
  pageIsCreatePage
}: Props) {
  const productName: ExtendedProduct['name'] = useAppSelector(state => state.product)?.name || '';
  const {
    managersOnPage
  } = useContext(ProductPageContext)

  return (
    <div className="product-page-header">
      <div className={'product-page-header_left-inner'}>
        <ToProductsLink />

        <h2>
          {pageIsCreatePage ? 'Добавление товара' : productName}
        </h2>
      </div>

      <ManagersOnPage managersOnPage={managersOnPage}/>
    </div>
  );
}

const ToProductsLink = memo(() => {
  const productId = useRouteId('productid')
  const location = useLocation<{prevPage?: string}>()
  const tabIndex = useTabIndex()
  // const history = useHistory()

  const toParams = useMemo(() => ({
    pathname: '/app/products',
    search: location.state ? location.state.prevPage : '',
    state: {toProduct: productId}
  }), [location, productId])

  return (
    <Link
      tabIndex={tabIndex}
      to={toParams}
      className="to-products-btn hide-for-print"
      >
        <i className="icofont-simple-left"></i>
    </Link>
    // <MyButton
    //   tabIndex={tabIndex}
    //   removeDefaultClass
    //   onClick={() => {
    //     history.goBack()
    //   }}
    //   className="to-products-btn hide-for-print"
    // >
    //   <i className="icofont-simple-left"></i>
    // </MyButton>
  )
})

export default ProductHeader;